import React from 'react';

import PropTypes from 'prop-types';

import { Support } from '../../containers';
import './Auth.css';

const Auth = ({ children }) => {
  return (
    <div className="authentication-route">
      <div className="action-section">
        <Support language support />
      </div>
      {children}
    </div>
  );
};

Auth.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Auth;
