/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { createContext, useState, useRef } from 'react';

import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import PropTypes from 'prop-types';

export const ToolsContext = createContext({});

export const ToolsContextProvider = ({ children }) => {
  const toast = useRef(null);
  const manageModal = useRef(null);
  const modalRef = useRef(null);
  const manageTooler = useRef(null);
  const toolerRef = useRef(null);
  const caseRef = useRef(null);
  const [modal, setModal] = useState({ modalRef, manageModal, showModal: false });
  const [tooler, setTooler] = useState({ toolerRef, manageTooler, showTooler: false });
  const [displayTooler, setDisplayTooler] = useState({});

  const showSnackbar = (severity, summary, detail, delay = 4000) => {
    return toast?.current?.show({
      severity,
      summary,
      detail,
      life: delay,
    });
  };

  const showModal = (content, header) => {
    setModal({
      ...modal,
      manageModal: { content, header },
      showModal: true,
    });
  };

  const handleCloseModal = () => {
    setModal({
      ...modal,
      showModal: false,
    });
  };

  const showTooler = (content, header) => {
    setTooler({
      ...tooler,
      manageTooler: { content, header },
      showTooler: true,
    });
  };

  const handleCloseTooler = () => {
    setTooler({
      ...tooler,
      showTooler: false,
    });
  };

  return (
    <ToolsContext.Provider
      value={{
        showSnackbar,
        showModal,
        handleCloseModal,
        showTooler,
        handleCloseTooler,
        displayTooler,
        setDisplayTooler,
        caseRef,
        setTooler,
        tooler,
      }}
    >
      <Dialog
        className="modal"
        header={modal.manageModal.header}
        visible={modal.showModal}
        onHide={handleCloseModal}
      >
        {modal.manageModal.content}
      </Dialog>
      <Dialog
        header={tooler?.manageTooler?.header}
        className="toolForm"
        onHide={handleCloseTooler}
        visible={tooler.showTooler}
      >
        {tooler.manageTooler.content}
      </Dialog>
      <Toast ref={toast} className="toast" />
      {children}
    </ToolsContext.Provider>
  );
};

ToolsContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
