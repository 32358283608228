/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from 'react';

import { useHistory } from 'react-router-dom';

import notAuthorized from '../../assets/svgs/arts/undraw_401.svg';
import { Button } from '../../components';
import { AccountContext } from '../../contexts/AccountContext';

import './PageNotAuthorized.css';

export default function PageNotAuthorized() {
  const { tools, setTools } = useContext(AccountContext);
  const history = useHistory();
  const handleGoHome = () => {
    history.push('DASHBOARD');
  };

  useEffect(() => {
    setTools({ ...tools, showModal: false });
  }, []);

  return (
    <div className="page-not-authorized-wrapper">
      <div className="page-not-authorized-container">
        <img src={notAuthorized} className="page-not-authorized-img" alt="Not Authorized" />
        <p className="page-not-authorized-label">Page Not Authorized</p>
        <Button className="button page-not-authorized-btn" label="Go Home" onClick={handleGoHome} />
      </div>
    </div>
  );
}
