/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useContext } from 'react';

import PropTypes from 'prop-types';

import { Button, InputText, Dropdown, MultiSelect } from '../../components';
import { AccountContext } from '../../contexts/AccountContext';
import { useInput } from '../../hooks';
import { workSpaceService } from '../../services';
import regexs from '../../utils/regexs';

import './StateCaseForm.css';

const StateCaseForm = ({ data, handleSubmit, editing, caseTypeFather }) => {
  const [dirty, setDirty] = useState(false);
  const { accountInfo } = useContext(AccountContext);

  const appnameInput = useInput('id', data?.case_state_id || data?.id || '', [
    {
      validatorName: 'id-required',
      regex: regexs.required,
    },
  ]);

  const descriptionInput = useInput(
    'description',
    data?.case_state_description || data?.description || '',
    [
      {
        validatorName: 'description-required',
        regex: regexs.required,
      },
    ]
  );

  const newData = {
    id: appnameInput?.value || data?.id || '',
    description: descriptionInput?.value || data?.description || '',
  };

  return (
    <form onSubmit={(e) => handleSubmit(newData)}>
      <section className="add-app-section">
        <div className="new-app-container">
          <label className="inputs-label">Name ID</label>
          <InputText
            disabled={editing}
            spellCheck={false}
            value={appnameInput.value}
            {...appnameInput.bind}
            className={
              !appnameInput.isValid && appnameInput.wasTouched
                ? 'app-form-inputText input-required'
                : 'app-form-inputText'
            }
          />
        </div>

        <div className="new-app-container">
          <label className="inputs-label">Description</label>
          <InputText
            spellCheck={false}
            value={descriptionInput.value}
            {...descriptionInput.bind}
            className={
              !descriptionInput.isValid ? 'app-form-inputText input-required' : 'app-form-inputText'
            }
          />
        </div>
        <div className="flex-row" style={{ marginTop: '30px' }}>
          <Button
            className="app-form-button"
            label={data?.id ? 'Edit' : 'Create'}
            disabled={dirty}
          />
        </div>
      </section>
    </form>
  );
};

StateCaseForm.propTypes = {
  data: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  editing: PropTypes.bool.isRequired,
  caseTypeFather: PropTypes.any.isRequired,
};

export default StateCaseForm;
