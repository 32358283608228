/* eslint-disable react/prop-types */
/* eslint-disable new-cap */
/* eslint-disable no-case-declarations */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undef */
/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useContext } from 'react';

import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import * as XLSX from 'xlsx'; // Importar la biblioteca XLSX para procesar archivos XLSX

import batchesSamples from '../../assets/csv/100.csv';
import { Button, InputText, Dropdown, DataTable, Column } from '../../components';
import { AccountContext } from '../../contexts/AccountContext';
import { ResponsiveContext } from '../../contexts/ResponsiveContext';
import { batchesService } from '../../services';
import formSchema from './schema';
import './BatchForm.css';

const EMPTY_BATCH = { name: '', description: '', case_type: '' };

const BatchForm = ({ preBatch, loadBatch }) => {
  const [name, setName] = useState('');
  const [descript, setDescript] = useState(null);
  const [step, setStep] = useState(0);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [fileInfo, setFileInfo] = useState(null);
  const [fileData, setFileData] = useState(null);
  const { isMobile, isTablet } = useContext(ResponsiveContext);
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: 'contains' },
    name: {
      operator: '&&',
      constraints: [{ value: null, matchMode: 'starts_with' }],
    },
    'country.name': {
      operator: '&&',
      constraints: [{ value: null, matchMode: 'starts_with' }],
    },
    representative: { value: null, matchMode: 'in' },
    date: {
      operator: '&&',
      constraints: [{ value: null, matchMode: 'equals' }],
    },
    balance: {
      operator: '&&',
      constraints: [{ value: null, matchMode: 'equals' }],
    },
    status: {
      operator: '||',
      constraints: [{ value: null, matchMode: 'equals' }],
    },
    activity: { value: null, matchMode: 'between' },
  });

  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const { handleCloseModal, typesInfo, showSnackbar } = useContext(AccountContext);

  const [selectedCaseType, setSelectedCaseType] = useState(null);

  const resetInputFile = (e) => {
    e.stopPropagation();
    setFileInfo(null);
    setFileUploaded(false);
    setFileData(null);
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    const filters2 = { ...filters };

    filters2.global.value = value;

    setFilters(filters2);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="header-jobs">
        <div className="counter">
          <p style={{ margin: '2px 10px 4px 5px' }}>⚠️</p>
          <p className="amount-counter">{data.length}</p>
          <p>lines to process</p>
        </div>
        {data?.length && false && (
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Keyword Search"
              disabled
            />
          </span>
        )}
      </div>
    );
  };

  const header = renderHeader();

  // const processDataFromXLSX = (contents) => {
  //   const workbook = XLSX.read(contents, { type: 'binary' });
  //   const sheetName = workbook.SheetNames[0];
  //   const sheet = workbook.Sheets[sheetName];

  //   // Convertir el contenido de la hoja de cálculo en un arreglo de objetos
  //   const newData2 = XLSX.utils.sheet_to_json(sheet);

  //   return newData2;
  // };

  const handleCreateBatch = async () => {
    const payload = {
      case_type: selectedCaseType?.id,
      description: descript,
      batch_data: data,
      status: 'Unprocessed',
    };
    const { success, data: addInfo } = await batchesService.createBatch(payload);
    if (success) {
      loadBatch();
      showSnackbar(
        'success',
        '',
        `Batch ${addInfo.batchID} created. Inserted (${data?.length}/${addInfo?.result?.insertedCount}) lines!`,
        3000
      );
      handleCloseModal();
    } else {
      showSnackbar('error', '', 'Batch could not be created!', 3000);
    }
  };

  const handleDownload = () => {
    // Crear un enlace temporal para la descarga
    const downloadLink = document.createElement('a');
    downloadLink.href = batchesSamples;
    downloadLink.download = 'Sample.csv';

    // Simular un clic en el enlace para iniciar la descarga
    downloadLink.click();
  };

  const processDataFromCSV = (contents) => {
    const lines = contents?.split(/\r?\n/);
    const headers = lines[0].split(',');
    const newData3 = lines
      .slice(1)
      .map((line) => {
        const obj = {};
        if (line) {
          const fields = line.split(',');
          headers.forEach((header2, index) => {
            if (fields[index]) {
              obj[header2] = fields[index];
            }
          });
          return obj;
        }
        return null;
      })
      .filter((f) => {
        return f;
      });
    return newData3;
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const contents = e.target.result;
      let newData3 = [];
      // Procesar el archivo según su tipo (XLSX o CSV)
      if (file.name.endsWith('.csv')) {
        newData3 = processDataFromCSV(contents);
        setFileUploaded(true);
        setFileData(event.target.files[0]);
        const fileSizeMB = file.size / (1024 * 1024); // Convert bytes to megabytes
        const formattedSize = `${fileSizeMB.toFixed(2)} MB`;
        setFileInfo({
          name: file.name,
          size: formattedSize,
          lastModified: file.lastModifiedDate.toString().split('GMT')[0],
        });
      } else {
        showSnackbar('error', '', 'Only files in CSV format are allowed!', 5000);
        setFileInfo(null);
      }
      // Actualizar el estado con los datos procesados
      setData(newData3);
    };

    reader?.readAsText(file);
  };

  const onTypeChange = (e) => {
    setSelectedCaseType(e.value);
  };

  const rolesTemplate = (option) => {
    return <div className="flex option-backgroung">{option.description}</div>;
  };

  return (
    <section className="add-batch-section">
      {step === 0 && (
        <>
          <div className="batch-app-container">
            <label htmlFor="name" className="inputs-label">
              Name
            </label>
            <input
              id="name"
              name="name"
              autoComplete="off"
              spellCheck={false}
              onBlur={(e) => setName(e.target.value)}
              onChange={(e) => setName(e.target.value)}
              value={name}
              placeholder="Name batch"
              type="text"
              className="input-form"
            />
          </div>
          <div className="batch-app-container">
            <label htmlFor="name" className="inputs-label">
              Description
            </label>
            <input
              id="description"
              name="description"
              autoComplete="off"
              spellCheck={false}
              onBlur={(e) => setDescript(e.target.value)}
              onChange={(e) => setDescript(e.target.value)}
              value={descript}
              placeholder="Description batch"
              type="text"
              className="input-form"
            />
          </div>
          <div className="batch-app-container">
            <label className="inputs-label">Case Type</label>
            <div className="flex-row">
              <InputText
                disabled
                type="text"
                value={selectedCaseType?.description}
                className="app-form-inputText"
                placeholder="Select a case"
              />
              <Dropdown
                className="user-dropdown"
                value={selectedCaseType}
                options={typesInfo}
                onChange={onTypeChange}
                optionLabel="description"
                itemTemplate={rolesTemplate}
              />
            </div>
          </div>
          <label htmlFor="file-upload" className={!fileUploaded ? 'dropArea' : 'dropAreaFull'}>
            {!fileUploaded ? (
              <div className="batch-ct">
                <input id="file-upload" type="file" onChange={handleFileChange} />
                <h2>Add batch content</h2>
                <p>Click to select files</p>
                <p style={{ margin: '10px 0' }}>Download a sample file:</p>
                <Button
                  type="button"
                  className="link-btn"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDownload();
                  }}
                >
                  Sample File
                </Button>
              </div>
            ) : (
              <div>
                <p className="info-batch-file">
                  <strong className="header-batch">Name:</strong>
                  {fileInfo?.name}
                </p>
                <p className="info-batch-file">
                  <strong className="header-batch">Size:</strong>
                  {fileInfo?.size}
                </p>
                <p className="info-batch-file">
                  <strong className="header-batch">Last Modified:</strong>
                  {fileInfo?.lastModified}
                </p>
                <Button
                  className="clean-btn2"
                  icon="pi pi-trash"
                  style={{ margin: '0px' }}
                  onClick={(e) => resetInputFile(e)}
                >
                  Delete file
                </Button>
              </div>
            )}
          </label>
          <div className="footer-batch">
            <Button
              onClick={() => setStep((s) => s + 1)}
              className="app-batch-btn"
              label="Continue"
              disabled={!(name && descript && selectedCaseType && fileData)}
            />
          </div>
        </>
      )}
      {fileUploaded && step === 1 && data && (
        <section>
          <DataTable
            value={data}
            header={header}
            paginator={data?.length > 5}
            scrollable
            resizableColumns
            style={{
              width: '100%',
            }}
            scrollHeight="100%"
            className="p-datatable-striped"
            emptyMessage="No lines found."
          >
            {Object.keys(data[0])?.map((k) => {
              return (
                <Column
                  field={k}
                  header={k}
                  headerStyle={{
                    width: '100px',
                    textAlign: 'left',
                    color: 'black',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    background: '#9fc7c263',
                  }}
                  bodyStyle={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    background: '#9fc7c263',
                  }}
                />
              );
            })}
          </DataTable>
          <div className="footer-batch">
            <Button
              type="button"
              className="app-batch-back"
              onClick={() => {
                setStep((s) => s - 1);
              }}
              label="Back"
            />
            <Button onClick={handleCreateBatch} className="app-batch-btn" label="Create batch" />
          </div>
        </section>
      )}
    </section>
  );
};

export default BatchForm;
