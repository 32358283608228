/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';

import { useFormik } from 'formik';
import md5 from 'md5';
import PropTypes from 'prop-types';

import loginLogo from '../../assets/images/HoundLogo.png';
import { Button, InputText } from '../../components';
import { loginService } from '../../services';
import { setCookie } from '../../utils/tools';
import formSchema from './schema';

import './UpdateExternalPwd.css';

const EMPTY_PASSWORD = { new_pwd: '', confirmation_pwd: '' };

const UpdateExternalPwd = ({ data, handleCloseCancel, handleSubmitUpdate }) => {
  const [isAutenticated, setisAutenticated] = useState(null);
  const [loading, setLoading] = useState(false);
  const [encrytData, setEncrytData] = useState(null);
  const [failed, setFailed] = useState(false);
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    resetForm,
    setValues,
    isValid,
  } = useFormik({
    initialValues: EMPTY_PASSWORD,
    validationSchema: formSchema,
  });

  const checkPwd = async (event) => {
    event.preventDefault();
    if (event.target.value.length > 7) {
      setLoading(false);
      const encryt = md5(`${data?.username}/${event.target.value}/hound`);
      const { success, data: dateRepsonse } = await loginService?.createToken({
        username: data?.username,
        password: encryt,
      });
      // console.log(`${data?.username}/${event.target.value}/hound`);
      if (success) {
        setEncrytData({ new_pwd: md5(`${data?.username}/${values?.new_pwd}/hound`) });
        const { user: dataUser, access_token, expires_in } = dateRepsonse;
        setCookie(
          'credentials',
          JSON.stringify({
            userId: dataUser.id,
            accessToken: access_token,
            expiresIn: expires_in,
          })
        );
        setisAutenticated(true);
        setFailed(false);
      } else {
        setFailed(true);
        resetForm();
        setisAutenticated(false);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <section className="recovery-update-section">
        <div className="keep-row2">
          <img src={loginLogo} alt="Login Logo" className="lHeaderImg bounce" />
        </div>
        <h2>Update your password:</h2>
        <ul className="bullet-white">
          <li>- Must have at least 8 characters.</li>
          <li>- Must include at least one uppercase letter.</li>
          <li>- Must include at least one lowercase letter.</li>
          <li>- Must include at least one numeric digit.</li>
        </ul>
        <>
          <div className="update-pwd-container">
            <InputText
              spellCheck={false}
              id="new_pwd"
              name="new_pwd"
              type="password"
              className="pwd-input"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.new_pwd}
              autoComplete="off"
              placeholder="New password"
            />
            {errors?.new_pwd && touched?.new_pwd ? (
              <>
                <p className="pwd-error">
                  <img
                    style={{ fill: 'white', marginRight: '5px', marginBottom: '-2px' }}
                    src="https://houndsoftware.net/assetstmp/mib/alarm.svg"
                    alt=""
                  />
                  {errors?.new_pwd}
                </p>
              </>
            ) : (
              <></>
            )}
          </div>
          <div className="update-pwd-container">
            <InputText
              spellCheck={false}
              id="confirmation_pwd"
              name="confirmation_pwd"
              type="password"
              className="pwd-input"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.confirmation_pwd}
              autoComplete="off"
              placeholder="Confirm new password"
            />
            {values.new_pwd !== values.confirmation_pwd && touched?.confirmation_pwd ? (
              <p className="pwd-error">
                <img
                  style={{ fill: 'white', marginRight: '5px', marginBottom: '-2px' }}
                  src="https://houndsoftware.net/assetstmp/mib/alarm.svg"
                  alt=""
                />
                {errors?.confirmation_pwd}
              </p>
            ) : (
              <></>
            )}
          </div>
        </>

        <div className="keep-row">
          <Button className="back-btn" label="Go back" onClick={handleCloseCancel} />
          <Button
            type="button"
            disabled={values.new_pwd !== values.confirmation_pwd && !isAutenticated}
            className="update-btn-primary"
            label="Update"
            onClick={() => handleSubmitUpdate({ data, ...encrytData })}
          >
            {loading && <div className="loader2" />}
          </Button>
        </div>
      </section>
    </form>
  );
};

UpdateExternalPwd.propTypes = {
  data: PropTypes.object.isRequired,
  handleSubmitUpdate: PropTypes.func.isRequired,
  handleCloseCancel: PropTypes.func.isRequired,
};

export default UpdateExternalPwd;
