import { httpRequest, wrap } from './tools';

const getEngine = async (id) => {
  const fn = async () => {
    const url = `/engines/${id}`;
    const response = await httpRequest(url, 'GET', null, true);

    return response.data;
  };
  return wrap(fn);
};

const getEnginesByWorkspace = async (id) => {
  const fn = async () => {
    const url = `/workspaces/${id}/engines`;
    const response = await httpRequest(url, 'GET', null, true);

    return response.data;
  };

  return wrap(fn);
};

const createEngine = async (data) => {
  const fn = async () => {
    const url = `/engines`;
    const response = await httpRequest(url, 'POST', data, true);
    if (response.status === 201) {
      return 'Created';
    }
    return response.data;
  };

  return wrap(fn);
};

const updateEngine = async (data) => {
  const fn = async () => {
    const url = `/engines/${data.id}`;
    const response = await httpRequest(url, 'PATCH', data, true);

    return response.data;
  };

  return wrap(fn);
};

export { getEngine, getEnginesByWorkspace, updateEngine, createEngine };
