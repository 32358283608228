/* eslint-disable no-unused-vars */
import React from 'react';

import PropTypes from 'prop-types';

function QRViewer({ url, options = {} }) {
  // console.log('url, options', url, options);
  return <div>QRViewer</div>;
}

QRViewer.propTypes = {
  url: PropTypes.string.isRequired,
  options: PropTypes.object.isRequired,
};

export default QRViewer;
