/* eslint-disable radix */
/* eslint-disable no-unused-vars */
/* eslint-disable new-cap */
/* eslint-disable react/prop-types */
/* eslint-disable no-constant-condition */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';

import PropTypes from 'prop-types';
import { DebounceInput } from 'react-debounce-input';

import { MultiSelect, Button, Loader, DataTable, Column, StatsBar } from '../../../../components';
import { AccountContext } from '../../../../contexts/AccountContext';
import { ResponsiveContext } from '../../../../contexts/ResponsiveContext';
import {
  ViewCaseForm,
  ViewCaseStatusHistoryForm,
  ViewCaseInstancesForm,
  ConfirmDeleteInstanceForm,
} from '../../../../forms';
import { casesDashboardService } from '../../../../services';
import tools from '../../../../utils/time';
import { DEFAULT_FILTERS } from './constants';

import './CaseActivity.css';

const CaseActivity = ({
  instances,
  setInstances,
  setCaseStatus,
  setCaseCategory,
  setCaseOperationId,
  titleCase,
  searchCase,
  defaultStates,
  defaultStatus,
  showHistory,
  setShowHistory,
  reset,
}) => {
  const {
    tools: tooler,
    setTools,
    typesInfo,
    setTypesInfo,
    accountInfo,
    handleCloseModal,
    showSnackbar,
  } = useContext(AccountContext);
  const { isMobile } = useContext(ResponsiveContext);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedCasesStatus, setSelectedCasesStatus] = useState([]);
  const [idFilter, setIdFilter] = useState(searchCase);
  const [loading, setLoading] = useState(false);
  const [disableClear, setDisableClear] = useState(false);
  const [filters, setFilters] = useState(DEFAULT_FILTERS);

  const getListCasesTypes = async () => {
    const { success, data: CasesInfo } = await casesDashboardService.listCasesTypes();
    if (success) {
      setTypesInfo(CasesInfo);
      setLoading(false);
    }
  };

  const handleGetStatusCode = () => {
    return selectedCasesStatus?.map((c) => {
      return c;
    });
  };

  const handleGetCategoryCode = () => {
    return selectedCategory?.map((c) => {
      return c;
    });
  };

  useEffect(() => {
    getListCasesTypes();
  }, []);

  useEffect(() => {
    setCaseCategory(selectedCategory);
    setCaseStatus(selectedCasesStatus);
    setCaseOperationId(idFilter.trim());
  }, [selectedCategory, selectedCasesStatus, idFilter]);

  useEffect(() => {
    setLoading(true);
    setFilters({
      id: { value: idFilter, matchMode: 'contains' },
      state: { value: handleGetCategoryCode(), matchMode: 'in' },
      status: { value: handleGetStatusCode(), matchMode: 'in' },
    });
    setTimeout(() => {
      if (selectedCasesStatus?.length || selectedCategory?.length || idFilter !== '') {
        setDisableClear(false);
      } else {
        setDisableClear(true);
      }
    }, 200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCasesStatus, selectedCategory, idFilter]);

  const FiltersActivities = ({ title2 }) => {
    const handleReset = () => {
      setSelectedCasesStatus(null);
      setSelectedCategory(null);
      setIdFilter('');
    };

    const handleShowHistory = () => {
      setLoading(true);
      setShowHistory((t) => !t);
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    };

    const cols = [
      { field: 'id', header: 'TicketId' },
      { field: 'status', header: 'Status' },
      { field: 'state', header: 'States' },
    ];

    const exportColumns = cols.map((col) => {
      return {
        title: col.header,
        dataKey: col.field,
      };
    });

    const exportPdf = () => {
      import('jspdf').then((jsPDF) => {
        import('jspdf-autotable').then(() => {
          const doc = new jsPDF.default(0, 0);
          doc.autoTable(exportColumns, instances);
          doc.save(`Tickets-${accountInfo?.userData?.name}-${title2}-${new Date()}.pdf`);
        });
      });
    };

    return (
      <div className="search-activities-wrapper">
        <div className="history-A-search">
          <DebounceInput
            style={{ marginLeft: '15px' }}
            className="buttonFilter"
            value={idFilter}
            debounceTimeout={700}
            onChange={(e) => setIdFilter(e.target.value)}
            placeholder="Search case ID"
            autoComplete="off"
          />
        </div>
        <MultiSelect
          optionLabel="value"
          placeholder={isMobile ? '' : 'Status'}
          className="history-A-dropdown"
          value={selectedCasesStatus}
          options={defaultStatus}
          onChange={(e) => {
            setSelectedCasesStatus(e.value);
          }}
          filter
          fixedPlaceholder
        />
        <MultiSelect
          optionLabel="value"
          placeholder={isMobile ? '' : 'State'}
          className="history-A-dropdown"
          value={selectedCategory}
          options={defaultStates}
          onChange={(e) => {
            setSelectedCategory(e.value);
          }}
          filter
          fixedPlaceholder
        />
        <Button
          title={showHistory ? 'See active cases' : 'See history'}
          icon="pi pi-book"
          className="history-A-clear"
          onClick={handleShowHistory}
        />
        <Button
          disabled={disableClear}
          title="Clear search"
          icon="pi pi-trash"
          className="history-A-clear"
          onClick={handleReset}
        />
        <Button
          disabled={instances?.length === 0}
          title="Download"
          type="button"
          icon="pi pi-file-pdf"
          className="pdf"
          rounded
          onClick={(e) => {
            e.stopPropagation();
            exportPdf(`Tickets-${accountInfo?.userData?.name}-${new Date()}.pdf`);
          }}
        />
        <Button
          type="button"
          icon="pi pi-refresh"
          className="pdf"
          onClick={() => {
            reset();
            setLoading(true);
            setTimeout(() => {
              setLoading(false);
            }, 2000);
          }}
        />
      </div>
    );
  };

  const TableActivities = () => {
    const HeaderForm = ({ title, iconName }) => {
      return <div className="activity-header">{title}</div>;
    };

    const showCaseDetail = async (caseId) => {
      const instanceResult = await casesDashboardService.getCase(caseId);

      setTools({
        ...tooler,
        manageModal: {
          header: <HeaderForm title="App Case" iconName="pi-wallet" />,
          content: (
            <ViewCaseForm
              data={instanceResult.data}
              title="Case Detail"
              handleSubmit={(e) => {
                // console.log(e);
              }}
            />
          ),
        },
        showModal: true,
      });
    };

    const showCaseStatusHistory = async (caseId) => {
      const caseResult = await casesDashboardService.getCase(caseId);
      setTools({
        ...tools,
        manageModal: {
          header: <HeaderForm title="Case Status History" iconName="pi-clock" />,
          content: <ViewCaseStatusHistoryForm data={caseResult.data} />,
        },
        showModal: true,
      });
    };

    const showCaseInstances = async (caseId) => {
      const caseResult = await casesDashboardService.getCase(caseId);
      setTools({
        ...tools,
        manageModal: {
          header: <HeaderForm title="Case App Instances" iconName="pi-compass" />,
          content: <ViewCaseInstancesForm data={caseResult.data} />,
        },
        showModal: true,
      });
    };

    const onDeleteInstance = async (appId) => {
      setLoading(true);
      const { success } = await casesDashboardService.cancelCase({ id: appId });
      if (success) {
        showSnackbar('success', '', 'Instance deleted succesfully');
        setInstances((i) => {
          return i.map((ins) => {
            return appId === ins.id ? { ...ins, status: 'COMPLETED', state: 'Denegado' } : ins;
          });
        });
      } else {
        showSnackbar('error', '', 'Something went wrong deleting the app! Please try again');
      }
      handleCloseModal();
      setTimeout(() => {
        setLoading(false);
      }, 1200);
    };

    const showConfirmationDeleteApp = (id) => {
      setTools({
        ...tools,
        manageModal: {
          content: (
            <ConfirmDeleteInstanceForm
              onDeleteApp={() => onDeleteInstance(id)}
              onCloseModal={handleCloseModal}
            />
          ),
        },
        showModal: true,
      });
    };

    const actionTemplate = (node) => {
      if (node?.category) {
        return (
          <>
            <Button
              type="button"
              icon="pi pi-wallet"
              className="p-button-success action-btn"
              onClick={() => {
                showCaseDetail(node.id);
              }}
            />
            {/* <Button
              type="button"
              icon="pi pi-clock"
              className="p-button-success action-btn"
              onClick={() => {
                showCaseStatusHistory(node.id);
              }}
            /> */}
            {/* <Button
              type="button"
              icon="pi pi-compass"
              className="p-button-success action-btn"
              onClick={() => {
                showCaseInstances(node.id);
              }}
            /> */}
            {node.status === 'PROGRESS' ? (
              <Button
                type="button"
                icon="pi pi-trash"
                className="p-button-success action-btn"
                onClick={() => {
                  showConfirmationDeleteApp(node.id);
                }}
              />
            ) : (
              <></>
            )}
          </>
        );
      }
    };

    const ConditionColumn = ({ creationDate }) => {
      // console.log('creationDate', new Date(creationDate).getUTCMilliseconds());
      const a = parseInt(new Date(creationDate).getUTCMilliseconds());
      const difference = tools.timeSince(a);
      return (
        <div className="flex-rows">
          <div className={`dot2 ${'yellow'}`} title="Created since">
            <p className="dot-value">{difference}</p>
          </div>
        </div>
      );
    };

    return (
      <div className="table-box">
        {!loading ? (
          <div className="modeler-table4">
            <DataTable
              showFilterMenu
              className="p-datatable-striped"
              value={instances}
              paginator
              rows={isMobile ? 13 : 17}
              responsiveLayout="scroll"
              resizableColumns
              filters={filters}
              emptyMessage="No cases found."
              scrollable
              selectionMode="single"
              exportCSV
              sortField="creationDate"
              sortOrder={-1}
              removableSort
              // onSelectionChange={(e) => showCaseInstances(e.value)}
            >
              <Column
                field="creationDate"
                filterField="id"
                header="Time"
                headerStyle={{
                  width: '40px',
                  minWidth: '40px',
                  maxWidth: '40px',
                  textAlign: 'center',
                  backgroundColor: '#22384d',
                  color: 'white',
                }}
                bodyStyle={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textAlign: 'center',
                }}
                body={ConditionColumn}
                sortable
              />
              <Column
                field="id"
                filterField="id"
                header="Case ID"
                headerStyle={{
                  width: '90px',
                  minWidth: '90px',
                  textAlign: 'center',
                  backgroundColor: '#22384d',
                  color: 'white',
                }}
                bodyStyle={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textAlign: 'center',
                }}
              />
              <Column
                field="state.id"
                header="State"
                headerStyle={{
                  width: '100px',
                  minWidth: '100px',
                  textAlign: 'center',
                  backgroundColor: '#22384d',
                  color: 'white',
                }}
                bodyStyle={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textAlign: 'center',
                }}
                sortable
              />
              <Column
                field="status"
                filterField="status"
                header="Status"
                headerStyle={{
                  width: '125px',
                  minWidth: '60px',
                  maxWidth: '100px',
                  textAlign: 'center',
                  backgroundColor: '#22384d',
                  color: 'white',
                }}
                bodyStyle={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textAlign: 'center',
                }}
                sortable
              />
              <Column
                header="Actions"
                body={actionTemplate}
                headerStyle={{
                  width: '110px',
                  minWidth: '110px',
                  maxWidth: '110px',
                  textAlign: 'center',
                  backgroundColor: '#22384d',
                  color: 'white',
                }}
                bodyStyle={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textAlign: 'center',
                }}
              />
            </DataTable>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  };
  const title = typesInfo.filter((f) => f.id === titleCase)[0]?.description;

  return (
    <div className="activities-wrapper">
      {!loading ? (
        <>
          <FiltersActivities title2={title} />
          <StatsBar defaultStates={defaultStates} defaultStatus={defaultStatus} />
          <TableActivities />
        </>
      ) : (
        <div className="load-flow">
          <Loader fit transparant />
        </div>
      )}
    </div>
  );
};

CaseActivity.propTypes = {
  instances: PropTypes.array.isRequired,
  setCaseStatus: PropTypes.func.isRequired,
  setCaseCategory: PropTypes.func.isRequired,
  setCaseOperationId: PropTypes.func.isRequired,
  titleCase: PropTypes.string.isRequired,
  searchCase: PropTypes.string.isRequired,
};

export default CaseActivity;
