/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react';

import Moment from 'moment';
import { useHistory } from 'react-router-dom';

import listEmptyImage from '../../assets/svgs/arts/undraw_apps.svg';
import {
  Button,
  Column,
  GoBackComponent,
  Calendar,
  DataTable,
  Loader,
  MultiSelect,
} from '../../components';
import { AccountContext } from '../../contexts/AccountContext';
import { ViewStageForm } from '../../forms';
import { appDashboardService } from '../../services';

import './AppsHistory.css';

const STATUS = {
  UNPROCESSED: 'UNPROCESSED',
  RUNNING: 'RUNNING',
  PROGRESS: 'PROGRESS',
  DONE: 'DONE',
  COMPLETED: 'COMPLETED',
  INCOMPLETE: 'INCOMPLETE',
  ERROR: 'ERROR',
  DELETED: 'DELETED',
  PREUNLOADED: 'PRE-UNLOADED',
};

const STATUS_COLORS = {
  UNPROCESSED: '#a1a1a1',
  RUNNING: '#FFCC00', // Amarillo brillante
  PROGRESS: '#FFCC00',
  DONE: '#87A0B2', // Naranja brillante
  COMPLETED: '#A4DD74', // Verde lima
  INCOMPLETE: '#868C9C',
  ERROR: 'RED', // Azul claro
  PREUNLOADED: '#a1a1a1',
};

const DEFAULT_STATUS = [
  { name: 'RUNNING', code: 1 },
  { name: 'ERROR', code: 2 },
  { name: 'HOLD', code: 3 },
  { name: 'DONE', code: 4 },
];

const DEFAULT_FILTERS = {
  startDate: { value: null, matchMode: 'contains' },
  endDate: { value: null, matchMode: 'contains' },
  status: { value: null, matchMode: 'in' },
};

const AppsHistory = (prop) => {
  const history = useHistory();
  const { tools, setTools, selectedApp, handleTask } = useContext(AccountContext);
  const [apps, setApps] = useState([]);
  const [rangeDates, setRangeDates] = useState('');
  const [selectedCasesStatus, setSelectedCasesStatus] = useState([]);
  const [loading, setLoading] = useState(true);
  const [disableClear, setDisableClear] = useState(false);
  const [filters, setFilters] = useState(DEFAULT_FILTERS);
  const [refreshTable, setRefreshTable] = useState(true);

  const handleReset = () => {
    setSelectedCasesStatus(null);
    setRangeDates('');
  };

  const handleGetStatusCode = () => {
    return selectedCasesStatus?.map((c) => c.name);
  };

  const getMinDate = () => {
    return '';
  };

  const getMaxDate = () => {
    return '';
  };

  const ListEmptyState = () => {
    return (
      <div className="list-empty-wrapper bounce">
        <div className="detail-container">
          <img className="detail-img" src={listEmptyImage} alt="Search a case" />
          <p className="detail-message">No instances found!</p>
        </div>
      </div>
    );
  };

  const handleGoBack = (preLoad) => {
    history.push({ pathname: '/apps', goBack: preLoad });
  };

  const fetchAppInstances = async () => {
    setLoading(true);
    const startRange = getMinDate();
    const endRange = getMaxDate();
    const start = startRange ? Moment(startRange).format('YYYY-MM-DDTHH:mm:ss') : null;
    const end = endRange ? Moment(endRange).format('YYYY-MM-DDTHH:mm:ss') : null;
    const { success, data: appsInstancesResult } = await appDashboardService.listInstancesOfApp(
      selectedApp.id,
      start,
      end,
      selectedCasesStatus
    );
    if (success && appsInstancesResult.length > 0) {
      setApps(appsInstancesResult);
    } else {
      handleGoBack(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (filters) {
      setRefreshTable(true);
      setTimeout(() => {
        setRefreshTable(false);
      }, 200);
    }
  }, [filters]);

  useEffect(() => {
    setFilters({
      startDate: { value: getMinDate(), matchMode: 'contains' },
      endDate: { value: getMaxDate(), matchMode: 'contains' },
      status: { value: handleGetStatusCode(), matchMode: 'in' },
    });
    setTimeout(() => {
      if (selectedCasesStatus?.length || rangeDates?.length) {
        setDisableClear(false);
      } else {
        setDisableClear(true);
      }
    }, 200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCasesStatus, rangeDates]);

  const showStageDetail = async (stageId) => {
    const { success, data } = await appDashboardService.getStageOfInstanceOfApp(
      selectedApp.id,
      selectedApp.id,
      stageId
    );

    if (success) {
      setTools({
        ...tools,
        manageModal: {
          content: (
            <ViewStageForm
              data={data}
              title="Stage Detail"
              handleSubmit={() => {
                handleTask();
              }}
            />
          ),
        },
        showModal: true,
      });
    }
  };

  const statusData = [
    <p className="chip">Running</p>,
    <p className="chip">Error</p>,
    <p className="chip">Hold</p>,
    <p className="chip">Done</p>,
  ];

  const actionTemplate = (node) => {
    return (
      <div>
        <Button
          type="button"
          icon="pi pi-eye"
          className="p-button-success action-btn"
          onClick={() => {
            showStageDetail(node.id);
            // handleTask();
          }}
        />
      </div>
    );
  };

  const statusTemplate = (option) => {
    return <div>{statusData[option.code - 1]}</div>;
  };

  useEffect(() => {
    fetchAppInstances();
  }, []);

  const handleDates = (value) => {
    if (value.length === 2) {
      setRangeDates(value);
    }
  };

  const StatusColumn = ({ status: codeStatus }) => {
    let styling = STATUS_COLORS.DELETED;
    let opa = 1;
    switch (codeStatus) {
      case STATUS.UNPROCESSED:
        styling = STATUS_COLORS.UNPROCESSED;
        break;
      case STATUS.RUNNING:
        styling = STATUS_COLORS.RUNNING;
        break;
      case STATUS.ONGOING:
        styling = STATUS_COLORS.RUNNING;
        break;
      case STATUS.WARNING:
        styling = STATUS_COLORS.WARNING;
        break;
      case STATUS.COMPLETED:
        styling = STATUS_COLORS.COMPLETED;
        break;
      case STATUS.DONE:
        styling = STATUS_COLORS.COMPLETED;
        break;
      case STATUS.DELETED:
        styling = STATUS_COLORS.DELETED;
        opa = 0.2;
        break;
      case STATUS.ERROR:
        styling = STATUS_COLORS.ERROR;
        opa = 1;
        break;
      case STATUS.PREUNLOADED:
        styling = STATUS_COLORS.PREUNLOADED;
        opa = 1;
        break;
      case STATUS.PROGRESS:
        styling = STATUS_COLORS.PROGRESS;
        opa = 1;
        break;

      default:
        styling = STATUS_COLORS.DELETED;
        break;
    }
    return (
      <div className="batchChip" style={{ background: styling, opacity: opa }}>
        {codeStatus || '-'}
      </div>
    );
  };

  const formatDate = (date) => {
    if (!date || date === 'Invalid date') {
      return '-';
    }
    const newDate = new Date(date);
    const opciones = {
      month: 'numeric', // Nombre completo del mes (por ejemplo, "enero")
      day: 'numeric', // Día del mes (por ejemplo, "26")
      hour: 'numeric', // Hora (por ejemplo, "13")
      year: 'numeric', // Hora (por ejemplo, "13")
      minute: 'numeric', // Minutos (por ejemplo, "45")
    };

    // Formatear la fecha
    try {
      const formatoFecha = new Intl.DateTimeFormat('en-EN', opciones).format(newDate);
      return formatoFecha;
    } catch (error) {
      return date;
    }
  };

  const CreationDateColumn = ({ startDate }) => {
    return startDate ? formatDate(startDate) : '-';
  };

  const EndDateColumn = ({ endDate }) => {
    return endDate ? formatDate(endDate) : '-';
  };

  const InstanceColumn = ({ id }) => {
    return <span className="instaneID-cls">{id}</span>;
  };
  const handleSeeInstance = (instance) => {
    history.push({ pathname: `/instances/${instance.id}`, instance });
    // history.push({ pathname: `/cases/${instance.case_id}`, caseData });
  };

  const rowBodyTemplate = (rowData, props) => {
    return <div className="secuencial-cls">{props.rowIndex + 1}</div>;
  };

  return (
    <div className="apps-history-wrapper">
      <div className="report-header">
        <GoBackComponent
          isWhite={false}
          title={`${apps[0]?.name || ''} Instances` || 'Application'}
          callback="/apps"
        />
      </div>
      {!loading ? (
        <div className="apps-container">
          {apps ? (
            <div className="myticket-wrapper">
              <div className="search-wrapper">
                <Calendar
                  placeholder="Select a range"
                  selectionMode="range"
                  className="range-picker"
                  readOnlyInput
                  hourFormat="24"
                  value={rangeDates}
                  onChange={(e) => handleDates(e.value)}
                  dateFormat="yy-mm-dd"
                />
                <MultiSelect
                  optionLabel="name"
                  placeholder="Status"
                  className="history-A-dropdown"
                  value={selectedCasesStatus}
                  options={DEFAULT_STATUS}
                  itemTemplate={statusTemplate}
                  onChange={(e) => setSelectedCasesStatus(e.value)}
                  maxSelectedLabels={1}
                  showClear
                  fixedPlaceholder
                />
                <Button
                  disabled={disableClear}
                  title="Clear filters"
                  icon="pi pi-trash"
                  className="history-A-clear"
                  onClick={handleReset}
                />
              </div>
              {!refreshTable ? (
                <DataTable
                  className="p-datatable-striped"
                  value={apps?.reverse()}
                  resizableColumns
                  emptyMessage="No Apps found."
                  rows={10}
                  paginator={apps?.length > 10}
                  globalFilterFields={['startDate', 'endDate', 'status']}
                  filters={filters}
                  scrollable
                  selectionMode="single"
                  onSelectionChange={(e) => handleSeeInstance(e.value)}
                >
                  <Column
                    header=""
                    headerStyle={{
                      maxWidth: '3vw',
                      minWidth: '50px',
                      width: '3vw',
                      textAlign: 'center',
                      backgroundColor: '#22384d',
                      color: 'white',
                    }}
                    bodyStyle={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textAlign: 'center',
                    }}
                    body={rowBodyTemplate}
                  />
                  <Column
                    field="id"
                    header="Instance ID"
                    body={InstanceColumn}
                    headerStyle={{
                      textAlign: 'left',
                      backgroundColor: '#22384d',
                      color: 'white',
                      maxWidth: '7vw',
                      minWidth: '150px',
                      width: '7vw',
                    }}
                    bodyStyle={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textAlign: 'left',
                    }}
                  />
                  <Column
                    field="status"
                    filterField="status"
                    header="Status"
                    sortable
                    headerStyle={{
                      textAlign: 'left',
                      backgroundColor: '#22384d',
                      color: 'white',
                      maxWidth: '7vw',
                      minWidth: '120px',
                      width: '7vw',
                    }}
                    bodyStyle={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textAlign: 'left',
                      height: '40px',
                    }}
                    body={StatusColumn}
                  />
                  <Column
                    field="startDate"
                    filterField="startDate"
                    header="Start"
                    headerStyle={{
                      textAlign: 'left',
                      backgroundColor: '#22384d',
                      color: 'white',
                      maxWidth: '12vw',
                      minWidth: '140px',
                      width: '12vw',
                    }}
                    sortable
                    bodyStyle={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textAlign: 'left',
                    }}
                    body={CreationDateColumn}
                  />
                  <Column
                    field="endDate"
                    filterField="endDate"
                    header="End"
                    headerStyle={{
                      textAlign: 'left',
                      backgroundColor: '#22384d',
                      color: 'white',
                      maxWidth: '12vw',
                      minWidth: '140px',
                      width: '12vw',
                    }}
                    sortable
                    bodyStyle={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textAlign: 'left',
                      height: '40px',
                    }}
                    body={EndDateColumn}
                  />
                  {/* <Column
                    field="statistics.executedPoints"
                    header="Executed Points"
                    sortable
                    headerStyle={{
                      textAlign: 'center',
                      backgroundColor: '#22384d',
                      color: 'white',
                      width: '170px',
                    }}
                    style={{ textAlign: 'center' }}
                    bodyStyle={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textAlign: 'center',

                      height: '40px',
                    }}
                  /> */}
                  {/* <Column
                      header="Actions"
                      body={actionTemplate}
                      headerStyle={{
                        textAlign: 'center',
                        backgroundColor: '#22384d',
                        color: 'white',
                        width: '5.5rem',
                      }}
                      style={{ textAlign: 'center' }}
                    /> */}
                </DataTable>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <ListEmptyState />
          )}
        </div>
      ) : (
        <Loader fit transparant />
      )}
    </div>
  );
};

export default AppsHistory;
