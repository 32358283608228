/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
/* eslint-disable no-empty-pattern */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState, useRef } from 'react';

import moment from 'moment-timezone';
import { Dialog } from 'primereact/dialog';
import { DebounceInput } from 'react-debounce-input';
import { useHistory } from 'react-router-dom';

import RefreshIcon from '../../assets/images/refresh.png';
import QrIcon from '../../assets/svgs/qr.svg';
import { Loader } from '../../components';
import { Button, DataTable, Column } from '../../components/BasicComponents';
import { AccountContext } from '../../contexts/AccountContext';
import { FrontdeskForm } from '../../forms';
import { batchesService, casesDashboardService, formsService } from '../../services';
import { handleCopyClipboard } from '../../utils/utils';
import FileDisplayer from '../Tooler/FileDisplayer/FileDisplayer';
import './FormsBlock.css';

function FormsBlock() {
  const history = useHistory();
  const manageDisplayFileRef = useRef(null);
  const displayFileRef = useRef(null);
  const [isLoading, setIsloading] = useState(false);
  const [formsFilter, setFormsFilter] = useState('');
  const [displayerFile, setDisplayerFile] = useState({
    displayFileRef,
    manageDisplayFileRef,
    showDisplayFile: false,
  });
  const {
    accountInfo,
    setTools,
    tools: tooler,
    handleCloseModal,
    showSnackbar,
    setTypesInfo,
    frontDeskForms,
    setFrontDeskForms,
    menuCollapse,
  } = useContext(AccountContext);
  const { userData } = accountInfo;

  const getListForms = async () => {
    setIsloading(true);
    const { success, data: formsList } = await formsService.listForms(userData.workspace.id);
    if (success) {
      setFrontDeskForms(formsList.data);
    } else {
      setFrontDeskForms(null);
    }
    setIsloading(false);
  };

  const getListCasesTypes = async () => {
    const { success, data: casesInfo } = await casesDashboardService.listCasesTypes();
    if (success) {
      const filter = casesInfo.filter((p) => p.id !== 'TBD');
      setTypesInfo(filter);
    }
  };

  useEffect(() => {
    getListForms();
    getListCasesTypes();
  }, []);

  const handleReset = () => {
    // setSelectedCasesGroups([]);
    setFormsFilter('');
  };

  const onExeApp = async (batchID) => {
    const { success } = await batchesService.executeBatch(batchID);
    handleCloseModal();
    if (success) {
      showSnackbar('success', '', 'Batch in progress.', 4000);
    } else {
      showSnackbar('error', '', 'Batch not executed.', 4000);
    }
    getListForms();
  };

  const onStopApp = () => {
    showSnackbar('info', '', "You're not able to stop the batch.", 4000);
  };

  // const exeStopForm = <BatchStopForm onExeApp={onStopApp} onCloseModal={handleCloseModal} />;

  const showModal = (content, header) => {
    setTools({
      ...tooler,
      manageModal: { content, header },
      showModal: true,
    });
  };

  const formatDate = (date) => {
    if (date) {
      const newDate = new Date(date);
      const opciones = {
        month: 'numeric', // Nombre completo del mes (por ejemplo, "enero")
        day: 'numeric', // Día del mes (por ejemplo, "26")
        hour: 'numeric', // Hora (por ejemplo, "13")
        year: 'numeric', // Hora (por ejemplo, "13")
        minute: 'numeric', // Minutos (por ejemplo, "45")
      };

      // Formatear la fecha
      const formatoFecha = new Intl.DateTimeFormat('en-EN', opciones).format(newDate);
      return formatoFecha;
    }
    return date;
  };

  const ConditionColumn = ({ created }) => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const localDate = new Date(
      created.toLocaleString('en-US', {
        timeZone: userTimeZone,
        hour12: true,
      })
    );
    const now = new Date().toLocaleDateString('en-US', {
      timeZone: userTimeZone,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true, // Opcional: usa el formato de 24 horas
    });
    // Formatear y mostrar la hora local
    // console.log('Hora local:', localTime.format('YYYY-MM-DD HH:mm:ss'));
    const formattedLocalDate = localDate
      .toISOString()
      .slice(0, 19)
      .replace('T', ' ')
      .replaceAll('-', '/');
    let now2 = new Date(
      `${formattedLocalDate} ${formatDate(created).slice(-2)}`
    ).toLocaleDateString('en-US', {
      timeZone: userTimeZone,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true, // Opcional: usa el formato de 24 horas
    });

    if (now2 === 'Invalid Date') {
      now2 = new Date(`${formattedLocalDate}`).toLocaleDateString('en-US', {
        timeZone: userTimeZone,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true, // Opcional: usa el formato de 24 horas
      });
    }

    // Convertir las fechas a objetos moment
    const momentDate1 = moment(now.split(', ').join(' '), 'MM/DD/YYYY hh:mm:ss A');
    const momentDate2 = moment(now2.split(', ').join(' '), 'MM/DD/YYYY hh:mm:ss A');

    const differenceInSeconds =
      momentDate1.diff(momentDate2, 'seconds') || momentDate2.diff(momentDate1, 'seconds');
    // Función timeSince
    const timeSince = (seconds) => {
      let difference = 'now';

      const duration = moment.duration(seconds, 'seconds');
      const days = Math.floor(duration.asDays());
      if (days < 1) {
        const hours = Math.floor(duration.asHours()) - days * 24;
        if (hours < 1) {
          const mins = Math.floor(duration.asMinutes()) - days * 24 * 60 - hours * 60;
          if (mins < 1) {
            const sec =
              Math.floor(duration.asSeconds()) - days * 24 * 60 * 60 - hours * 60 * 60 - mins * 60;
            if (sec > 1) {
              difference = `${sec}s`;
            }
          } else {
            difference = `${mins}m`;
          }
        } else {
          difference = `${hours}h`;
        }
      } else {
        difference = `${days}d`;
      }

      return difference;
    };

    // Aplicar la función timeSince
    const timeDifference = timeSince(differenceInSeconds);

    return (
      <div className="flex-rows">
        <div className={`dot2 green `} title={`Created: ${created}`}>
          <p className="dot-value">{timeDifference}</p>
        </div>
      </div>
    );
  };

  const addForm = <FrontdeskForm refreshFrontdeskForms={getListForms} />;

  const showDisplayerFile = (content, header) => {
    setDisplayerFile({
      ...displayerFile,
      manageDisplayFileRef: { content, header },
      showDisplayFile: true,
    });
  };

  const handleCloseDisplayerFile = () => {
    setDisplayerFile({
      ...displayerFile,
      showDisplayFile: false,
    });
  };

  const ActionColumn = (event) => {
    return (
      <div className="action-wrapper">
        <Button
          type="button"
          title="Scan QR"
          className="exe-btn"
          onClick={(e) => {
            e.stopPropagation();
            const FileContent = (
              <FileDisplayer
                event={{
                  isDisplayQR: true,
                  stylescls: 'qr-class',
                  file: { link: event.link, name: event.name },
                  isDownloadable: true,
                }}
                handleCloseDisplayerFile={handleCloseDisplayerFile}
              />
            );
            showDisplayerFile(FileContent, null);
          }}
        >
          <img className="qr-code" src={QrIcon} alt="qr" />
        </Button>
        <Button
          title="Copy link"
          icon="pi pi-share-alt"
          type="button"
          className="exe-btn"
          onClick={(e) => {
            e.stopPropagation();
            handleCopyClipboard(event.link);
            showSnackbar('info', '', 'Form URL copied!', 3000);
          }}
        />
        <Button
          title="Edit Form"
          type="button"
          icon="pi pi-pencil"
          className="exe-btn"
          onClick={(e) => {
            e.stopPropagation();
            showModal(
              <FrontdeskForm refreshFrontdeskForms={getListForms} isEditing data={event} />,
              <HeaderAddApp title="⚠️ Edit frontdesk form" />
            );
          }}
        />
      </div>
    );
  };

  const HeaderAddApp = ({ title }) => {
    return <div className="activity-header bounce">{title}</div>;
  };

  const filtered = frontDeskForms?.filter((b) => {
    return b?.formId?.includes(formsFilter);
  });

  const rowBodyTemplate = (rowData, props) => {
    return <div className="secuencial-cls">{props.rowIndex + 1}</div>;
  };

  const handleSeeForms = (formData) => {
    if (formData?.link) {
      window.open(formData?.link, '_blank');
    } else {
      showSnackbar('info', '', 'Currently, this form does not have a link assigned to it!', 5000);
    }
  };

  const joinArray = formsFilter !== '' ? filtered : frontDeskForms;
  const reversedList = joinArray?.reduce((acc, val) => [val, ...acc], []);

  return !isLoading ? (
    <div className={menuCollapse ? 'cases-wrp2' : 'cases-wrp2-ext'}>
      <div className="cases-container" style={{ padding: '0px' }}>
        <Dialog
          closable={false}
          header={displayerFile?.manageDisplayFileRef?.header}
          className="displayer-file"
          onHide={handleCloseDisplayerFile}
          visible={displayerFile?.showDisplayFile}
        >
          {displayerFile?.manageDisplayFileRef.content}
        </Dialog>
        <div className="search-wrapper">
          <Button
            className="add-ticket-btn"
            icon="pi pi-plus"
            onClick={() => {
              showModal(addForm, <HeaderAddApp title="New frontdesk form" />);
            }}
          />

          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <DebounceInput
              id="searchTicket-input"
              className="buttonFilter2"
              value={formsFilter}
              debounceTimeout={700}
              onChange={(e) => setFormsFilter(e.target.value)}
              placeholder="Form ID"
              autoComplete="off"
            />
            {formsFilter && (
              <Button
                type="button"
                title="Clear form"
                className="pi pi-trash adjust-trash2"
                onClick={handleReset}
              />
            )}
          </span>
          <Button
            title="Refresh tickets"
            className="refresh-basket"
            onClick={() => {
              showSnackbar('success', '', 'Forms refreshed!', 2000);
              getListForms();
            }}
          >
            <img className="refresh-img" src={RefreshIcon} alt="refresh tickets button" />
          </Button>
        </div>
        <DataTable
          className="p-datatable-striped"
          emptyMessage="No frontdesk forms found."
          value={reversedList}
          responsiveLayout="scroll"
          paginator={joinArray?.length > 10}
          rows={10}
          resizableColumns
          scrollable
          scrollHeight="80vh"
          selectionMode="single"
          onSelectionChange={(e) => handleSeeForms(e.value)}
        >
          <Column
            field="created"
            header=""
            headerStyle={{
              backgroundColor: '#22384d',
              color: 'white',
              maxWidth: '7vw',
              minWidth: '50px',
              width: '7vw',
              textAlign: 'center',
            }}
            bodyStyle={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textAlign: 'center',
            }}
            body={ConditionColumn}
            sortable
          />
          <Column
            field="formId"
            filterField="batchID"
            header="Form ID"
            headerStyle={{
              maxWidth: '7vw',
              minWidth: '100px',
              width: '7vw',
              textAlign: 'left',
              backgroundColor: '#22384d',
              color: 'white',
            }}
            bodyStyle={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textAlign: 'left',

              height: '40px',
            }}
          />
          <Column
            field="name"
            header="Name"
            sortable
            headerStyle={{
              maxWidth: '7vw',
              minWidth: '180px',
              width: '7vw',
              textAlign: 'left',
              backgroundColor: '#22384d',
              color: 'white',
            }}
            bodyStyle={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textAlign: 'left',

              height: '40px',
            }}
          />
          <Column
            field="description"
            header="Description"
            sortable
            headerStyle={{
              maxWidth: '15vw',
              minWidth: '200px',
              width: '15vw',
              textAlign: 'left',
              backgroundColor: '#22384d',
              color: 'white',
            }}
            bodyStyle={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textAlign: 'left',

              height: '40px',
            }}
          />
          <Column
            field="case_type"
            header="Case Type"
            sortable
            headerStyle={{
              maxWidth: '15vw',
              minWidth: '240px',
              width: '15vw',
              textAlign: 'left',
              backgroundColor: '#22384d',
              color: 'white',
            }}
            bodyStyle={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textAlign: 'left',

              height: '40px',
            }}
          />
          {/* <Column
            field="webhook"
            header="Webhook"
            headerStyle={{
              width: '120px',
              minWidth: '120px',
              maxWidth: '120px',
              textAlign: 'left',
              backgroundColor: '#22384d',
              color: 'white',
              height: '40px',
            }}
            bodyStyle={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              
            }}
          /> */}
          <Column
            field=""
            header="Actions"
            headerStyle={{
              maxWidth: '7vw',
              minWidth: '200px',
              width: '7vw',
              textAlign: 'center',
              backgroundColor: '#22384d',
              color: 'white',
            }}
            bodyStyle={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textAlign: 'center',
            }}
            body={ActionColumn}
          />
        </DataTable>
      </div>
    </div>
  ) : (
    <div className="loader-wrapper">
      <Loader fit transparant />
    </div>
  );
}

FormsBlock.propTypes = {};

export default FormsBlock;
