/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext } from 'react';

import { useHistory } from 'react-router-dom';

import { AccountContext } from '../../contexts/AccountContext';
import { appDashboardService } from '../../services';
import { InputSwitch } from '../BasicComponents';
import './EditAppCard.css';

const EditAppCard = ({ workflowId }) => {
  const history = useHistory();
  const { selectedApp, setSelectedApp, setApps, showSnackbar, accountInfo } = useContext(
    AccountContext
  );
  const { userData } = accountInfo;
  const { id: userId } = userData;

  const fetchAllApps = async () => {
    const { success, data: allApps } = await appDashboardService.getAllApps(userId);
    if (success && allApps.length) {
      setApps(allApps);
    }
  };

  const onChangeIsActive = async (appId, value) => {
    const { success } = await appDashboardService.updateApp(appId, {
      isActive: value,
    });
    setSelectedApp((pre) => ({ ...pre, isActive: value }));
    if (success) {
      showSnackbar('success', '', 'App updated succesfully');
      fetchAllApps();
    } else {
      showSnackbar('error', '', 'Something went wrong updating the app! Please try again');
    }
  };

  return (
    <article id="editAppCard" className="flex-column">
      <section className="flex-column">
        <div id="appMainData" className="flex-column">
          <div className="flex-edit-wrapper">
            <p className="tag-title">App Name</p>
            <p className="tag-value">{selectedApp?.name}</p>
          </div>
          <div className="flex-edit-wrapper">
            <p className="tag-title">App ID</p>
            <p className="tag-value">{selectedApp?.id}</p>
          </div>
          <div className="flex-edit-wrapper">
            <p className="tag-title">Engine</p>
            <p className="tag-value">{selectedApp?.engine?.name}</p>
          </div>
          {selectedApp?.description && (
            <div className="flex-edit-wrapper">
              <p className="tag-title">Description</p>
              <p className="tag-value">{selectedApp?.description}</p>
            </div>
          )}
          <div className="flex-edit-wrapper">
            <p className="tag-title">Owner</p>
            <p className="tag-value">{selectedApp?.user?.name}</p>
          </div>
          <div className="flex-edit-wrapper">
            <p className="tag-title">Created</p>
            <p className="tag-value">{selectedApp?.creationDate}</p>
          </div>
          <div className="flex-edit-wrapper">
            <p className="tag-title">Is Active ?</p>
            <InputSwitch
              checked={selectedApp?.isActive}
              onChange={(e) => onChangeIsActive(selectedApp?.id, e.value)}
            />
          </div>
          {/* {isExternal ? (
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: '15px', alignItems: 'center' }}>
                <div className="flex-edit-wrapper">
                  <p className="tag-title">Is External workflow ? </p>
                  <p className="tag-value">Confirmed</p>
                </div>
                {workflowId}
              </div>
            ) : (
              <></>
            )} */}
        </div>
      </section>
    </article>
  );
};

export default EditAppCard;
