/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import axios from 'axios';

import { getCookie } from '../utils/tools';
import { httpRequest, wrap, externalWrap } from './tools';
// TODO make urls constants

const casesByGroup = async (group) => {
  const fn = async () => {
    const url = `humaninterface/hicase/searchbygroup`;
    const payload = {
      group_id: group,
      all: true,
    };
    const response = await httpRequest(url, 'POST', payload, true);
    return response.data;
  };

  return wrap(fn);
};

const casesByUser = async (all) => {
  const fn = async () => {
    const url = `humaninterface/hicase/searchbyuser`;
    const payload = {
      all,
    };
    const response = await httpRequest(url, 'POST', payload, true);
    return response.data;
  };

  return wrap(fn);
};

const casesById = async (ticketId) => {
  const fn = async () => {
    const url = `humaninterface/hicase/searchbyid`;
    const payload = {
      case_id: ticketId,
    };
    const response = await httpRequest(url, 'POST', payload, true);
    return response.data;
  };

  return wrap(fn);
};

const lock = async (caseId) => {
  const fn = async () => {
    const url = `humaninterface/hicase/lock`;
    const payload = {
      case_id: caseId,
    };
    const response = await httpRequest(url, 'POST', payload, true);
    return response.data;
  };
  return wrap(fn);
};

const unlock = async (caseId) => {
  const fn = async () => {
    const url = `humaninterface/hicase/unlock`;
    const payload = {
      case_id: caseId,
    };
    const response = await httpRequest(url, 'POST', payload, true);
    return response.data;
  };
  return wrap(fn);
};

const attend = async (code) => {
  const fn = async () => {
    const url = `flowtimer/contact/answer?code=${code}&answer=${1}`;
    const response = await httpRequest(url, 'GET', {}, true);
    return response.data;
  };
  return wrap(fn);
};

const resolve = async (caseId, data, accepted) => {
  const fn = async () => {
    const url = `humaninterface/hicase/setstatus`;
    const payload = {
      case_id: caseId,
      data,
      accepted: false,
    };
    const response = await httpRequest(url, 'POST', payload, true);
    return response.data;
  };
  return wrap(fn);
};

const saveS3 = async ({ caseId, files }) => {
  const { accessToken } = getCookie('credentials') ? JSON.parse(getCookie('credentials')) : '';
  // TO do check multiple sends to several apis dependes the client
  const fn = async () => {
    const url = `${process.env.REACT_APP_BASE_API_URL}/cases/${caseId}/files`;
    const formData = new FormData();
    files.forEach((element, index) => {
      formData.append(`file${index + 1}`, element);
    });
    const result = await axios
      .post(url, formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => response)
      .catch((error) => {
        return { success: false, data: error };
      });
    return result.data;
  };
  return externalWrap(fn);
};

const getS3 = async ({ caseId }) => {
  const fn = async () => {
    const url = `/cases/${caseId}/files?pageSize=6&since=1`;
    const payload = {
      case_id: caseId,
      pageSize: 10,
    };
    const response = await httpRequest(url, 'GET', payload, true);
    return response.data;
  };
  return wrap(fn);
};

const saveData = async (caseId, data) => {
  const fn = async () => {
    const url = `humaninterface/hicase/savedata`;
    const payload = {
      case_id: caseId,
      data,
    };
    const response = await httpRequest(url, 'POST', payload, true);
    return response.data;
  };
  return wrap(fn);
};

const updateTicket = async (workflowId, payload) => {
  const fn = async () => {
    const url = `/tickets/${workflowId}`;
    const response = await httpRequest(url, 'PATCH', payload, true);
    return response.data;
  };

  return wrap(fn);
};

// eslint-disable-next-line import/prefer-default-export
export {
  casesByGroup,
  casesByUser,
  lock,
  unlock,
  attend,
  resolve,
  saveData,
  saveS3,
  getS3,
  casesById,
  updateTicket,
};
