/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState, useEffect, useContext } from 'react';

import PropTypes from 'prop-types';

import { AccountContext } from '../../contexts/AccountContext';
import { Button } from '../BasicComponents';

import './EngineUserCard.css';

const EngineUserCard = ({ name, id, selected, onSelect, isPersonal, type }) => {
  const { userState, setUserState } = useContext(AccountContext);
  const [isActive, setIsActive] = useState(false);
  useEffect(() => {
    return () => {
      setIsActive(false);
    };
  }, []);
  const userRoutes = () => {
    const customTag = () => {
      switch (userState) {
        case 0:
          return { title: 'Active', styling: 'bagde-activity2' };
        case 1:
          return { title: 'Inactive', styling: 'bagde-activity2 bagde-yellow' };
        case 2:
          return { title: 'Expired', styling: 'bagde-activity2 bagde-red' };
        default:
          return { title: 'Active', styling: 'bagde-activity2' };
      }
    };
    const { title, styling } = customTag();
    return <span className={styling} title={title} />;
  };
  return (
    <Button
      type="button"
      className={`users-cls ${selected ? 'card-container-seleted' : 'card-container'}`}
      style={{ backgroundColor: '#3e678e' }}
      onClick={() => {
        setIsActive(true);
        onSelect(id);
        setTimeout(() => {
          setIsActive(false);
        }, 1000);
      }}
      disable={`${!isActive}`}
    >
      {type === 'user' && (isPersonal ? userRoutes() : <span className="bagde-activity2" />)}
      <h3 className="header-title-seleted">{name}</h3>
      {isPersonal && <p className="your-account-badge-seleted">Yourself</p>}
    </Button>
  );
};

EngineUserCard.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  isPersonal: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
};

export default EngineUserCard;
