/* eslint-disable max-len */
/* eslint-disable no-plusplus */
/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useContext, useEffect } from 'react';

import { TabView, TabPanel } from '../../components';
import { AccountContext } from '../../contexts/AccountContext';
import { TicketsContext } from '../../contexts/TicketsContext';
import './Cases.css';
import { workSpaceService } from '../../services';
import History from './History/History';

const Cases = ({ location }) => {
  const { accountInfo, menuCollapse } = useContext(AccountContext);
  const [availabledList, setAvailableList] = useState(null);
  const { getGroups, fetchOverAllCases, setEmptyPageState, setOverall } = useContext(
    TicketsContext
  );
  const { role, workspace } = accountInfo?.userData || {};
  const allowMyTeamCases = role === 'ADMIN' || role === 'OPS-ADMIN';
  // const data = location.hash.split('/');
  // const areMyCases = data[0] === '#personal';
  // const areMyTeamCases = data[0] === '#history';
  // const history = useHistory();
  // const isValidTab = areMyCases || areMyTeamCases;
  // if (!isValidTab) {
  //   history.push('/404');
  // }
  const [isLoading, setIsLoading] = useState(true);
  const tab = 0;
  const [activeIndex, setActiveIndex] = useState(tab);

  const loadAvailableUsers = async () => {
    const { success, data: availableUserData } = await workSpaceService.getAllUsersByWorkspace(
      workspace?.id
    );
    if (success) {
      setAvailableList(availableUserData.data);
    } else {
      setAvailableList([]);
    }
  };

  const handleChangeTab = (index) => {
    setActiveIndex(index);
    // window.location.hash = index === 0 ? '#personal' : index === 1 ? '#history' : '#settings';
  };

  const handleCleanHasgAction = () => {
    const currentRoute = window.location.hash.split('/');
    const [id] = currentRoute[1].split('#');
    if (currentRoute.length === 2 || currentRoute.length === 1) {
      window.location.hash = `${currentRoute[0]}/${id}`;
    }
  };

  // useEffect(() => {
  //   if (cases?.length) {
  //     if (data[1] && data[0] !== '#history') {
  //       let result;
  //       if (data[1].includes('#')) {
  //         const [id, actions] = data[1].split('#');
  //         result = handleCaseById(id, actions);
  //         handleCleanHasgAction();
  //       } else {
  //         result = handleCaseById(data[1]);
  //       }
  //       if (!result) {
  //         const base = data[0];
  //         window.location.hash = base;
  //       }
  //     }
  //   }
  // }, [data]);

  useEffect(async () => {
    const datas = await fetchOverAllCases();
    setIsLoading(!datas);
    getGroups();
    await loadAvailableUsers();
    return () => {
      setOpenTickets(null);
      setOverall([]);
      setEmptyPageState(true);
    };
  }, []);

  return (
    <div className={menuCollapse ? 'cases-wrp2' : 'cases-wrp2-ext'}>
      {allowMyTeamCases ? (
        <TabView activeIndex={activeIndex} onTabChange={(e) => handleChangeTab(e.index)}>
          <TabPanel disabled={!allowMyTeamCases} header="Cases" headerClassName="bounce">
            <History />
          </TabPanel>
        </TabView>
      ) : (
        <TabView activeIndex={activeIndex} onTabChange={(e) => handleChangeTab(e.index)}>
          <TabPanel header="Personal" headerClassName="bounce">
            {/* <MyBatches /> */}
          </TabPanel>
        </TabView>
      )}
    </div>
  );
};

export default Cases;
