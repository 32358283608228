/* eslint-disable no-unused-vars */
import React, { useContext } from 'react';

import { HashRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

import { Loader } from '../components';
import { PageNotFound, PageNotAuthorized } from '../containers';
import { AccountContext } from '../contexts/AccountContext';
import { AuthLayout, DashboardLayout } from '../Layouts';
import { getCookie } from '../utils/tools';
import { dashboardLayoutRoutes, authLayoutRoutes } from './Routes';

const publicRoutes = (Layout, routes) => {
  return routes.map(({ children, path, component: Component }) =>
    children ? (
      // Route item with children
      children.map(({ path: subPath, component: SubComponent }) => {
        return (
          <Route
            key={subPath}
            path={subPath}
            exact
            render={(props) => (
              <Layout>
                <SubComponent {...props} />
              </Layout>
            )}
          />
        );
      })
    ) : (
      // Route item without children
      <Route
        key={path}
        path={path}
        exact
        render={(props) => (
          <Layout>
            <Component {...props} />
          </Layout>
        )}
      />
    )
  );
};

const privateInternalRoutes = (Layout, routes) => {
  const getChildrenRoutes = (children) => {
    return children.map(({ path: subPath, component: SubComponent }) => {
      const { isAccountInfoLoading } = useContext(AccountContext);
      return (
        <Route
          key={subPath}
          path={subPath}
          exact
          render={(props) =>
            // eslint-disable-next-line no-undef
            !isAccountInfoLoading ? (
              getCookie('credentials') ? (
                <Layout routes={routes}>
                  <SubComponent {...props} />
                </Layout>
              ) : (
                <Redirect to="/" />
              )
            ) : (
              <div className="loader-wrapper">
                <Loader fit transparant />
              </div>
            )
          }
        />
      );
    });
  };
  return routes.map(({ children, path, component: Component }) => {
    return children ? (
      getChildrenRoutes(children)
    ) : (
      // Route item without children
      <Route
        key={path}
        path={path}
        exact
        render={(props) =>
          // eslint-disable-next-line no-undef
          getCookie('credentials') ? (
            <Layout routes={routes}>
              <Component {...props} />
            </Layout>
          ) : (
            <Redirect to="/" />
          )
        }
      />
    );
  });
};

const edgesRoutes = () => {
  return (
    <>
      <Route path="/401" render={() => <PageNotAuthorized />} />
      <Route path="/404" render={() => <PageNotFound />} />
      <Route path="*" render={() => <PageNotFound />} />
    </>
  );
};

const Routes = () => {
  return (
    <Router>
      <Switch>
        {publicRoutes(AuthLayout, authLayoutRoutes)}
        {privateInternalRoutes(DashboardLayout, dashboardLayoutRoutes)}
        {edgesRoutes()}
      </Switch>
    </Router>
  );
};

export default Routes;
