/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable max-len */
/* eslint-disable no-constant-condition */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useContext, useState, useRef } from 'react';

import { DebounceInput } from 'react-debounce-input';

import listEmptyCasesImage from '../../../assets/svgs/cases.svg';
import { Accordion, Button } from '../../../components';
import { AccountContext } from '../../../contexts/AccountContext';
import { TicketsContext } from '../../../contexts/TicketsContext';
import { ToolsContext } from '../../../contexts/ToolsContext';
import { CaseTypeForm } from '../../../forms';
import { casesDashboardService, humansService } from '../../../services';

import './Settings.css';

function Settings() {
  const [caseTypeSearch, setCaseTypeSearch] = useState('');
  const scrollContainerRef = useRef(null);
  const leftSide = useRef(null);
  const rightSide = useRef(null);
  const [caseTypeFilter, setCaseTypeFilter] = useState('');
  const [disableClear, setDisableClear] = useState(false);
  const [filteredCaseTypes, setFilteredCaseTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const {
    typesInfo,
    setTypesInfo,
    selectedCaseType,
    setSelectedCaseType,
    infoCase,
    setInfoCase,
  } = useContext(AccountContext);
  const { setTeamSelected, setMemberSelected } = useContext(TicketsContext);
  const { showModal, handleCloseModal } = useContext(ToolsContext);
  const [showCaseTypes, setShowCaseTypes] = useState(true);

  const getListCasesTypes = async () => {
    const { success, data: CasesInfo } = await casesDashboardService.listCasesTypes();
    if (success) {
      setTypesInfo(CasesInfo);
    }
  };

  const getCase = async () => {
    const { success, data: caseData } = await casesDashboardService.getCaseType(
      selectedCaseType?.id
    );
    if (success) {
      setInfoCase(caseData);
      return caseData;
    }
  };

  useEffect(async () => {
    await getCase();
    return () => {
      setInfoCase(null);
    };
  }, [selectedCaseType, caseTypeSearch]);

  const updateCaseType = async (team) => {
    const tempTeamSample = {
      id: '',
      name: 'Team de prueba',
      engine: {
        id: '101x01',
      },
      slackLink: 'ASDASD',
      lightIndicator: {
        yellow: 30,
        red: 60,
      },
      notifyChannels: ['SLACK'],
      humans: [
        {
          id: 'HUMAN-1',
        },
        {
          id: 'HUMAN-2',
        },
      ],
    };
    const { success: teamInfoFlag, data: teamInfoResponse } = await humansService.updateHumanGroup(
      tempTeamSample
    );
  };

  useEffect(async () => {
    await getListCasesTypes();
  }, []);

  const handleFilterDistribution = [
    {
      id: 1,
      type: 'cases',
      title: 'Cases Types',
      amount: filteredCaseTypes?.length || 0,
      content: filteredCaseTypes,
      legend: 'Configurate a case!',
    },
  ];

  const handleDistribution = [
    {
      id: 1,
      type: 'cases',
      title: 'Cases Types',
      amount: typesInfo?.length || 0,
      content: typesInfo,
      legend: 'Configurate a case!',
    },
  ];

  const handleFocusInput = () => {
    const input = document.getElementById('searchSettings-input');
    input?.focus();
  };

  useEffect(() => {
    setTeamSelected(null);
    setMemberSelected(null);
    setSelectedCaseType(null);
    setTimeout(() => {
      handleFocusInput();
    }, 1000);
  }, []);

  const handleFiltering = () => {
    const filterInLower = caseTypeSearch?.toLowerCase();
    const typesReady = typesInfo?.filter(
      (g) => g?.description?.toLowerCase()?.indexOf(filterInLower) >= 0
    );
    return { casesTypes: typesReady };
  };

  useEffect(() => {
    setSelectedCaseType(null);
    setInfoCase(null);
    if (caseTypeSearch) {
      const { casesTypes } = handleFiltering();
      setFilteredCaseTypes(casesTypes);
    } else {
      setFilteredCaseTypes([]);
    }
    setTimeout(() => {
      if (caseTypeSearch !== '') {
        setDisableClear(false);
      } else {
        setDisableClear(true);
      }
    }, 200);
  }, [caseTypeSearch]);

  const toggleShowCaseType = () => {
    setShowCaseTypes((s) => !s);
  };

  const HeaderForm = ({ titleForm }) => {
    return <div className="activity-header bounce">{titleForm}</div>;
  };

  const ListEmptyState = () => {
    return (
      <div className="list-empty-apps-wrapper">
        <div className="detail-container">
          <img className="detail-img" src={listEmptyCasesImage} alt="No apps found!" />
          <p className="details-message bounce">
            Select one case type to visualize your information.
          </p>
        </div>
      </div>
    );
  };

  const HIListing = () => (
    <div className="engine-section">
      <div className="left-search">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <DebounceInput
            className="buttonFilter2"
            value={caseTypeSearch}
            debounceTimeout={700}
            onChange={(e) => setCaseTypeSearch(e.target.value)}
            placeholder="Search"
            autoComplete="off"
          />
          {caseTypeSearch && (
            <Button
              type="button"
              title="Clear user"
              className="pi pi-trash adjust-trash2"
              onClick={() => setCaseTypeSearch('')}
            />
          )}
        </span>
        <Button
          className="add-ticket-btn"
          icon="pi pi-plus"
          onClick={(e) => {
            e.stopPropagation();
            handleCloseModal();
            const teamForm = (
              <CaseTypeForm
                isNewCaseType
                getListCasesTypes={getListCasesTypes}
                setInfoCase={setInfoCase}
              />
            );
            showModal(teamForm, <HeaderForm titleForm="Add Case Type" />);
          }}
        />
      </div>
      <div className="flex-row" style={{ height: '100vh', alignItems: 'flex-start' }}>
        <div ref={leftSide} className="left-engine">
          <div className="listing">
            {!loading ? (
              <div className="HI-listing-container" ref={scrollContainerRef}>
                <Accordion
                  headerColor="header-tickets-bg"
                  items={caseTypeSearch ? handleFilterDistribution : handleDistribution}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        {!loading ? (
          <div ref={rightSide} className="right-engine">
            <>
              {infoCase ? (
                <CaseTypeForm
                  caseInfo2={infoCase}
                  setInfoCase={setInfoCase}
                  getListCasesTypes={getListCasesTypes}
                />
              ) : (
                <ListEmptyState type="users" />
              )}
            </>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );

  return (
    <div className="HI-wrapper">
      <HIListing />
    </div>
  );
}

Settings.propTypes = {};

export default Settings;
