import * as React from 'react';

// eslint-disable-next-line react/prop-types
const Icon = ({ size = 43, color = 'currentColor', ...props }) => (
  <svg
    width={size}
    height={size}
    fill={color}
    viewBox="-3 -2 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.3126 3.27849L13.6563 5.62339L11.3126 7.96829L8.96894 5.62339L11.3126 3.27849ZM4.96894 3.67621V6.99056H1.65631V3.67621H4.96894ZM13.2505 11.9621V15.2764H9.93789V11.9621H13.2505ZM4.96894 11.9621V15.2764H1.65631V11.9621H4.96894ZM11.3126 0.933594L6.62526 5.61511L11.3126 10.3049L16 5.61511L11.3126 0.933594ZM6.62526 2.01904H0V8.64773H6.62526V2.01904ZM14.9068 10.3049H8.28157V16.9336H14.9068V10.3049ZM6.62526 10.3049H0V16.9336H6.62526V10.3049Z"
      fill="whitesmoke"
    />
  </svg>
);

export default Icon;
