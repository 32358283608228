import * as React from 'react';

// eslint-disable-next-line react/prop-types
const Icon = ({ size = 43, color = 'currentColor', ...props }) => (
  <svg
    width={size}
    height={size}
    fill={color}
    viewBox="-3 -2 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8 0.933594C3.584 0.933594 0 4.51759 0 8.93359C0 13.3496 3.584 16.9336 8 16.9336H14.4C15.28 16.9336 16 16.2136 16 15.3336V8.93359C16 4.51759 12.416 0.933594 8 0.933594ZM8 15.3336C5.688 15.3336 3.656 14.1016 2.536 12.2616L4.896 9.90159L7.528 12.1336L11.2 8.46959V9.73359H12.8V5.73359H8.8V7.33359H10.064L7.44 9.95759L4.8 7.73359L1.848 10.6936C1.688 10.1336 1.6 9.54159 1.6 8.93359C1.6 5.40559 4.472 2.53359 8 2.53359C11.528 2.53359 14.4 5.40559 14.4 8.93359C14.4 12.4616 11.528 15.3336 8 15.3336ZM14 15.7336C13.56 15.7336 13.2 15.3736 13.2 14.9336C13.2 14.4936 13.56 14.1336 14 14.1336C14.44 14.1336 14.8 14.4936 14.8 14.9336C14.8 15.3736 14.44 15.7336 14 15.7336Z"
      fill="white"
    />
  </svg>
);

export default Icon;
