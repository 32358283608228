/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useContext, useEffect } from 'react';

import { useFormik } from 'formik';

import {
  Button,
  InputText,
  InputTextarea,
  MultiSelect,
} from '../../components/BasicComponents/index';
import { AccountContext } from '../../contexts/AccountContext';
import { humansService, ticketsService } from '../../services';
import formSchema from './schema';

import './EditATicketForm.css';

const EMPTY_OPERATOR = { id: '', name: '' };

const EditATicketForm = ({ isNewTicket = false, caseInfo, groupsRetrieved, refresh }) => {
  // console.log(caseInfo);
  const { showSnackbar, handleCloseModal } = useContext(AccountContext);
  const [selectedCasesGroups, setSelectedCasesGroups] = useState(null);
  const [selectedTeamOperator, setSelectedTeamOperator] = useState([
    JSON.stringify(EMPTY_OPERATOR) !== JSON.stringify(caseInfo.operator)
      ? caseInfo.operator
      : { id: 'empty', name: 'Waiting for operator' },
  ]);
  const [operatorsRelated, setOperatorsRelated] = useState([]);
  const styleGuideByGroup = ['color1', 'color2', 'color3', 'color4', 'color5', 'color6'];
  const handleSubmitTicketUpdate = async () => {
    if (selectedTeamOperator[0]?.id && selectedCasesGroups[0]?.id) {
      const isEmpty = selectedTeamOperator[0]?.id === 'empty';
      const result = await ticketsService.updateTicket(caseInfo.id, {
        assigned_operator: !isEmpty ? selectedTeamOperator[0]?.id : '',
        operators_group: selectedCasesGroups[0]?.id,
      });
      handleCloseModal();
      if (result?.success) {
        showSnackbar('success', '', 'Ticket updated!', 4000);
        refresh();
      } else {
        showSnackbar('error', '', 'Ticket could not be updated!', 4000);
      }
    }
  };
  const { handleSubmit, handleChange, handleBlur, values, errors, isValid, touched } = useFormik({
    initialValues: caseInfo,
    validationSchema: formSchema,
    onSubmit: () => {
      handleSubmitTicketUpdate();
      handleCloseModal();
      showSnackbar('info', '', 'API CALL in progresss !!');
    },
  });

  const groupTemplate = (c) => {
    return (
      <div
        className={`wrapper-groups2 ${styleGuideByGroup[c.id?.slice(-1)] || styleGuideByGroup[0]} `}
      >
        <p className="groups">{c.name || ''}</p>
      </div>
    );
  };

  const operatorTemplate = (c) => {
    return <div className="wrapper-groups2">{c.name}</div>;
  };

  const handleRetrieve = async () => {
    const {
      success: usersByGroupFlag,
      data: usersByGroupResponse,
    } = await humansService.getListHumansOfGroups(selectedCasesGroups[0]?.id);
    if (usersByGroupFlag) {
      const addEmptyOption = [
        { id: 'empty', name: 'Waiting for operator' },
        ...usersByGroupResponse.data,
      ];
      setOperatorsRelated(addEmptyOption);
    } else {
      setOperatorsRelated([{ id: 'empty', name: 'Waiting for operator' }]);
    }
  };

  useEffect(() => {
    if (selectedCasesGroups) {
      handleRetrieve();
    }
  }, [selectedCasesGroups]);

  useEffect(() => {
    setSelectedTeamOperator([{ id: 'empty', name: 'Waiting for operator' }]);
    setSelectedCasesGroups([caseInfo.group]);
    return () => {};
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <div className="ticket-container2">
        {/* <div className="edit-container">
          {!isNewTicket ? (
            <div className="edit-ticket-group">
              <label className="text-label" htmlFor="id">
                Ticket Id
              </label>
              <InputText
                spellCheck={false}
                autoComplete="off"
                disabled
                id="id"
                name="id"
                type="text"
                className="input-form"
                placeholder=""
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.id}
              />
              {errors?.id && touched?.id ? <p>{errors?.id}</p> : <></>}
            </div>
          ) : null}
        </div> */}
        <div className="edit-container-tickets">
          <div className="edit-ticket-group">
            <label className="text-label" htmlFor="operation">
              Case
            </label>
            <InputText
              disabled={!isNewTicket}
              autoComplete="off"
              spellCheck={false}
              id="operation"
              name="operation"
              type="text"
              className="input-form"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.operation}
            />
            {errors?.operation && touched?.operation ? <p>{errors?.operation}</p> : <></>}
          </div>
          {values.status && (
            <div className="edit-ticket-group">
              <label className="text-label" htmlFor="status">
                Status
              </label>
              <InputText
                autoComplete="off"
                disabled={!isNewTicket}
                spellCheck={false}
                id="status"
                name="status"
                type="text"
                className="input-form"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.status}
              />
              {errors?.status && touched?.status ? <p>{errors?.status}</p> : <></>}
            </div>
          )}
        </div>
        <div className="edit-container-tickets">
          <div className="edit-ticket-group">
            <label className="text-label" htmlFor="description">
              Description
            </label>
            <InputTextarea
              autoComplete="off"
              disabled={!isNewTicket}
              spellCheck={false}
              id="description"
              name="description"
              className="text-area-input"
              type="text"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.description}
            />
            {errors?.description && touched?.description ? <p>{errors?.description}</p> : <></>}
          </div>
        </div>
        {/* <div className="edit-container">
          <div className="edit-ticket-group">
            <label className="text-label" htmlFor="contact_name">
              Contact Name
            </label>
            <InputText
              autoComplete="off"
              disabled={!isNewTicket}
              spellCheck={false}
              id="contact_name"
              name="contact_name"
              type="text"
              className="input-form"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.contact_name}
            />
            {errors?.contact_name && touched?.contact_name ? <p>{errors?.contact_name}</p> : <></>}
          </div>
          <div className="edit-ticket-group">
            <label className="text-label" htmlFor="contact">
              Contact
            </label>
            <InputText
              autoComplete="off"
              disabled={!isNewTicket}
              spellCheck={false}
              id="contact"
              name="contact"
              type="text"
              className="input-form"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.contact}
            />
            {errors?.contact && touched?.contact ? <p>{errors?.contact}</p> : <></>}
          </div>
        </div> */}
        {/* {!isNewTicket ? (
          <div className="edit-container-tickets">
            <div className="edit-ticket-group">
              <label className="text-label" htmlFor="data">
                Data
              </label>
              <InputText
                disabled
                spellCheck={false}
                id="data"
                name="data"
                type="text"
                className="input-form"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.data}
              />
              {errors?.data && touched?.data ? <p>{errors?.data}</p> : <></>}
            </div>
            <div className="edit-ticket-group">
              <label className="text-label" htmlFor="chat_code">
                Chat code
              </label>
              <InputText
                autoComplete="off"
                disabled
                spellCheck={false}
                id="chat_code"
                name="chat_code"
                type="text"
                className="input-form"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.chat_code}
              />
              {errors?.chat_code && touched?.chat_code ? <p>{errors?.chat_code}</p> : <></>}
            </div>
          </div>
        ) : (
          <></>
        )} */}
        <div className="edit-container-tickets">
          <div className="edit-ticket-group">
            <label className="text-label" htmlFor="created_at">
              Created date
            </label>
            <InputText
              autoComplete="off"
              disabled={!isNewTicket}
              id="created_at"
              name="created_at"
              className="input-form"
              spellCheck={false}
              type="text"
              onBlur={handleBlur}
              onChange={handleChange}
              value={new Date(values?.created_at?.seconds * 1000)}
            />
            {errors?.created_at && touched?.created_at ? <p>{errors?.created_at}</p> : <></>}
          </div>
          <div className="edit-ticket-group">
            <label className="text-label" htmlFor="due_date">
              Due date
            </label>
            <InputText
              autoComplete="off"
              disabled={!isNewTicket}
              id="due_date"
              name="due_date"
              spellCheck={false}
              type="text"
              className="input-form"
              onBlur={handleBlur}
              onChange={handleChange}
              value={new Date(values?.due_date?.seconds * 1000)}
            />
            {errors?.due_date && touched?.due_date ? <p>{errors?.due_date}</p> : <></>}
          </div>
        </div>
        {/* <div className="edit-container-tickets">
          <div className="edit-ticket-group">
            <label className="text-label" htmlFor="template">
              Template
            </label>
            <InputTextarea
              autoComplete="off"
              disabled={!isNewTicket}
              id="template"
              name="template"
              className="text-area-input"
              spellCheck={false}
              type="text"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.template}
            />
            {errors?.template && touched?.template ? <p>{errors?.template}</p> : <></>}
          </div>
        </div> */}

        <div className="edit-container">
          <div className="edit-ticket-group">
            <label className="text-label" htmlFor="group">
              Team Name
            </label>
            <MultiSelect
              optionLabel="name"
              className="blue-input"
              options={groupsRetrieved}
              value={selectedCasesGroups}
              onChange={(e) => {
                setSelectedTeamOperator([{ id: 'empty', name: 'Waiting for operator' }]);
                e.value[1] && setSelectedCasesGroups([e.value[1]]);
              }}
              itemTemplate={groupTemplate}
              selectionLimit={2}
            />
            {errors?.group && touched?.group ? <p>{errors?.group}</p> : <></>}
          </div>
          <div className="edit-ticket-group">
            <label className="text-label" htmlFor="operator">
              Assigned to
            </label>
            <MultiSelect
              optionLabel="name"
              placeholder="Operator"
              className="blue-input"
              options={operatorsRelated}
              value={selectedTeamOperator}
              onChange={(e) => {
                e.value[1] && setSelectedTeamOperator([e.value[1]]);
              }}
              itemTemplate={operatorTemplate}
              selectionLimit={2}
            />
            {/* <InputText
            id="operator"
            name="operator"
            type="text"
            className="input-form"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.operator.name}
          />
          {errors?.operator && touched?.operator ? <p>{errors?.operator}</p> : <></>} */}
          </div>
        </div>
        <div className="edit-ticket-footer">
          <Button className="edit-action-btn" type="button" onClick={handleSubmitTicketUpdate}>
            {isNewTicket ? 'Create Ticket' : 'Update Ticket'}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default EditATicketForm;
