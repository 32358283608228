/* eslint-disable jsx-a11y/aria-role */
/* eslint-disable camelcase */
/* eslint-disable react/no-array-index-key */
/* eslint-disable func-names */
/* eslint-disable max-len */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import React, { useContext, useState, useEffect, useRef } from 'react';

import { DebounceInput } from 'react-debounce-input';
import { ImCogs } from 'react-icons/im';
import { useHistory } from 'react-router-dom';

import listEmptyEnginesImage from '../../assets/svgs/enginesNew.svg';
import listEmptyUsersImage from '../../assets/svgs/users.svg';
import { TabView, TabPanel, Button, EngineUserCard, Loader } from '../../components';
import { AccountContext } from '../../contexts/AccountContext';
import { TicketsContext } from '../../contexts/TicketsContext';
import {
  UserForm,
  UserToEngineForm,
  EngineForm,
  RecoveryForm,
  UpdatePassword,
  DeleteUser,
  DeleteEngine,
} from '../../forms';
import { usersService, enginesService, workSpaceService, systemService } from '../../services';
import utils from '../../utils/utils';
// import AccountBilling from '../AccountBilling/AccountBilling';
import './AdminInfo.css';
import SettingsCases from '../Cases/Settings/Settings';
import SettingsInbox from '../HumanInterface/Settings/Settings';

const AdminInfo = ({ location }) => {
  const leftSide = useRef(null);
  const rightSide = useRef(null);
  const leftSide2 = useRef(null);
  const rightSide2 = useRef(null);
  const [lang, setLang] = useState('en');

  const [userSearch, setUserSearch] = useState('');
  const [engineSearch, setEngineSearch] = useState('');
  const [infoUser, setInfoUser] = useState(null);
  const [infoEngine, setInfoEngine] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(false);
  const [isAdmin, setIsAdmin] = useState(null);
  const [loadingMini, setLoadingMini] = useState(false);
  const history = useHistory();
  // const isValidTab =
  //   location.hash === '#users' ||
  //   location.hash === '#engines' ||
  //   location.hash === '#billing' ||
  //   location.hash === '#inbox';
  // if (!isValidTab) {
  //   history.push('/404');
  // }
  const tab = 0;
  const [activeIndex, setActiveIndex] = useState(tab);
  const {
    accountInfo,
    tools,
    setTools,
    showSnackbar,
    handleCloseModal,
    setInfoCase,
    setSelectedCaseType,
    setShowDetails,
  } = useContext(AccountContext);
  const { getGroups, setMemberSelected } = useContext(TicketsContext);
  const { userData, workspaceData } = accountInfo;
  const [usersByWorkspace, setUsersByWorkspace] = useState([]);
  const [usersFiltered, setUsersFiltered] = useState([]);
  const [enginesByWorksapace, setEnginesByWorkspace] = useState([]);
  // Selected user/engine
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedEngine, setSelectedEngine] = useState(null);

  useEffect(async () => {
    if (selectedUser) {
      const { success, data } = await usersService.getUser(selectedUser?.id);
      if (success) {
        setInfoUser(data);
        const windowWidth = window.innerWidth;
        if (windowWidth < 900) {
          if (rightSide.current && leftSide.current) {
            leftSide.current.style.display = 'none';
            rightSide.current.style.display = 'flex';
          }
          if (success && isAdmin) {
            rightSide.current.style.display = 'flex';
          }
        }
      }
    }
  }, [selectedUser]);

  useEffect(async () => {
    if (selectedEngine) {
      const { success, data } = await enginesService.getEngine(selectedEngine?.id);
      if (success) {
        setInfoEngine(data);
        const windowWidth = window.innerWidth;
        if (windowWidth < 900) {
          if (rightSide2.current && leftSide2.current) {
            leftSide2.current.style.display = 'none';
            rightSide2.current.style.display = 'flex';
          }
          if (success && isAdmin) {
            rightSide2.current.style.display = 'flex';
          }
        }
      }
    }
  }, [selectedEngine]);

  const handleFiltering = (data) => {
    const filterInLower = userSearch.toLowerCase();
    const teamsReady = data?.filter((g) => g?.name.toLowerCase()?.indexOf(filterInLower) >= 0);
    return teamsReady;
  };

  const getUsersByWorkspace = async () => {
    const { success, data } = await workSpaceService.getAllUsersByWorkspace(
      workspaceData?.data?.id
    );
    if (success && data) {
      const info = data?.data?.map((user) => {
        return {
          ...user,
        };
      });
      setUsersByWorkspace(handleFiltering(info));
      // setUsersByWorkspace([]);
      // Usando reduce para organizar los usuarios por rol
      const usersByRoleTep = [...info].reduce((acc, user) => {
        const { role } = user;

        // Si aún no existe un arreglo para este rol, crea uno
        if (!acc[role]) {
          acc[role] = [];
        }

        // Agrega el usuario al arreglo correspondiente al rol
        acc[role].push(user);

        return acc;
      }, {});
      setUsersFiltered(usersByRoleTep);
      setLoading(false);
    } else {
      return data;
    }
  };

  const getEnginesByWorkspace = async () => {
    const { success, data: enginesByWorkspaceData } = await workSpaceService.getEnginesByWorkspace(
      workspaceData?.data?.id
    );
    if (success) {
      setEnginesByWorkspace(
        enginesByWorkspaceData.map((engine) => ({
          ...engine,
          icon: <ImCogs className="icon" />,
        }))
      );
      setLoading(false);
    } else {
      return enginesByWorkspaceData;
    }
  };

  const handleSelectedUser = async (id) => {
    if (usersByWorkspace) {
      const newSelectedUser = usersByWorkspace?.find((user) => user.id === id);
      if (selectedUser !== newSelectedUser) {
        setSelectedUser(newSelectedUser);
      }
    }
  };

  const handleSelectedEngine = async (id) => {
    const newSelectedEngine = enginesByWorksapace.find((engine) => engine.id === id);
    setSelectedEngine(newSelectedEngine);
  };

  useEffect(() => {
    if (usersByWorkspace.length > 0) {
      if (!isAdmin) {
        handleSelectedUser(userData?.id);
      }
    }
  }, [isAdmin]);

  useEffect(async () => {
    await getUsersByWorkspace();
  }, [userSearch]);

  const handleSubmitAddUsersToEngine = async (e) => {
    e.preventDefault();
    const { success } = await enginesService.updateEngine(selectedEngine);
    if (success) {
      showSnackbar('success', 'Engine update!', 'Engine updated successfully');
      await getEnginesByWorkspace();
    } else {
      // showSnackbar(
      //   'error',
      //   'Updating engine error!',
      //   'Something went wrong updating the engine! Please try again'
      // );
    }
    handleCloseModal();
  };

  const handleSubmitAddUser = async (e, user) => {
    e?.preventDefault();
    // const newUser = { ...user, workspace: { id: workspaceData?.data?.id } };
    // const { success } = await usersService.createUser(newUser);

    // if (!success) {
    //   showSnackbar(
    //     'error',
    //     'Creating user error!',
    //     'Something went wrong creating the user! Please try again'
    //   );
    // } else {
    //   showSnackbar('success', 'User creation', 'User created successfully');
    // }
    // await getUsersByWorkspace();
    handleCloseModal();
  };

  const handleUpdateUser = async (userId, info) => {
    try {
      const { success } = await usersService.updateUser(userId, info);
      if (success) {
        showSnackbar('success', 'User updated', 'User updated successfully');
      } else {
        showSnackbar(
          'error',
          'Updating user error!',
          'Something went wrong updating the user! Please try again'
        );
      }
    } catch (error) {
      showSnackbar(
        'error',
        'Updating user error!',
        'Something went wrong updating the user! Please try again'
      );
    }
    handleCloseModal();
  };

  const handleSubmitAddEngine = async (e, data) => {
    e.preventDefault();
    const { success } = await enginesService.createEngine(data);
    if (!success) {
      showSnackbar(
        'error',
        'Creating engine error!',
        'Something went wrong creating the engine! Please try again'
      );
    } else {
      handleCloseModal();
      showSnackbar('success', 'Engine creation!', 'Engine created successfully');
    }
    handleCloseModal();
    await getEnginesByWorkspace();
  };

  const handleUpdateEngine = async (e, data) => {
    e.preventDefault();
    return null;
    // const { success } = await enginesService.updateEngine(data);

    // if (success) {
    //   setTools({
    //     ...tools,
    //     showModal: false,
    //     selectedEditUser: null,
    //   });
    //   showSnackbar('success', 'Engine update', 'Engine updated successfully');
    // } else {
    //   showSnackbar(
    //     'error',
    //     'Updating engine error!',
    //     'Something went wrong updating the engine! Please try again'
    //   );
    // }
    // await getEnginesByWorkspace();
  };

  const showModal = (content, header) => {
    setTools({
      ...tools,
      manageModal: { content, header },
      showModal: true,
    });
  };

  const handleRecovery = async (d) => {
    setLoading2(true);
    const payload = { email: d.email };
    const status = await systemService.recoveryPwd(payload);
    if (status === 200) {
      showSnackbar('success', 'Recovery sended', 'User  will be noticed');
    } else {
      showSnackbar('error', 'Recovery error', 'Please try again later!');
    }
    setLoading2(false);
    handleCloseModal();
  };

  const handleRecoveryUser = (data) => {
    const recoveryForm = (
      <RecoveryForm
        handleSubmit={handleRecovery}
        data={{ ...data }}
        loading={loading2}
        handleCloseCancel={handleCloseModal}
      />
    );
    showModal(recoveryForm, <HeaderForm title="Recovery User" iconName="pi-user" />);
  };

  const handleUpdatePasswordUser = () => {
    const updatePasswordForm = (
      <UpdatePassword
        handleRecoveryUser={handleRecoveryUser}
        data={{ ...infoUser, workspace: workspaceData.data.id }}
        handleCloseCancel={handleCloseModal}
        handleSubmitUpdate={handleUpdateUser}
      />
    );
    showModal(updatePasswordForm, <HeaderForm title="Change your password" />);
  };

  const resetUser = () => {
    setSelectedUser(null);
    setInfoUser(null);
  };

  const resetEngine = () => {
    setSelectedEngine(null);
    setInfoEngine(null);
  };

  const closeAll = () => {
    resetEngine();
    handleCloseModal();
  };

  const handleDeleteUser = (data) => {
    const deleteUserForm = (
      <DeleteUser
        handleSubmit={handleUpdateUser}
        handleRecoveryUser={handleRecoveryUser}
        data={{ ...data, workspace: workspaceData.data.id }}
        loading={loading2}
        handleCloseCancel={handleCloseModal}
      />
    );
    showModal(deleteUserForm, <HeaderForm title="Delete User" />);
  };

  const handleDeleteEngine = (data) => {
    const deleteEngineForm = (
      <DeleteEngine
        handleSubmit={handleUpdateUser}
        handleRecoveryUser={handleRecoveryUser}
        data={{ ...data, workspace: workspaceData.data.id }}
        loading={loading2}
        handleCloseCancel={handleCloseModal}
      />
    );
    showModal(deleteEngineForm, <HeaderForm title="Delete Engine" />);
  };

  const onEditButton = async (id, type) => {
    if (type !== 'engine') {
      const { success, data } = await usersService.getUser(id);
      if (success) {
        const userForm = (
          <UserForm
            handleSubmit={handleUpdateUser}
            handleRecoveryUser={handleRecoveryUser}
            handleUpdatePasswordUser={handleUpdatePasswordUser}
            data={{ ...data, workspace: workspaceData.data.id }}
            isModal
            handleCloseModal={closeAll}
            onEditButton={onEditButton}
            isAdmin={isAdmin}
            resetUser={resetUser}
            type="user"
            loadingMini
            handleDeleteUser={handleDeleteUser}
            isPersonal={userData?.id === infoUser?.id}
          />
        );
        showModal(userForm, <HeaderForm title="Modify User" iconName="pi-user" />);
      }
    } else {
      const engineForm = (
        <EngineForm
          handleSubmit={handleSubmitAddEngine}
          isModal
          onEditButton={onEditButton}
          resetEngine={resetEngine}
          loadingMini
          type="engine"
          data={{
            workspace: workspaceData.data.id,
            ...infoEngine,
          }}
          handleDeleteEngine={handleDeleteEngine}
        />
      );
      showModal(engineForm, <HeaderForm title="Modify Engine" />);
    }
  };

  const handleChangeTab = (index) => {
    setActiveIndex(index);
    setMemberSelected(null);
    setInfoCase(null);
    setSelectedCaseType(null);
    setShowDetails(false);
  };

  const forms = [
    <UserForm
      data={{
        workspace: workspaceData.data.id,
      }}
      handleSubmit={() => handleSubmitAddUser(null, selectedUser)}
      handleRecoveryUser={handleRecoveryUser}
      handleUpdatePasswordUser={handleUpdatePasswordUser}
      isModal
      handleCloseModal={closeAll}
      onEditButton={onEditButton}
      isAdmin={isAdmin}
      resetUser={resetUser}
      type="user"
      loadingMini
      handleDeleteUser={handleDeleteUser}
      isPersonal={userData?.id === infoUser?.id}
    />,
    <EngineForm
      handleSubmit={handleSubmitAddEngine}
      isModal
      onEditButton={onEditButton}
      resetEngine={resetEngine}
      loadingMini
      type="engine"
      handleDeleteEngine={handleDeleteEngine}
    />,
    <UserToEngineForm handleSubmit={handleSubmitAddUsersToEngine} data={selectedEngine} />,
  ];

  const HeaderForm = ({ title }) => {
    return <div className="activity-header bounce">{title}</div>;
  };

  // Usando reduce para organizar los usuarios por rol
  const engineByActivity = [...enginesByWorksapace].reduce((acc, engine) => {
    const { isActive } = engine;

    if (!acc[isActive]) {
      acc[isActive] = [];
    }

    acc[isActive].push(engine);

    return acc;
  }, {});

  const handleUserValidation = () => {
    const validUser2 = userData?.role === 'ADMIN';
    setIsAdmin(validUser2);
  };

  useEffect(async () => {
    handleChangeTab(); // Initial reset
    await getEnginesByWorkspace();
    await getUsersByWorkspace();

    handleUserValidation();

    const lanStorage = localStorage.getItem('lanStorage');
    if (!lanStorage) {
      localStorage.setItem('lanStorage', navigator.language.substring(0, 2));
    } else {
      const langTamp = localStorage.getItem('lanStorage');
      setLang(langTamp);
    }
    return () => {
      setUsersByWorkspace([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!tools.showModal && (selectedEngine || selectedUser)) {
      setSelectedUser(null);
      setSelectedEngine(null);
    }
  }, [tools]);

  useEffect(() => {
    getGroups();
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setSelectedUser(null);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const MaskRole = ({ role }) => {
    switch (role) {
      case 'NORMAL':
        return <>Users</>;
      case 'ADMIN':
        return <>Managers</>;
      case 'true':
        return <>Active engines</>;
      case 'false':
        return <>Inactive engines</>;
      default:
        return <>{role}</>;
    }
  };

  const UserSection = () => {
    return usersByWorkspace ? (
      <div className="engine-section">
        <div className="left-search">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <DebounceInput
              className="buttonFilter2"
              value={userSearch}
              debounceTimeout={700}
              onChange={(e) => setUserSearch(e.target.value)}
              placeholder="Search"
              autoComplete="off"
            />
            {userSearch && (
              <Button
                type="button"
                title="Clear user"
                className="pi pi-trash adjust-trash2"
                onClick={() => setUserSearch('')}
              />
            )}
          </span>
          <Button
            className="add-ticket-btn"
            label=""
            icon="pi pi-plus"
            style={{ margin: '0px' }}
            onClick={() => showModal(forms[0], <HeaderForm title="New User" iconName="pi-user" />)}
          />
        </div>
        <div className="flex-row" style={{ height: '100vh', alignItems: 'flex-start' }}>
          <div ref={leftSide} className="left-engine">
            <div className="left-scroller">
              {Object.entries(usersFiltered).map(([key, value], i) => {
                if (key === 'null') {
                  return <div key={`${key}-${i}`}> </div>;
                }
                return (
                  <div className="contT" key={`${key}-${i}`}>
                    <h2 className="roles-txt">
                      <MaskRole role={key} />
                      <div className="roles-len">{value?.length}</div>
                    </h2>
                    <div className="accordion-wrapper-list adjust-field">
                      {value?.map((v, r) => {
                        if (!v?.name || !v?.name.includes(userSearch)) {
                          return;
                        }
                        return (
                          <EngineUserCard
                            key={`EngineUserCard${v}-${r}`}
                            selected={selectedUser ? selectedUser.id === v.id : false}
                            onSelect={handleSelectedUser}
                            color={utils.getColor(i, utils.appDashboardColors)}
                            type="user"
                            {...v}
                            onEditButton={() => null}
                            isPersonal={userData?.id === v.id}
                          />
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div ref={rightSide} className="right-engine">
            <>
              {infoUser ? (
                <UserForm
                  data={{
                    workspace: workspaceData.data.id,
                    ...infoUser,
                  }}
                  handleSubmit={handleSubmitAddUser}
                  handleRecoveryUser={handleRecoveryUser}
                  handleUpdatePasswordUser={handleUpdatePasswordUser}
                  onEditButton={onEditButton}
                  handleCloseModal={closeAll}
                  isModal={false}
                  isAdmin={isAdmin}
                  resetUser={resetUser}
                  type="user"
                  loadingMini
                  handleDeleteUser={handleDeleteUser}
                  isPersonal={userData?.id === infoUser?.id}
                />
              ) : (
                <ListEmptyState type="users" />
              )}
            </>
          </div>
        </div>
      </div>
    ) : (
      <div className="engine-section">
        <div className="full-engine">
          {selectedUser ? (
            <UserForm
              data={{
                workspace: workspaceData.data.id,
              }}
              handleSubmit={handleSubmitAddUser}
              handleRecoveryUser={handleRecoveryUser}
              handleUpdatePasswordUser={handleUpdatePasswordUser}
              onEditButton={onEditButton}
              handleCloseModal={closeAll}
              isModal={false}
              isAdmin={isAdmin}
              resetUser={resetUser}
              type="user"
              loadingMini
              handleDeleteUser={handleDeleteUser}
              isPersonal={userData?.id === infoUser?.id}
            />
          ) : (
            <ListEmptyState type="users" />
          )}
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (tools.showModal) {
      resetUser();
      resetEngine();
    }
  }, [tools]);

  const EnginesSection = () => {
    return enginesByWorksapace ? (
      <div className="engine-section">
        <div className="left-search">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <DebounceInput
              className="buttonFilter2"
              value={engineSearch}
              debounceTimeout={700}
              onChange={(e) => setEngineSearch(e.target.value)}
              placeholder="Search"
              autoComplete="off"
            />
            {engineSearch && (
              <Button
                type="button"
                title="Clear engine"
                className="pi pi-trash adjust-trash2"
                onClick={() => setEngineSearch('')}
              />
            )}
          </span>
          {/* <Button
              className="clean-btn"
              label=""
              icon="pi pi-trash"
              style={{ margin: '0px' }}
              onClick={resetInputEngine}
            /> */}
          <Button
            className="add-ticket-btn"
            label=""
            icon="pi pi-plus"
            style={{ margin: '0px' }}
            onClick={() => showModal(forms[1], <HeaderForm title="New Engine" />)}
          />
        </div>
        <div className="flex-row" style={{ height: '100vh', alignItems: 'flex-start' }}>
          <div ref={leftSide2} className="left-engine">
            <div className="left-scroller">
              {Object.entries(engineByActivity).map(([key, value], i) => {
                if (key === 'null') {
                  return <div key={`${key}-${i}`}> </div>;
                }
                return (
                  <div className="contT" key={`${key}-${i}`}>
                    <h2 className="roles-txt">
                      <MaskRole role="Engines" />
                      <div className="roles-len">{value?.length}</div>
                    </h2>
                    <div className="accordion-wrapper-list adjust-field">
                      {value.map((v, r) => {
                        if (!v?.name || !v?.name.includes(engineSearch)) {
                          return;
                        }
                        return (
                          <EngineUserCard
                            key={`EngineUserCard${v}-${r}`}
                            selected={selectedEngine ? selectedEngine.id === v.id : false}
                            onSelect={handleSelectedEngine}
                            color={utils.getColor(i, utils.appDashboardColors)}
                            type="engine"
                            {...v}
                            onEditButton={() => null}
                            isPersonal={false}
                          />
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div ref={rightSide2} className="right-engine">
            <>
              {infoEngine ? (
                <EngineForm
                  data={{
                    workspace: workspaceData.data.id,
                    ...infoEngine,
                  }}
                  isModal={false}
                  handleSubmit={() => null}
                  onEditButton={onEditButton}
                  resetEngine={resetEngine}
                  loadingMini
                  type="engine"
                  handleDeleteEngine={handleDeleteEngine}
                />
              ) : (
                <ListEmptyState type="engines" />
              )}
            </>
          </div>
        </div>
      </div>
    ) : (
      <div className="engine-section">
        <div className="full-engine">
          {selectedUser ? (
            <UserForm
              data={{
                workspace: workspaceData.data.id,
              }}
              handleSubmit={handleSubmitAddUser}
              handleRecoveryUser={handleRecoveryUser}
              handleUpdatePasswordUser={handleUpdatePasswordUser}
              onEditButton={onEditButton}
              handleCloseModal={closeAll}
              isModal={false}
              isAdmin={isAdmin}
              resetUser={resetUser}
              type="engine"
              loadingMini
              handleDeleteUser={handleDeleteUser}
              isPersonal={userData?.id === infoUser?.id}
            />
          ) : (
            <ListEmptyState type="engines" />
          )}
        </div>
      </div>
    );
  };

  const ListEmptyState = ({ type }) => {
    let typeState;
    switch (type) {
      case 'users':
        typeState = {
          src: listEmptyUsersImage,
          description: 'Select one of the users to visualize the information.',
        };
        break;
      case 'engines':
        typeState = {
          src: listEmptyEnginesImage,
          description: 'Select one of the engines to visualize your information',
        };
        break;

      default:
        break;
    }
    return (
      <div className="list-empty-apps-wrapper bounce">
        <div className="detail-container">
          <img className="detail-img" src={typeState?.src} alt="No apps found!" />
          <p className="details-message bounce">{typeState?.description}</p>
        </div>
      </div>
    );
  };

  useEffect(() => {
    resetUser();
    resetEngine();
  }, [activeIndex]);

  return !loading ? (
    isAdmin ? (
      <div className="cases-wrp">
        <TabView activeIndex={activeIndex} onTabChange={(e) => handleChangeTab(e.index)} scrollable>
          <TabPanel header="Cases" headerClassName="bounce">
            <SettingsCases />
          </TabPanel>
          <TabPanel header="Teams/Members" headerClassName="bounce" title="Inbox Teams">
            <SettingsInbox />
          </TabPanel>
          <TabPanel header="Accounts" headerClassName="bounce">
            <UserSection />
          </TabPanel>
          <TabPanel header="Engines" headerClassName="bounce">
            <EnginesSection />
          </TabPanel>
          <TabPanel header="Billing" headerClassName="bounce">
            {' '}
          </TabPanel>
          {/* <TabPanel header="Billing" disabled>
          <AccountBilling />
        </TabPanel> */}
        </TabView>
      </div>
    ) : (
      <div className="user-wrapper">
        {infoUser?.id ? (
          <UserForm
            data={{
              workspace: workspaceData.data.id,
              ...infoUser,
            }}
            handleSubmit={handleSubmitAddUser}
            handleRecoveryUser={handleRecoveryUser}
            handleUpdatePasswordUser={handleUpdatePasswordUser}
            onEditButton={onEditButton}
            handleCloseModal={closeAll}
            isModal={false}
            isAdmin={isAdmin}
            resetUser={resetUser}
            type="user"
            loadingMini
            handleDeleteUser={handleDeleteUser}
            isPersonal={userData?.id === infoUser?.id}
          />
        ) : (
          <></>
        )}
      </div>
    )
  ) : (
    <Loader fit transparant />
  );
};

export default AdminInfo;
