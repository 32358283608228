/* eslint-disable max-len */
/* eslint-disable no-constant-condition */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import React, { useState, useEffect, useContext } from 'react';

import { DebounceInput } from 'react-debounce-input';

import RefreshIcon from '../../../assets/images/refresh.png';
import listEmptyImage from '../../../assets/svgs/arts/undraw_collaboration.svg';
import { Button, MultiSelect, Accordion, Loader } from '../../../components';
import { AccountContext } from '../../../contexts/AccountContext';
import { TicketsContext } from '../../../contexts/TicketsContext';
import { EditATicketForm } from '../../../forms';

import './GroupCases.css';

const DEFAULT_FILTERS = {
  id: { value: null, matchMode: 'contains' },
  'operator.name': { value: null, matchMode: 'in' },
  'group.id': { value: null, matchMode: 'in' },
  status: { value: null, matchMode: 'in' },
  light_indicator: { value: null, matchMode: 'in' },
};

export default function GroupCases() {
  const { accountInfo, showSnackbar } = useContext(AccountContext);
  const { userData } = accountInfo || {};
  const { role } = userData || [];
  // const validAdminUser = role === 'ADMIN';
  const {
    styleGuideByGroup,
    groupsRetrieved,
    overall,
    fetchOverAllCases,
    onCreateATicket,
    casesByUser,
    handleFilterCase,
    setCaseSelected,
  } = useContext(TicketsContext);
  const [filteredCases, setFilteredCases] = useState([]);
  const [selectedCasesPriorities, setSelectedCasesPriorities] = useState([]);
  const [selectedRestOfPpl, setSelectedRestOfPpl] = useState([]);
  const [idFilter, setIdFilter] = useState('');
  const [selectedPpl, setSelectedPpl] = useState([]);
  const [selectedTempPpl, setSelectedTempPpl] = useState([]);
  const [disableClear, setDisableClear] = useState(false);
  const [filters, setFilters] = useState(DEFAULT_FILTERS);
  const [usersRetrieved, setUsersRetrieved] = useState([]);
  const [selectedCasesGroups, setSelectedCasesGroups] = useState([]);
  const [selectedCasesStatus, setSelectedCasesStatus] = useState([]);
  const [casesManger, setCasesManger] = useState(null);
  const [loading, setLoading] = useState(true);

  const handleGetStatusCode = () => {
    return selectedCasesStatus?.map((c) => {
      return c.code;
    });
  };

  const handleGetConditionsCode = () => {
    return selectedCasesPriorities?.map((c) => c.name);
  };

  const handleGetGroupsCode = () => {
    return selectedCasesGroups?.map((c) => c.id);
  };

  const handleLeftScrollInFilters = () => {
    document.getElementById('filter-teams').scrollTo(0, 0);
  };

  const retriveUsers = (groupList) => {
    const listUsers = groupList
      .filter((g) => {
        return (
          (!!g.operator.name && !g.group.id.toLowerCase().includes('mybasket')) ||
          !g.group.id.toLowerCase().includes('my-basket')
        );
      })
      .map((g) => g.operator.name)
      .flat();
    setUsersRetrieved([...new Set(listUsers)]);
  };

  const handleFocusInput = () => {
    const input = document.getElementById('searchTicketInGroups-input');
    input?.focus();
  };

  useEffect(() => {
    setFilters({
      operation: { value: idFilter, matchMode: 'contains' },
      'operator.name': { value: selectedPpl, matchMode: 'in' },
      'group.id': {
        value: handleGetGroupsCode(),
        matchMode: 'in',
      },
      status: {
        value: handleGetStatusCode(),
        matchMode: 'in',
      },
      light_indicator: {
        value: handleGetConditionsCode(),
        matchMode: 'in',
      },
    });
    setTimeout(() => {
      if (
        selectedCasesGroups?.length ||
        selectedCasesPriorities?.length ||
        selectedCasesStatus?.length ||
        selectedPpl?.length ||
        idFilter !== ''
      ) {
        setDisableClear(false);
      } else {
        setDisableClear(true);
      }
    }, 200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCasesGroups, selectedCasesPriorities, selectedCasesStatus, selectedPpl, idFilter]);

  useEffect(() => {
    if (usersRetrieved) {
      setSelectedRestOfPpl([...usersRetrieved.slice(5, usersRetrieved.length)]);
    }
  }, [usersRetrieved]);

  useEffect(() => {
    setLoading(true);
    if (overall?.length === 0) {
      fetchOverAllCases();
    }
    setTimeout(() => {
      handleFocusInput();
    }, 1000);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  useEffect(() => {
    if (overall?.length > 0) {
      setLoading(true);
      retriveUsers(overall);
      const filteredData = overall.filter((f) => {
        return !(
          f?.group?.id.toLowerCase().includes('mybasket') ||
          f?.group?.id.toLowerCase().includes('my-basket')
        );
      });
      console.log('filteredData', filteredData);
      const groupedData = filteredData.reduce((acc, obj, i) => {
        const operatorId = obj.operator.name;
        if (!acc[operatorId]) {
          acc[operatorId] = [];
        }
        acc[operatorId].push(obj);
        return acc;
      }, {});

      const handleDistribution = Object.entries(groupedData).map(
        ([operatorId, operatorData], index) => ({
          id: operatorId + index + 1,
          type: 'ticket-manager',
          title: `${operatorId || 'Unassigned'}`,
          titleMobile: `${operatorId || 'Unassigned'}`,
          amount: operatorData.length || 0,
          content: operatorData,
          legend: 'Waiting!',
        })
      );
      setCasesManger(handleDistribution);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }, [overall]);

  const handleReset = () => {
    handleLeftScrollInFilters();
    setSelectedCasesStatus([]);
    setSelectedCasesPriorities([]);
    setSelectedCasesGroups([]);
    setSelectedPpl([]);
    setSelectedTempPpl([]);
    setIdFilter('');
  };

  const handleNamePrefix = () => {
    return usersRetrieved?.reduce((namesPrefixed, player) => {
      const [first, second] = player?.split(' ');
      if (!second) {
        namesPrefixed?.push(`${first[0]}${first[1]}`.toUpperCase());
      } else {
        namesPrefixed?.push(`${first[0]}${second[0]}`.toUpperCase());
      }
      return namesPrefixed;
    }, []);
  };

  const diffBetweenArrays = (arr1, arr2) => {
    return arr1.filter((item) => arr2.indexOf(item) === -1);
  };

  const handleTogglePpl = (pivot, isActionButton) => {
    let persona;
    if (isActionButton) {
      persona = usersRetrieved[pivot];
    } else {
      // eslint-disable-next-line no-lonely-if
      if (!(selectedTempPpl.length <= pivot.length)) {
        const leftPerson = diffBetweenArrays(selectedTempPpl, pivot);
        [persona] = leftPerson;
      } else {
        persona = pivot[pivot.length - 1];
      }
    }
    const isSelected = selectedPpl.some((p) => persona === p);
    if (!isSelected) {
      setSelectedPpl((prev) => [...prev, persona]);
      setSelectedTempPpl((prev) => [...prev, persona]);
    } else {
      setSelectedPpl((prev) => prev.filter((value) => value !== persona));
      setSelectedTempPpl((prev) =>
        prev.filter((value) => {
          return persona ? value !== persona : [];
        })
      );
    }
  };

  const Avatar = (p, i) => {
    if (i > 5) {
      return;
    }
    return i !== 5 ? (
      <Button
        key={`${p}-${i}`}
        title={usersRetrieved[i]}
        className="history-ppl-button"
        onClick={() => handleTogglePpl(i, true)}
      >
        {p}
      </Button>
    ) : (
      <MultiSelect
        key={`${p}-${i}`}
        className="history-A-users"
        placeholder={<i className="pi pi-users" />}
        value={selectedTempPpl}
        options={selectedRestOfPpl}
        onChange={(e) => handleTogglePpl(e.value, false)}
        fixedPlaceholder
      />
    );
  };

  const AvatarGroups = () => {
    const ppl = handleNamePrefix();
    return ppl?.map((p, i) => {
      return Avatar(p, i);
    });
  };

  const status_default = [
    { name: 'Unassigned', code: 'unassigned' },
    { name: 'Assigned', code: 'assigned' },
  ];

  const conditions = [
    { name: 'green', code: 'On time' },
    { name: 'yellow', code: 'Warning' },
    { name: 'red', code: 'Running late' },
  ];

  const conditionTemplate = (c) => {
    return (
      <div className="wrapper-conditions">
        <span className={`dot ${c.name}`} />
        <p className="conditions">{c.code}</p>
      </div>
    );
  };

  const groupTemplate = (c) => {
    return (
      <div
        className={`wrapper-groups ${styleGuideByGroup[c.id?.slice(-1)] || styleGuideByGroup[0]}`}
      >
        <p className="groups">{c.name}</p>
      </div>
    );
  };

  const HeaderForm = ({ title, iconName = '' }) => {
    return <div className="activity-header">{title}</div>;
  };

  const ListEmptyState = () => {
    return (
      <div className="list-empty-wrapper bounce">
        <div className="detail-container">
          <div className="detail-wrapper">
            <img className="mail-img" src={listEmptyImage} alt="Search a case" />
            <p className="detail-message bounce">No team tickets found!</p>
          </div>
        </div>
      </div>
    );
  };

  const filteredTeams = Array.isArray(groupsRetrieved)
    ? groupsRetrieved.filter((g) => {
        return !(
          g?.name?.toLowerCase().includes('mybasket') ||
          g?.name?.toLowerCase().includes('my-basket')
        );
      })
    : [];

  const handleFilterDistribution = [
    {
      id: 1,
      title: 'Results',
      titleMobile: 'Results',
      type: 'ticket-manager',
      amount: filteredCases?.length || 0,
      content: filteredCases,
      legend: 'Filter results!',
    },
  ];

  useEffect(async () => {
    setCaseSelected({});
    if (idFilter !== '' || filteredTeams?.length > 0) {
      setFilteredCases(await handleFilterCase(idFilter, selectedCasesGroups));
    } else {
      setFilteredCases([]);
    }
    setTimeout(() => {
      if (selectedCasesGroups?.length || idFilter !== '') {
        setDisableClear(false);
      } else {
        setDisableClear(true);
      }
    }, 200);
  }, [idFilter, selectedCasesGroups]);

  return (
    <div className="history-wrapper">
      {casesManger?.length > 0 ? (
        <div className="history-container">
          {/* <div className="search-wrapper">
            {validAdminUser && false ? (
              <div className="plus-icon">
                <Button
                  title="Clear search"
                  icon="pi pi-plus"
                  className="add-ticket-btn"
                  onClick={() =>
                    onCreateATicket({
                      header: <HeaderForm title="Create a ticket" />,
                      content: (
                        <EditATicketForm
                          isNewTicket
                          caseInfo={[]}
                          groupsRetrieved={filteredTeams}
                          refresh={fetchOverAllCases}
                        />
                      ),
                    })
                  }
                />
              </div>
            ) : null}
            <div className="history-A-search">
              <DebounceInput
                style={{ marginLeft: '25px' }}
                id="searchTicketInGroups-input"
                className="buttonFilter2"
                value={idFilter}
                debounceTimeout={700}
                onChange={(e) => setIdFilter(e.target.value)}
                placeholder="Search by Case ID"
                autoComplete="off"
              />
            </div>
            <div className="history-A-filters">
              <MultiSelect
                optionLabel=""
                placeholder="Teams"
                className="history-A-dropdown"
                value={selectedCasesGroups}
                options={filteredTeams}
                onChange={(e) => setSelectedCasesGroups(e.value)}
                itemTemplate={groupTemplate}
                fixedPlaceholder
              />
              <MultiSelect
                optionLabel="name"
                placeholder="Status"
                className="history-A-dropdown"
                options={status_default}
                value={selectedCasesStatus}
                onChange={(e) => setSelectedCasesStatus(e.value)}
                filter
                fixedPlaceholder
              />
              <MultiSelect
                optionLabel="name"
                placeholder="Condition"
                className="history-A-dropdown"
                value={selectedCasesPriorities}
                options={conditions}
                onChange={(e) => setSelectedCasesPriorities(e.value)}
                filter
                itemTemplate={conditionTemplate}
                fixedPlaceholder
              />
              <div className="history-A-people">
                <AvatarGroups />
              </div>
              <Button
                disabled={disableClear}
                title="Clear search"
                icon="pi pi-filter"
                className="history-A-clear"
                onClick={handleReset}
              />
            </div>
            <Button
              title="Refresh tickets"
              className="refresh-basket2"
              onClick={() => {
                // console.log('');
              }}
            >
              <p>Collapse</p>
            </Button>
            <Button
              title="Refresh tickets"
              className="refresh-basket2"
              onClick={() => {
                showSnackbar('success', '', '¡Tickets  refreshed!', 2000);
                fetchOverAllCases();
                handleReset();
              }}
            >
              <img className="refresh-img" src={RefreshIcon} alt="refresh tickets button" />
              <p>Refresh</p>
            </Button>
          </div> */}
          <div className="HI-group-container">
            <Accordion
              items={
                idFilter !== '' ||
                selectedCasesGroups?.length > 0 ||
                selectedCasesStatus?.length > 0 ||
                selectedCasesPriorities?.length > 0
                  ? handleFilterDistribution
                  : casesManger
              }
              headerColor="header-tickets-bg"
            />
          </div>
        </div>
      ) : (
        <ListEmptyState />
      )}
    </div>
  );
}
