import { wedhookRequest, wrap } from './tools';

const recoveryPwd = (payload) => {
  const fn = async () => {
    const url = `/4755c226b1`;
    const response = await wedhookRequest(url, 'POST', payload);
    return response;
  };

  return wrap(fn);
};

// eslint-disable-next-line import/prefer-default-export
export { recoveryPwd };
