/* eslint-disable max-len */
/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useContext, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import { TabView, TabPanel } from '../../components';
import './HumanInterface.css';
import { AccountContext } from '../../contexts/AccountContext';
import { ResponsiveContext } from '../../contexts/ResponsiveContext';
import { TicketsContext } from '../../contexts/TicketsContext';
import ExternalCases from './ExternalCases/ExternalCases';
import GroupCases from './GroupCases/GroupCases';
import MyTickets from './MyTickets';

const HumanInterface = ({ location }) => {
  const { accountInfo, menuCollapse } = useContext(AccountContext);
  const { getGroups, fetchOverAllCases, setEmptyPageState, setOverall } = useContext(
    TicketsContext
  );
  const { isMobile } = useContext(ResponsiveContext);
  const { role } = accountInfo?.userData || {};
  const allowMyTeamCases = role === 'ADMIN' || role === 'OPS-ADMIN';
  // const data = location.hash.split('/');
  // const areMyCases = data[0] === '#personal';
  // const areManager = data[0] === '#manager';
  // const areMySetting = data[0] === '#external';
  // const areMyEvents = data[0] === '#events';
  // if (areMyEvents) {
  //   setMenuCollapse(false);
  //   window.close();
  //   window.top.postMessage({ isEvent: true }, '*');
  // }
  const history = useHistory();
  // const isValidTab = areMyCases || areManager || areMySetting || areMyEvents;
  // if (!isValidTab) {
  //   history.push('/tickets');
  //   setSelectedTicket(null);
  // }
  const [isLoading, setIsLoading] = useState(true);
  const tab = 0;
  const [activeIndex, setActiveIndex] = useState(tab);

  const handleChangeTab = (index) => {
    setActiveIndex(index);
    // window.location.hash = index === 0 ? '#personal' : index === 1 ? '#manager' : '#external';
  };

  const handleCleanHasgAction = () => {
    const currentRoute = window.location.hash.split('/');
    const [id] = currentRoute[1].split('#');
    if (currentRoute.length === 2 || currentRoute.length === 1) {
      window.location.hash = `${currentRoute[0]}/${id}`;
    }
  };

  // useEffect(() => {
  //   if (cases?.length) {
  //     if (data[1] && data[0] !== '#manager') {
  //       let result;
  //       if (data[1].includes('#')) {
  //         const [id, actions] = data[1].split('#');
  //         result = handleCaseById(id, actions);
  //         handleCleanHasgAction();
  //       } else {
  //         result = handleCaseById(data[1]);
  //       }
  //       if (!result) {
  //         const base = data[0];
  //         window.location.hash = base;
  //       }
  //     }
  //   }
  // }, [data]);

  useEffect(async () => {
    const datas = await fetchOverAllCases();
    setIsLoading(!datas);
    getGroups();
    return () => {
      setOpenTickets(null);
      setOverall([]);
      setEmptyPageState(true);
    };
  }, []);

  return !isMobile ? (
    <div className={menuCollapse ? 'cases-wrp2' : 'cases-wrp2-ext'}>
      {allowMyTeamCases ? (
        <TabView activeIndex={activeIndex} onTabChange={(e) => handleChangeTab(e.index)}>
          <TabPanel header="Personal" headerClassName="bounce">
            <MyTickets isLoading={isLoading} />
          </TabPanel>
          <TabPanel disabled={!allowMyTeamCases} header="Manager" headerClassName="bounce">
            <GroupCases />
          </TabPanel>
          <TabPanel disabled={!allowMyTeamCases} header="External" headerClassName="bounce">
            <ExternalCases />
          </TabPanel>
        </TabView>
      ) : (
        <TabView activeIndex={activeIndex} onTabChange={(e) => handleChangeTab(e.index)}>
          <TabPanel header="Personal" headerClassName="bounce">
            <MyTickets isLoading={isLoading} />
          </TabPanel>
        </TabView>
      )}
    </div>
  ) : (
    <div className={menuCollapse ? 'cases-wrp2' : 'cases-wrp2-ext'}>
      <TabView activeIndex={activeIndex} onTabChange={(e) => handleChangeTab(e.index)}>
        <TabPanel header="Personal" headerClassName="bounce">
          <MyTickets isLoading={isLoading} />
        </TabPanel>
      </TabView>
    </div>
  );
};

export default HumanInterface;
