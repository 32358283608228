/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
/* eslint-disable no-empty-pattern */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';

import moment from 'moment-timezone';
import { DebounceInput } from 'react-debounce-input';
import { useHistory } from 'react-router-dom';

import RefreshIcon from '../../../assets/images/refresh.png';
import { Loader } from '../../../components';
import { Button, DataTable, Column } from '../../../components/BasicComponents';
import { AccountContext } from '../../../contexts/AccountContext';
import { BatchForm, BatchExeForm } from '../../../forms';
import { batchesService, casesDashboardService } from '../../../services';

import './MyBatches.css';

function MyBatches() {
  const history = useHistory();
  const [isLoading, setIsloading] = useState(false);
  const [batchFilter, setBatchFilter] = useState('');
  const {
    batches,
    setBatches,
    accountInfo,
    setTools,
    tools: tooler,
    setTotalBatches,
    handleCloseModal,
    showSnackbar,
    setTypesInfo,
  } = useContext(AccountContext);
  const { userData } = accountInfo;

  const getListBatches = async () => {
    setIsloading(true);
    const { success, data: batchesList } = await batchesService.listBatchesByWorkspace();
    if (success) {
      setTotalBatches(batchesList.length);
      setBatches(batchesList);
    } else {
      setBatches(null);
    }
    setIsloading(false);
  };

  const getListCasesTypes = async () => {
    const { success, data: casesInfo } = await casesDashboardService.listCasesTypes();
    if (success) {
      const filter = casesInfo.filter((p) => p.id !== 'TBD');
      setTypesInfo(filter);
    }
  };

  useEffect(() => {
    getListBatches();
    getListCasesTypes();
  }, []);

  const handleReset = () => {
    // setSelectedCasesGroups([]);
    setBatchFilter('');
  };

  const STATUS = {
    UNPROCESSED: 'UNPROCESSED',
    RUNNING: 'RUNNING',
    PROGRESS: 'PROGRESS',
    DONE: 'DONE',
    COMPLETED: 'COMPLETED',
    INCOMPLETE: 'INCOMPLETE',
    ERROR: 'ERROR',
    DELETED: 'DELETED',
    PREUNLOADED: 'PRE-UNLOADED',
  };

  const STATUS_COLORS = {
    UNPROCESSED: '#a1a1a1',
    RUNNING: '#FFCC00', // Amarillo brillante
    PROGRESS: '#FFCC00',
    DONE: '#87A0B2', // Naranja brillante
    COMPLETED: '#A4DD74', // Verde lima
    INCOMPLETE: '#868C9C',
    ERROR: 'RED', // Azul claro
    PREUNLOADED: '#a1a1a1',
  };

  const StatusColumn = ({ status: codeStatus }) => {
    let styling = STATUS_COLORS.DELETED;
    let opa = 1;
    switch (codeStatus.toLocaleUpperCase()) {
      case STATUS.UNPROCESSED:
        styling = STATUS_COLORS.UNPROCESSED;
        break;
      case STATUS.ONGOING:
        styling = STATUS_COLORS.ONGOING;
        break;
      case STATUS.WARNING:
        styling = STATUS_COLORS.WARNING;
        break;
      case STATUS.COMPLETED:
        styling = STATUS_COLORS.COMPLETED;
        break;
      case STATUS.DELETED:
        styling = STATUS_COLORS.DELETED;
        opa = 0.7;
        break;

      default:
        styling = STATUS_COLORS.DELETED;
        break;
    }
    return (
      <div className="batchChip" style={{ background: styling, opacity: opa }}>
        {codeStatus || 'N/A'}
      </div>
    );
  };

  const onExeApp = async (batchID) => {
    const { success } = await batchesService.executeBatch(batchID);
    handleCloseModal();
    if (success) {
      showSnackbar('success', '', 'Batch in progress.', 4000);
    } else {
      showSnackbar('error', '', 'Batch not executed.', 4000);
    }
    getListBatches();
  };

  const onStopApp = () => {
    showSnackbar('info', '', "You're not able to stop the batch.", 4000);
  };

  // const exeStopForm = <BatchStopForm onExeApp={onStopApp} onCloseModal={handleCloseModal} />;

  const showModal = (content, header) => {
    setTools({
      ...tooler,
      manageModal: { content, header },
      showModal: true,
    });
  };

  const formatDate = (date) => {
    const newDate = new Date(date);
    const opciones = {
      month: 'numeric', // Nombre completo del mes (por ejemplo, "enero")
      day: 'numeric', // Día del mes (por ejemplo, "26")
      hour: 'numeric', // Hora (por ejemplo, "13")
      year: 'numeric', // Hora (por ejemplo, "13")
      minute: 'numeric', // Minutos (por ejemplo, "45")
      hour12: true, // Opcional: usa el formato de 24 horas
    };

    // Formatear la fecha
    const formatoFecha = new Intl.DateTimeFormat('en-EN', opciones).format(newDate);
    return formatoFecha;
  };

  const ConditionColumn = ({ created }) => {
    // console.log('start ->', created);
    const utcDate = new Date(created);

    // console.log('www', formatDate(created));
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const localDate = utcDate.toLocaleString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true, // Opcional: usa el formato de 24 horas
    });

    // Formatear y mostrar la hora local
    // console.log('Hora local:', localDate);

    const now2 = new Date(`${localDate}`).toLocaleDateString('en-US', {
      timeZone: userTimeZone,
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true, // Opcional: usa el formato de 24 horas
    });

    const now = new Date().toLocaleDateString('en-US', {
      timeZone: userTimeZone,
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true, // Opcional: usa el formato de 24 horas
    });

    // console.log('now', now);
    // console.log('now2', now2);

    // Convertir las fechas a objetos moment
    const momentDate1 = moment(now.split(', ').join(' '), 'MM/DD/YYYY hh:mm:ss A');
    const momentDate2 = moment(now2.split(', ').join(' '), 'MM/DD/YYYY hh:mm:ss A');

    // console.log(momentDate1);
    // console.log(momentDate2);

    const differenceInSeconds =
      momentDate1.diff(momentDate2, 'seconds') || momentDate2.diff(momentDate1, 'seconds');
    // Función timeSince
    const timeSince = (seconds) => {
      let difference = 'now';

      const duration = moment.duration(seconds, 'seconds');
      const days = Math.floor(duration.asDays());
      if (days < 1) {
        const hours = Math.floor(duration.asHours()) - days * 24;
        if (hours < 1) {
          const mins = Math.floor(duration.asMinutes()) - days * 24 * 60 - hours * 60;
          if (mins < 1) {
            const sec =
              Math.floor(duration.asSeconds()) - days * 24 * 60 * 60 - hours * 60 * 60 - mins * 60;
            if (sec > 1) {
              difference = `${sec}s`;
            }
          } else {
            difference = `${mins}m`;
          }
        } else {
          difference = `${hours}h`;
        }
      } else {
        difference = `${days}d`;
      }

      return difference;
    };

    // Aplicar la función timeSince
    const timeDifference = timeSince(differenceInSeconds);
    return (
      <div className="flex-rows">
        <div className={`dot2 green `} title={`Created: ${now2}`}>
          <p className="dot-value">{timeDifference}</p>
        </div>
      </div>
    );
  };

  const ProcessedColumn = ({ processed, status, batchID }) => {
    if (status === 'Unprocessed') {
      return (
        <Button
          label="Start batch"
          type="button"
          icon="pi pi-play"
          className="exe-btn"
          onClick={() => {
            showModal(
              <BatchExeForm
                batchID={batchID}
                onExeApp={() => onExeApp(batchID)}
                onCloseModal={handleCloseModal}
              />,
              <HeaderAddApp title="⚠️ Play batch" />
            );
          }}
        />
      );
    }
    if (!processed && status === 'Ongoing') {
      return (
        <Button
          label="Stop batch"
          type="button"
          icon="pi pi-spin pi-spinner"
          className="stop-btn"
          onClick={() => {
            // showModal(exeStopForm, <HeaderAddApp title="✋ Stop batch" />);
            onStopApp();
          }}
        />
      );
    }
    if (processed) {
      return <>{formatDate(processed)}</>;
    }
    return <>N/A</>;
  };

  const HeaderAddApp = ({ title }) => {
    return <div className="activity-header bounce">{title}</div>;
  };

  const addBatchForm = <BatchForm preBatch={[]} loadBatch={getListBatches} />;

  const filteredPersonal = batches?.filter((b) => {
    return b.user_id === userData.id;
  });

  const filtered = filteredPersonal?.filter((b) => {
    return b.batchID.includes(batchFilter);
  });

  // const filter2 = [
  //   // ...filteredPersonal,
  //   {
  //     batchID: '517eb0010b',
  //     description: '1',
  //     created: '2024-01-29T22:23:26.000Z',
  //     case_type: 'COLLECTION',
  //     case_type_description: 'A collection type',
  //     status: 'Unprocessed',
  //     processed: null,
  //     starting_app_id: null,
  //     user_id: 'u00u01',
  //     user_name: 'Manrique Feoli',
  //     params_expected: null,
  //   },
  //   {
  //     batchID: '517eb0010b',
  //     description: '1',
  //     created: '2024-01-29T22:23:26.000Z',
  //     case_type: 'COLLECTION',
  //     case_type_description: 'A collection type',
  //     status: 'Ongoing',
  //     processed: null,
  //     starting_app_id: null,
  //     user_id: 'u00u01',
  //     user_name: 'Manrique Feoli',
  //     params_expected: null,
  //   },
  //   {
  //     batchID: '517eb0010b',
  //     description: '1',
  //     created: '2024-01-29T22:23:26.000Z',
  //     case_type: 'COLLECTION',
  //     case_type_description: 'A collection type',
  //     status: 'Completed',
  //     processed: '2024-03-29T22:23:26.000Z',
  //     starting_app_id: null,
  //     user_id: 'u00u01',
  //     user_name: 'Manrique Feoli',
  //     params_expected: null,
  //   },
  //   {
  //     batchID: '517eb0010b',
  //     description: '1',
  //     created: '2024-01-29T22:23:26.000Z',
  //     case_type: 'COLLECTION',
  //     case_type_description: 'A collection type',
  //     status: 'Deleted',
  //     processed: '2024-03-29T22:23:26.000Z',
  //     starting_app_id: null,
  //     user_id: 'u00u01',
  //     user_name: 'Manrique Feoli',
  //     params_expected: null,
  //   },
  // ];

  const rowBodyTemplate = (rowData, props) => {
    return <div className="secuencial-cls">{props.rowIndex + 1}</div>;
  };

  const handleSeeCases = (batchData) => {
    history.push({ pathname: `/batches/${batchData.batchID}`, batch: batchData });
  };

  const joinArray = batchFilter !== '' ? filtered : filteredPersonal;

  return !isLoading ? (
    <div className="cases-container">
      <div className="search-wrapper">
        <Button
          className="add-ticket-btn"
          icon="pi pi-plus"
          onClick={() => {
            showModal(addBatchForm, <HeaderAddApp title="🚀 New batch" />);
          }}
        />
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <DebounceInput
            id="searchTicket-input"
            className="buttonFilter2"
            value={batchFilter}
            debounceTimeout={700}
            onChange={(e) => setBatchFilter(e.target.value)}
            placeholder="Batch ID"
            autoComplete="off"
          />
          {batchFilter && (
            <Button
              type="button"
              title="Clear apps"
              className="pi pi-trash adjust-trash2"
              onClick={handleReset}
            />
          )}
        </span>
        <Button
          title="Refresh tickets"
          className="refresh-basket"
          onClick={() => {
            showSnackbar('success', '', '¡Batches refreshed!', 2000);
            getListBatches();
          }}
        >
          <img className="refresh-img" src={RefreshIcon} alt="refresh tickets button" />
        </Button>
      </div>
      <DataTable
        className="p-datatable-striped"
        emptyMessage="No personal batches found."
        value={joinArray?.reverse()}
        globalFilterFields={['case-type']}
        responsiveLayout="scroll"
        paginator={joinArray?.length > 10}
        rows={10}
        resizableColumns
        scrollable
        scrollHeight="80vh"
        selectionMode="single"
        onSelectionChange={(e) => handleSeeCases(e.value)}
      >
        <Column
          header=""
          headerStyle={{
            maxWidth: '3vw',
            minWidth: '50px',
            width: '3vw',
            textAlign: 'center',
            backgroundColor: '#22384d',
            color: 'black',
          }}
          bodyStyle={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textAlign: 'center',
          }}
          body={rowBodyTemplate}
        />
        <Column
          field="creationDate"
          filterField="creationDate"
          header=""
          headerStyle={{
            backgroundColor: '#22384d',
            color: 'white',
            maxWidth: '5vw',
            minWidth: '70px',
            width: '5vw',
            textAlign: 'center',
          }}
          bodyStyle={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textAlign: 'center',
          }}
          body={ConditionColumn}
          sortable
        />
        <Column
          field="batchID"
          filterField="batchID"
          header="Batch ID"
          headerStyle={{
            maxWidth: '7vw',
            minWidth: '100px',
            width: '7vw',
            textAlign: 'left',
            backgroundColor: '#22384d',
            color: 'white',
          }}
          bodyStyle={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textAlign: 'left',
            height: '40px',
          }}
        />
        <Column
          field="case_type_description"
          header="Case Type"
          sortable
          headerStyle={{
            maxWidth: '15vw',
            minWidth: '200px',
            width: '15vw',
            textAlign: 'left',
            backgroundColor: '#22384d',
            color: 'white',
          }}
          bodyStyle={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textAlign: 'left',
            height: '40px',
          }}
        />
        <Column
          field="description"
          header="Description"
          headerStyle={{
            maxWidth: '15vw',
            minWidth: '200px',
            width: '15vw',
            textAlign: 'left',
            backgroundColor: '#22384d',
            color: 'white',
            height: '40px',
          }}
          bodyStyle={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        />
        <Column
          field="processed"
          filterField="processed"
          header="Execute(d)"
          headerStyle={{
            backgroundColor: '#22384d',
            color: 'white',
            maxWidth: '15vw',
            minWidth: '200px',
            width: '15vw',
            textAlign: 'left',
          }}
          bodyStyle={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textAlign: 'left',
          }}
          sortable
          body={ProcessedColumn}
        />
      </DataTable>
    </div>
  ) : (
    <div className="loader-wrapper">
      <Loader fit transparant />
    </div>
  );
}

MyBatches.propTypes = {};

export default MyBatches;
