/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { useState, useContext } from 'react';

import { useHistory } from 'react-router-dom';

import { LoginHeader } from '../../components';
import { AccountContext } from '../../contexts/AccountContext';
import { RecoveryExternalForm } from '../../forms';
import { systemService } from '../../services';

import './RecoveryPwd.css';

const RecoveryPwd = () => {
  const { showSnackbar } = useContext(AccountContext);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [onError, setOnError] = useState(false);
  const [isReady, setIsReady] = useState(false);

  const handleCloseCancel = () => {
    history.push(`/`);
  };

  const handleSubmit = async (email) => {
    setLoading(true);
    const payload = { email };
    const result = await systemService.recoveryPwd(payload);
    if (result?.success) {
      setLoading(false);
      setOnError(true);
      setIsReady(true);
    } else {
      showSnackbar('error', 'Failed recovery password', `Try later!`, 5000);
      handleCloseCancel();
    }
  };

  return (
    <div className="recovery-container bounce">
      <div className="wrapper">
        <LoginHeader />
        <RecoveryExternalForm
          loading={loading}
          handleCloseCancel={handleCloseCancel}
          handleSubmit2={handleSubmit}
          onError={onError}
          setOnError={setOnError}
          isReady={isReady}
        />
      </div>
    </div>
  );
};

export default RecoveryPwd;
