/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useContext } from 'react';

import PropTypes from 'prop-types';

import { Button, InputText, Dropdown, MultiSelect } from '../../components';
import { AccountContext } from '../../contexts/AccountContext';
import { useInput } from '../../hooks';
import { workSpaceService } from '../../services';
import regexs from '../../utils/regexs';
import isEmpty from '../../utils/validators';
import './CreateWorkflowForm.css';

const CreateWorkflowForm = ({ data, handleSubmit }) => {
  const [dirty, setDirty] = useState(false);
  const [selectedEngine, setSelectedEngine] = useState({});
  const { accountInfo, tools, setTools, handleCloseModal } = useContext(AccountContext);
  const { workspaceData } = accountInfo;
  const [enginesByWorkspace, setEnginesByWorkspace] = useState([]);

  const getEnginesByWorkspace = async () => {
    const { success, data: enginesByWorkspaceData } = await workSpaceService.getEnginesByWorkspace(
      workspaceData?.data?.id
    );
    if (success) {
      setEnginesByWorkspace(
        enginesByWorkspaceData.map((engine) => ({
          ...engine,
        }))
      );
    } else {
      return enginesByWorkspaceData;
    }
  };

  useEffect(async () => {
    await getEnginesByWorkspace();
  }, []);

  const appnameInput = useInput('name', data.name || '', [
    {
      validatorName: 'name-required',
      regex: regexs.required,
    },
  ]);

  const descriptionInput = useInput('description', data.description || '', [
    {
      validatorName: 'description-required',
      regex: regexs.required,
    },
  ]);

  const newData = {
    id: data.id || '',
    name: appnameInput.value,
    description: descriptionInput.value,
    engine: selectedEngine.id,
  };

  const inputs = [appnameInput, descriptionInput];

  useEffect(() => {
    let isDirty = true;

    if (isEmpty(data)) {
      isDirty = data.id
        ? !(appnameInput.isValid && descriptionInput.isValid && selectedEngine.id)
        : !appnameInput.isValid;
    } else {
      let valueChanged = false;

      inputs.forEach((input) => {
        input.setIsEnabled(true);

        if (input.value !== data[input.inputName]) {
          valueChanged = true;
        }
      });
      isDirty = !(valueChanged && appnameInput.isValid && descriptionInput.isValid);
    }

    setDirty(isDirty);
  }, [appnameInput, descriptionInput]);

  const onEngineChange = (e) => {
    setSelectedEngine(e.value);
  };

  const styleGuideByGroup = ['color1', 'color2', 'color3', 'color4', 'color5', 'color6'];
  const groupTemplate = (c) => {
    return (
      <div
        className={`wrapper-groups2 ${styleGuideByGroup[c.id?.slice(-1)] || styleGuideByGroup[0]} `}
      >
        <p className="groups">{c.name || ''}</p>
      </div>
    );
  };

  const rolesTemplate = (option) => {
    return <div className="flex option-backgroung">{option.name}</div>;
  };

  return (
    <form onSubmit={(e) => handleSubmit(e, newData)}>
      <section className="add-app-section">
        <div className="new-app-container">
          <label className="inputs-label">Name</label>
          <InputText
            spellCheck={false}
            value={appnameInput.value}
            {...appnameInput.bind}
            className={
              !appnameInput.isValid && appnameInput.wasTouched
                ? 'app-form-inputText input-required'
                : 'app-form-inputText'
            }
          />
        </div>

        <div className="new-app-container">
          <label className="inputs-label">Description</label>
          <InputText
            spellCheck={false}
            value={descriptionInput.value}
            {...descriptionInput.bind}
            className={
              !descriptionInput.isValid ? 'app-form-inputText input-required' : 'app-form-inputText'
            }
          />
        </div>
        <div className="flex-row" style={{ marginTop: '30px' }}>
          <Button className="app-form-button" label="Create" disabled={dirty} />
        </div>
      </section>
    </form>
  );
};

CreateWorkflowForm.propTypes = {
  data: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default CreateWorkflowForm;
