// eslint-disable-next-line import/prefer-default-export
export const environment = {
  production: false,
  defaultLang: 'en',
  ws: 'https://mgdb.houndsoftware.net',
  legacy: {
    host: 'http://localhost',
    path: '/HoundManager',
  },
  modeler: {
    host: 'https://houndmodeler.netlify.app',
  },
  pingPongMonitorQueryInterval: 5000,
};
