import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import { ChartLineContainer, Loader } from '../../../components';

const LineCharts = ({ dataSets, activeFilter, type, loading }) => {
  const [changeFilter, setchangeFilter] = useState(false);
  const LIST_CHARTS = {
    CREATED_CASE: { id: 1, title: 'Created cases' },
    ACTIVE_CASE: { id: 2, title: 'Active cases' },
    CLOSED_CASE: { id: 3, title: 'Closed cases' },
    RESOLUTION_TIME: { id: 4, title: 'Resolution time' },
  };

  useEffect(() => {
    setchangeFilter(true);
    setTimeout(() => {
      setchangeFilter(false);
    }, 1);
  }, [activeFilter]);

  return (
    <div className="section-container">
      <h1 className="titles bounce">{LIST_CHARTS[type].title}</h1>
      <hr className="lines-hr" />
      <div className="info-container">
        {loading && !changeFilter ? (
          <ChartLineContainer
            dataSets={dataSets}
            dateFilter={activeFilter - 1}
            idType={LIST_CHARTS[type].id}
          />
        ) : (
          <Loader fit type="feeder" transparant={changeFilter} />
        )}
      </div>
    </div>
  );
};
LineCharts.defaultProps = {
  dataSets: null,
};

LineCharts.propTypes = {
  dataSets: PropTypes.array,
  activeFilter: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default LineCharts;
