/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

import { useFormik } from 'formik';
import PropTypes from 'prop-types';

import mailSended from '../../assets/svgs/mail_sent.svg';
import { Button } from '../../components';
import formSchema from './schema';
import './RecoveryExternalForm.css';

const EMPTY_RECOVERY = { email: '' };

const RecoveryExternalForm = ({
  handleCloseCancel,
  handleSubmit2,
  loading,
  onError,
  setOnError,
  isReady = false,
}) => {
  const { handleSubmit, handleChange, handleBlur, values, errors, isValid, touched } = useFormik({
    initialValues: EMPTY_RECOVERY,
    validationSchema: formSchema,
    onChange: () => {
      setOnError(false);
    },
    onSubmit: async (v) => {
      if (loading) {
        return;
      }
      handleSubmit2(values.email);
    },
  });

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <section className="pwd-section">
        {!isReady ? (
          <>
            <h3 className="pwd-h3">Enter your email to recover your password</h3>
            <span className="p-input-icon-left">
              <i className="pi pi-unlock" />
              <input
                spellCheck={false}
                disabled={loading}
                className="recoveryInputText"
                id="email"
                placeholder="Email user"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.email}
                required
                autoComplete="off"
              />
              {errors?.email && touched?.email ? (
                <div className="error-bg">
                  <p
                    className="error-white bounce"
                    style={{ marginTop: '-23px', marginBottom: '20px' }}
                  >
                    <img
                      style={{ fill: 'white' }}
                      src="https://houndsoftware.net/assetstmp/mib/alarm.svg"
                      alt=""
                    />
                    {errors?.email}
                  </p>
                </div>
              ) : (
                <></>
              )}
              {onError ? (
                <div className="error-bg">
                  <p
                    className="error-white bounce"
                    style={{ marginTop: '-23px', marginBottom: '20px' }}
                  >
                    <img
                      style={{ fill: 'white' }}
                      src="https://houndsoftware.net/assetstmp/mib/alarm.svg"
                      alt=""
                    />
                    Inténtelo más tarde
                  </p>
                </div>
              ) : (
                <></>
              )}
            </span>
          </>
        ) : (
          <div className="success-recovery">
            <img className="sended-img" src={mailSended} alt="email with recovery sended" />
            <h3 className="pwd-h4 bounce">We have sent the password reset flow to your email</h3>
          </div>
        )}
        <div className="keep-row2">
          {!isReady ? (
            <Button
              disabled={!isValid || loading}
              className="rvy-primary"
              label="Recovery password"
              onClick={handleSubmit}
            >
              {loading && <div className="loader2" />}
            </Button>
          ) : (
            <></>
          )}
          <Button className="back-btn" label="Go back" onClick={handleCloseCancel} />
        </div>
      </section>
    </form>
  );
};

RecoveryExternalForm.propTypes = {
  handleSubmit2: PropTypes.func.isRequired,
  handleCloseCancel: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  onError: PropTypes.bool.isRequired,
  setOnError: PropTypes.func.isRequired,
  isReady: PropTypes.bool.isRequired,
};

export default RecoveryExternalForm;
