import * as React from 'react';

// eslint-disable-next-line react/prop-types
const Icon = ({ size = 43, color = 'currentColor', ...props }) => (
  <svg
    width={size}
    height={size}
    fill={color}
    viewBox="-1 -2 17 17"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8 0C3.584 0 0 3.584 0 8C0 12.416 3.584 16 8 16C12.416 16 16 12.416 16 8C16 3.584 12.416 0 8 0ZM4.28 13.2C5.328 12.448 6.608 12 8 12C9.392 12 10.672 12.448 11.72 13.2C10.672 13.952 9.392 14.4 8 14.4C6.608 14.4 5.328 13.952 4.28 13.2ZM12.912 12.096C11.56 11.04 9.856 10.4 8 10.4C6.144 10.4 4.44 11.04 3.088 12.096C2.16 10.984 1.6 9.56 1.6 8C1.6 4.464 4.464 1.6 8 1.6C11.536 1.6 14.4 4.464 14.4 8C14.4 9.56 13.84 10.984 12.912 12.096Z"
      fill="white"
    />
    <path
      d="M7.99995 3.19922C6.45595 3.19922 5.19995 4.45522 5.19995 5.99922C5.19995 7.54322 6.45595 8.79922 7.99995 8.79922C9.54395 8.79922 10.8 7.54322 10.8 5.99922C10.8 4.45522 9.54395 3.19922 7.99995 3.19922ZM7.99995 7.19922C7.33595 7.19922 6.79995 6.66322 6.79995 5.99922C6.79995 5.33522 7.33595 4.79922 7.99995 4.79922C8.66395 4.79922 9.19995 5.33522 9.19995 5.99922C9.19995 6.66322 8.66395 7.19922 7.99995 7.19922Z"
      fill="white"
    />
  </svg>
);

export default Icon;
