/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable prefer-const */
/* eslint-disable no-case-declarations */
/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useContext, useEffect } from 'react';

import { Button, Dropdown, InputText, MultiSelect } from '../../components/BasicComponents/index';
import { AccountContext } from '../../contexts/AccountContext';
import { TicketsContext } from '../../contexts/TicketsContext';
import { ToolsContext } from '../../contexts/ToolsContext';
import { humansService } from '../../services';
import './EditATeamForm.css';

const defaultTeam = {
  id: 'Select an engine',
  name: 'Select an engine',
};

const channels = [
  { name: 'SMS', id: 'sms' },
  { name: 'Phone', id: 'phone' },
  { name: 'Email', id: 'email' },
  { name: 'Slack', id: 'slack' },
];

const EditATeamForm = ({
  isNewTeam = false,
  isEditing = false,
  data = null,
  handleSubmitTeam,
  enginesRetrieved,
}) => {
  // console.log('data', data);
  const enginePre = enginesRetrieved.filter((e) => {
    // console.log('e.id', e.id);
    // console.log('data?.engine', data?.engine);
    // console.log(e.id === data?.engine);
    return e.id === data?.engine;
  });
  const [lightIndicator, setLightIndicator] = useState({
    yellow: data?.lightIndicator?.yellow || 50,
    red: data?.lightIndicator?.red || 75,
  });

  // Convertimos el string en un array de IDs
  const filterIds = data?.notifyChannels?.split(',');

  // Filtramos los canales que tienen IDs que coinciden con los del array
  const filteredChannels = channels.filter((channel) => filterIds?.includes(channel.id));
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [membersByTeam, setMembersByTeam] = useState(null);
  const [membersAvailable, setMembersAvailable] = useState(null);
  const [selectedWorkflow, setSelectedWorkflow] = useState(null);
  const { showSnackbar, setShowDetails, enginesByWorkspace } = useContext(AccountContext);
  const { showModal, handleCloseModal } = useContext(ToolsContext);
  const [selectedChannels, setSelectedChannels] = useState(filteredChannels);
  const [ch, setCh] = useState(data?.notifyChannels);
  const [selectedID, setSelectedID] = useState(data?.id);
  const [selectedName, setSelectedName] = useState(data?.name);
  const [selectedNotifyEmail, setSelectedNotifyEmail] = useState(data?.webhookEmail);
  const [selectedTokEmail, setSelectedTokEmail] = useState(data?.tokEmail);
  const [selectedTokSlack, setSelectedTokSlack] = useState(data?.tokSlack);
  const [selectedLinkSlack, setSelectedLinkSlack] = useState(data?.slackLink);
  const [color, setColor] = useState(data?.colorTeam);
  const [selectedEngine, setSelectedEngine] = useState([
    enginePre[0]?.name
      ? enginePre[0]
      : null || !isEditing
      ? { ...defaultTeam, id: data?.engine, name: enginePre[0]?.name }
      : defaultTeam,
  ]);

  const { humansRetrieved, getGroups } = useContext(TicketsContext);

  // Manejador para el cambio de color
  const handleColorChange = (event) => {
    setColor(event.target.value);
  };

  const toogleShowAdvanced = () => {
    setShowAdvanced((s) => !s);
  };

  const styleGuideByGroup = ['color1', 'color2', 'color3', 'color4', 'color5', 'color6'];

  const groupTemplate = (c) => {
    return (
      <div
        className={`wrapper-groups2 ${styleGuideByGroup[c.id?.slice(-1)] || styleGuideByGroup[0]} `}
      >
        <p className="groups">{`${c.name} (${c.id})` || ''}</p>
      </div>
    );
  };

  const operatorTemplate = (c) => {
    return (
      <div className="wrapper-groups2">
        <p className="operator">{c.name}</p>
      </div>
    );
  };

  const HeaderForm = ({ titleForm }) => {
    return <div className="activity-header bounce">{titleForm}</div>;
  };

  useEffect(async () => {
    if (selectedWorkflow) {
      const { code } = selectedWorkflow;
      switch (code) {
        case 'update':
          const teamForm = (
            <EditATeamForm
              isEditing
              enginesRetrieved={enginesByWorkspace}
              handleSubmitTeam={handleSubmitTeam}
              data={data}
            />
          );
          showModal(teamForm, <HeaderForm titleForm="Edit Team" />);
          // handleSelectedCase(infoLine._id, infoLine.case_id, true);
          break;
        case 'delete':
          // console.log('addData');
          // handleAddData();
          showSnackbar('info', '', 'Delete in progress!', 3000);
          break;
        default:
          break;
      }
    }
    setSelectedWorkflow(null);
    return () => {
      setSelectedWorkflow(null);
    };
  }, [selectedWorkflow]);

  const resetModuleTeam = () => {
    setShowDetails(false);
  };

  const teamsOptions = [
    { name: 'Edit team', code: 'update', icon: 'pi pi-fw pi-refresh', display: true },
    { name: 'Delete team', code: 'delete', icon: 'pi pi-fw pi-trash', display: true },
  ];

  const modulesOptionTemplate = (option) => {
    return (
      <div className="flex option-backgroung">
        <i className={option.icon} />
        <p className="value-txt">{option.name}</p>
      </div>
    );
  };

  const onWorkflowChange = (e) => {
    setSelectedWorkflow(e.value);
  };

  const handleRetrieve = async () => {
    if (selectedID) {
      const {
        success: usersByGroupFlag,
        data: usersByGroupResponse,
      } = await humansService.getListHumansOfGroups(selectedID);
      if (usersByGroupFlag) {
        let nuevoArray = humansRetrieved.map((obj) => {
          let nuevoObj = { ...obj };
          nuevoObj.id = nuevoObj.human_id;
          delete nuevoObj.human_id;
          return nuevoObj;
        });
        let idsUnicos = new Set();
        if (usersByGroupResponse?.data && usersByGroupResponse?.data?.length > 0) {
          let arraySinDuplicados = usersByGroupResponse?.data?.filter((obj) => {
            if (idsUnicos.has(obj.id)) {
              return false;
            }
            idsUnicos.add(obj.id);
            return true;
          });
          setMembersByTeam(arraySinDuplicados);
          let idsEnB = new Set(arraySinDuplicados.map((obj) => obj.id));
          let arrayAfiltrado = nuevoArray.filter((obj) => !idsEnB.has(obj.id));
          setMembersAvailable([...arrayAfiltrado, ...arraySinDuplicados]);
        }
      } else {
        setMembersByTeam([{ id: 'empty', name: 'Waiting for member' }]);
      }
    } else {
      setMembersAvailable(humansRetrieved);
    }
  };

  useEffect(async () => {
    await handleRetrieve();
    return () => {};
  }, []);

  const ParamsExpectedCmp = () => {
    return (
      <div className="groupParams">
        {membersByTeam?.map((p) => {
          return (
            <div className="flex-row">
              <div className="statesExpected">{`${p.name} ${showAdvanced ? `(${p.id})` : ''}`}</div>
            </div>
          );
        })}
      </div>
    );
  };

  const ChannelsExpectedCmp = () => {
    const lenChannels = ch?.split(',').length;
    return lenChannels > 0 && ch ? (
      <div className="groupParams">
        {ch?.split(',')?.map((p) => {
          return <div className="resultExpected">{p}</div>;
        })}
      </div>
    ) : (
      <p className="none-params bounce">⚠️ Notifications are disabled.</p>
    );
  };

  const filterMember = (values) => {
    // console.log(values, values);
    return values.map((item) => {
      return item.id || item.human_id;
    });
  };

  const filterMedia = (values) => {
    return values.map((item) => item.id).join(',');
  };

  const validateForm = !(isEditing || isNewTeam);

  const buildPayload = () => {
    const teamPayload = {};
    if (selectedID) {
      teamPayload.id = selectedID;
    }
    if (selectedName) {
      teamPayload.name = selectedName;
    }
    if (selectedEngine && selectedEngine[0] && selectedEngine[0].id) {
      teamPayload.engine = {
        id: selectedEngine[0].id,
      };
    }
    if (lightIndicator !== undefined) {
      teamPayload.lightIndicator = lightIndicator;
    }
    if (selectedChannels?.length > 0) {
      teamPayload.notifyChannels = isEditing ? filterMedia(selectedChannels) : filterMedia(ch);
    }
    if (membersByTeam?.length > 0) {
      // console.log(filterMember(membersByTeam));
      teamPayload.humans = filterMember(membersByTeam);
    }
    if (selectedNotifyEmail) {
      teamPayload.webhookEmail = selectedNotifyEmail;
    }
    if (selectedTokEmail) {
      teamPayload.tokEmail = selectedTokEmail;
    }
    if (selectedTokSlack) {
      teamPayload.tokSlack = selectedTokSlack;
    }
    if (color) {
      teamPayload.color = color;
    }
    if (selectedLinkSlack) {
      teamPayload.slackLink = selectedLinkSlack;
    }

    return teamPayload;
  };

  const onSubmit = async () => {
    const dataTeam = buildPayload();
    const result = await handleSubmitTeam(dataTeam, isNewTeam);
    if (result) {
      resetModuleTeam();
      getGroups();
    }
  };

  return (
    <div className={validateForm ? 'casetype-wrapper' : 'casetype-wrapper2'}>
      <div className="section-scroller">
        {validateForm ? (
          <div className="flex-wrapper">
            <div className="flex aling-items-center">
              <Button
                title="Previous page"
                icon="pi pi-arrow-left"
                className="back2 bounce"
                onClick={resetModuleTeam}
              />
              <h2 className="subtitle-data-txt">Team Info</h2>
            </div>
            {!isEditing && (
              <div className="flex-row">
                <Button className="advanced-cls3" type="button" onClick={toogleShowAdvanced}>
                  Advanced Settings
                </Button>
                <Dropdown
                  value={selectedWorkflow}
                  options={teamsOptions}
                  onChange={onWorkflowChange}
                  optionLabel="name"
                  placeholder={null}
                  filterPlaceholder={null}
                  itemTemplate={modulesOptionTemplate}
                />
              </div>
            )}
          </div>
        ) : (
          <></>
        )}
        <div className="casetype-body">
          <div className={`flex-row ${isEditing ? 'user-formGroup2' : 'user-formGroup'}`}>
            <label htmlFor="name">Name</label>
            <InputText
              placeholder="Enter the team's name"
              spellCheck={false}
              autoComplete="off"
              disabled={!isEditing}
              id="name"
              name="name"
              type="text"
              className="user-inputText"
              onChange={(e) => setSelectedName(e.target.value)}
              value={selectedName}
            />
          </div>
          <div className={`flex-row ${isEditing ? 'user-formGroup2' : 'user-formGroup'}`}>
            <label htmlFor="group">
              {!validateForm ? `Engine ` : `${enginePre?.length !== 0 ? '' : 'External'} Engine`}
            </label>
            <>
              {isEditing ? (
                <MultiSelect
                  optionLabel="name"
                  placeholder="Select an engine"
                  autoComplete="off"
                  className="blue-input"
                  options={[defaultTeam, ...enginesByWorkspace]}
                  value={selectedEngine}
                  onChange={(e) => {
                    if (e.value.length === 1) {
                      setSelectedEngine([e.target.value[0]]);
                    } else {
                      e.target.value[1] && setSelectedEngine([e.target.value[1]]);
                    }
                  }}
                  itemTemplate={groupTemplate}
                  selectionLimit={2}
                />
              ) : (
                <InputText
                  spellCheck={false}
                  autoComplete="off"
                  disabled
                  id="name"
                  name="name"
                  type="text"
                  className="user-inputText"
                  value={`${selectedEngine[0].name || selectedEngine[0].id} ${
                    showAdvanced && selectedEngine[0].name ? `(${selectedEngine[0].id})` : ''
                  }`}
                />
              )}
            </>
          </div>
          <div className={`flex-row ${isEditing ? 'user-formGroup2' : 'user-formGroup'}`}>
            <label htmlFor="operator">{`Active Members (${membersAvailable?.length - 1})`}</label>
            {isEditing ? (
              <>
                <MultiSelect
                  optionLabel="name"
                  placeholder="Select the member(s)"
                  className="blue-input"
                  autoComplete="off"
                  options={membersAvailable}
                  value={membersByTeam}
                  onChange={(e) => {
                    setMembersByTeam([...e.target.value]);
                  }}
                  filterPlaceholder="null"
                />
              </>
            ) : (
              <>
                {membersByTeam?.length > 0 ? (
                  <ParamsExpectedCmp />
                ) : (
                  <p className="none-params bounce">⚠️ This team currently has no members.</p>
                )}
              </>
            )}
          </div>
          <div className={`flex-row ${isEditing ? 'user-formGroup2' : 'user-formGroup'}`}>
            <label htmlFor="colorteam">Color</label>
            <div className="flex-color">
              <InputText
                spellCheck={false}
                disabled={validateForm}
                autoComplete="off"
                placeholder="Enter the team color"
                id="id"
                name="id"
                type="text"
                className="user-inputText"
                onChange={(e) => setColor(e.target.value)}
                value={color}
              />
              {color && <span className="colorAvatar" style={{ background: color }} />}
            </div>
          </div>
          {showAdvanced && (
            <>
              {!isNewTeam && (
                <div className={`flex-row ${isEditing ? 'user-formGroup2' : 'user-formGroup'}`}>
                  <label htmlFor="id">Team ID</label>
                  <InputText
                    spellCheck={false}
                    disabled
                    id="id"
                    name="id"
                    type="text"
                    className="user-inputText"
                    placeholder=""
                    onChange={(e) => {
                      // console.log('e.target.value', e.target.value);
                      setSelectedID(e.target.value);
                    }}
                    value={selectedID}
                  />
                </div>
              )}
              <div className={`flex-row ${isEditing ? 'user-formGroup2' : 'user-formGroup'}`}>
                <label htmlFor="id">Email Notification</label>
                {selectedNotifyEmail ? (
                  <InputText
                    spellCheck={false}
                    autoComplete="off"
                    disabled={validateForm}
                    id="id"
                    name="id"
                    type="text"
                    className="user-inputText"
                    placeholder="Enter the team email notification"
                    onChange={(e) => {
                      // console.log('e.target.value', e.target.value);
                      setSelectedNotifyEmail(e.target.value);
                    }}
                    value={selectedNotifyEmail}
                  />
                ) : isEditing ? (
                  <InputText
                    spellCheck={false}
                    disabled={validateForm}
                    autoComplete="off"
                    id="id"
                    name="id"
                    type="text"
                    className="user-inputText"
                    placeholder="Enter the team email notification"
                    onChange={(e) => {
                      // console.log('e.target.value', e.target.value);
                      setSelectedNotifyEmail(e.target.value);
                    }}
                    value={selectedNotifyEmail}
                  />
                ) : (
                  <p className="none-params bounce">⚠️ Email notifications empty.</p>
                )}
              </div>
              <div className={`flex-row ${isEditing ? 'user-formGroup2' : 'user-formGroup'}`}>
                <label htmlFor="id">Email token</label>
                {selectedTokEmail ? (
                  <InputText
                    spellCheck={false}
                    disabled={validateForm}
                    autoComplete="off"
                    id="id"
                    name="id"
                    type="text"
                    className="user-inputText"
                    placeholder="Enter the team email token"
                    onChange={(e) => {
                      setSelectedTokEmail(e.target.value);
                    }}
                    value={selectedTokEmail}
                  />
                ) : isEditing ? (
                  <InputText
                    spellCheck={false}
                    disabled={validateForm}
                    autoComplete="off"
                    id="id"
                    name="id"
                    type="text"
                    className="user-inputText"
                    placeholder="Enter the team email token"
                    onChange={(e) => {
                      setSelectedTokEmail(e.target.value);
                    }}
                    value={selectedTokEmail}
                  />
                ) : (
                  <p className="none-params bounce">⚠️ Email token empty.</p>
                )}
              </div>
              <div className={`flex-row ${isEditing ? 'user-formGroup2' : 'user-formGroup'}`}>
                <label htmlFor="id">Slack Link</label>
                {selectedLinkSlack ? (
                  <InputText
                    spellCheck={false}
                    disabled={validateForm}
                    autoComplete="off"
                    id="id"
                    name="id"
                    type="text"
                    className="user-inputText"
                    placeholder="Enter the team slack link"
                    onChange={(e) => {
                      setSelectedLinkSlack(e.target.value);
                    }}
                    value={selectedLinkSlack}
                  />
                ) : isEditing ? (
                  <InputText
                    spellCheck={false}
                    disabled={validateForm}
                    autoComplete="off"
                    id="id"
                    name="id"
                    type="text"
                    className="user-inputText"
                    placeholder="Enter the team slack link"
                    onChange={(e) => {
                      setSelectedLinkSlack(e.target.value);
                    }}
                    value={selectedLinkSlack}
                  />
                ) : (
                  <p className="none-params bounce">⚠️ Slack link empty.</p>
                )}
              </div>
              <div className={`flex-row ${isEditing ? 'user-formGroup2' : 'user-formGroup'}`}>
                <label htmlFor="id">Slack token</label>
                {selectedTokSlack ? (
                  <InputText
                    spellCheck={false}
                    disabled={validateForm}
                    autoComplete="off"
                    id="id"
                    name="id"
                    type="text"
                    className="user-inputText"
                    placeholder="Enter the team slack token"
                    onChange={(e) => {
                      setSelectedTokSlack(e.target.value);
                    }}
                    value={selectedTokSlack}
                  />
                ) : isEditing ? (
                  <InputText
                    spellCheck={false}
                    disabled={validateForm}
                    autoComplete="off"
                    id="id"
                    name="id"
                    type="text"
                    className="user-inputText"
                    placeholder="Enter the team slack token"
                    onChange={(e) => {
                      setSelectedTokSlack(e.target.value);
                    }}
                    value={selectedTokSlack}
                  />
                ) : (
                  <p className="none-params bounce">⚠️ Slack token empty.</p>
                )}
              </div>
              <div className={`flex-row ${isEditing ? 'user-formGroup2' : 'user-formGroup'}`}>
                <label htmlFor="operator">Channels Notification</label>
                {validateForm ? (
                  <ChannelsExpectedCmp />
                ) : (
                  <MultiSelect
                    optionLabel="name"
                    placeholder="Select the channel(s) to notify"
                    className="blue-input"
                    options={channels}
                    value={selectedChannels}
                    onChange={(e) => {
                      setSelectedChannels(e.value);
                    }}
                    itemTemplate={operatorTemplate}
                  />
                )}
              </div>
              <div className={`flex-row ${isEditing ? 'user-formGroup2' : 'user-formGroup'}`}>
                <label>🟡 Yellow Warning %</label>
                <InputText
                  placeholder="Warning (%)"
                  spellCheck={false}
                  disabled={!isEditing}
                  autoComplete="off"
                  id="Warning"
                  name="Warning"
                  type="number"
                  className="user-inputText"
                  onChange={(e) => {
                    if (e.target.value > 0 && e.target.value < 50) {
                      setLightIndicator({ ...lightIndicator, yellow: e.target.value });
                    } else {
                      setLightIndicator({ ...lightIndicator, yellow: e.target.value || 50 });
                    }
                  }}
                  value={lightIndicator?.yellow}
                  min={0}
                  max={100}
                />
              </div>
              <div className={`flex-row ${isEditing ? 'user-formGroup2' : 'user-formGroup'}`}>
                <label>🔴 Red Warning %</label>
                <InputText
                  placeholder="Alert (%)"
                  autoComplete="off"
                  spellCheck={false}
                  disabled={!isEditing}
                  id="Alert"
                  name="Alert"
                  type="number"
                  className="user-inputText"
                  onChange={(e) => {
                    if (e.target.value > 0 && e.target.value < 75) {
                      setLightIndicator({ ...lightIndicator, red: e.target.value });
                    } else {
                      setLightIndicator({ ...lightIndicator, red: e.target.value || 75 });
                    }
                  }}
                  value={lightIndicator?.red}
                  min={0}
                  max={75}
                />
              </div>
              {validateForm && <span className="spanner" />}
            </>
          )}
          {!validateForm && (
            <div className="edit-ticket-footer">
              <Button className="advanced-cls" type="button" onClick={toogleShowAdvanced}>
                Advanced Settings
              </Button>
              <Button className="edit-action-btn" type="submit" onClick={onSubmit}>
                {isNewTeam ? 'Add' : 'Edit'}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditATeamForm;
