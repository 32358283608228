/* eslint-disable no-unused-vars */
import React from 'react';

import PropTypes from 'prop-types';

import './YesNoPopup.css';

import { Button } from '../../../components/BasicComponents';

function YesNoPopup({ event, userName, className, handleCloseTooler, msg }) {
  const handleNegative = () => {
    event.window.postMessage({ isYou: true, isTrue: false }, '*');
    handleCloseTooler();
  };

  const handlePositive = () => {
    event.window.postMessage({ isYou: true, isTrue: true }, '*');
    handleCloseTooler();
  };

  let data;
  switch (className) {
    case 'mb-styles':
      data = {
        mainstyle: 'yesno-txt',
        mainMsg: `¿Eres ${userName.trim()}?`,
        primaryBtn: 'mb-primary',
        primaryTxt: 'Sí soy',
        secondaryBtn: 'mb-secondary',
        secondaryTxt: 'No soy',
      };
      break;

    case 'denied-styles':
      data = {
        mainstyle: 'yesno-txt2',
        mainMsg: msg,
        primaryBtn: 'mb-primary',
        primaryTxt: 'Sí, eliminar',
        secondaryBtn: 'mb-cancel',
        secondaryTxt: 'Cancelar',
      };
      break;

    default:
      data = {
        mainstyle: 'yesno-txt',
        mainMsg: `¿Default?`,
        primaryBtn: 'mb-primary',
        primaryTxt: 'Aceptar',
        secondaryBtn: 'mb-secondary',
        secondaryTxt: 'Cancelar',
      };
      break;
  }

  return (
    <div className="yesno-wrapper">
      <p className={data.mainstyle}>{data.mainMsg}</p>
      <div className="flex-row3">
        <Button type="button" onClick={handleNegative} className={data.secondaryBtn}>
          {data.secondaryTxt}
        </Button>
        <Button type="button" onClick={handlePositive} className={data.primaryBtn}>
          {data.primaryTxt}
        </Button>
      </div>
    </div>
  );
}

YesNoPopup.propTypes = {
  event: PropTypes.any.isRequired,
  userName: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  handleCloseTooler: PropTypes.func.isRequired,
  msg: PropTypes.string.isRequired,
};

export default YesNoPopup;
