import moment from 'moment';

const timeSince = (seconds) => {
  let difference = 'now';

  const duration = moment.duration(moment().diff(moment.unix(seconds)));
  const days = Math.floor(duration.asDays());
  if (days < 1) {
    const hours = Math.floor(duration.asHours()) - days * 24;
    if (hours < 1) {
      const mins = Math.floor(duration.asMinutes()) - days * 24 * 60 - hours * 60;
      if (mins < 1) {
        const sec =
          Math.floor(duration.asSeconds()) - days * 24 * 60 * 60 - hours * 60 * 60 - mins * 60;
        if (sec > 1) {
          difference = `${sec}s`;
        }
      } else {
        difference = `${mins}m`;
      }
    } else {
      difference = `${hours}h`;
    }
  } else {
    difference = `${days}d`;
  }

  return difference;
};

const formatDate = (date) => {
  const options = {
    year: '2-digit',
    month: 'numeric',
    day: 'numeric',
    hour12: false,
    hour: 'numeric',
    minute: 'numeric',
  };
  return date.toLocaleString([], options);
};

export default { timeSince, formatDate };
