/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-plusplus */
/* eslint-disable prefer-const */
/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-empty-pattern */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';

import Moment from 'moment';
import moment from 'moment-timezone';
import { DebounceInput } from 'react-debounce-input';
import { useHistory } from 'react-router-dom';

import RefreshIcon from '../../../assets/images/refresh.png';
import { Loader } from '../../../components';
import {
  Button,
  DataTable,
  Column,
  Calendar,
  MultiSelect,
} from '../../../components/BasicComponents';
import { AccountContext } from '../../../contexts/AccountContext';
import { BatchExeForm } from '../../../forms';
import { batchesService, casesDashboardService, workSpaceService } from '../../../services';
import './History.css';
import { getCasesByWS } from '../../../services/cases.service';
import tools from '../../../utils/time';

const STATUS_DEFAULT = [
  { name: 'Created', code: 'CREATED' },
  { name: 'Progress', code: 'PROGRESS' },
  { name: 'Completed', code: 'COMPLETED' },
];

function History({}) {
  const history = useHistory();
  const [isLoading, setIsloading] = useState(false);
  const [casesFilter, setCasesFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [inputPage, setInputPage] = useState(1);
  const [selectedCasesStatus, setSelectedCasesStatus] = useState([]);
  const [selectedCasesType, setSelectedCasesType] = useState([]);

  const {
    setTypesInfo,
    typesInfo,
    showSnackbar,
    setTools,
    tools: tooler,
    handleCloseModal,
    setCases,
  } = useContext(AccountContext);
  const getOneMonthBack = () => {
    const currentDate = new Date(); // Gets today's date
    currentDate.setMonth(currentDate.getMonth() - 1); // Sets the date to one month back

    return currentDate;
  };
  const [disableClear, setDisableClear] = useState(true);
  const [rangeDates, setRangeDates] = useState([]);
  const [caseCategory, setCaseCategory] = useState(null);
  const [caseOperationId, setCaseOperationId] = useState(null);
  const [caseSelectedId] = useState(null);
  const [instances, setInstances] = useState([]);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const pageSize = 500;

  useEffect(async () => {
    if (
      rangeDates.length === 2 ||
      selectedCasesType?.length ||
      selectedCasesStatus?.length ||
      casesFilter !== ''
    ) {
      setDisableClear(false);
    } else {
      setDisableClear(true);
    }
  }, [casesFilter, rangeDates, selectedCasesType, selectedCasesStatus]);

  const getListCasesTypes = async () => {
    const { success, data: casesInfo } = await casesDashboardService.listCasesTypes();
    if (success) {
      const filter2 = casesInfo.filter((p) => p.id !== 'TBD');
      setTypesInfo(filter2);
    }
  };

  const handlePageInput = (e) => {
    if (totalPages === 1) {
      setInputPage(totalPages);
      setCurrentPage(totalPages);
      return;
    }
    if (e.target.value > totalPages) {
      setInputPage(totalPages);
      setCurrentPage(totalPages);
    } else if (e.target.value < 1) {
      setInputPage(1);
      setCurrentPage(1);
    } else {
      setInputPage(e.target.value);
      setCurrentPage(e.target.value);
    }
  };

  const fetchCases = async (dates) => {
    setIsloading(true);
    const { success, data: casesResult } = await casesDashboardService.v2listCases(
      dates ? Moment(dates[0] || '').format('YYYY-MM-DD') : null,
      dates
        ? Moment(dates[1] || '')
            .add('days', 1)
            .format('YYYY-MM-DD')
        : null,
      selectedCasesStatus[0]?.code ? selectedCasesStatus[0]?.code : '',
      caseCategory,
      caseOperationId,
      caseSelectedId,
      pageSize,
      currentPage - 1,
      casesFilter || null,
      selectedCasesType.length > 0 ? selectedCasesType[0].id : ''
    );
    // getStatusWs();
    if (success && casesResult?.cases?.length > 0) {
      setInstances(casesResult.cases);
      setTotalRecords(casesResult?.total);
      setTotalPages(Math.ceil(casesResult?.total / pageSize));
    } else {
      setInstances(0);
      setTotalRecords(0);
      setTotalPages(0);
      // history.push(`/dashboard/${location.pathname.split('/')[2]}`);
    }
    setIsloading(false);
  };

  const handleDates = ({ value }) => {
    if (value[0]) {
      setRangeDates(value);
    }
    if (value.length === 2 && value[1]) {
      setRangeDates(value);
      fetchCases(value);
    }
  };

  useEffect(() => {
    fetchCases(rangeDates.length === 2 ? rangeDates : null);
  }, [first, rows, currentPage, inputPage, selectedCasesStatus, casesFilter, selectedCasesType]);

  // Pagination event handler
  const onPaginate = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  useEffect(() => {
    fetchCases();
    getListCasesTypes();
  }, []);

  const handleReset = () => {
    setCasesFilter('');
    setCurrentPage(1);
    setInputPage(1);
    setRangeDates([]);
    fetchCases();
    setFirst(0);
    setRows(10);
    setSelectedCasesStatus([]);
    setSelectedCasesType([]);
  };

  const STATUS = {
    UNPROCESSED: 'UNPROCESSED',
    RUNNING: 'RUNNING',
    PROGRESS: 'PROGRESS',
    DONE: 'DONE',
    COMPLETED: 'COMPLETED',
    INCOMPLETE: 'INCOMPLETE',
    ERROR: 'ERROR',
    DELETED: 'DELETED',
    PREUNLOADED: 'PRE-UNLOADED',
  };

  const STATUS_COLORS = {
    UNPROCESSED: '#a1a1a1',
    RUNNING: '#FFCC00', // Amarillo brillante
    PROGRESS: '#FFCC00',
    DONE: '#87A0B2', // Naranja brillante
    COMPLETED: '#A4DD74', // Verde lima
    INCOMPLETE: '#868C9C',
    ERROR: 'RED', // Azul claro
    PREUNLOADED: '#a1a1a1',
  };

  const formatDate = (date) => {
    if (!date || date === 'Invalid date') {
      return '-';
    }
    const newDate = new Date(date);
    const opciones = {
      month: 'numeric', // Nombre completo del mes (por ejemplo, "enero")
      day: 'numeric', // Día del mes (por ejemplo, "26")
      hour: 'numeric', // Hora (por ejemplo, "13")
      year: 'numeric', // Hora (por ejemplo, "13")
      minute: 'numeric', // Minutos (por ejemplo, "45")
    };

    // Formatear la fecha
    try {
      const formatoFecha = new Intl.DateTimeFormat('en-EN', opciones).format(newDate);
      return formatoFecha;
    } catch (error) {
      return date;
    }
  };

  const ConditionColumn = ({ creationDate }) => {
    // console.log('_________________________________________');
    const entrada = formatDate(creationDate);
    const nowUtcString = new Date();
    // console.log('Current UTC time (UTC string format):', nowUtcString);

    const options = {
      timeZone: 'UTC',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false, // Use 24-hour time
    };

    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(nowUtcString);

    // console.log('Entrada', entrada);
    // console.log('Formatted UTC date:', formatDate(formattedDate));

    // Convertir las fechas a objetos moment
    const momentDate1 = moment(entrada, 'M/D/YYYY, h:mm A');
    const momentDate2 = moment(formatDate(formattedDate), 'M/D/YYYY, h:mm A');

    // Calcular la diferencia en segundos
    const differenceInSeconds = momentDate2.diff(momentDate1, 'seconds');

    // Mostrar la diferencia
    // console.log(`Diferencia en segundos: ${differenceInSeconds}`);

    // const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const userTimeZone = 'America/New_York';
    // console.log('TimeZone :', userTimeZone);
    const localDate = new Date(
      creationDate.toLocaleString('en-US', {
        timeZone: userTimeZone,
        hour12: true,
      })
    );
    const now = new Date();
    // Formatear y mostrar la hora local
    // // console.log('Hora local:', localTime.format('YYYY-MM-DD HH:mm:ss'));
    // let formattedLocalDate;
    // if (localDate) {
    //   console.log('localDate', localDate);
    //   formattedLocalDate = localDate
    //     ?.toISOString()
    //     ?.slice(0, 19)
    //     ?.replace('T', ' ')
    //     ?.replaceAll('-', '/');
    // }

    // console.log('formattedLocalDate', formattedLocalDate);
    // console.log('b -> ', new Date(`${formattedLocalDate} ${formatDate(creationDate).slice(-2)}`));
    let now2 = new Date(`${formatDate(creationDate)}`).toLocaleDateString('en-US', {
      timeZone: userTimeZone,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true, // Opcional: usa el formato de 24 horas
    });

    if (now2 === 'Invalid Date') {
      // console.log('b -> ', new Date(`${formattedLocalDate}`));
      // now2 = new Date(`${formattedLocalDate}`).toLocaleDateString('en-US', {
      //   timeZone: userTimeZone,
      //   year: 'numeric',
      //   month: '2-digit',
      //   day: '2-digit',
      //   hour: '2-digit',
      //   minute: '2-digit',
      //   second: '2-digit',
      //   hour12: true, // Opcional: usa el formato de 24 horas
      // });
    }

    // console.log('Nueva fecha calculada al localtime --->', now2);
    // console.log('Este es su tiempo en este momento en su país --->', now);
    // console.log('_________________________________________');

    // Convertir las fechas a objetos moment
    // const momentDate1 = moment();
    // const momentDate2 = moment(now2);

    // // console.log('momentDate1', momentDate1);
    // // console.log('momentDate2', momentDate2);

    // const differenceInSeconds =
    //   momentDate1.diff(momentDate2, 'seconds') || momentDate2.diff(momentDate1, 'seconds');
    // Función timeSince
    const timeSince = (seconds) => {
      let difference = 'now';

      const duration = moment.duration(seconds, 'seconds');
      const days = Math.floor(duration.asDays());
      if (days < 1) {
        const hours = Math.floor(duration.asHours()) - days * 24;
        if (hours < 1) {
          const mins = Math.floor(duration.asMinutes()) - days * 24 * 60 - hours * 60;
          if (mins < 1) {
            const sec =
              Math.floor(duration.asSeconds()) - days * 24 * 60 * 60 - hours * 60 * 60 - mins * 60;
            if (sec > 1) {
              difference = `${sec}s`;
            }
          } else {
            difference = `${mins}m`;
          }
        } else {
          difference = `${hours}h`;
        }
      } else {
        difference = `${days}d`;
      }

      return difference;
    };

    // Aplicar la función timeSince
    const timeDifference = timeSince(differenceInSeconds);

    // Mostrar el resultado
    // console.log(`Diferencia en segundos: ${differenceInSeconds}`);
    // console.log(`Diferencia legible: ${timeDifference}`);
    return (
      <div className="flex-rows">
        <div className={`dot2 green `} title={`Created: `}>
          <p className="dot-value">{timeDifference}</p>
        </div>
      </div>
    );
  };

  const CreationDateColumn = ({ creationDate }) => {
    return creationDate ? formatDate(creationDate) : '-';
  };

  const UpdatedDateColumn = ({ lastUpdate }) => {
    console.log('_____');
    // console.log(
    //   '000000000 lastUpdate',
    //   lastUpdate,
    //   '\n',
    //   new Date(lastUpdate).toLocaleDateString(),
    //   '\n'
    // );
    console.log(moment(lastUpdate, 'M/D/YYYY, h:mm').format().toLo);
    return lastUpdate
      ? new Date(moment(lastUpdate, 'M/D/YYYY, h:mm A').format('lll')).toDateString()
      : '-';
  };

  const ClosingDateColumn = ({ closing }) => {
    return closing ? formatDate(closing) : '-';
  };

  const StatusColumn = ({ status: codeStatus }) => {
    let styling = STATUS_COLORS.DELETED;
    let opa = 1;
    switch (codeStatus.toLocaleUpperCase()) {
      case STATUS.UNPROCESSED:
        styling = STATUS_COLORS.UNPROCESSED;
        break;
      case STATUS.ONGOING:
        styling = STATUS_COLORS.ONGOING;
        break;
      case STATUS.WARNING:
        styling = STATUS_COLORS.WARNING;
        break;
      case STATUS.COMPLETED:
        styling = STATUS_COLORS.COMPLETED;
        break;
      case STATUS.DELETED:
        styling = STATUS_COLORS.DELETED;
        opa = 0.2;
        break;
      case STATUS.PREUNLOADED:
        styling = STATUS_COLORS.PREUNLOADED;
        opa = 1;
        break;
      case STATUS.PROGRESS:
        styling = STATUS_COLORS.PROGRESS;
        opa = 1;
        break;

      default:
        styling = STATUS_COLORS.DELETED;
        break;
    }
    return (
      <div className="batchChip" style={{ background: styling, opacity: opa }}>
        {codeStatus || '-'}
      </div>
    );
  };

  const showModal = (content, header) => {
    setTools({
      ...tooler,
      manageModal: { content, header },
      showModal: true,
    });
  };

  const HeaderAddApp = ({ title }) => {
    return <div className="activity-header bounce">{title}</div>;
  };

  function contarOcurrenciasPorEstado(arr) {
    // Objeto para almacenar el recuento de ocurrencias por estado
    let ocurrenciasPorEstado = {};

    // Iterar sobre el arreglo de objetos
    arr?.forEach((obj) => {
      // Verificar si el estado ya ha sido contabilizado
      if (ocurrenciasPorEstado[obj.status]) {
        // Si ya existe, incrementar el contador
        ocurrenciasPorEstado[obj.status]++;
      } else {
        // Si no existe, inicializar el contador en 1
        ocurrenciasPorEstado[obj.status] = 1;
      }
    });

    // Devolver el objeto con el recuento de ocurrencias por estado
    return ocurrenciasPorEstado;
  }

  function contarOcurrenciasPorCase(arr) {
    // Objeto para almacenar el recuento de ocurrencias por estado
    let ocurrenciasPorCase = {};

    // Iterar sobre el arreglo de objetos
    arr?.forEach((obj) => {
      // Verificar si el estado ya ha sido contabilizado
      if (ocurrenciasPorCase[obj.caseType]) {
        // Si ya existe, incrementar el contador
        ocurrenciasPorCase[obj.caseType]++;
      } else {
        // Si no existe, inicializar el contador en 1
        ocurrenciasPorCase[obj.caseType] = 1;
      }
    });

    // Devolver el objeto con el recuento de ocurrencias por estado
    return ocurrenciasPorCase;
  }

  const onExeApp = async (batchID) => {
    const { success } = await batchesService.executeBatch(batchID);
    handleCloseModal();
    if (success) {
      showSnackbar('success', '', 'Batch in progress.', 4000);
    } else {
      showSnackbar('error', '', 'Batch not executed.', 4000);
    }
    getCasesByWS();
  };

  const ProcessedColumn = ({ processed, status, batchID }) => {
    if (!processed && status === 'Unprocessed') {
      return (
        <Button
          label="Start batch"
          type="button"
          icon="pi pi-play"
          className="exe-btn"
          onClick={() => {
            showModal(
              <BatchExeForm
                batchID={batchID}
                onExeApp={() => onExeApp(batchID)}
                onCloseModal={handleCloseModal}
              />,
              <HeaderAddApp title="⚠️ Play batch" />
            );
          }}
        />
      );
    }
    if (!processed && status === 'Ongoing') {
      return (
        <Button
          label="Stop batch"
          type="button"
          icon="pi pi-spin pi-spinner"
          className="stop-btn"
          onClick={() => {
            // showModal(exeStopForm, <HeaderAddApp title="✋ Stop batch" />);
            // onStopApp();
          }}
        />
      );
    }
    if (processed) {
      return <>{formatDate(processed)}</>;
    }
    return <>N/A</>;
  };

  const CasesStats = () => {
    const ocurrencias = contarOcurrenciasPorEstado(instances);
    const casesCounter = contarOcurrenciasPorCase(instances);
    return (
      <div className="caseType-container">
        {/* <div className="paginator-total">
          <p>Total cases</p>
          {totalRecords}
        </div> */}
        {Object.keys(casesCounter)?.map((o, index) => {
          return o !== 'null' ? (
            <div className="card-batch2" key={`${o + index}Ot`}>
              <div className="casetype-name">{o}</div>
              <div className="casetype-value">{casesCounter[o]}</div>
            </div>
          ) : null;
        })}
        <hr className="card-hr" />
        {Object.keys(ocurrencias)?.map((o, index) => {
          return o !== 'null' ? (
            <div className="card-batch" key={`${o + index}OC`}>
              <div className="casetype-name">{o}</div>
              <div className="casetype-value">{ocurrencias[o]}</div>
            </div>
          ) : null;
        })}
      </div>
    );
  };

  const handleSeeCase = (caseData) => {
    history.push({ pathname: `/cases/${caseData.case_id}`, caseData });
  };

  const goToNextPage = () => {
    if (currentPage === totalPages) {
      setCurrentPage(totalPages);
      setInputPage(totalPages);
    } else {
      setCurrentPage((prev) => (prev < totalRecords ? prev + 1 : prev));
      setInputPage(currentPage < totalRecords ? currentPage + 1 : currentPage);
    }
  };

  const goToPrevPage = () => {
    setCurrentPage((prev) => (prev > 1 ? prev - 1 : prev));
    setInputPage(currentPage > 1 ? currentPage - 1 : currentPage);
  };
  const goToEndPage = () => {
    setCurrentPage(totalPages);
    setInputPage(totalPages);
  };

  const goToStartPage = () => {
    setCurrentPage(1);
    setInputPage(1);
  };

  const rowBodyTemplate = (rowData, props) => {
    return (
      <div className="secuencial-cls">{props.rowIndex + 1 + (currentPage - 1) * pageSize}</div>
    );
  };

  return !isLoading ? (
    <div className="cases-container">
      <div className="search-wrapper">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <DebounceInput
            id="searchTicket-input"
            className="buttonFilter2"
            value={casesFilter}
            debounceTimeout={700}
            onChange={(e) => setCasesFilter(e.target.value)}
            placeholder="Case ID"
            autoComplete="off"
          />
          {casesFilter && (
            <Button
              type="button"
              title="Clear case"
              className="pi pi-trash adjust-trash2"
              onClick={() => setCasesFilter('')}
            />
          )}
        </span>
        <Calendar
          placeholder="Select a date range"
          selectionMode="range"
          className="range-picker"
          readOnlyInput
          hourFormat="24"
          value={rangeDates}
          onChange={handleDates}
          dateFormat="mm-dd-yy"
          onDateChange
        />
        <MultiSelect
          optionLabel="description"
          placeholder="Case Type"
          className="history-A-dropdown"
          options={typesInfo}
          value={selectedCasesType}
          onChange={(e) => {
            if (e.value.length === 1) {
              setSelectedCasesType([e.value[0]]);
            } else {
              e.value[1] && setSelectedCasesType([e.value[1]]);
            }
          }}
          filter
          maxSelectedLabels={2}
        />
        <MultiSelect
          optionLabel="name"
          placeholder="Status"
          className="history-A-dropdown"
          options={STATUS_DEFAULT}
          value={selectedCasesStatus}
          onChange={(e) => {
            if (e.value.length === 1) {
              setSelectedCasesStatus([e.value[0]]);
            } else {
              e.value[1] && setSelectedCasesStatus([e.value[1]]);
            }
          }}
          filter
          maxSelectedLabels={2}
        />
        {!disableClear && (
          <Button
            title="Clear search"
            icon="pi pi-trash"
            className="history-A-clear"
            onClick={handleReset}
          />
        )}
        <Button
          title="Refresh tickets"
          className="refresh-basket"
          onClick={() => {
            fetchCases();
            showSnackbar('success', '', 'Cases refreshed!', 2000);
          }}
        >
          <img className="refresh-img" src={RefreshIcon} alt="refresh tickets button" />
        </Button>
      </div>
      {instances?.length > 0 && <CasesStats />}
      <DataTable
        emptyMessage="No cases found."
        className="p-datatable-striped"
        value={instances?.length > 0 ? instances : []}
        responsiveLayout="scroll"
        paginator={instances?.length > 10}
        removableSort
        resizableColumns
        // globalFilterFields={['case_id, status']}
        scrollable
        selectionMode="single"
        onSelectionChange={(e) => handleSeeCase(e.value)}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate={
          !casesFilter
            ? `Showing ${(currentPage - 1) * pageSize + first + 1} to ${
                (currentPage - 1) * pageSize + first + rows
              }`
            : ''
        }
        first={first}
        rows={rows}
        totalRecords={totalRecords}
        onPage={onPaginate}
      >
        <Column
          header=""
          headerStyle={{
            minWidth: '50px',
            textAlign: 'center',
            backgroundColor: '#22384d',
            color: 'white',
          }}
          bodyStyle={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textAlign: 'center',
          }}
          body={rowBodyTemplate}
        />
        <Column
          field="creationDate"
          filterField="creationDate"
          header=""
          headerStyle={{
            backgroundColor: '#22384d',
            color: 'white',
            width: '60px',
            minWidth: '60px',
            maxWidth: '60px',
            textAlign: 'center',
          }}
          bodyStyle={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textAlign: 'center',
          }}
          body={ConditionColumn}
          sortable
        />
        <Column
          field="case_id"
          filterField="case_id"
          header="Case ID"
          headerStyle={{
            maxWidth: '100px',
            width: '100px',
            textAlign: 'center',
            backgroundColor: '#22384d',
            color: 'white',
          }}
          bodyStyle={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textAlign: 'center',
          }}
        />
        <Column
          field="caseType"
          header="Case Type"
          headerStyle={{
            maxWidth: '180px',
            mminWidth: '180px',
            width: '180px',
            textAlign: 'left',
            backgroundColor: '#22384d',
            color: 'white',
          }}
          bodyStyle={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textAlign: 'left',
          }}
          sortable
        />
        <Column
          field="state.id"
          header="State"
          headerStyle={{
            width: '220px',
            minWidth: '220px',
            textAlign: 'left',
            backgroundColor: '#22384d',
            color: 'white',
          }}
          bodyStyle={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
          sortable
        />
        <Column
          field="status"
          filterField="status"
          header="Status"
          headerStyle={{
            width: '170px',
            height: '50px',
            minWidth: '170px',
            maxWidth: '170px',
            textAlign: 'center',
            backgroundColor: '#22384d',
            color: 'white',
          }}
          body={StatusColumn}
          bodyStyle={{ overflow: 'visible' }}
          sortable
        />
        <Column
          field="lastUpdate"
          filterField="lastUpdate"
          header="Last Update"
          headerStyle={{
            backgroundColor: '#22384d',
            color: 'white',
            width: '170px',
            minWidth: '170px',
            maxWidth: '170px',
            textAlign: 'center',
          }}
          bodyStyle={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textAlign: 'center',
          }}
          body={UpdatedDateColumn}
          sortable
        />
        <Column
          field="closing"
          filterField="closing"
          header="Closed on"
          headerStyle={{
            backgroundColor: '#22384d',
            color: 'white',
            width: '170px',
            minWidth: '170px',
            maxWidth: '170px',
            textAlign: 'center',
          }}
          bodyStyle={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textAlign: 'center',
          }}
          body={ClosingDateColumn}
          sortable
        />
        <Column
          field=""
          filterField=""
          header="Tags"
          headerStyle={{
            backgroundColor: '#22384d',
            color: 'white',
            width: '170px',
            minWidth: '170px',
            maxWidth: '170px',
            textAlign: 'center',
          }}
          bodyStyle={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textAlign: 'center',
          }}
          sortable
        />
      </DataTable>
      {totalPages !== 0 && totalPages !== 1 && (
        <div className="paginator-wrapper" title="Each group has 500 cases">
          <div className="paginator-flex bounce">
            <div className="paginator-bg">
              <Button
                type="button"
                className="paginator-btn"
                onClick={goToStartPage}
                icon="pi pi-angle-left"
                disabled={currentPage === 1}
              >
                <div className="pi pi-angle-left adj-right" />
              </Button>
              <Button
                type="button"
                className="paginator-btn"
                onClick={goToPrevPage}
                icon="pi pi-angle-left"
                disabled={currentPage === 1}
              />
              <span>
                {`Group `}
                <DebounceInput
                  type="number"
                  value={inputPage}
                  className="paginator-page bounce"
                  onChange={handlePageInput}
                  onBlur={handlePageInput}
                  debounceTimeout={700}
                  min={1}
                  max={totalPages}
                  disabled={totalPages === 1}
                />
                {` of ${totalPages}`}
              </span>
              <Button
                type="button"
                className="paginator-btn"
                onClick={goToNextPage}
                icon="pi pi-angle-right"
                disabled={currentPage === totalPages || totalPages === 1}
              />
              <Button
                type="button"
                className="paginator-btn"
                onClick={goToEndPage}
                icon="pi pi-angle-right"
                disabled={currentPage === totalPages || totalPages === 1}
              >
                <div className="pi pi-angle-right adj-right" />
              </Button>
            </div>
            <div className="paginator-flex">
              <div className="paginator-total-txt">
                <p>Total cases:</p>
                {totalRecords}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  ) : (
    <div className="loader-wrapper">
      <Loader fit transparant />
    </div>
  );
}

History.propTypes = {};

export default History;
