import { httpRequest, wrap } from './tools';

const getEnvelope = async (caseId, docusignId) => {
  const fn = async () => {
    // const payload = {
    //   ticket: {
    //     id: ticketId,
    //   },
    //   signer,
    // };
    const url = `/cases/${caseId}/envelopes/${docusignId}`;
    const response = await httpRequest(url, 'GET', {}, true);

    return response.data;
  };
  return wrap(fn);
};

const generateUrlEnvelope = async ({ caseId, docusignId, payload }) => {
  const fn = async () => {
    const url = `/cases/${caseId}/envelopes/${docusignId}/generateUrl`;
    const response = await httpRequest(url, 'POST', payload, true);
    if (response.status === 201) {
      return response.data;
    }
    return response.data;
  };

  return wrap(fn);
};

const createEnvelope = async (caseId, payload) => {
  const fn = async () => {
    const url = `/cases/${caseId}/envelopes`;
    const response = await httpRequest(url, 'POST', payload, true);
    if (response.status === 201) {
      return response.data;
    }
    return response.data;
  };

  return wrap(fn);
};

export { getEnvelope, createEnvelope, generateUrlEnvelope };
