import { httpRequest, wrap } from './tools';

const getWorkspace = async (id) => {
  const fn = async () => {
    const url = `/workspaces/${id}`;
    const response = await httpRequest(url, 'GET', {}, true);
    return response;
  };
  return wrap(fn);
};

const getAllWorkspaces = async (id) => {
  const fn = async () => {
    const url = `/users/${id}/workspaces`;
    const response = await httpRequest(url, 'GET', {}, true);
    return response;
  };

  return wrap(fn);
};

const getAllUsersByWorkspace = async (id) => {
  const fn = async () => {
    const url = `/workspaces/${id}/users`;
    const response = await httpRequest(url, 'GET', {}, true);
    return response;
  };

  return wrap(fn);
};

const getAllEnginesByUser = async (userId) => {
  const fn = async () => {
    const url = `/users/${userId}/engines`;
    const response = await httpRequest(url, 'GET', {}, true);
    return response;
  };

  return wrap(fn);
};

const getAppsByEngine = async (engineId) => {
  const fn = async () => {
    const url = `/engines/${engineId}/apps`;
    const response = await httpRequest(url, 'GET', {}, true);
    return response;
  };

  return wrap(fn);
};

const getEnginesByWorkspace = async (workspaceId) => {
  const fn = async () => {
    const url = `/workspaces/${workspaceId}/engines`;
    const response = await httpRequest(url, 'GET', {}, true);

    return response.data;
  };

  return wrap(fn);
};

const linkHumanWorkspace = async (workspaceId, payload) => {
  const fn = async () => {
    const url = `/workspaces/${workspaceId}/add-human`;
    const response = await httpRequest(url, 'POST', payload, true);
    return response.status;
  };

  return fn();
};

const removeHumanToWorkspace = async (workspaceId, payload) => {
  const fn = async () => {
    const url = `/workspaces/${workspaceId}/remove-human`;
    const response = await httpRequest(url, 'POST', payload, true);
    return response.data;
  };

  return wrap(fn);
};

const getAllHumansByWorkspace = async (id, isDirect = false) => {
  const fn = async () => {
    const url = `/workspaces/${id}/humans?isDirect=${isDirect}`;
    const response = await httpRequest(url, 'GET', {}, true);
    return response;
  };

  return wrap(fn);
};

const getStatus = async () => {
  const fn = async () => {
    const url = `/status`;
    const response = await httpRequest(url, 'GET', {}, true);
    return response.data;
  };

  return wrap(fn);
};

export {
  getWorkspace,
  getAllWorkspaces,
  getAllUsersByWorkspace,
  getAllEnginesByUser,
  getAppsByEngine,
  getEnginesByWorkspace,
  linkHumanWorkspace,
  removeHumanToWorkspace,
  getAllHumansByWorkspace,
  getStatus,
};
