/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable prefer-const */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import React, { useEffect, useContext, useState, useRef } from 'react';

import { DebounceInput } from 'react-debounce-input';
import { useHistory } from 'react-router-dom';

import { AppCard, Button, Loader, SelectButton } from '../../components';
import { AccountContext } from '../../contexts/AccountContext';
import { ToolsContext } from '../../contexts/ToolsContext';
import { CreateAppForm, ConfirmDeleteAppForm, ThunderAppForm } from '../../forms';
import {
  appDashboardService,
  enginesService,
  flowsService,
  workSpaceService,
} from '../../services';
import utils from '../../utils/utils';

import './MyAppsPage.css';

const MyAppsPage = ({ location }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const searchRef2 = useRef('fade-right');
  const {
    accountInfo,
    apps,
    setApps,
    setSelectedApp,
    setSelectedFlow,
    setMenuCollapse,
    tools,
    setTools,
    handleCloseModal,
    setTotalApps,
    workflowsByWs,
    setWorkflowsByWs,
  } = useContext(AccountContext);
  const { showSnackbar } = useContext(ToolsContext);
  const { userData, workspaceData } = accountInfo;
  const { id: userId, enabledModules } = userData;
  const [filter, setFilter] = useState(0);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [enginesByWorkspace, setEnginesByWorkspace] = useState([]);

  const handleSortingByDescription = (info) => {
    const withDescription = info.filter((d) => d.description);
    const withoutDescription = info.filter((d) => !d.description);
    return [...withDescription, ...withoutDescription];
  };

  const fetchAllApps = async (loader = true) => {
    loader && setLoading(true);
    if (userId) {
      const { success, data: allApps } = await appDashboardService.getAllApps(userId);
      setTotalApps(allApps.length);
      if (success) {
        setApps(allApps);
        loader && setLoading(false);
      } else {
        loader && setLoading(false);
      }
    } else {
      showSnackbar('error', '', 'Apps could not be loaded');
      loader && setLoading(false);
    }
    setLoading(false);
  };

  const handleCreateFlow = async (info) => {
    const payload = {
      name: info.name,
      description: info.description,
      WS: workspaceData?.data?.id,
    };
    const { success } = await await flowsService.createFlow(payload);

    if (success) {
      fetchAllApps();
      showSnackbar('success', 'Atention!', 'Workflow created succesfully');
    } else {
      showSnackbar('error', '', 'Something went wrong updating the app! Please try again');
    }
    handleCloseModal();
  };

  const filterOptions2 = [{ name: 'New App', value: 1 }];

  const onAppEdit = (bot) => {
    setSelectedApp(bot);
    history.push({ pathname: '/apps/edit' });
  };

  const handleFocusInput = () => {
    const input = document.getElementById('searchApp-input');
    input?.focus();
  };

  const onAppHistorySelect = (app) => {
    setSelectedApp(app);
    history.push({ pathname: '/apps/history' });
  };

  const fetchFlows = async () => {
    const workflows = (await flowsService.getAllFlowsByWS()).data.data;
    if (workflows) {
      setWorkflowsByWs(workflows);
    }
  };

  const onFlowSelected = async (app, engine) => {
    const workflows = (await flowsService.getAllFlows('1')).data;
    const appWorkflow = workflows?.flows?.find((w) => w.id === app.workflow.id);

    const dataEngine = await enginesService.getEngine(engine);
    setSelectedFlow({ ...appWorkflow, WS: dataEngine?.data?.workspace.id });
    history.push(`/flows/flow?canEditFlow=${true}&&isNewFlow=false`);
  };

  const HeaderForm = ({ title }) => {
    return <div className="activity-header bounce">⚡{` ${title}`}</div>;
  };

  const handleExecEngine = () => {};

  const showModal = (content, header) => {
    setTools({
      ...tools,
      manageModal: { content, header },
      showModal: true,
    });
  };

  const onGenerateSelected = async (app) => {
    const engineForm = <ThunderAppForm data={app} handleSubmit={handleExecEngine} />;
    showModal(engineForm, <HeaderForm title={`New instance(s) of ${app.name}`} />);
  };

  useEffect(() => {
    if (filter !== 2) {
      setGlobalFilter(null);
    }
    return () => {
      setGlobalFilter(null);
    };
  }, [filter]);

  const getEnginesByWorkspace = async () => {
    const { success, data: enginesByWorkspaceData } = await workSpaceService.getEnginesByWorkspace(
      workspaceData?.data?.id
    );
    if (success) {
      setEnginesByWorkspace(
        enginesByWorkspaceData.map((engine) => ({
          ...engine,
        }))
      );
    } else {
      return enginesByWorkspaceData;
    }
  };

  useEffect(async () => {
    getEnginesByWorkspace();
    fetchFlows();
    fetchAllApps();

    // setTimeout(() => {
    //   searchRef2.current = '';
    // }, 1000);
  }, []);

  const onChangeIsActive = async (appId, value) => {
    const { success } = await appDashboardService.updateApp(appId, {
      isActive: value,
    });
    if (success) {
      showSnackbar('success', '', 'App updated succesfully');
      fetchAllApps(true);
    } else {
      showSnackbar('error', '', 'Something went wrong updating the app! Please try again');
    }
  };

  const onDeleteApp = async (appId) => {
    setLoading(true);
    setFilter(0);
    setGlobalFilter(null);
    const { success } = await appDashboardService.deleteApp(appId);
    if (success) {
      setApps([]);
      showSnackbar('success', '', 'App deleted succesfully');
      fetchAllApps(false);
    } else {
      showSnackbar('error', '', 'Something went wrong updating the app! Please try again');
    }
    handleCloseModal();
    setLoading(false);
  };

  const showConfirmationDeleteApp = (id) => {
    setTools({
      ...tools,
      manageModal: {
        content: (
          <ConfirmDeleteAppForm
            onDeleteApp={() => onDeleteApp(id)}
            onCloseModal={handleCloseModal}
          />
        ),
      },
      showModal: true,
    });
  };

  const handleCreateApp = async (e, info) => {
    setLoading(true);
    e.preventDefault();
    const { success } = await appDashboardService.createApp(
      {
        id: userId,
      },
      {
        name: info.name,
        description: info.description,
        engine: info.engine,
      }
    );

    await handleCreateFlow({
      name: `AppFlow_${info.name}`,
      description: info.description,
    });

    if (success) {
      showSnackbar('success', 'Atention!', 'App created succesfully');
    } else {
      showSnackbar('error', '', 'Something went wrong updating the app! Please try again');
    }
    handleCloseModal();
    setLoading(false);
  };

  const HeaderAddApp = ({ title }) => {
    return <div className="activity-header">{title}</div>;
  };

  const filterTemplate = (option) => {
    if (option.value === 1) {
      const addAppForm = (
        <CreateAppForm
          data={{
            workspace: workspaceData?.data?.id || '',
          }}
          handleSubmit={handleCreateApp}
        />
      );
      return (
        <Button
          className="button create-button"
          label={option.name}
          icon="pi pi-plus"
          onClick={() => {
            showModal(addAppForm, <HeaderAddApp title="New App" />);
          }}
        />
      );
    }
    return <p className="chipFilter">{option.name}</p>;
  };

  useEffect(async () => {
    setMenuCollapse(true);
    if (!location?.goBack && apps?.length === 0) {
      const handleUserValidation = () => {
        const validUser = enabledModules?.some((data) => data.id === 'APPS');
        if (!validUser) {
          history.push('/401');
        }
      };
      handleUserValidation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apps]);

  const toogleSearch = () => {
    setFilter(2);
  };

  const ListEmptyState = () => {
    return (
      <div className="list-empty-apps-wrapper">
        <div className="detail-container">
          <p className="list-empty-apps-message">No apps found!</p>
          <SelectButton
            value={filter}
            options={filterOptions2}
            onChange={(e) => setFilter(e.value === null ? 0 : e.value)}
            optionLabel="name"
            className="filters-apps"
            itemTemplate={filterTemplate}
          />
        </div>
      </div>
    );
  };

  const handleFiltering = () => {
    const result = apps?.filter((app) => {
      const appNameLower = app?.name?.toLowerCase();
      const globalFilterLower = globalFilter?.toLowerCase();
      return appNameLower?.indexOf(globalFilterLower) >= 0;
    });
    return globalFilter ? result : apps;
  };

  const appsFilteredByName = handleFiltering();

  // const showLastLine =
  // eslint-disable-next-line max-len
  //   isMobile || isTablet ? appsFilteredByName.length > 4 : appsFilteredByName.length > 10;

  const ABCenginesByName = ({ filtered, groupsEnginesByApp, isNotEmpty, children }) => {
    const displayWorkflow = (values) => {
      const result = workflowsByWs?.some((w) => w._id === values);
      return result;
    };
    const handleExternalWorflowMsg = (app) => {
      showSnackbar(
        'info',
        '',
        `The workflow belongs to another workspace, the workflow ${app?.workflow?.id} is not enabled.`,
        6000
      );
    };

    return (
      <>
        <div className="my-apps-list" style={{ padding: '0px', paddingBottom: '100px' }}>
          {children}
          {isNotEmpty() ? (
            groupsEnginesByApp?.map((g, i2) =>
              filtered[i2].length > 0 ? (
                <div style={{ width: '100%' }} key={`App2-${g[0] + i2}`}>
                  <div className="engine-title-wrapper">
                    <h2 className="title-section bounce">{g[0]}</h2>
                    <p className="subtitle-section bounce">Engine</p>
                  </div>
                  <div className="my-apps-list">
                    {filtered[i2]?.map((app, i) => {
                      return g[0] === app?.engine?.name ? (
                        <article className="my-apps-list-item" key={`App-${app.id + i}`}>
                          <AppCard
                            app={app}
                            onAppEdit={onAppEdit}
                            backgroundColor={utils.getColor(i, utils.appDashboardColors)}
                            onHistorySelect={onAppHistorySelect}
                            onFlowSelected={onFlowSelected}
                            onChangeIsActive={onChangeIsActive}
                            onDelete={() => showConfirmationDeleteApp(app.id)}
                            onGenerateSelected={onGenerateSelected}
                            showFlow={displayWorkflow(app?.workflow?.id)}
                            handleExternalWorkflowMsg={handleExternalWorflowMsg}
                            engine={g[1]}
                          />
                        </article>
                      ) : null;
                    })}
                  </div>
                  {groupsEnginesByApp.length - 2 === i2 && <div className="spanner2"> </div>}
                </div>
              ) : null
            )
          ) : (
            <ListEmptyState />
          )}
          {/* {showLastLine ? <hr style={{ width: '100%' }} /> : <></>} */}
        </div>
      </>
    );
  };

  const groupsEnginesByApp = enginesByWorkspace?.map((e) => {
    return [e.name, e.id];
  });

  const filtered = groupsEnginesByApp.map((app) => {
    return appsFilteredByName.filter((a) => {
      return a.engine.id === app[1] ? app[0] : null;
    });
  });

  const isNotEmpty = () => {
    return filtered.some((f) => {
      return f.length >= 1;
    });
  };

  return !loading ? (
    <div className="my-apps-wrapper">
      {apps.length > 0 ? (
        <div className="header-flows">
          <div className="info-space-A">
            <Button
              className="add-ticket-btn"
              label=""
              icon="pi pi-plus"
              style={{ margin: '0px' }}
              onClick={() =>
                showModal(
                  <CreateAppForm
                    data={{
                      workspace: workspaceData?.data?.id || '',
                    }}
                    handleSubmit={handleCreateApp}
                  />,
                  <HeaderForm title="New App" />
                )
              }
            />
            <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <DebounceInput
                id="searchApp-input"
                className="buttonFilter2"
                value={globalFilter}
                debounceTimeout={700}
                onChange={(e) => setGlobalFilter(e.target.value)}
                placeholder="Search app"
                autoComplete="off"
              />
            </span>
            {globalFilter && (
              <Button
                type="button"
                title="Clear apps"
                className="pi pi-trash adjust-trash"
                onClick={() => setGlobalFilter('')}
              />
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className="my-apps-container">
        <ABCenginesByName
          filtered={filtered}
          groupsEnginesByApp={groupsEnginesByApp}
          isNotEmpty={isNotEmpty}
        />
      </div>
    </div>
  ) : (
    <Loader fit transparant />
  );
};

export default MyAppsPage;
