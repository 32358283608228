import { httpRequest, wrap } from './tools';

const getAllFlows = async (id) => {
  const fn = async () => {
    const url = `/flowmanager/flows/all`;
    const response = await httpRequest(url, 'POST', { creator: id, all: false }, true);

    return response.data;
  };

  return wrap(fn);
};

const getAllFlowsByWS = async () => {
  const fn = async () => {
    const url = `/flowmanager/flows`;
    const response = await httpRequest(url, 'GET', {}, true);

    return response.data;
    // return true;
  };

  return wrap(fn);
};

const createFlow = async (payload) => {
  const fn = async () => {
    const url = `/flowmanager/flows`;
    const response = await httpRequest(url, 'POST', payload, true);
    return response.data;
    // return true;
  };

  return wrap(fn);
};

const updateFlow = async (id, payload) => {
  const fn = async () => {
    const url = `/flowmanager/flows/${id}`;
    const response = await httpRequest(url, 'PATCH', payload, true);

    return response.data;
  };

  return wrap(fn);
};

// eslint-disable-next-line import/prefer-default-export
export { getAllFlows, getAllFlowsByWS, updateFlow, createFlow };
