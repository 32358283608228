/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';

import Moment from 'moment';
import PropTypes from 'prop-types';
import { Stepper, Step, StepTitle, StepDescription, StepStatus } from 'react-progress-stepper';

import './Stepper.css';

const StepperCmp = ({ history, path }) => {
  useEffect(() => {
    let stepsData = [];
    stepsData = path?.filter((s) => {
      return s.order !== -1 && s.hide !== 1;
    });
    return () => {};
  }, []);

  return history.length > 0 && path ? (
    <div className="stepper-wrapper">
      <Stepper vertical step={history.length === 1 ? 0 : history.length}>
        {history?.map((s, index) => {
          const newData = path?.filter((a) => {
            return a.id === history[index].field_new_data;
          });
          return newData && newData.length > 0 ? (
            <Step vertical key={history[index].field_new_data}>
              {/* <StepStatus /> */}
              <StepTitle>{newData[0]?.description}</StepTitle>
              <StepDescription>
                {Moment(history[index]?.transaction_date).format('YYYY-MM-DD - HH:mm:ss')}
              </StepDescription>
            </Step>
          ) : null;
        })}
      </Stepper>
    </div>
  ) : null;
};

StepperCmp.propTypes = { history: PropTypes.array.isRequired, path: PropTypes.array.isRequired };

export default StepperCmp;
