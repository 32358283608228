import axios from 'axios';

const axiosInstance = axios.create({
  headers: { 'Content-Type': 'application/json' },
});

axiosInstance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      // eslint-disable-next-line no-undef
      window.location.replace('/');
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
