/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable prefer-const */
/* eslint-disable no-case-declarations */
/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useState, useEffect } from 'react';

import { Button, Dropdown, InputText, MultiSelect } from '../../components/BasicComponents/index';
import { AccountContext } from '../../contexts/AccountContext';
import { TicketsContext } from '../../contexts/TicketsContext';
import { ToolsContext } from '../../contexts/ToolsContext';
import { humansService } from '../../services';
import DeleteMember from '../DeleteMember/DeleteMember';

import './EditMemberForm.css';

const EditMemberForm = ({
  isEditingMember = false,
  data,
  isNewMember,
  handleSubmit,
  onClickMember,
}) => {
  // console.log(data);
  const [selectedID, setSelectedID] = useState(data?.human_id);
  const [selectedSlackLink, setSelectedSlackLink] = useState(data?.Slack_link);
  const [selectedName, setSelectedName] = useState(data?.name);
  const [selectedEmail, setSelectedEmail] = useState(data?.email);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [selectedWorkflow, setSelectedWorkflow] = useState(null);

  const { showSnackbar, setShowDetails } = useContext(AccountContext);
  const { showModal, handleCloseModal } = useContext(ToolsContext);
  const { groupsRetrieved, getGroups, setHumansRetrieved } = useContext(TicketsContext);
  const [teams, setTeams] = useState(null);
  const [membersAvailable, setMembersAvailable] = useState(null);
  const [teamsByHuman, setTeamsByHuman] = useState(null);

  const toogleShowAdvanced = () => {
    setShowAdvanced((s) => !s);
  };

  const retriveHumans = async () => {
    const { success, data: info } = await humansService.getHumansByWS();
    if (success) {
      setHumansRetrieved(info.data[0]);
    }
  };

  const resetModuleTeam = () => {
    setShowDetails(false);
  };

  const updateAHuman = async (payload) => {
    const { success: humanInfoFlag, data: humanInfoResponse } = await humansService.updateHuman(
      payload
    );
    if (humanInfoFlag && humanInfoResponse.data.result === 'updated') {
      await retriveHumans();
      resetModuleTeam();
      showSnackbar('success', '', 'Member edited!', 4000);
      handleCloseModal();
    } else {
      showSnackbar('error', '', `Failed member edition!`, 5000);
    }
  };

  const getTeamsOfHuman = async () => {
    if (data?.human_id) {
      const { success: teamsFlag, data: teamsResponse } = await humansService.getGroupHumansByHuman(
        data?.human_id
      );
      if (teamsFlag) {
        setTeamsByHuman(teamsResponse.data);
        setTeams(teamsResponse.data);
        if (teamsResponse?.data) {
          let idsEnB = new Set(teamsResponse?.data?.map((obj) => obj.id));
          let arrayAfiltrado = groupsRetrieved?.filter((obj) => !idsEnB.has(obj.id));
          setMembersAvailable([...arrayAfiltrado, ...teamsResponse.data]);
        }
      } else {
        setTeams(null);
      }
    } else {
      setMembersAvailable([...groupsRetrieved]);
    }
  };

  const membersOptions = [
    { name: 'Edit member', code: 'update', icon: 'pi pi-fw pi-refresh', display: true },
    { name: 'Delete member', code: 'delete', icon: 'pi pi-fw pi-trash', display: true },
  ];

  const modulesOptionTemplate = (option) => {
    return (
      <div className="flex option-backgroung">
        <i className={option.icon} />
        <p className="value-txt">{option.name}</p>
      </div>
    );
  };

  const onWorkflowChange = (e) => {
    setSelectedWorkflow(e.value);
  };

  const onDeleteMember = async (memberId) => {
    const success = await humansService?.deleteHuman(memberId);
    if (success) {
      await retriveHumans();
      resetModuleTeam();
      showSnackbar('success', '', 'Member deleted!', 4000);
      handleCloseModal();
    } else {
      showSnackbar('error', '', 'Member could not be deleted!', 5000);
    }
  };

  const handleDeleteMember = (dataMember) => {
    const deleteMemberForm = (
      <DeleteMember
        isEditingMember
        handleSubmit={onDeleteMember}
        data={dataMember}
        loading={false}
        handleCloseCancel={handleCloseModal}
      />
    );
    showModal(deleteMemberForm, <HeaderForm titleForm="Delete Member" />);
  };

  const ParamsExpectedCmp = () => {
    return (
      <div className="flex-row groupParams">
        {teams?.map((p) => {
          return (
            <Button
              key={p.name}
              type="button"
              className="resultExpected"
              onClick={(val) => {
                val.stopPropagation();
                onClickMember(p.name);
              }}
            >
              {`${p.name} ${showAdvanced ? `(${p.id})` : ''}`}
            </Button>
          );
        })}
      </div>
    );
  };

  const operatorTemplate = (c) => {
    return (
      <div className="wrapper-groups3" style={{ background: c.colorTeam || 'gray' }}>
        <p className="operator">{c.name}</p>
      </div>
    );
  };

  const validateForm = !(isNewMember || isEditingMember);

  const filterTeam = (values) => {
    return values.map((item) => {
      return item.id;
    });
  };

  const buildPayload = () => {
    const memberPayload = {};
    if (selectedID) {
      memberPayload.id = selectedID;
    }
    if (selectedName) {
      memberPayload.name = selectedName;
    }
    if (selectedEmail) {
      memberPayload.email = selectedEmail;
    }
    if (selectedSlackLink) {
      memberPayload.slackLink = selectedSlackLink;
    }
    if (teamsByHuman?.length > 0) {
      memberPayload.teams = filterTeam(teamsByHuman);
    }
    return memberPayload;
  };

  const onSubmit = async () => {
    const dataMember = buildPayload();
    const result = await handleSubmit(dataMember);
    if (result) {
      resetModuleTeam();
      getGroups();
    }
  };

  const SlackIdCmp = () => {
    return selectedSlackLink ? (
      <div className="groupParams">
        <div className="flex-row">
          <div className="statesExpected">{`${selectedSlackLink}`}</div>
        </div>
      </div>
    ) : (
      <p className="none-params bounce">⚠️ Slack Link is empty.</p>
    );
  };

  useEffect(async () => {
    await getTeamsOfHuman();
    return () => {};
  }, []);

  const HeaderForm = ({ titleForm }) => {
    return <div className="activity-header bounce">{titleForm}</div>;
  };

  useEffect(async () => {
    if (selectedWorkflow) {
      const { code } = selectedWorkflow;
      switch (code) {
        case 'update':
          const memberForm = (
            <EditMemberForm isEditingMember data={data} handleSubmit={updateAHuman} />
          );
          showModal(memberForm, <HeaderForm titleForm="Edit Member" />);
          break;
        case 'delete':
          handleDeleteMember(data);
          break;
        default:
          break;
      }
    }
    setSelectedWorkflow(null);
    return () => {
      setSelectedWorkflow(null);
    };
  }, [selectedWorkflow]);

  return (
    <div className={validateForm ? 'casetype-wrapper' : 'casetype-wrapper2'}>
      <section className="section-scroller">
        {validateForm ? (
          <div className="section-scroller">
            <div className="flex-wrapper">
              <div className="flex aling-items-center">
                <Button
                  title="Previous page"
                  icon="pi pi-arrow-left"
                  className="back2 bounce"
                  onClick={resetModuleTeam}
                />
                <h2 className="subtitle-data-txt">Member Info</h2>
              </div>
              {!isEditingMember && (
                <div className="flex-row">
                  <Button className="advanced-cls3" type="button" onClick={toogleShowAdvanced}>
                    Advanced Settings
                  </Button>
                  <Dropdown
                    value={selectedWorkflow}
                    options={membersOptions}
                    onChange={onWorkflowChange}
                    optionLabel="name"
                    placeholder={null}
                    filterPlaceholder={null}
                    itemTemplate={modulesOptionTemplate}
                  />
                </div>
              )}
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="casetype-body">
          <div className={`flex-row ${isEditingMember ? 'user-formGroup2' : 'user-formGroup'}`}>
            <label htmlFor="name">Name</label>
            <InputText
              disabled={!isEditingMember}
              spellCheck={false}
              autoComplete="off"
              id="name"
              placeholder="Enter the member's name"
              name="name"
              className="user-inputText"
              onChange={(e) => setSelectedName(e.target.value)}
              value={selectedName}
            />
          </div>
          <div className={`flex-row ${isEditingMember ? 'user-formGroup2' : 'user-formGroup'}`}>
            <label htmlFor="email">Email</label>
            <InputText
              spellCheck={false}
              autoComplete="off"
              disabled={validateForm}
              id="email"
              placeholder="Enter the member's email"
              name="email"
              className="user-inputText"
              type="email"
              onChange={(e) => setSelectedEmail(e.target.value)}
              value={selectedEmail}
            />
          </div>
          {validateForm && (
            <div className={`flex-row ${isEditingMember ? 'user-formGroup2' : 'user-formGroup'}`}>
              <label htmlFor="member">
                {`${!isEditingMember ? 'Active' : ''} Teams (${
                  !isEditingMember ? teamsByHuman?.length || '' : membersAvailable?.length - 1 || ''
                })`}
              </label>
              {isEditingMember ? (
                <MultiSelect
                  optionLabel="name"
                  placeholder="Select the team(s) to take part"
                  className="blue-input"
                  options={membersAvailable}
                  value={teamsByHuman}
                  onChange={(e) => {
                    setTeamsByHuman([...e.target.value]);
                  }}
                  itemTemplate={operatorTemplate}
                />
              ) : (
                <>
                  {teams?.length > 0 ? (
                    <ParamsExpectedCmp />
                  ) : (
                    <p className="none-params bounce">⚠️ This member currently has no teams.</p>
                  )}
                </>
              )}
            </div>
          )}
          {showAdvanced && (
            <>
              {!isNewMember && (
                <div
                  className={`flex-row ${isEditingMember ? 'user-formGroup2' : 'user-formGroup'}`}
                >
                  <label htmlFor="email">Member ID</label>
                  <InputText
                    spellCheck={false}
                    disabled
                    id="id"
                    name="id"
                    autoComplete="off"
                    className="user-inputText"
                    placeholder="Enter the team color"
                    type="email"
                    onChange={(e) => {
                      setSelectedID(e.target.value);
                    }}
                    value={selectedID}
                  />
                </div>
              )}
              <div className={`flex-row ${isEditingMember ? 'user-formGroup2' : 'user-formGroup'}`}>
                <label htmlFor="email">Slack ID</label>
                {validateForm ? (
                  <SlackIdCmp />
                ) : (
                  <InputText
                    spellCheck={false}
                    disabled={!isEditingMember}
                    id="email"
                    autoComplete="off"
                    placeholder="Enter the member's slack ID"
                    name="email"
                    className="user-inputText"
                    onChange={(e) => {
                      setSelectedSlackLink(e.target.value);
                    }}
                    value={selectedSlackLink}
                  />
                )}
              </div>
            </>
          )}
          {!validateForm && (
            <div className="edit-ticket-footer">
              <Button className="advanced-cls" type="button" onClick={toogleShowAdvanced}>
                Advanced Settings
              </Button>
              <Button
                onClick={onSubmit}
                className="edit-action-btn"
                label={isNewMember ? 'Add' : 'Edit'}
              />
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default EditMemberForm;
