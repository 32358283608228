/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useContext, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import workflowIcon from '../../assets/images/Workflow_4803.png';
import { EditAppCard, EventsTable, Dropdown, Button, TabView, TabPanel } from '../../components';
import { AccountContext } from '../../contexts/AccountContext';
import { flowsService, appDashboardService } from '../../services';

import './EditAppForm.css';

const EditAppForm = () => {
  const {
    showSnackbar,
    selectedApp,
    setSelectedFlow,
    workflowsByWs,
    setWorkflowsByWs,
  } = useContext(AccountContext);
  const { workflow, calendar: appCalendar, id } = selectedApp;
  const history = useHistory();
  const [selectedWorkflow, setSelectedWorkflow] = useState({});
  const [calendar, setCalendar] = useState([]);
  const [selectedCalendar, setSelectedCalendar] = useState({});

  const loadFlows = async () => {
    const flowsResult = await flowsService.getAllFlowsByWS();
    const convertedFlows = flowsResult?.data?.data ? flowsResult?.data?.data : null;
    if (flowsResult?.success) {
      const appWorkflow = convertedFlows?.find((w) => {
        return w._id === workflow?.id;
      });
      if (appWorkflow) setSelectedWorkflow(appWorkflow);
    }
    setWorkflowsByWs(convertedFlows);
  };

  const displayWorkflow = (values) => {
    const result = workflowsByWs.some((w) => {
      return w._id === values;
    });
    return result;
  };

  const loadCalendar = async () => {
    const calendarResult = await appDashboardService.getCalendars({ id: '1' });
    setCalendar(calendarResult.data.calendars);

    if (appCalendar) {
      const calendarSelected = calendarResult.data.calendars.find(
        (item) => item.id === appCalendar.id
      );
      setSelectedCalendar(calendarSelected || {});
    }
  };

  const updateApp = async (e, type) => {
    // console.log('e.value', e.value, type);
    const app = {};
    if (type === 'workflow') {
      app.workflow = { id: e.value._id };
    } else if (type === 'calendar') {
      app.calendar = { id: e.value.id };
    } else {
      return;
    }
    const { success } = await appDashboardService.updateApp(id, app);

    if (success) {
      showSnackbar('success', '', 'App updated succesfully');
    } else {
      showSnackbar('error', '', 'Something went wrong updating the app! Please try again');
    }
  };

  const onWorkflowChange = (e) => {
    setSelectedWorkflow(e.value);
    updateApp(e, 'workflow');
  };

  const onFlowSelected = async () => {
    setSelectedFlow(selectedWorkflow);
    history.push({
      pathname: `/flows/flow?canEditFlow=${true}&&isNewFlow=false`,
      state: selectedWorkflow,
    });
  };

  const onCalendarChange = (e) => {
    setSelectedCalendar(e.value);
    updateApp(e, 'calendar');
  };

  useEffect(async () => {
    await loadFlows();
    await loadCalendar();
  }, []);

  const countryOptionTemplate = (option) => {
    return (
      <div className="flex option-backgroung">
        <p className="value-txt">{option.name}</p>
      </div>
    );
  };

  return (
    <div className="edit-wrapper">
      <EditAppCard workflowId={workflow?.id} />
      {
        <section id="appMainData" className="flex-column">
          <div className="webhooks-info">
            <div className="webhooks-constainer">
              <p className="tag-title">Workflow</p>
              <div className="flex-row">
                <p className="edit-selectedWorkflow">{selectedWorkflow.name}</p>
                <Dropdown
                  value={selectedWorkflow}
                  options={workflowsByWs}
                  onChange={onWorkflowChange}
                  optionLabel="name"
                  placeholder={null}
                  filterPlaceholder={null}
                  itemTemplate={countryOptionTemplate}
                />
                <Button className="events-action" onClick={onFlowSelected}>
                  <img src={workflowIcon} className="button-useropt-image2" alt="Workflow icon" />
                </Button>
              </div>
            </div>
            <div className="webhooks-constainer">
              <label className="flex">
                <p className="tag-title">Calendar</p>
              </label>
            </div>
            <div className="flex-row">
              <p className="edit-selectedWorkflow">{selectedCalendar?.name}</p>
              <Dropdown
                value={selectedCalendar}
                options={calendar}
                onChange={onCalendarChange}
                optionLabel="name"
                placeholder={null}
                filterPlaceholder={null}
                itemTemplate={countryOptionTemplate}
              />
            </div>
          </div>
          <div>
            <h3 className="title-txt">List of Webhooks</h3>
            <EventsTable />
          </div>
        </section>
      }
      {/* <section className="flex-row edit-app-formgroup">
        <div className="flex-column">
          <label>History</label>
          <Button className="events-action">
            <img src={historyIcon} className="events-img" alt="Workflow icon" />
          </Button>
        </div>
        <div className="flex-column">
          <label>Analytics</label>
          <Button className="events-action">
            <img src={statsIcon} className="events-img" alt="Workflow icon" />
          </Button>
        </div>
      </section> */}
    </div>
  );
};

export default EditAppForm;
