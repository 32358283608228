/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react';

import { useHistory } from 'react-router-dom';

import { TabView, TabPanel, Loader } from '../../components';
import { AccountContext } from '../../contexts/AccountContext';
import CollectionsModuleDashboardSection from './CollectionsModuleDashboardSection';

import './CollectionsModule.css';

const CollectionsModule = () => {
  const history = useHistory();
  const { accountInfo, menuCollapse } = useContext(AccountContext);
  const { enabledModules } = accountInfo?.userData || {};
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const handleUserValidation = () => {
      const validUser = enabledModules?.some((data) => data.id === 'COLLECTIONS');
      if (!validUser) {
        history.push('/401');
      }
    };
    handleUserValidation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={menuCollapse ? 'cases-wrp2' : 'cases-wrp2-ext'}>
      <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
        <TabPanel header="External" headerClassName="bounce">
          <CollectionsModuleDashboardSection />
        </TabPanel>
        {/* <TabPanel header="Team Operations"> */}
        {/* <CollectionsModuleTeamOperationSection /> */}
        {/* </TabPanel> */}
      </TabView>
    </div>
  );
};

export default CollectionsModule;
