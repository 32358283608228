/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-unused-vars */
import AppsIcom from '../assets/nav-icons/Apps';
import Batches from '../assets/nav-icons/Batches';
import CasesIcon from '../assets/nav-icons/Cases2';
import CollectionsIcon from '../assets/nav-icons/Coins';
import DashboardIcon from '../assets/nav-icons/Dashboard';
import FormIcom from '../assets/nav-icons/Forms';
import ModelerIcon from '../assets/nav-icons/GitMerge';
import AdminIcon from '../assets/nav-icons/Settings';
import Tickets from '../assets/nav-icons/Ticket';
import WA from '../assets/nav-icons/WA';
import {
  EditAppPage,
  LoginPage,
  MyAppsPage,
  ModelerList,
  ModelerIframe,
  MainDashboard,
  AppsHistory,
  InstanceInfo,
  AdminInfo,
  CollectionsModule,
  HumanInterface,
  TicketResolution,
  CaseReport,
  CaseInstances,
  RecoveryPwd,
  BatchesModule,
  UpdateUser,
  WAModule,
  OneBatch,
  CasesModule,
  OneCase,
  FormsBlock,
} from '../containers';

// Auth components
const authRoutes = {
  id: 'Auth',
  path: '/',
  // eslint-disable-next-line react/react-in-jsx-scope
  icon: <DashboardIcon size={20} color="white" />,
  position: 'body',
  children: [
    {
      path: '/',
      name: 'Log in',
      component: LoginPage,
    },
  ],
  component: null,
};

// Auth components
const formsRoutes = {
  id: 'frontdesk',
  path: '/frontdesk',
  module: 'FRONTDESK',
  name: 'FrontDesk',
  // eslint-disable-next-line react/react-in-jsx-scope
  icon: <FormIcom size={20} color="white" />,
  position: 'body',
  children: [
    {
      path: '/frontdesk',
      name: 'frontdesk',
      component: FormsBlock,
    },
  ],
  component: null,
};

// Auth components
const recoveryRoutes = {
  id: 'Recovery',
  path: '/recovery',
  // eslint-disable-next-line react/react-in-jsx-scope
  icon: <></>,
  position: '',
  children: [
    {
      path: '/recovery',
      name: 'Recovery',
      component: RecoveryPwd,
    },
  ],
  component: null,
};

// Auth components
const userExtenalUpdatesRoutes = {
  id: 'UpdateUser',
  path: '/update',
  // eslint-disable-next-line react/react-in-jsx-scope
  icon: <></>,
  position: '',
  children: [
    {
      path: '/update',
      name: 'update',
      component: UpdateUser,
    },
  ],
  component: null,
};

const WACenter = {
  id: 'whatsapp',
  path: '/WA',
  name: 'Whatsapp',
  module: 'WA',
  // eslint-disable-next-line react/react-in-jsx-scope
  icon: <WA size={23} color="white" />,
  position: 'footer',
  children: [
    {
      name: 'Whatsapp',
      path: '/WA',
      component: WAModule,
    },
    // {
    //   name: 'Tickets',
    //   path: '/batches/:id',
    //   component: TicketResolution,
    // },
  ],
  component: null,
};

const casesCenter = {
  id: 'cases',
  path: '/cases',
  name: 'Cases',
  module: 'CASES',
  // eslint-disable-next-line react/react-in-jsx-scope
  icon: <CasesIcon size={23} color="white" />,
  position: 'body',
  children: [
    {
      name: 'Cases',
      path: '/cases',
      component: CasesModule,
    },
    {
      name: 'CasesId',
      path: '/cases/:id',
      component: OneCase,
    },
  ],
  component: null,
};

const batchesCenter = {
  id: 'batches',
  path: '/batches',
  name: 'Batches',
  module: 'BATCHES',
  // eslint-disable-next-line react/react-in-jsx-scope
  icon: <Batches size={23} color="white" />,
  position: 'body',
  children: [
    {
      name: 'Batches',
      path: '/batches',
      component: BatchesModule,
    },
    {
      name: 'BatchesId',
      path: '/batches/:id',
      component: OneBatch,
    },
  ],
  component: null,
};

const ticketsCenter = {
  id: 'tickets',
  path: '/tickets',
  name: 'Inbox',
  module: 'HUMAN INTERFACE',
  // eslint-disable-next-line react/react-in-jsx-scope
  icon: <Tickets size={20} color="white" />,
  position: 'body',
  children: [
    {
      name: 'Tickets',
      path: '/tickets',
      component: HumanInterface,
    },
    {
      name: 'Tickets',
      path: '/tickets/:id',
      component: TicketResolution,
    },
  ],
  component: null,
};

const dashboardRoutes = {
  id: 'dashboard',
  path: '/dashboard',
  name: 'Dashboard',
  position: 'body',
  module: 'DASHBOARD',
  // eslint-disable-next-line react/react-in-jsx-scope
  icon: <DashboardIcon size={20} color="white" />,
  children: [
    {
      name: 'dashboard',
      path: '/dashboard',
      component: MainDashboard,
    },
    {
      name: 'dashboard',
      path: '/dashboard/:id',
      component: CaseReport,
    },
    {
      name: 'dashboard',
      path: '/dashboard/:id/activity',
      component: CaseInstances,
    },
  ],
  component: null,
};

const adminInfoRoute = {
  id: 'admin-info',
  path: '/admin-info',
  position: 'footer',
  name: 'Settings',
  module: 'ADMIN',
  // eslint-disable-next-line react/react-in-jsx-scope
  icon: <AdminIcon size={20} color="white" />,
  children: [
    {
      name: 'Settings',
      path: '/admin-info',
      component: AdminInfo,
    },
  ],
  component: null,
};

const collectionRoute = {
  id: 'collections',
  path: '/collections',
  name: 'Collections',
  position: 'body',
  module: 'COLLECTIONS',
  // eslint-disable-next-line react/react-in-jsx-scope
  icon: <CollectionsIcon size={20} color="white" />,
  children: [
    {
      name: 'Collection Module',
      path: '/collections',
      component: CollectionsModule,
    },
  ],
  component: null,
};

const appDashboardRoutes = {
  id: 'app-dashboard',
  path: '/apps',
  name: 'Apps',
  position: 'body',
  module: 'APPS',
  // eslint-disable-next-line react/react-in-jsx-scope
  icon: <AppsIcom size={20} color="white" />,
  children: [
    {
      name: 'Edit App',
      path: '/apps/edit',
      component: EditAppPage,
    },
    {
      name: 'App history',
      path: '/apps/history',
      component: AppsHistory,
    },
    {
      name: 'App history',
      path: '/instances/:id',
      component: InstanceInfo,
    },
    {
      name: 'App List',
      path: '/apps',
      component: MyAppsPage,
    },
  ],
  component: null,
};

const modelerRoutes = {
  id: 'modeler',
  path: '/flows',
  name: 'Workflows',
  position: 'body',
  module: 'MODELER',
  // eslint-disable-next-line react/react-in-jsx-scope
  icon: <ModelerIcon size={20} color="white" />,
  children: [
    {
      name: 'Flow List',
      path: '/flows',
      component: ModelerList,
    },
    {
      name: 'Flow',
      path: '/flows/flow',
      component: ModelerIframe,
    },
  ],
  component: null,
};

// Routes visible in the sidebar
export const sidebarRoutes = [
  {
    path: '/dashboard',
    position: 'body',
    module: 'DASHBOARD',
  },
  {
    path: '/apps',
    position: 'body',
    module: 'APPS',
  },
  {
    path: '/cases',
    position: 'body',
    module: 'CASES',
  },
  {
    path: '/forms',
    position: 'body',
    module: 'FRONTDESK',
  },
  {
    path: '/batches',
    position: 'body',
    module: 'BATCHES',
  },
  {
    path: '/flows',
    position: 'body',
    module: 'MODELER',
  },
  {
    path: '/collections',
    position: 'body',
    module: 'COLLECTIONS',
  },
];

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
  dashboardRoutes,
  casesCenter,
  ticketsCenter,
  formsRoutes,
  batchesCenter,
  appDashboardRoutes,
  modelerRoutes,
  collectionRoute,
  WACenter,
  adminInfoRoute,
];

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes, recoveryRoutes, userExtenalUpdatesRoutes];
