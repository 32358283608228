/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/order */
/* eslint-disable prefer-const */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-plusplus */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-constant-condition */
/* eslint-disable camelcase */
/* eslint-disable react/no-array-index-key */
/* eslint-disable func-names */
/* eslint-disable max-len */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import React, { useContext, useState, useEffect, useRef } from 'react';

import { useHistory } from 'react-router-dom';
import { Button, Loader, GoBackComponent } from '../../components';
import { AccountContext } from '../../contexts/AccountContext';
import FileDisplayer from '../Tooler/FileDisplayer/FileDisplayer';
import { casesDashboardService, appDashboardService } from '../../services';

import './InstanceInfo.css';
import { Dialog } from 'primereact/dialog';

const STATUS = {
  UNPROCESSED: 'UNPROCESSED',
  RUNNING: 'RUNNING',
  PROGRESS: 'PROGRESS',
  DONE: 'DONE',
  COMPLETED: 'COMPLETED',
  INCOMPLETE: 'INCOMPLETE',
  ERROR: 'ERROR',
  DELETED: 'DELETED',
  PREUNLOADED: 'PRE-UNLOADED',
};

const STATUS_COLORS = {
  UNPROCESSED: '#a1a1a1',
  RUNNING: '#FFCC00', // Amarillo brillante
  PROGRESS: '#FFCC00',
  DONE: '#87A0B2', // Naranja brillante
  COMPLETED: '#A4DD74', // Verde lima
  INCOMPLETE: '#868C9C',
  ERROR: 'RED', // Azul claro
  PREUNLOADED: '#a1a1a1',
};
const InstanceInfo = ({ location }) => {
  const history = useHistory();
  if (!location.instance) {
    history.push('/apps');
  }
  const [loading, setLoading] = useState(true);
  const [stagesInstance, setStagesInstance] = useState(null);
  const [infoStages, setInfoStages] = useState(null);
  const [valuesInstance, setValuesInstance] = useState(null);
  const [infoInstance, setInfoInstance] = useState(null);
  const [showStages, setShowStages] = useState(false);
  const [showCase, setShowCase] = useState(true);
  const displayFileRef = useRef(null);
  const manageDisplayFileRef = useRef(null);
  const [displayerFile, setDisplayerFile] = useState({
    displayFileRef,
    manageDisplayFileRef,
    showDisplayFile: false,
  });
  const { showSnackbar } = useContext(AccountContext);

  const handleCloseDisplayerFile = () => {
    setDisplayerFile({
      ...displayerFile,
      showDisplayFile: false,
    });
  };

  useEffect(() => {
    return () => {};
  }, []);

  function capitalizeFirstLetter(word) {
    return word.replace(/_/g, ' ').charAt(0).toUpperCase() + word.replace(/_/g, ' ').slice(1);
  }

  const toogleCases = () => {
    setShowCase((v) => !v);
    setShowStages(false);
  };

  const toogleStages = () => {
    setShowStages((v) => !v);
    setShowCase(false);
  };

  const showDisplayerFile = (content, header) => {
    setDisplayerFile({
      ...displayerFile,
      manageDisplayFileRef: { content, header },
      showDisplayFile: true,
    });
  };

  const handleFile = (file) => {
    const FileContent = (
      <FileDisplayer file={file} handleCloseDisplayerFile={handleCloseDisplayerFile} notEvent />
    );
    showDisplayerFile(FileContent, null);
  };
  const StatusColumn = ({ status: codeStatus }) => {
    let styling = STATUS_COLORS.DELETED;
    let opa = 1;
    switch (codeStatus.toLocaleUpperCase()) {
      case STATUS.UNPROCESSED:
        styling = STATUS_COLORS.UNPROCESSED;
        break;
      case STATUS.ONGOING:
        styling = STATUS_COLORS.ONGOING;
        break;
      case STATUS.RUNNING:
        styling = STATUS_COLORS.RUNNING;
        break;
      case STATUS.WARNING:
        styling = STATUS_COLORS.WARNING;
        break;
      case STATUS.COMPLETED:
        styling = STATUS_COLORS.COMPLETED;
        break;
      case STATUS.DONE:
        styling = STATUS_COLORS.DONE;
        break;
      case STATUS.DELETED:
        styling = STATUS_COLORS.DELETED;
        opa = 0.2;
        break;
      case STATUS.PREUNLOADED:
        styling = STATUS_COLORS.PREUNLOADED;
        opa = 1;
        break;
      case STATUS.PROGRESS:
        styling = STATUS_COLORS.PROGRESS;
        opa = 1;
        break;

      default:
        styling = STATUS_COLORS.DELETED;
        break;
    }
    return (
      <div className="batchChip" style={{ background: styling, opacity: opa }}>
        {codeStatus || '-'}
      </div>
    );
  };

  const handleSeeCases = (batchData) => {
    history.push({ pathname: `/batches/${batchData.batchID}`, batch: batchData });
  };

  const calcDiffDates = (dateA, dateB) => {
    const fecha1 = new Date(dateA);
    const fecha2 = new Date(dateB);
    const diferencia = fecha2 - fecha1;
    const segundos = Math.floor(diferencia / 1000);
    const minutos = Math.floor(segundos / 60);
    const horas = Math.floor(minutos / 60);
    const dias = Math.floor(horas / 24);

    const segundosRestantes = segundos % 60;
    const minutosRestantes = minutos % 60;
    const horasRestantes = horas % 24;
    return `${dias} days, ${horasRestantes} hours, ${minutosRestantes} minutes and ${segundosRestantes} seconds`;
  };
  function flattenJSON(data, parentKey = '') {
    let result = {};

    for (let key in data) {
      let newKey = parentKey ? `${parentKey}_${key}` : key;
      if (typeof data[key] === 'object' && !Array.isArray(data[key])) {
        Object.assign(result, flattenJSON(data[key], newKey));
      } else {
        result[newKey] = data[key];
      }
    }

    return result;
  }

  const isJSON = (str) => {
    try {
      const temp = JSON.parse(str);
      return Object.keys(temp).length > 0 ? flattenJSON(temp) : null;
    } catch (e) {
      return null;
    }
  };

  const ConvertFlattenData = ({ data }) => {
    return Object.keys(data).map((key) => {
      const isArray = isJSON(data[key]);
      return key !== 'files' && key.slice(0, 6) !== 'checks' ? (
        <div className="lineBatch2" key={key}>
          <strong>{capitalizeFirstLetter(key)}</strong>
          {!isArray ? data[key].toString() || '-' : <ConvertFlattenData data={isArray} />}
        </div>
      ) : null;
    });
  };

  useEffect(async () => {
    if (location?.instance?._id || location?.instance?.id) {
      const data = await casesDashboardService.getInstance(
        location?.instance?._id || location?.instance?.id
      );
      setInfoInstance(data);
      const { success: a1, data: d1 } = await appDashboardService.getInfoOfInstance(
        location.instance._id || location.instance.id
      );
      if (a1) {
        setStagesInstance(d1);
        if (d1?.data?.stage_history) {
          setInfoStages(d1?.data?.stage_history);
        } else {
          setInfoStages(null);
        }
      }
      const { success: a2, data: d2 } = await appDashboardService.getValuesOfInstance(
        location.instance._id || location.instance.id
      );
      if (a2) {
        setValuesInstance(d2.data.stages);
      }
    } else {
      history.push('/apps');
    }
    setLoading(false);

    return () => {};
  }, []);

  const formatDate = (date) => {
    if (!date || date === 'Invalid date') {
      return '-';
    }
    const newDate = new Date(date);
    const opciones = {
      month: 'numeric', // Nombre completo del mes (por ejemplo, "enero")
      day: 'numeric', // Día del mes (por ejemplo, "26")
      hour: 'numeric', // Hora (por ejemplo, "13")
      year: 'numeric', // Hora (por ejemplo, "13")
      minute: 'numeric', // Minutos (por ejemplo, "45")
    };

    // Formatear la fecha
    try {
      const formatoFecha = new Intl.DateTimeFormat('en-EN', opciones).format(newDate);
      return formatoFecha;
    } catch (error) {
      return date;
    }
  };

  const stageComp = (values) => {
    return values && Object.entries(values).length > 0 ? (
      <div
        className="flex-column"
        style={{
          background: 'rgba(255, 255, 255, 0.273)',
          margin: '10px',
          padding: '10px',
          borderRadius: '10px',
        }}
      >
        {Object.entries(values).map((key) => {
          return key[1] ? (
            <div className="lineBatch" key={key[0] || '-'}>
              <strong>{`${key[0]}:` || '-'}</strong>
              <p>{`${key[1]}`}</p>
            </div>
          ) : (
            <></>
          );
        })}
      </div>
    ) : (
      <></>
    );
  };

  const toogle = (value, values2) => {
    console.log('________________');
    console.log('Nodo Definition', value);
    console.log('Nodo Values', values2);
  };

  const maskTitle = (serviceName, valuesInstanc) => {
    if (!(infoStages && infoStages[serviceName] && infoStages[serviceName][0])) {
      return <>{serviceName}</>;
    }
    return infoStages ? (
      <div>
        <div className="flex-row">
          <button
            type="button"
            onClick={() => toogle(infoStages[serviceName][0], valuesInstanc)}
            className="stage-btn"
          >
            {`${infoStages[serviceName][0].type}`}
          </button>
        </div>
      </div>
    ) : (
      <></>
    );
  };

  return (
    <div>
      {!loading ? (
        <div className="main-case-container">
          <div className="flex-wrapper2" style={{ marginLeft: '-20px' }}>
            <GoBackComponent title={`${location.instance?.name}`} isWhite={false} />
            <h2 className="subtitle-data-txt2 bounce">
              {`Instance ${location.instance?._id || location.instance?.id || ''}`}
            </h2>
          </div>
          <div className="container-cases bounce">
            <div className="slot-wrapper" style={{ background: 'rgba(0, 255, 255, 0.053)' }}>
              <Button className="btn-accordion" onClick={toogleCases}>
                <h2 className="headings-batch">General</h2>
                <i
                  className={`pi ${
                    showCase ? 'pi-angle-up' : 'pi-angle-down'
                  } accordion-toggler-icon`}
                />
              </Button>
              {showCase ? (
                <>
                  {/* {infoCase?.batchID && (
                    <div className="lineBatch">
                      <strong>Batch Id</strong>
                      {infoCase?.batchID}
                    </div>
                  )} */}
                  <div className="lineBatch">
                    <strong>Status</strong>
                    <StatusColumn status={stagesInstance?.status} />
                  </div>
                  <div className="lineBatch">
                    <strong>Started</strong>
                    {formatDate(stagesInstance?.startDate)}
                  </div>
                  {stagesInstance.endDate && (
                    <div className="lineBatch">
                      <strong>Ended</strong>
                      {formatDate(stagesInstance?.endDate)}
                    </div>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="slot-wrapper" style={{ background: 'rgba(02, 55, 255, 0.01)' }}>
              <Button className="btn-accordion" onClick={toogleStages}>
                <h2 className="headings-batch">Stages</h2>
                <i
                  className={`pi ${
                    showStages ? 'pi-angle-up' : 'pi-angle-down'
                  } accordion-toggler-icon`}
                />
              </Button>
              {showStages && valuesInstance && Object.entries(valuesInstance)?.length > 0 ? (
                <>
                  {Object.entries(valuesInstance)?.map((s, i) => {
                    return (
                      <div className="lineBatch2" key={`hola-${i}`}>
                        <div className="flex-row">{maskTitle(s[0], valuesInstance)}</div>
                        {stageComp(s[1][0]?.values)}
                      </div>
                    );
                  })}
                </>
              ) : showStages ? (
                <p className="none-params bounce">⚠️ Empty stages</p>
              ) : (
                <></>
              )}
            </div>
          </div>

          <Dialog
            closable={false}
            header={displayerFile?.manageDisplayFileRef?.header}
            className="displayer-file"
            onHide={handleCloseDisplayerFile}
            visible={displayerFile?.showDisplayFile}
          >
            {displayerFile?.manageDisplayFileRef.content}
          </Dialog>
        </div>
      ) : (
        <div className="loader-wrapper">
          <Loader fit transparant />
        </div>
      )}
    </div>
  );
};

export default InstanceInfo;
