import React from 'react';

import PropTypes from 'prop-types';

import './ChartWrapper.css';
import DashboardChart from '../DashboardChart/DashboardChart';

const ChartWrapper = ({ dataName, dataSets, chartList, showMetrics }) => {
  const acum = [0, 0, 0];
  let j = 0;

  if (dataName === 'Cases ACTIVE') {
    acum[0] = '';
    acum[1] = '';
    acum[2] = '';
  } else {
    for (j = 0; j < dataSets[0].data.length; j += 1) {
      acum[0] += dataSets[0].data[j];
    }

    for (j = 0; j < dataSets[1].data.length; j += 1) {
      acum[1] += dataSets[1].data[j];
    }

    for (j = 0; j < dataSets[2].data.length; j += 1) {
      acum[2] += dataSets[2].data[j];
    }
  }

  return (
    <div className="dashboard-charts-wrapper">
      {chartList.map((dashboardChart, i) => (
        <DashboardChart
          dataCount={acum[i]}
          dataName={dataName}
          color="#000"
          dataSets={dataSets}
          key={dashboardChart.id}
          selectedChart={i + 1}
          {...dashboardChart}
          showMetrics={showMetrics}
        />
      ))}
    </div>
  );
};

ChartWrapper.defaultProps = {
  dataName: 'Data Name',
  showMetrics: false,
};

ChartWrapper.propTypes = {
  dataName: PropTypes.string,
  chartList: PropTypes.array.isRequired,
  dataSets: PropTypes.array.isRequired,
  showMetrics: PropTypes.bool,
};

export default ChartWrapper;
