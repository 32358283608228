/* eslint-disable prefer-destructuring */
/* eslint-disable import/order */
/* eslint-disable prefer-const */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-plusplus */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-constant-condition */
/* eslint-disable camelcase */
/* eslint-disable react/no-array-index-key */
/* eslint-disable func-names */
/* eslint-disable max-len */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import React, { useContext, useState, useEffect, useRef } from 'react';

import { Dialog } from 'primereact/dialog';
import { DebounceInput } from 'react-debounce-input';
import { useHistory } from 'react-router-dom';

import pdfIcon from '../../../assets/svgs/pdf.svg';
import imageIcon from '../../../assets/svgs/image.svg';
import RefreshIcon from '../../../assets/images/refresh.png';
import listEmptyLines from '../../../assets/svgs/arts/pair.svg';
import filetypeCSV from '../../../assets/svgs/filetype-csv.svg';
import {
  TabView,
  TabPanel,
  Button,
  Loader,
  GoBackComponent,
  LineCard,
  Dropdown,
  StepperCmp,
} from '../../../components';
import tools from '../../../utils/time';
import { AccountContext } from '../../../contexts/AccountContext';
import { ResponsiveContext } from '../../../contexts/ResponsiveContext';
import { LineDataForm } from '../../../forms';
import { batchesService, ticketsService, casesDashboardService } from '../../../services';

import './OneBatch.css';
import FileDisplayer from '../../Tooler/FileDisplayer/FileDisplayer';

const STATUS = {
  UNPROCESSED: 'UNPROCESSED',
  RUNNING: 'RUNNING',
  PROGRESS: 'PROGRESS',
  DONE: 'DONE',
  COMPLETED: 'COMPLETED',
  INCOMPLETE: 'INCOMPLETE',
  ERROR: 'ERROR',
  DELETED: 'DELETED',
  PREUNLOADED: 'PRE-UNLOADED',
};

const STATUS_COLORS = {
  UNPROCESSED: '#a1a1a1',
  RUNNING: '#FFCC00', // Amarillo brillante
  PROGRESS: '#FFCC00',
  DONE: '#87A0B2', // Naranja brillante
  COMPLETED: '#A4DD74', // Verde lima
  INCOMPLETE: '#868C9C',
  ERROR: 'RED', // Azul claro
  PREUNLOADED: '#a1a1a1',
};

const OneBatch = ({ location }) => {
  const history = useHistory();
  const leftSide = useRef(null);
  const rightSide = useRef(null);
  const displayFileRef = useRef(null);
  const manageDisplayFileRef = useRef(null);
  const [displayerFile, setDisplayerFile] = useState({
    displayFileRef,
    manageDisplayFileRef,
    showDisplayFile: false,
  });
  const [historial, setHistorial] = useState(null);
  const [showHistorial, setShowHistorial] = useState(false);
  const [selectedWorkflow, setSelectedWorkflow] = useState(null);
  const [currentBatch, setCurrentBatch] = useState(location?.batch);
  const [showExe, setShowExe] = useState(false);
  const [linesFiltered, setLinesFiltered] = useState({});
  const [files, setFiles] = useState(null);
  const [notes, setNotes] = useState(null);
  const { isMobile, isTablet } = useContext(ResponsiveContext);

  const [batchSearch, setBatchSearch] = useState('');
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(false);

  const [activeIndex, setActiveIndex] = useState(0);
  const { tools: tooler, setTools, handleCloseModal, showSnackbar } = useContext(AccountContext);
  const [selectedLine, setSelectedLine] = useState(null);
  const [infoLine, setInfoLine] = useState(null);
  const [showFiles, setShowFiles] = useState(false);
  const [showValues, setShowValues] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const [listStates, setListStates] = useState(null);
  const [infoCase, setInfoCase] = useState(null);
  const [infoCase2, setInfoCase2] = useState(null);
  const [showCase, setShowCase] = useState(true);

  const getCaseStates = async (caseType) => {
    const statesTemp = await casesDashboardService.getCaseStates(caseType);
    if (statesTemp.data.length > 0) {
      setListStates(statesTemp.data);
    } else {
      setListStates(null);
    }
  };

  const toogleHistory = () => {
    setShowValues(false);
    setShowNotes(false);
    setShowCase(false);
    setShowFiles(false);
    setShowHistorial((f) => !f);
  };

  const toogleFiles = () => {
    setShowValues(false);
    setShowNotes(false);
    setShowHistorial(false);
    setShowCase(false);
    setShowFiles((f) => !f);
  };
  const toogleValues = () => {
    setShowFiles(false);
    setShowNotes(false);
    setShowHistorial(false);
    setShowCase(false);
    setShowValues((v) => !v);
  };

  const toogleNotes = () => {
    setShowFiles(false);
    setShowValues(false);
    setShowHistorial(false);
    setShowCase(false);
    setShowNotes((n) => !n);
  };

  const resetLine = () => {
    setSelectedLine(null);
    setInfoLine(null);
    setShowHistorial(null);
    setFiles(null);
  };

  const toogleCases = () => {
    setShowFiles(false);
    setShowNotes(false);
    setShowValues(false);
    setShowHistorial(false);
    setShowCase((v) => !v);
  };

  const decodeNote = (note) => {
    const result = note?.split('///');
    return {
      title: result[0],
      details: result[1],
      description: result[2],
      agentPrefix: result[3],
    };
  };

  let acceptData = (notes3) => {
    let data = [];
    const infoNotes = notes3.map((n) => {
      return n.case_note;
    });
    const filtered = infoNotes.map((n) => {
      return decodeNote(n);
    });
    filtered.forEach((f) => {
      data.push(f);
    });
    return data;
  };

  const fetchLines = async (batchId) => {
    const { success, data } = await batchesService.listBatchLines(batchId);
    if (success) {
      // setUsersByWorkspace([]);
      // Usando reduce para organizar los usuarios por rol
      const linesByStatusTep = [...data.data].reduce((acc, line) => {
        const { status } = line;

        // Si aún no existe un arreglo para este rol, crea uno
        if (!acc[status]) {
          acc[status] = [];
        }

        // Agrega el usuario al arreglo correspondiente al rol
        acc[status].push(line);

        return acc;
      }, {});
      setLinesFiltered(linesByStatusTep);
      console.log('linesByStatusTep', linesByStatusTep);
      if (Object.entries(linesByStatusTep).length === 0) {
        showSnackbar('error', '', 'This batch does not contain any case!', 5000);
      }
    }
  };

  const convertS3toFiles = (arrayBase64) => {
    const files3 = arrayBase64.map((element, index) => {
      const decodeImg = atob(element.data);
      const typedImgA = new Uint8Array(decodeImg.length);
      for (let i = 0; i < decodeImg.length; ++i) {
        typedImgA[i] = decodeImg.charCodeAt(i);
      }
      const blob = new Blob([typedImgA], { type: element.type }); // Create a BLOB object
      const file = new File([blob], element.name, { type: element.type });
      return file;
    });
    // // Comenta o descomenta esta línea según sea necesario
    return files3;
  };

  const handleSelectedCase = async (id, id2, loader = false) => {
    setFiles(null);
    setLoading2(true);
    const { success, data: info2 } = await batchesService.getBatchLine(
      location?.batch?.batchID,
      id
    );
    if (success) {
      setInfoLine(info2.data);
      loader && showSnackbar('success', '', 'Line refreshed!', 3000);
      if (info2.data.caseId) {
        const { success: success3, data: data3 } = await casesDashboardService.getCaseInfo(
          info2.data.caseId
        );
        if (success3) {
          const { moduleA, moduleB } = data3.data;
          setInfoCase(moduleA[0]);
          setInfoCase2(moduleB[0]);
          await getCaseStates(location?.batch?.case_type);
        } else {
          setInfoCase(null);
          setInfoCase2(null);
        }
        const { success: s2, data: d2 } = await ticketsService.getS3({ caseId: info2.data.caseId });
        if (s2) {
          setFiles(convertS3toFiles(d2?.data));
        } else {
          setFiles(null);
          setShowFiles(false);
        }
        const notesTemp = await casesDashboardService.getCaseNotes(info2.data.caseId);
        if (notesTemp.data.length > 0) {
          setNotes(acceptData(notesTemp.data[0]));
        } else {
          setNotes(null);
          setShowNotes(false);
        }
        const historyTemp = await casesDashboardService.getCaseHistory(info2.data.caseId);
        if (historyTemp.data.length > 0) {
          setHistorial(historyTemp.data);
        } else {
          setHistorial(null);
          setShowHistorial(false);
        }
      }
    }
    if (isMobile || isTablet) {
      leftSide.current.style.display = 'none';
      rightSide.current.style.display = 'flex';
    }
    setLoading2(false);
  };

  const HeaderAddApp = ({ title }) => {
    return <div className="activity-header bounce">{title}</div>;
  };

  const handleAddDetail = async (batch) => {
    const { success, data } = await batchesService.updateBatchCase({
      ...batch,
      status: '1234',
    });
    if (success) {
      await fetchLines(batch.batchID);
      showSnackbar('success', '', 'Line updated!', 3000);
    } else {
      showSnackbar('error', '', 'Line could not updated!', 3000);
    }
    handleCloseModal();
  };

  const showModal = (content, header) => {
    setTools({
      ...tooler,
      manageModal: { content, header },
      showModal: true,
    });
  };

  const handleAddData = () => {
    const tempBatch = { status: infoLine.status, lineId: infoLine._id, batchId: infoLine.batchId };
    // console.log(tempBatch);
    const addDataForm = <LineDataForm data={infoLine} handleSubmit={handleAddDetail} />;
    showModal(addDataForm, <HeaderAddApp title="Add new detail" />);
  };

  useEffect(async () => {
    if (selectedWorkflow) {
      const { code } = selectedWorkflow;
      switch (code) {
        case 'refresh':
          handleSelectedCase(infoLine._id, infoLine.case_id, true);
          break;
        case 'addData':
          // console.log('addData');
          // handleAddData();
          break;
        default:
          break;
      }
    }
    setSelectedWorkflow(null);
    return () => {
      setSelectedWorkflow(null);
    };
  }, [selectedWorkflow]);

  const closeAll = () => {
    resetLine();
    handleCloseModal();
  };

  const userOptions = [
    { name: 'Refresh case', code: 'refresh', icon: 'pi pi-fw pi-refresh' },
    // {
    //   name: 'Add data',
    //   code: 'addData',
    //   icon: 'pi pi-fw pi-flag',
    // },
  ];

  // useEffect(() => {
  //   if (linesBatch.length > 0) {
  //     if (!isAdmin) {
  //       handleSelectedCase(userData.id);
  //     }
  //   }
  // }, [isAdmin]);

  const handleChangeTab = (index) => {
    setActiveIndex(index);
  };

  const modulesOptionTemplate = (option) => {
    return (
      <div className="flex option-backgroung">
        <i className={option.icon} />
        <p className="value-txt">{option.name}</p>
      </div>
    );
  };

  useEffect(() => {
    if (!tooler.showModal && selectedLine) {
      selectedLine(null);
    }
  }, [tooler]);

  useEffect(() => {
    setShowExe(currentBatch?.status === 'Unprocessed');
    fetchLines(currentBatch?.batchID);
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setSelectedLine(null);
      }
    };
    if (!location?.batch?.batchID) {
      resetLine();
      history.push('/batches');
    }

    if (!location?.batch?.batchID) {
      closeAll();
    }

    // document.addEventListener('keydown', handleKeyDown);
    setLoading(false);
    return () => {
      // document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const MaskStatus = ({ status }) => {
    switch (status) {
      case 'Unprocessed':
        return <>Por iniciar</>;
      default:
        return <>{status}</>;
    }
  };

  const resetInputBatchSearch = () => {
    setBatchSearch('');
  };

  const onWorkflowChange = (e) => {
    setSelectedWorkflow(e.value);
  };

  const handleCloseDisplayerFile = () => {
    setDisplayerFile({
      ...displayerFile,
      showDisplayFile: false,
    });
  };

  const showDisplayerFile = (content, header) => {
    setDisplayerFile({
      ...displayerFile,
      manageDisplayFileRef: { content, header },
      showDisplayFile: true,
    });
  };

  const handleFile = (file) => {
    const FileContent = (
      <FileDisplayer file={file} handleCloseDisplayerFile={handleCloseDisplayerFile} notEvent />
    );
    showDisplayerFile(FileContent, null);
  };

  function flattenJSON(data, parentKey = '') {
    let result = {};

    for (let key in data) {
      let newKey = parentKey ? `${parentKey}_${key}` : key;
      if (typeof data[key] === 'object' && !Array.isArray(data[key])) {
        Object.assign(result, flattenJSON(data[key], newKey));
      } else {
        result[newKey] = data[key];
      }
    }

    return result;
  }

  const filterOutput = (k) => {
    return typeof k === 'string' && k.slice(0, 7) !== 'checks_' && k !== 'files';
  };

  function capitalizeFirstLetter(word) {
    return word.replace(/_/g, ' ').charAt(0).toUpperCase() + word.replace(/_/g, ' ').slice(1);
  }

  const exportCSVBatch = async () => {
    // const currentDate = new Date();
    const { success, data: listBatches } = await batchesService.downloadBatch(currentBatch.batchID);
    const filter = currentBatch.results_expected.split('/');
    let obj2 = {};
    filter.forEach((f) => {
      obj2[f] = capitalizeFirstLetter(f.replace(/_/g, ' '));
    });
    obj2 = { estado: 'Estado', ...obj2 };
    if (success && listBatches.data.length > 0) {
      const uu = listBatches.data.map((d) => {
        return { state: d.state, ...flattenJSON(d.data) };
      });
      const rows = [obj2, ...uu]
        .map((obj) => {
          const rowValues = []; // Incluye el key como primer valor
          Object.keys(obj).forEach((k) => {
            filterOutput(k) ? rowValues.push(`${obj[k].replaceAll(',', '')}`) : null;
          });
          return [...rowValues].join(',');
        })
        .join('\n');
      const currentDate = new Date();
      const csvData = `data:text/csv;charset=utf-8,${encodeURIComponent(rows)}`;
      const link = document.createElement('a');
      link.setAttribute('href', csvData);
      link.setAttribute('download', `Batch-${currentBatch.batchID}-${currentDate.getTime()}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const NotasContainer = () => {
    const data = notes?.map((n, i) => {
      return (
        <div key={`NotasContainer-${i}`} className="note-wrapper">
          <div className="note-line">
            <strong>{n.title}</strong>
            {n.description}
          </div>
          <div className="note-date">{n.details.split(',')[1] + n.details.split(',')[0]}</div>
        </div>
      );
    });
    return data;
  };

  const StatusColumn = ({ status: codeStatus }) => {
    let styling = STATUS_COLORS.DELETED;
    let opa = 1;
    switch (codeStatus.toLocaleUpperCase()) {
      case STATUS.UNPROCESSED:
        styling = STATUS_COLORS.UNPROCESSED;
        break;
      case STATUS.RUNNING:
        styling = STATUS_COLORS.RUNNING;
        break;
      case STATUS.ONGOING:
        styling = STATUS_COLORS.RUNNING;
        break;
      case STATUS.WARNING:
        styling = STATUS_COLORS.WARNING;
        break;
      case STATUS.COMPLETED:
        styling = STATUS_COLORS.COMPLETED;
        break;
      case STATUS.DONE:
        styling = STATUS_COLORS.COMPLETED;
        break;
      case STATUS.DELETED:
        styling = STATUS_COLORS.DELETED;
        opa = 0.2;
        break;
      case STATUS.ERROR:
        styling = STATUS_COLORS.ERROR;
        opa = 1;
        break;
      case STATUS.PREUNLOADED:
        styling = STATUS_COLORS.PREUNLOADED;
        opa = 1;
        break;
      case STATUS.PROGRESS:
        styling = STATUS_COLORS.PROGRESS;
        opa = 1;
        break;

      default:
        styling = STATUS_COLORS.DELETED;
        break;
    }
    return (
      <div className="batchChip" style={{ background: styling, opacity: opa }}>
        {codeStatus || '-'}
      </div>
    );
  };

  const createTooltipDownload = (btc) => {
    const data = btc?.split('/').join(' ');
    return `Datos recolectados: ${data}` || 'Download Batch';
  };

  const calcDiffDates = (dateA, dateB) => {
    const fecha1 = new Date(dateA);
    const fecha2 = new Date(dateB);
    const diferencia = fecha2 - fecha1;
    const segundos = Math.floor(diferencia / 1000);
    const minutos = Math.floor(segundos / 60);
    const horas = Math.floor(minutos / 60);
    const dias = Math.floor(horas / 24);

    const segundosRestantes = segundos % 60;
    const minutosRestantes = minutos % 60;
    const horasRestantes = horas % 24;
    return `${dias} days, ${horasRestantes} hours, ${minutosRestantes} minutes and ${segundosRestantes} seconds`;
  };

  const onExeApp = async (batchID) => {
    const { success } = await batchesService.executeBatch(batchID);
    handleCloseModal();
    if (success) {
      setShowExe(false);
      showSnackbar('success', '', 'Batch in progress.', 4000);
    } else {
      setShowExe(true);
      showSnackbar('error', '', 'Batch not executed.', 4000);
    }
  };

  const ConditionColumn = ({ created, color = 'green', title }) => {
    const difference = tools.timeSince(Date.parse(created));
    return (
      <div className={`dot2 ${color}`} title={`${title}: ${created}`}>
        <p className="dot-value">{difference}</p>
      </div>
    );
  };

  const LineSection = () => {
    const www = createTooltipDownload(currentBatch.results_expected);
    return (
      <div className="lines-section">
        <div className="left-search">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <DebounceInput
              className="buttonFilter2"
              value={batchSearch}
              debounceTimeout={700}
              onChange={(e) => setBatchSearch(e.target.value)}
              placeholder="Search case ID"
              autoComplete="off"
            />
          </span>
          {batchSearch && (
            <Button
              type="button"
              title="Clear batch"
              className="pi pi-trash adjust-trash3"
              onClick={resetInputBatchSearch}
            />
          )}
        </div>
        <div className="flex-row" style={{ height: '100vh', alignItems: 'flex-start' }}>
          <div ref={leftSide} className="left-engine">
            <div className="batch-title">
              <div className="flex-row" style={{ justifyContent: 'center' }}>
                <GoBackComponent title="" isWhite />
                <Button
                  title="Batch info"
                  className="badge-batch"
                  onClick={() => {
                    console.log(currentBatch);
                  }}
                >
                  {`Batch ID ${currentBatch?.batchID}`}
                </Button>
                {/* {showExe && (
                <Button
                  disabled={infoLine?.length === 0}
                  title={www}
                  type="button"
                  icon={PlayIcon}
                  className="play-img"
                  rounded
                  onClick={(e) => {
                    showModal(
                      <BatchExeForm
                        batchID={currentBatch.batchID}
                        onExeApp={() => onExeApp(currentBatch.batchID)}
                        onCloseModal={handleCloseModal}
                      />,
                      <HeaderAddApp title="⚠️ Play batch" />
                    );
                  }}
                >
                  <img className="csv-img" src={PlayIcon} alt="Download th whole batch" />
                </Button>
              )} */}
              </div>
              <div className="caseType-cls">
                <p className="caseType-txt">{currentBatch?.case_type_description}</p>
                <Button
                  title="Refresh tickets"
                  className="refresh-lines"
                  onClick={async () => {
                    showSnackbar('success', '', 'Lines refreshed!', 3000);
                    await fetchLines(currentBatch?.batchID);
                    setInfoLine(null);
                  }}
                >
                  <img className="refresh-img" src={RefreshIcon} alt="refresh tickets button" />
                </Button>
                {currentBatch.results_expected && (
                  <Button
                    disabled={infoLine?.length === 0}
                    title={www}
                    type="button"
                    icon={filetypeCSV}
                    className="csv-img"
                    rounded
                    onClick={(e) => {
                      e.stopPropagation();
                      exportCSVBatch();
                    }}
                  >
                    <img className="csv-img" src={filetypeCSV} alt="Download th whole batch" />
                  </Button>
                )}
              </div>
            </div>
            <div className="left-scroller">
              {Object.entries(linesFiltered).map(([key, value], i) => {
                if (key === 'null') {
                  return <div key={`${key}-${i}`}> </div>;
                }
                return (
                  <div className="group-lines contT" key={`LineCard-${key}-${i}`}>
                    <h2 className="roles-txt">
                      <MaskStatus status={key} />
                      <div className="roles-len">{value?.length}</div>
                    </h2>

                    <div className="accordion-wrapper-list adjust-field">
                      {value.map((v, r) => {
                        if (!v?.lineId || !v?.caseId?.includes(batchSearch)) {
                          return <div key={`LineCard${v}-${r}`}> </div>;
                        }
                        return (
                          <LineCard
                            key={`LineCard${v}-${r}`}
                            selected={infoLine ? infoLine._id === v.lineId : false}
                            onSelect={() => handleSelectedCase(v.lineId, v?.caseId)}
                            type="user"
                            {...v}
                          />
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div ref={rightSide} className="right-engine">
            {!loading2 ? (
              <>
                {infoLine && Object.entries(infoLine)?.length > 0 ? (
                  <div className="json-container">
                    <div className="flex-wrapper2" key="epa">
                      <div className="flex aling-items-center">
                        <Button
                          title="Previous page"
                          icon="pi pi-arrow-left"
                          className="back2 bounce"
                          onClick={resetLine}
                        />
                      </div>
                      <div className="flexs">
                        <h2 className="subtitle-data-txt2 bounce">
                          {`Case ${infoCase?.caseId || ''}`}
                        </h2>
                        <Dropdown
                          value={selectedWorkflow}
                          options={userOptions}
                          onChange={onWorkflowChange}
                          optionLabel="name"
                          placeholder={null}
                          filterPlaceholder={null}
                          itemTemplate={modulesOptionTemplate}
                        />
                      </div>
                    </div>
                    {infoCase2 && Object.keys(infoCase2)?.length > 0 && (
                      <div
                        className="slot-wrapper"
                        style={{ background: 'rgba(0, 255, 255, 0.053)' }}
                      >
                        <Button className="btn-accordion" onClick={toogleCases}>
                          <h2 className="headings-batch">General</h2>
                          <i
                            className={`pi ${
                              showCase ? 'pi-angle-up' : 'pi-angle-down'
                            } accordion-toggler-icon`}
                          />
                        </Button>
                        {showCase ? (
                          <>
                            {infoCase?.batchID && (
                              <div className="lineBatch">
                                <strong>Batch Id</strong>
                                {infoCase?.batchID}
                              </div>
                            )}
                            <div className="lineBatch">
                              <strong>Case Type</strong>
                              {currentBatch?.case_type_description}
                            </div>
                            <div className="lineBatch">
                              <strong>State</strong>
                              {infoCase2?.state?.id || infoCase?.state}
                            </div>
                            <div className="lineBatch">
                              <strong>Status</strong>
                              <StatusColumn status={infoCase2.status || infoCase.status} />
                            </div>
                            <div className="lineBatch">
                              <strong>Created</strong>
                              <ConditionColumn
                                created={infoCase?.created || infoCase2?.creationDate}
                                color="green"
                                title="Created"
                              />
                            </div>
                            {infoCase2.lastUpdate !== 'Invalid date' && (
                              <div className="lineBatch">
                                <strong>Last Update</strong>
                                <ConditionColumn
                                  created={infoCase2.lastUpdate}
                                  color="gray"
                                  title="Last Update"
                                />
                              </div>
                            )}
                            {infoCase2.closing !== 'Invalid date' && (
                              <div className="lineBatch">
                                <strong>Closed on</strong>
                                {infoCase2.closing}
                              </div>
                            )}
                            {(infoCase?.created !== 'Invalid date' || infoCase2?.creationDate) &&
                              infoCase2.closing !== 'Invalid date' && (
                                <div className="lineBatch">
                                  <strong>Resolution time</strong>
                                  {calcDiffDates(
                                    infoCase?.created || infoCase2?.creationDate,
                                    infoCase2.closing
                                  )}
                                </div>
                              )}
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    )}
                    {historial?.length > 1 &&
                    !historial[0]?.field_old_data &&
                    historial !== 'Error' ? (
                      <div
                        className="slot-wrapper"
                        style={{ background: 'rgba(34, 81, 156, 0.127)' }}
                      >
                        <Button className="btn-accordion" onClick={toogleHistory}>
                          <h2 className="headings-batch">History </h2>
                          <i
                            className={`pi ${
                              showHistorial ? 'pi-angle-up' : 'pi-angle-down'
                            } accordion-toggler-icon blue-color`}
                          />
                        </Button>
                        {showHistorial && listStates.length > 0 && (
                          <StepperCmp history={historial} path={listStates} />
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                    {Object.keys(infoLine?.data).length > 0 && (
                      <div className="slot-wrapper">
                        <Button className="btn-accordion" onClick={toogleValues}>
                          <h2 className="headings-batch">Values</h2>
                          <i
                            className={`pi ${
                              showValues ? 'pi-angle-up' : 'pi-angle-down'
                            } accordion-toggler-icon blue-color`}
                          />
                        </Button>
                        {showValues &&
                          Object.keys(infoLine?.data).map((key) => {
                            return key !== 'files' && key.slice(0, 6) !== 'checks' ? (
                              <div className="lineBatch" key={key}>
                                <strong>{capitalizeFirstLetter(key)}</strong>
                                {infoLine?.data[key] || '-'}
                              </div>
                            ) : (
                              <></>
                            );
                          })}
                      </div>
                    )}
                    {files?.length > 0 ? (
                      <div
                        className="slot-wrapper"
                        style={{ background: 'rgba(34, 56, 77, 0.182)' }}
                      >
                        <Button className="btn-accordion" onClick={toogleFiles}>
                          <h2 className="headings-batch">Files retrieved</h2>
                          <i
                            className={`pi ${
                              showFiles ? 'pi-angle-up' : 'pi-angle-down'
                            } accordion-toggler-icon blue-color`}
                          />
                        </Button>
                        {showFiles &&
                          files?.map((f, i) => {
                            return (
                              <Button
                                key={`File-${i}`}
                                className="file-btn"
                                title="Show File"
                                onClick={() => handleFile(f)}
                              >
                                <img
                                  className="img-icon"
                                  src={f.name.toLowerCase().endsWith('.pdf') ? pdfIcon : imageIcon}
                                  alt="files"
                                />
                                <p>{`File ${f.name}`}</p>
                              </Button>
                            );
                          })}
                      </div>
                    ) : (
                      <></>
                    )}
                    {notes?.length > 0 ? (
                      <div
                        className="slot-wrapper"
                        style={{ background: 'rgba(34, 56, 77, 0.08)' }}
                      >
                        <Button className="btn-accordion" onClick={toogleNotes}>
                          <h2 className="headings-batch">Comments</h2>
                          <i
                            className={`pi ${
                              showNotes ? 'pi-angle-up' : 'pi-angle-down'
                            } accordion-toggler-icon blue-color`}
                          />
                        </Button>
                        {showNotes && <NotasContainer />}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <ListEmptyState />
                )}
              </>
            ) : (
              <Loader fit transparant />
            )}
          </div>
        </div>
      </div>
    );
  };

  const ListEmptyState = () => {
    return (
      <div className="list-empty-apps-wrapper">
        <div className="detail-container">
          <img className="detail-img" src={listEmptyLines} alt="No apps found!" />
          <p className="details-message bounce">
            Select one of the lines to visualize the information.
          </p>
        </div>
      </div>
    );
  };

  useEffect(() => {
    resetLine();
  }, [activeIndex]);

  return !loading ? (
    <div className="cases-wrp">
      <TabView activeIndex={activeIndex} onTabChange={(e) => handleChangeTab(e.index)} scrollable>
        <TabPanel header="Cases" headerClassName="bounce">
          <LineSection />
        </TabPanel>
      </TabView>
      <Dialog
        closable={false}
        header={displayerFile?.manageDisplayFileRef?.header}
        className="displayer-file"
        onHide={handleCloseDisplayerFile}
        visible={displayerFile?.showDisplayFile}
      >
        {displayerFile?.manageDisplayFileRef.content}
      </Dialog>
    </div>
  ) : (
    <Loader fit transparant />
  );
};

export default OneBatch;
