/* eslint-disable lines-between-class-members */
import axiosInstance from '../utils/interceptor';
import { getCookie } from '../utils/tools';

class APIResult {
  data = [];
  success = true;
}

// WARNING: for testing purpose
const handleConsoleError = (err) => {
  if (err.response) {
    // client received an error response (5xx, 4xx)
    switch (err.response.status) {
      case 400: // Bad request
        console.log('error', '400!', 'Bad request');
        break;
      case 401: // Unauthorized
        console.log('error', '401!', 'Unauthorized');
        break;
      case 404: // Not found
        console.log('error', '404!', 'Not found');
        break;
      case 408: // Timeout
        console.log('warn', '408!', 'Timeout');
        break;
      case 500: // Internal Server Error
        console.log('warn', '500!', 'Internal Server Error');
        break;
      default:
        break;
      // console.log(err.response);
    }
  } else if (err.request) {
    // client never received a response, or request never left
    console.log('error', '', err.request);
  } else {
    console.log('error', '', 'Something went wrong. Please try again');
  }
};

export const wrap = async (fn) => {
  const response = new APIResult();
  try {
    const result = await fn();
    if (result) {
      response.data = result;
      response.success = true;
    } else {
      response.data = 'Error';
      response.success = false;
    }
  } catch (error) {
    // TODO when production comment the line below
    handleConsoleError(error);
    response.data = error;
    response.success = false;
  }
  return response;
};

export const externalWrap = async (fn) => {
  const response = new APIResult();
  try {
    const result = await fn();
    if (result) {
      response.data = result;
      response.success = true;
    } else {
      response.data = 'Error';
      response.success = false;
    }
  } catch (error) {
    // TODO when production comment the line below
    handleConsoleError(error);
    response.data = error;
    response.success = false;
  }
  return response;
};

export const wedhookRequest = async (url, method, body) => {
  return axiosInstance({
    baseURL: process.env.REACT_APP_WEDHOOK_API_URL,
    url,
    method,
    data: body,
    // headers: {
    //   Authorization: `Bearer ${accessToken}`,
    // },
  });
};

export const httpRequest = async (url, method, body, adminApi = false) => {
  const { accessToken } = getCookie('credentials') ? JSON.parse(getCookie('credentials')) : '';
  if (adminApi) {
    return axiosInstance({
      baseURL: process.env.REACT_APP_ADMIN_API_URL,
      url,
      method,
      data: body,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }
  return axiosInstance({
    baseURL: process.env.REACT_APP_BASE_API_URL,
    url,
    method,
    data: body,
  });
};

export const httpRequestCustom = async ({
  base,
  url,
  accessToken,
  method,
  body,
  adminApi = false,
}) => {
  if (adminApi) {
    return axiosInstance({
      baseURL: base,
      url,
      method,
      data: body,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }
  return axiosInstance({
    baseURL: base,
    url,
    method,
    data: body,
  });
};
