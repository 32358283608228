/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';

import { GoBackComponent } from '../../components';
import { AccountContext } from '../../contexts/AccountContext';
import { EditAppForm } from '../../forms';
import './EditAppPage.css';

const EditApp = () => {
  const { selectedApp } = useContext(AccountContext);
  return (
    <div className="edit-app-wrapper">
      <div className="edit-app-container">
        <div style={{ margin: '10px 0' }}>
          <GoBackComponent title="Go back" isWhite={false} />
        </div>
        <EditAppForm />
      </div>
    </div>
  );
};

export default EditApp;
