/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable react/react-in-jsx-scope */
import { useContext, useState, useRef } from 'react';

import html2canvas from 'html2canvas';
import PropTypes from 'prop-types';
import QRCode from 'react-qr-code';
import { MobilePDFReader } from 'reactjs-pdf-reader';

import BackIcon from '../../../assets/pngs/arrow-left.png';
import DownloadIcon from '../../../assets/pngs/download-icon.png';
// eslint-disable-next-line import/order
import { Button } from '../../../components';

import './FileDisplayer.css';

import { AccountContext } from '../../../contexts/AccountContext';
import { ResponsiveContext } from '../../../contexts/ResponsiveContext';

const FileDisplayer = ({ event, handleCloseDisplayerFile, file, notEvent = false }) => {
  const [numPages, setNumPages] = useState(null);
  const qrCanvasRef = useRef(null);
  const [pageNumber, setPageNumber] = useState(1);
  const { isMobile } = useContext(ResponsiveContext);
  const [scaleNumber, setScaleNumber] = useState(isMobile ? 0.45 : 0.8);
  const { showSnackbar } = useContext(AccountContext);
  const handleDownloadFile = () => {
    try {
      const url = URL.createObjectURL(event?.file || file);
      const link = document.createElement('a');
      link.href = url;
      link.download = event?.file?.name || file?.name;
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      showSnackbar('error', '', 'Archivo con problemas.');
    }
  };
  const downloadQR = () => {
    if (!qrCanvasRef.current) return;

    html2canvas(qrCanvasRef.current).then((canvas) => {
      const pngUrl = canvas.toDataURL('image/png');
      const downloadLink = document.createElement('a');
      downloadLink.href = pngUrl;
      downloadLink.download = `${event?.file?.name || file?.name}.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    });
  };

  const onDocumentLoadSuccess = (data) => {
    setNumPages(data);
  };

  const ShowContent = () => {
    try {
      const url = URL.createObjectURL(event?.file || file);
      const fileType =
        event?.file?.type ||
        event?.file?.name.split('.')[1] ||
        file?.type ||
        file?.name.split('.')[1];
      // console.log(fileType);
      switch (fileType) {
        case 'application/pdf':
        case 'pdf':
          return (
            <MobilePDFReader
              onDocumentComplete={onDocumentLoadSuccess}
              isShowHeader={false}
              isShowFooter
              page={pageNumber}
              scale={scaleNumber}
              url={url}
            />
          );
        case 'application/zip':
          return (
            <p className="txt-body">
              We are unable to display the file momentarily.
              <strong>Download it to see it.</strong>
            </p>
          );
        case 'image/svg+xml':
        case 'png':
        case 'jpeg':
          return <img className="img-class" src={url} alt="content" />;

        default:
          return (
            <p className="txt-body">
              We are unable to display the file momentarily.
              <b>Download it to see it.</b>
            </p>
          );
      }
    } catch (error) {
      if (event?.isDisplayQR) {
        return (
          <div className="qr-container bounce" ref={qrCanvasRef}>
            <QRCode
              style={{ height: 'auto', maxWidth: '100%', width: '100%', borderRadius: '8%' }}
              value={event?.file?.link}
            />
          </div>
        );
      }
      return (
        <p className="txt-body">
          We are unable to display the file momentarily.
          <strong>Download it to see it.</strong>
        </p>
      );
    }
  };

  return (
    <div className="file-wapper">
      <div className="headerFile">
        <Button
          title="Previous page"
          className="white-btn bounce"
          onClick={() => handleCloseDisplayerFile()}
        >
          <img className="back-img" src={BackIcon} alt="back to the form" />
        </Button>
        <p className="titleFile">{event?.file?.name || file?.name}</p>
        <div className="actions-file">
          {(event?.isDownloadable && (event?.file || file)) || notEvent ? (
            <Button
              title="Download the file"
              className="white-btn"
              onClick={event?.isDisplayQR ? downloadQR : handleDownloadFile}
            >
              <img className="download-img" src={DownloadIcon} alt="download file" />
            </Button>
          ) : (
            <span />
          )}
        </div>
      </div>
      <div className="contentFile">
        {event?.file || file ? (
          <div className={event?.stylescls || 'auto-class'}>
            <ShowContent />
          </div>
        ) : (
          'No resource'
        )}
        {event?.isDisplayQR && (
          <div className="radar-overlay">
            <div className="radar-circle"> </div>
            <div className="radar-circle2"> </div>
            <div className="radar-circle3"> </div>
          </div>
        )}
      </div>
    </div>
  );
};

FileDisplayer.propTypes = {
  handleCloseDisplayerFile: PropTypes.func.isRequired,
};

export default FileDisplayer;
