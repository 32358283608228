/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
/* eslint-disable import/no-useless-path-segments */
/* eslint-disable no-case-declarations */
/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable no-empty-pattern */
import React, { useState, useContext, useEffect } from 'react';

import { Button, Dropdown, InputText, MultiSelect } from '../../components';
import './CaseTypeForm.css';
import { AccountContext } from '../../contexts/AccountContext';
import { ToolsContext } from '../../contexts/ToolsContext';
import { StateCaseForm, InputsCaseTypeForm, OutputsCaseTypeForm } from '../../forms';
import { appDashboardService, casesDashboardService } from '../../services';

const DEFAULT_CASETYPE = {
  description: '',
  paramsExpected: '',
  resultsExpected: '',
  startingAppId: '',
  casesState: [],
};

function CaseTypeForm({
  isEditingCaseType = false,
  isNewCaseType = false,
  caseInfo2,
  setInfoCase,
  getListCasesTypes,
}) {
  const [loading, setLoading] = useState(true);
  const [caseInfo, setCaseInfo] = useState(caseInfo2 || DEFAULT_CASETYPE);
  const [selectedApp, setSelectedApp] = useState([]);
  const { setSelectedCaseType, showSnackbar, apps, setApps, accountInfo } = useContext(
    AccountContext
  );
  const { showModal, handleCloseModal } = useContext(ToolsContext);
  const { userData, workspaceData } = accountInfo;
  const [showAdvanced, setShowAdvanced] = useState(false);
  const { id: userId } = userData;
  const [selectedWorkflow, setSelectedWorkflow] = useState(null);

  const userOptions = [
    { name: 'Edit case type', code: 'update', icon: 'pi pi-fw pi-refresh', display: true },
    { name: 'Delete case type', code: 'delete', icon: 'pi pi-fw pi-trash', display: true },
  ];

  const HeaderForm = ({ titleForm }) => {
    return <div className="activity-header bounce">{titleForm}</div>;
  };

  const toogleShowAdvanced = () => {
    setShowAdvanced((s) => !s);
  };

  const handleEditTypeCase = () => {
    // console.log('EP', selectedApp, caseInfo);
  };

  useEffect(async () => {
    if (selectedWorkflow) {
      const { code } = selectedWorkflow;
      switch (code) {
        case 'update':
          const caseForm = (
            <CaseTypeForm
              isEditingCaseType
              caseInfo2={caseInfo}
              setInfoCase={setInfoCase}
              getListCasesTypes={getListCasesTypes}
            />
          );
          showModal(caseForm, <HeaderForm titleForm="Edit Case Type" />);
          break;
        case 'delete':
          showSnackbar('info', '', 'Delete in progress!', 3000);
          break;
        default:
          break;
      }
    }
    setSelectedWorkflow(null);
    return () => {
      setSelectedWorkflow(null);
    };
  }, [selectedWorkflow]);

  const resetCase = () => {
    setSelectedCaseType(null);
    setInfoCase(null);
  };

  const fetchAllApps = async (loader = true) => {
    loader && setLoading(true);
    if (userId) {
      const { success, data: allApps } = await appDashboardService.getAllApps(userId);
      if (success) {
        setApps(allApps);
        loader && setLoading(false);
      } else {
        loader && setLoading(false);
      }
    } else {
      showSnackbar('error', '', 'Apps could not be loaded');
      loader && setLoading(false);
    }
    setLoading(false);
  };

  useEffect(async () => {
    if (apps.length === 0) {
      await fetchAllApps();
    }
    if (isNewCaseType) {
      setShowAdvanced(true);
    }
    setLoading(false);
    return () => {};
  }, []);

  useEffect(() => {
    if (apps?.length > 0) {
      const ajua = apps.filter((a) => a.id === caseInfo.startingApp);
      setSelectedApp(ajua);
    }
    return () => {};
  }, [apps]);

  const modulesOptionTemplate = (option) => {
    return (
      option.display && (
        <div className="flex option-backgroung">
          <i className={option.icon} />
          <p className="value-txt">{option.name}</p>
        </div>
      )
    );
  };

  const onWorkflowChange = (e) => {
    setSelectedWorkflow(e.value);
  };

  const handleCreateCaseType = async () => {
    const { success } = await casesDashboardService.createCaseType({
      id: caseInfo?.id,
      description: caseInfo?.description,
      workspace: { id: workspaceData?.data?.id },
      startingApp: caseInfo?.startingAppId,
    });
    if (success) {
      getListCasesTypes();
      handleCloseModal();
      resetCase();
      showSnackbar('success', '', 'Case Type created successfully!');
    } else {
      showSnackbar('error', '', 'Case Type could not be created!', 3000);
    }
  };

  const ParamsExpectedCmp = ({ editing = false }) => {
    return !editing ? (
      <div className={isEditingCaseType ? 'groupParams2' : 'groupParams'}>
        {caseInfo?.paramsExpected?.split('/').map((p) => {
          return <div className="paramsExpected">{p}</div>;
        })}
      </div>
    ) : (
      <div className={isEditingCaseType ? 'groupParams2' : 'groupParams'}>
        {caseInfo?.paramsExpected?.split('/').map((p) => {
          return <div className="paramsExpected">{p}</div>;
        })}
      </div>
    );
  };
  const createStateCaseType = async (values) => {
    const { success } = await casesDashboardService.createStateOfCaseType(caseInfo.id, {
      id: values?.id,
      description: values?.description,
    });
    if (success) {
      getListCasesTypes();
      handleCloseModal();
      resetCase();
      showSnackbar('success', '', 'State created successfully!');
    } else {
      showSnackbar('error', '', 'State could not be created!', 3000);
    }
  };

  const updateStateCaseType = async (values) => {
    // console.log('values', values);
    // console.log(caseInfo);
    const { success } = await casesDashboardService.updateStateOfCaseType(caseInfo.id, {
      type: { id: values?.id },
      description: values?.description,
    });
    if (success) {
      getListCasesTypes();
      handleCloseModal();
      resetCase();
      showSnackbar('success', '', 'State edited successfully!');
    } else {
      showSnackbar('error', '', 'State could not be edited!', 3000);
    }
  };

  const handleUpdateCaseType = async () => {
    const { success } = await casesDashboardService.updateCaseType(caseInfo?.id, {
      id: caseInfo?.id,
      description: caseInfo?.description,
      workspace: { id: workspaceData?.data?.id },
      startingAppId: caseInfo?.startingAppId,
      // paramsExpected: caseInfo?.paramsExpected || 'Test/test2/jeje',
      // resultsExpected: caseInfo?.resultsExpected || 'Test3/test4/jaja',
    });
    if (success) {
      getListCasesTypes();
      handleCloseModal();
      resetCase();
      showSnackbar('success', '', 'Case Type edited successfully!');
    } else {
      showSnackbar('error', '', 'Case Type could not be edited!', 3000);
    }
  };

  const ResultsExpectedCmp = () => {
    return (
      <div className={isEditingCaseType ? 'groupParams2' : 'groupParams'}>
        {caseInfo?.resultsExpected?.split('/').map((p) => {
          return <div className="resultExpected">{p}</div>;
        })}
      </div>
    );
  };

  const StatesExpectedCmp = () => {
    return (
      <div className={isEditingCaseType ? 'groupParams2' : 'groupParams'}>
        {caseInfo.casesState.map((p, i) => {
          return (
            <Button
              key={`but${i}`}
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                const stateCaseTypeForm = (
                  <StateCaseForm
                    handleSubmit={updateStateCaseType}
                    data={p}
                    editing={isEditingCaseType}
                    caseTypeFather={caseInfo}
                  />
                );
                showModal(stateCaseTypeForm, <HeaderForm titleForm="Edit State" />);
              }}
              className="statesExpected"
            >
              {p.case_state_description}
            </Button>
          );
        })}
      </div>
    );
  };

  const statusTemplate = (option) => {
    return (
      <div className="flex-row">
        <div>{`${option.name}`}</div>
        <span className="engine-bagde">{`Engine ${option.engine.name}`}</span>
      </div>
    );
  };

  const handleEditInputCaseType = async () => {
    // console.log('Sebas');
  };

  const validateForm = !(isNewCaseType || isEditingCaseType);

  return (
    <div className={validateForm ? 'casetype-wrapper' : 'casetype-wrapper2'}>
      <section className="section-scroller">
        {validateForm ? (
          <div className="flex-wrapper">
            <div className="flex aling-items-center">
              <Button
                title="Previous page"
                icon="pi pi-arrow-left"
                className="back2 bounce"
                onClick={resetCase}
              />
              <h2 className="subtitle-data-txt">Case Type Info</h2>
            </div>

            <div className="flex-row">
              <Button className="advanced-cls3" type="button" onClick={toogleShowAdvanced}>
                Advanced Settings
              </Button>
              <Dropdown
                value={selectedWorkflow}
                options={userOptions}
                onChange={onWorkflowChange}
                optionLabel="name"
                placeholder={null}
                filterPlaceholder={null}
                itemTemplate={modulesOptionTemplate}
              />
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="casetype-body">
          <div
            className={`flex-row ${
              isNewCaseType || isEditingCaseType ? 'user-formGroup2' : 'user-formGroup'
            }`}
          >
            <label>Name</label>
            <InputText
              disabled={validateForm}
              spellCheck={false}
              value={caseInfo?.description}
              placeholder="Select a name"
              className="user-inputText"
              onChange={(e) => {
                setCaseInfo({ ...caseInfo, description: e.target.value });
              }}
            />
          </div>

          {!isNewCaseType ? (
            <div
              className={`flex-row ${
                isNewCaseType || isEditingCaseType ? 'user-formGroup2' : 'user-formGroup'
              }`}
            >
              <div className="flexer">
                <p
                  className={
                    isNewCaseType || isEditingCaseType
                      ? 'subheader-casetype2'
                      : 'subheader-casetype'
                  }
                >
                  States
                </p>
              </div>
              {caseInfo?.casesState?.length > 0 ? (
                <StatesExpectedCmp editing={isEditingCaseType} />
              ) : (
                <p className="none-params bounce">⚠️ Empty states</p>
              )}
              {isEditingCaseType && (
                <Button
                  icon="pi pi-plus"
                  className="add-ticket-btn"
                  onClick={(e) => {
                    e.stopPropagation();
                    const stateCaseTypeForm = <StateCaseForm handleSubmit={createStateCaseType} />;
                    showModal(stateCaseTypeForm, <HeaderForm titleForm="Add State" />);
                  }}
                />
              )}
            </div>
          ) : (
            // <div className={`flex-row user-formGroup2 `}>
            //   <label>States</label>
            //   {caseInfo?.casesState?.length > 0 ? (
            //     <StatesExpectedCmp editing />
            //   ) : (
            //     <div className="add-caseTypeDetail">
            //       <Button
            //         onClick={(e) => {
            //           e.stopPropagation();
            //           const teamForm = <StateCaseForm handleSubmit={createStateCaseType} />;
            //           showModal(teamForm, <HeaderForm titleForm="Add State" />);
            //         }}
            //       >
            //         Add State
            //       </Button>
            //     </div>
            //   )}
            // </div>
            <></>
          )}
          {(showAdvanced || isNewCaseType || isEditingCaseType) && (
            <>
              <div
                className={`flex-row ${
                  isNewCaseType || isEditingCaseType ? 'user-formGroup2' : 'user-formGroup'
                }`}
              >
                <label>CaseType ID</label>
                <InputText
                  disabled={!isNewCaseType}
                  spellCheck={false}
                  value={caseInfo?.id}
                  placeholder="Select a ID"
                  className="user-inputText"
                  onChange={(e) => {
                    setCaseInfo({ ...caseInfo, id: e.target.value });
                  }}
                />
              </div>
              {validateForm ? (
                <div className={`flex-row user-formGroup `}>
                  <label>Starting App ⚡</label>
                  {caseInfo?.startingApp ? (
                    <InputText
                      disabled
                      spellCheck={false}
                      value={selectedApp[0]?.name}
                      className="user-inputText"
                    />
                  ) : (
                    <p className="none-params bounce">⚠️ Empty starting application</p>
                  )}
                </div>
              ) : (
                <div className={`flex-row user-formGroup2 `}>
                  <label>Starting App ⚡</label>
                  <MultiSelect
                    optionLabel="name"
                    placeholder="Select the App to be related"
                    className="blue-input"
                    style={{ background: 'rgb(62, 103, 142)' }}
                    value={selectedApp}
                    options={apps}
                    onChange={(e) => {
                      if (e.value.length === 1) {
                        setSelectedApp([e.value[0]]);
                        setCaseInfo({ ...caseInfo, startingAppId: e.value[0].id });
                      } else {
                        e.value[1] && setSelectedApp([e.value[1]]);
                        e.value[1] && setCaseInfo({ ...caseInfo, startingAppId: e.value[1].id });
                      }
                    }}
                    itemTemplate={statusTemplate}
                    filter
                    maxSelectedLabels={2}
                  />
                </div>
              )}
              {!isNewCaseType ? (
                <div
                  className={`flex-row ${
                    isNewCaseType || isEditingCaseType ? 'user-formGroup2' : 'user-formGroup'
                  }`}
                >
                  <div className="flexer">
                    <p
                      className={
                        isNewCaseType || isEditingCaseType
                          ? 'subheader-casetype2'
                          : 'subheader-casetype'
                      }
                    >
                      Input values
                    </p>
                  </div>
                  {caseInfo?.paramsExpected?.length > 0 ? (
                    <ParamsExpectedCmp editing={isEditingCaseType} />
                  ) : (
                    <p className="none-params bounce">⚠️ Empty input values</p>
                  )}
                  {isEditingCaseType && (
                    <Button
                      type="button"
                      icon="pi pi-plus"
                      className="add-ticket-btn"
                      onClick={(e) => {
                        e.stopPropagation();
                        const inputsCaseTypeForm = (
                          <InputsCaseTypeForm
                            data={caseInfo?.paramsExpected}
                            handleSubmit={handleEditInputCaseType}
                          />
                        );
                        showModal(inputsCaseTypeForm, <HeaderForm titleForm="Add inputs" />);
                      }}
                    />
                  )}
                </div>
              ) : (
                // <div className={`flex-row user-formGroup2 `}>
                //   <label>Input values</label>
                //   <InputText
                //     spellCheck={false}
                //     value={caseInfo?.paramsExpected}
                //     className="user-inputText"
                //     onChange={setCaseInfo}
                //   />
                // </div>
                <></>
              )}
              {!isNewCaseType ? (
                <div
                  className={`flex-row ${
                    isNewCaseType || isEditingCaseType ? 'user-formGroup2' : 'user-formGroup'
                  }`}
                >
                  <div className="flexer">
                    <p
                      className={
                        isNewCaseType || isEditingCaseType
                          ? 'subheader-casetype2'
                          : 'subheader-casetype'
                      }
                    >
                      Output values
                    </p>
                  </div>
                  {caseInfo?.resultsExpected?.length > 0 ? (
                    <ResultsExpectedCmp />
                  ) : (
                    <p className="none-params bounce">⚠️ Empty output values</p>
                  )}
                  {isEditingCaseType && (
                    <Button
                      icon="pi pi-plus"
                      className="add-ticket-btn"
                      onClick={(e) => {
                        e.stopPropagation();
                        const teamForm = <StateCaseForm handleSubmit={handleCreateCaseType} />;
                        showModal(teamForm, <HeaderForm titleForm="Add Outputs" />);
                      }}
                    />
                  )}
                </div>
              ) : (
                // <div className={`flex-row user-formGroup2 `}>
                //   <label>Output values</label>
                //   <InputText
                //     spellCheck={false}
                //     value={caseInfo?.resultsExpected}
                //     className="user-inputText"
                //     onChange={setCaseInfo}
                //   />
                // </div>
                <></>
              )}
            </>
          )}
          {(isNewCaseType || isEditingCaseType) && (
            <div className="footer-batch">
              <Button
                onClick={!isEditingCaseType ? handleCreateCaseType : handleUpdateCaseType}
                className="app-batch-btn"
                label={!isEditingCaseType ? 'Add' : 'Edit'}
              />
            </div>
          )}
        </div>
      </section>
    </div>
  );
}

export default CaseTypeForm;
