import * as Yup from 'yup';

const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
// min 8 characters, 1 upper case letter, 1 lower case letter, 1 numeric digit.

const formSchema = Yup.object().shape({
  new_pwd: Yup.string()
    .matches(passwordRules, { message: 'Please create a stronger password' })
    .required('Required'),
  confirmation_pwd: Yup.string()
    .oneOf([Yup.ref('password'), null], 'New passwords must match')
    .required('Required'),
});

export default formSchema;
