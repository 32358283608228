import * as Yup from 'yup';

const formSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email address format').required('Email is required'),
  password: Yup.string()
    .min(6, 'Password must be 6 characters at minimun')
    .required('Password is required'),
});

export default formSchema;
