/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

import PropTypes from 'prop-types';

import ForgotImg from '../../assets/images/forgot_password.svg';
import { Button } from '../../components';

import './RecoveryForm.css';

const RecoveryForm = ({ data, handleCloseCancel, handleSubmit, loading }) => {
  // const dataTitles = [{}, {}];
  return (
    <form>
      <section className="recovery-section">
        <img className="recovery-img" src={ForgotImg} alt="forgot password" />
        <h2 className="disclosure-txt bounce">
          ¿Deseas enviarle un correo electrónico al usuario
          <span className="name-txt">{` ${data?.name} `}</span>
          asociada con tu cuenta para ayudarle a restablecer su contraseña?
        </h2>
        <div className="keep-row2">
          <Button
            type="button"
            className="recovery-primary"
            label="Si, enviar"
            onClick={() => handleSubmit(data)}
          >
            {loading && <div className="loader2" />}
          </Button>
          <Button
            type="button"
            className="recovery-cancel"
            label="Cancelar"
            onClick={handleCloseCancel}
          />
        </div>
      </section>
    </form>
  );
};

RecoveryForm.propTypes = {
  data: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleCloseCancel: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default RecoveryForm;
