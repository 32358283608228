import { httpRequest, wrap } from './tools';
// TODO make urls constants

const createForm = async (ws, payload) => {
  const fn = async () => {
    const url = `/${ws}/forms`;
    const response = await httpRequest(url, 'POST', payload, true);
    return response.data;
  };

  return wrap(fn);
};

const getForm = async (ws, formId, token) => {
  const fn = async () => {
    const url = `/${ws}/forms/${formId}`;
    const response = await httpRequest(url, 'GET', null, true, token);
    return response.data;
  };

  return wrap(fn);
};

const listForms = async (ws) => {
  const fn = async () => {
    const url = `/${ws}/forms`;
    const response = await httpRequest(url, 'GET', null, true);
    return response.data;
  };

  return wrap(fn);
};

const updateForm = async (ws, formId, payload) => {
  const fn = async () => {
    const url = `/${ws}/forms/${formId}`;
    const response = await httpRequest(url, 'PATCH', payload, true);

    return response.data;
  };

  return wrap(fn);
};

// eslint-disable-next-line import/prefer-default-export
export { getForm, createForm, listForms, updateForm };
