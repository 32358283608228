import { httpRequest, wrap } from './tools';

const createToken = async (params) => {
  const fn = async () => {
    const url = `/token?grant_type=password`;
    const response = await httpRequest(url, 'POST', params, true);
    return response.data;
  };

  return wrap(fn);
};

// eslint-disable-next-line import/prefer-default-export
export { createToken };
