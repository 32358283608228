import * as Yup from 'yup';

const formSchema = Yup.object().shape({
  name: Yup.string().required('Name is requiered'),
  description: Yup.string()
    .min(10, 'Description must be at least 10 characters')
    .max(120, 'The description must have a maximum of 120 characters')
    .required('Description is required'),
});

export default formSchema;
