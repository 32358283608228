/* eslint-disable no-constant-condition */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import React, { useState, useEffect, useContext } from 'react';

import { DebounceInput } from 'react-debounce-input';

import RefreshIcon from '../../../assets/images/refresh.png';
import listEmptyImage from '../../../assets/svgs/arts/undraw_collaboration.svg';
import InfoTicket from '../../../assets/svgs/info.svg';
import { Button, DataTable, MultiSelect, Column } from '../../../components';
import { AccountContext } from '../../../contexts/AccountContext';
import { TicketsContext } from '../../../contexts/TicketsContext';
import { ToolsContext } from '../../../contexts/ToolsContext';
import { EditATicketForm } from '../../../forms';
import tools from '../../../utils/time';
import { handleCopyClipboard } from '../../../utils/utils';

import './ExternalCases.css';

const DEFAULT_FILTERS = {
  'operator.name': { value: null, matchMode: 'in' },
  light_indicator: { value: null, matchMode: 'in' },
};

export default function ExternalCases() {
  const { menuCollapse, accountInfo, showSnackbar } = useContext(AccountContext);
  const { showModal, handleCloseModal } = useContext(ToolsContext);
  const { userData } = accountInfo || {};
  const { role } = userData || [];
  // const validAdminUser = role === 'ADMIN';
  const {
    groupsRetrieved,
    overall,
    fetchOverAllCases,
    onCreateATicket,
    casesByUser,
    resolveCase,
  } = useContext(TicketsContext);
  const [selectedCasesPriorities, setSelectedCasesPriorities] = useState([]);
  const [selectedRestOfPpl, setSelectedRestOfPpl] = useState([]);
  const [idFilter, setIdFilter] = useState('');
  const [selectedPpl, setSelectedPpl] = useState([]);
  const [selectedTempPpl, setSelectedTempPpl] = useState([]);
  const [disableClear, setDisableClear] = useState(false);
  const [filters, setFilters] = useState(DEFAULT_FILTERS);
  const [usersRetrieved, setUsersRetrieved] = useState([]);
  const [selectedCasesGroups, setSelectedCasesGroups] = useState([]);
  const [selectedCasesStatus, setSelectedCasesStatus] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleGetConditionsCode = () => {
    return selectedCasesPriorities?.map((c) => c.name);
  };

  const retriveUsers = (groupList) => {
    const listUsers = groupList
      .filter((g) => {
        return !!g.operator.name;
      })
      .map((g) => g.operator.name)
      .flat();
    setUsersRetrieved([...new Set(listUsers)]);
  };

  const handleFocusInput = () => {
    const input = document.getElementById('searchTicketInGroups-input');
    input?.focus();
  };

  useEffect(() => {
    setFilters({
      'operator.name': { value: idFilter, matchMode: 'contains' },
      light_indicator: { value: handleGetConditionsCode(), matchMode: 'in' },
    });
    setTimeout(() => {
      if (
        selectedCasesGroups?.length ||
        selectedCasesPriorities?.length ||
        selectedCasesStatus?.length ||
        selectedPpl?.length ||
        idFilter !== ''
      ) {
        setDisableClear(false);
      } else {
        setDisableClear(true);
      }
    }, 200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCasesGroups, selectedCasesPriorities, selectedCasesStatus, selectedPpl, idFilter]);

  useEffect(() => {
    if (filters) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 200);
    }
  }, [filters]);

  useEffect(() => {
    if (usersRetrieved) {
      setSelectedRestOfPpl([...usersRetrieved.slice(5, usersRetrieved.length)]);
    }
  }, [usersRetrieved]);

  useEffect(() => {
    if (overall?.length === 0) {
      fetchOverAllCases();
    }
    setTimeout(() => {
      handleFocusInput();
    }, 1000);
  }, []);

  useEffect(() => {
    if (overall?.length > 0) {
      retriveUsers(overall);
    }
  }, [overall]);

  const handleReset = () => {
    setSelectedCasesStatus([]);
    setSelectedCasesPriorities([]);
    setSelectedCasesGroups([]);
    setSelectedPpl([]);
    setSelectedTempPpl([]);
    setIdFilter('');
  };

  const handleNamePrefix = () => {
    return usersRetrieved?.reduce((namesPrefixed, player) => {
      const [first, second] = player?.split(' ');
      if (!second) {
        namesPrefixed?.push(`${first[0]}${first[1]}`.toUpperCase());
      } else {
        namesPrefixed?.push(`${first[0]}${second[0]}`.toUpperCase());
      }
      return namesPrefixed;
    }, []);
  };

  const diffBetweenArrays = (arr1, arr2) => {
    return arr1.filter((item) => arr2.indexOf(item) === -1);
  };

  const handleTogglePpl = (pivot, isActionButton) => {
    let persona;
    if (isActionButton) {
      persona = usersRetrieved[pivot];
    } else {
      // eslint-disable-next-line no-lonely-if
      if (!(selectedTempPpl.length <= pivot.length)) {
        const leftPerson = diffBetweenArrays(selectedTempPpl, pivot);
        [persona] = leftPerson;
      } else {
        persona = pivot[pivot.length - 1];
      }
    }
    const isSelected = selectedPpl.some((p) => persona === p);
    if (!isSelected) {
      setSelectedPpl((prev) => [...prev, persona]);
      setSelectedTempPpl((prev) => [...prev, persona]);
    } else {
      setSelectedPpl((prev) => prev.filter((value) => value !== persona));
      setSelectedTempPpl((prev) =>
        prev.filter((value) => {
          return persona ? value !== persona : [];
        })
      );
    }
  };

  const conditions = [
    { name: 'green', code: 'On time' },
    { name: 'yellow', code: 'Warning' },
    { name: 'red', code: 'Running late' },
  ];

  const AvatarColumn = ({ operator, status: caseStatus }) => {
    return caseStatus === 'unassigned' ? (
      <Button className="history-avatar avatar-align-empty" title="Case unassigned">
        -
      </Button>
    ) : (
      <p>{operator.name}</p>
    );
  };

  const ConditionColumn = ({ light_indicator, created_at }) => {
    const difference = tools.timeSince(created_at.seconds);
    return (
      <div className={`dot2 ${light_indicator}`} title="Created since">
        <p className="dot-value">{difference}</p>
      </div>
    );
  };

  const conditionTemplate = (c) => {
    return (
      <div className="wrapper-conditions">
        <span className={`dot ${c.name}`} />
        <p className="conditions">{c.code}</p>
      </div>
    );
  };

  const HeaderForm = ({ title, iconName = '' }) => {
    return <div className="activity-header">{title}</div>;
  };

  const ListEmptyState = () => {
    return (
      <div className="list-empty-wrapper bounce">
        <div className="detail-container">
          <div className="detail-wrapper">
            <img className="mail-img" src={listEmptyImage} alt="Search a case" />
            <p className="detail-message bounce">No external tickets found!</p>
          </div>
        </div>
      </div>
    );
  };

  const handleCancel = async (id2) => {
    const result = await resolveCase(
      id2,
      JSON.stringify({
        initialValues: {},
        exportedValues: { exit: 'canceled' },
      }),
      false
    );
    if (result) {
      showSnackbar('success', '', 'Ticket canceled!', 3000);
    } else {
      showSnackbar('error', '', 'Ticket could not be canceled!', 3000);
    }
    await fetchOverAllCases();
    handleCloseModal();
  };

  const ContentHero = ({
    contact,
    operation,
    id: id2,
    group,
    description,
    creation,
    light_indicator,
    due_date,
    created_at,
  }) => {
    return (
      <div className={`details-case-hero `}>
        <table className="details-case-table">
          <>
            <tr className="details-case-tr">
              <th className="details-case-th">Ticket ID</th>
              <td className="details-case-td">{id2}</td>
            </tr>
            <tr className="details-case-tr">
              <th className="details-case-th">Contact</th>
              <td className="details-case-td">{contact.slice(6)}</td>
            </tr>
            <tr className="details-case-tr">
              <th className="details-case-th">Case ID</th>
              <div className="flex-row">
                <td className="details-case-td">{operation}</td>
                <Button
                  title="Copy ID"
                  className="button-action-share"
                  icon="pi pi-share-alt"
                  onClick={() => handleCopyClipboard(operation)}
                />
              </div>
            </tr>
            <tr className="details-case-tr">
              <th className="details-case-th">Team</th>
              <td className="details-case-td">{group.name}</td>
            </tr>
            <tr className="details-case-tr">
              <th className="details-case-th">Description</th>
              <td className="details-case-td">{description}</td>
            </tr>
            <tr className="details-case-tr">
              <th className="details-case-th">Created</th>
              <td className="details-case-td">
                {creation}
                <div
                  className={`dot2 ${light_indicator}`}
                  title="Created since"
                  style={{ marginLeft: '10px' }}
                >
                  <p className="dot-value">{tools.timeSince(created_at?.seconds)}</p>
                </div>
              </td>
            </tr>
            <tr className="details-case-tr">
              <th className="details-case-th">Due date</th>
              <td className="details-case-td">{tools.timeSince(due_date?.seconds)}</td>
            </tr>
          </>
        </table>
        <div className="actioners-tickets">
          <Button
            title="Cancel ticket"
            className="btn-delete"
            icon="pi pi-trash"
            onClick={() => {
              handleCancel(operation);
            }}
          >
            Cancel ticket
          </Button>
        </div>
      </div>
    );
  };

  const ActionsColumn = ({
    status,
    id: idCase,
    operation,
    contact_name,
    group,
    description,
    creation,
    due_date,
    created_at,
    light_indicator,
    contact,
  }) => {
    return (
      <div className="ticket-actions">
        <Button
          title="Ticket Info"
          className="assigned-arrow"
          style={{ outline: 'none' }}
          onClick={(e) => {
            e.stopPropagation();
            showModal(
              <ContentHero
                contact={contact}
                contact_name={contact_name}
                operation={operation}
                id={idCase}
                group={group}
                description={description}
                creation={creation}
                due_date={due_date}
                created_at={created_at}
                light_indicator={light_indicator}
              />,
              <HeaderForm title="Ticket Info" />
            );
          }}
        >
          <img className="assigned-arrow" src={InfoTicket} alt="" />
        </Button>
      </div>
    );
  };

  const filteredData = overall.filter((f) => {
    return (
      f?.group?.id.toLowerCase().includes('mybasket') ||
      f?.group?.id.toLowerCase().includes('my-basket')
    );
  });

  return (
    <div className="history-wrapper">
      {overall?.length > 0 ? (
        <div className={!menuCollapse ? 'history-container2' : 'history-container'}>
          <div className="search-wrapper">
            {false ? (
              <div className="plus-icon">
                <Button
                  title="Clear search"
                  icon="pi pi-plus"
                  className="add-ticket-btn"
                  onClick={() =>
                    onCreateATicket({
                      header: <HeaderForm title="Create a ticket" />,
                      content: (
                        <EditATicketForm
                          isNewTicket
                          caseInfo={[]}
                          groupsRetrieved={groupsRetrieved}
                          refresh={() => null}
                        />
                      ),
                    })
                  }
                />
              </div>
            ) : null}

            <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <DebounceInput
                id="searchTicket-input"
                className="buttonFilter2"
                value={idFilter}
                debounceTimeout={700}
                onChange={(e) => setIdFilter(e.target.value)}
                placeholder="Search by target"
                autoComplete="off"
              />
              <Button
                type="button"
                title="Clear batch"
                className="pi pi-trash adjust-trash2"
                onClick={() => setIdFilter('')}
              />
            </span>
            <div className="history-A-filters">
              {/* <MultiSelect
                optionLabel=""
                placeholder="Teams"
                className="history-A-dropdown"
                value={selectedCasesGroups}
                options={groupsRetrieved}
                onChange={(e) => setSelectedCasesGroups(e.value)}
                itemTemplate={groupTemplate}
                fixedPlaceholder
              /> */}
              {/* <MultiSelect
                optionLabel="name"
                placeholder="Status"
                className="history-A-dropdown"
                options={status_default}
                value={selectedCasesStatus}
                onChange={(e) => setSelectedCasesStatus(e.value)}
                filter
                fixedPlaceholder
              /> */}
              <MultiSelect
                optionLabel="name"
                placeholder="Condition"
                className="history-A-dropdown"
                value={selectedCasesPriorities}
                options={conditions}
                onChange={(e) => setSelectedCasesPriorities(e.value)}
                filter
                itemTemplate={conditionTemplate}
                fixedPlaceholder
              />
              {/* <div className="history-A-people">
                <AvatarGroups />
              </div> */}
              {!disableClear && (
                <Button
                  title="Clear filters"
                  icon="pi pi-trash"
                  className="history-A-clear"
                  onClick={handleReset}
                />
              )}
            </div>
            <Button
              title="Refresh tickets"
              className="refresh-basket2"
              onClick={() => {
                showSnackbar('success', '', '¡Tickets  refreshed!', 2000);
                casesByUser();
                handleReset();
              }}
            >
              <img className="refresh-img" src={RefreshIcon} alt="refresh tickets button" />
            </Button>
          </div>
          {!loading ? (
            <DataTable
              className="p-datatable-striped"
              emptyMessage="No tickets found."
              value={filteredData.reverse()}
              globalFilterFields={[
                'operation',
                'light_indicator',
                'operator.name',
                'group.name',
                'status',
              ]}
              responsiveLayout="scroll"
              paginator={filteredData.length > 10}
              rows={10}
              resizableColumns
              filters={filters}
              scrollable
              scrollHeight="100%"
            >
              <Column
                field="group.id"
                filterField="group.id"
                header="Actions"
                headerStyle={{
                  maxWidth: '3vw',
                  minWidth: '20px',
                  width: '3vw',
                  textAlign: 'left',
                  backgroundColor: '#22384d',
                  color: 'white',
                  height: '40px',
                }}
                body={ActionsColumn}
                bodyStyle={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
              />
              {/* <Column
                  field="id"
                  filterField="id"
                  header=""
                  headerStyle={{
                    maxWidth: '0px',
                    width: '0px',
                    textAlign: 'center',
                    backgroundColor: 'transparent',
                    color: 'transparent',
                  }}
                  bodyStyle={{
                    textAlign: 'center',
                    overflow: 'visible',
                    backgroundColor: 'transparent',
                    color: 'transparent',
                  }}
                /> */}
              <Column
                field="light_indicator"
                filterField="light_indicator"
                header=""
                headerStyle={{
                  maxWidth: '3vw',
                  minWidth: '30px',
                  width: '3vw',
                  textAlign: 'center',
                  backgroundColor: '#22384d',
                  color: 'white',
                }}
                sortable
                body={ConditionColumn}
                bodyStyle={{
                  textAlign: 'center',
                  overflow: 'visible',
                }}
              />
              <Column
                field="operation"
                header="Case ID"
                headerStyle={{
                  maxWidth: '7vw',
                  minWidth: '100px',
                  width: '7vw',
                  textAlign: 'left',
                  backgroundColor: '#22384d',
                  color: 'white',
                }}
                bodyStyle={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  textAlign: 'left',
                  whiteSpace: 'nowrap',
                }}
              />
              <Column
                field="description"
                header="Description"
                headerStyle={{
                  maxWidth: '15vw',
                  minWidth: '200px',
                  width: '15vw',
                  textAlign: 'left',
                  backgroundColor: '#22384d',
                  color: 'white',
                }}
                bodyStyle={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
              />
              {/* <Column
                  field="group.id"
                  filterField="group.id"
                  header="Team"
                  headerStyle={{
                    width: '150px',
                    minWidth: '150px',
                    maxWidth: '150px',
                    textAlign: 'center',
                    backgroundColor: '#22384d',
                    color: 'white',
                  }}
                  body={GroupColumn}
                  bodyStyle={{
                    textAlign: 'center',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    
                  }}
                  sortable
                /> */}
              <Column
                field="operator.name"
                filterField="operator.name"
                header="Target to"
                headerStyle={{
                  backgroundColor: '#22384d',
                  color: 'white',
                  maxWidth: '15vw',
                  minWidth: '150px',
                  width: '15vw',
                  textAlign: 'left',
                }}
                bodyStyle={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textAlign: 'left',
                }}
                body={AvatarColumn}
                sortable
              />
              {/* <Column
                  field="status.id"
                  filterField="status"
                  header="Status"
                  headerStyle={{
                    width: '110px',
                    height: '50px',
                    minWidth: '110px',
                    maxWidth: '110px',
                    textAlign: 'center',
                    backgroundColor: '#22384d',
                    color: 'white',
                  }}
                  body={StatusColumn}
                  bodyStyle={{ overflow: 'visible', backgroundColor: '#F2F2F5' }}
                  sortable
                /> */}
              {/* <Column
                  field="edit"
                  header="Edit"
                  headerStyle={{
                    width: '70px',
                    minWidth: '70px',
                    textAlign: 'center',
                    backgroundColor: '#22384d',
                    color: 'white',
                  }}
                  body={EditColumn}
                  bodyStyle={{ overflow: 'visible', backgroundColor: '#F2F2F5' }}
                /> */}
            </DataTable>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <ListEmptyState />
      )}
    </div>
  );
}
