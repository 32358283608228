/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useContext } from 'react';

import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import {
  Button,
  InputSwitch,
  InputText,
  UsersAndEngineDrag,
  Dropdown,
  DataTable,
  Column,
  TabView,
  TabPanel,
  Loader,
} from '../../components';
import { AccountContext } from '../../contexts/AccountContext';
import { useInput } from '../../hooks';
import { workSpaceService, adminInfoService } from '../../services';
import regexs from '../../utils/regexs';
import isEmpty from '../../utils/validators';
import './EngineForm.css';

const engineOptions = [
  { name: 'Edit engine', code: 'update', icon: 'pi pi-fw pi-refresh', display: true },
  { name: 'Delete engine', code: 'delete', icon: 'pi pi-fw pi-trash', display: true },
];

const EngineForm = ({
  data,
  handleSubmit,
  isModal,
  loadingMini = true,
  resetEngine,
  onEditButton,
  type = 'engine',
  handleDeleteEngine,
}) => {
  const history = useHistory();
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [loading, setLoading] = useState(true);
  const { tools, accountInfo, setSelectedApp, showSnackbar, menuCollapse } = useContext(
    AccountContext
  );
  const [selectedWorkflow, setSelectedWorkflow] = useState(null);
  const { workspaceData } = accountInfo;
  const [dirty, setDirty] = useState(false);
  const [assignedList, setAssignedList] = useState([]);
  const [availabledList, setAvailableList] = useState([]);
  // Engines section
  const [appsByEngine, setAppsByEngine] = useState(null);

  const nameInput = useInput('name', data.name || '', [
    {
      validatorName: 'name-required',
      regex: regexs.required,
    },
  ]);

  const idInput = useInput('id', data.id || '', [
    {
      validatorName: 'name-required',
      regex: regexs.required,
    },
  ]);

  const getAppsByEngine = async (userId) => {
    const { success, data: appsByEngineData } = await adminInfoService.getAppsByEngine(userId);
    if (success) {
      setAppsByEngine(appsByEngineData);
    } else {
      setAppsByEngine(null);
      // showSnackbar('error', '', 'We could not get the apps. Please try again');
    }
  };
  const descriptionInput = useInput('description', data.description || '', [
    {
      validatorName: 'description-required',
      regex: regexs.required,
    },
  ]);

  const isActiveInput = useInput('isActive', data.isActive || true, [
    {
      validatorName: 'isActive-required',
      regex: regexs.required,
    },
  ]);

  useEffect(async () => {
    if (selectedWorkflow) {
      const { code } = selectedWorkflow;
      switch (code) {
        case 'update':
          // onEditButton(data.id, type);
          showSnackbar('info', '', 'Edit in progress!', 3000);
          break;
        case 'delete':
          // handleDeleteEngine(data);
          showSnackbar('info', '', 'Delete in progress!', 3000);
          break;
        default:
          break;
      }
    }
    return () => {
      setSelectedWorkflow(null);
    };
  }, [selectedWorkflow]);

  const newData = {
    id: data.id || '',
    name: nameInput.value,
    description: descriptionInput.value,
    isActive: isActiveInput.value,
    users: assignedList,
    workspace: {
      id: workspaceData?.data?.id,
    },
  };

  const inputs = [nameInput, descriptionInput];

  const onWorkflowChange = (e) => {
    setSelectedWorkflow(e.value);
  };

  const modulesOptionTemplate = (option) => {
    return (
      option.display && (
        <div className="flex option-backgroung">
          <i className={option.icon} />
          <p className="value-txt">{option.name}</p>
        </div>
      )
    );
  };

  const handleAssignedClick = (id) => {
    const index = assignedList.findIndex((item) => item.id === id);

    if (index !== -1) {
      assignedList[index].selected = !assignedList[index].selected;
      setAssignedList([...assignedList]);
    }
  };

  const handleAvailableClick = (id) => {
    const index = availabledList.findIndex((item) => item.id === id);

    if (index !== -1) {
      availabledList[index].selected = !availabledList[index].selected;
      setAvailableList([...availabledList]);
    }
  };

  const onAssigned = (e) => {
    e.preventDefault();

    availabledList.forEach((item) => {
      if (item.selected) {
        // eslint-disable-next-line no-param-reassign
        const obj = { ...item, selected: false };
        assignedList?.push(obj);
      }
    });
    const newAvailabled = availabledList.filter((item) => !item.selected);
    setAvailableList([...newAvailabled]);
    setAssignedList([...assignedList]);
  };

  const onAvailable = (e) => {
    e.preventDefault();

    assignedList?.forEach((item) => {
      if (item.selected) {
        // eslint-disable-next-line no-param-reassign
        const obj = { ...item, selected: false };
        availabledList?.push(obj);
      }
    });
    const newAssigned = assignedList.filter((item) => !item.selected);
    setAvailableList([...availabledList]);
    setAssignedList([...newAssigned]);
  };

  const loadAvailables = async () => {
    const {
      success,
      data: allUsersByWorkspaceData,
    } = await workSpaceService.getAllUsersByWorkspace(workspaceData?.data?.id);
    if (success) {
      setAvailableList(allUsersByWorkspaceData?.data);
    } else {
      setAvailableList([]);
    }
  };

  useEffect(async () => {
    if (data && data.users) setAssignedList(data.users);
    await loadAvailables();
    await getAppsByEngine();
    await getAppsByEngine(data?.id);
    setLoading(false);
  }, []);

  useEffect(() => {
    let isDirty = true;
    let i = 0;
    if (isEmpty(data)) {
      isDirty = !nameInput.isValid;
    } else {
      let valueChanged = false;
      let item;

      if (isActiveInput.value !== data.isActive || isEmpty(data)) valueChanged = true;

      inputs.map((input) => {
        input.setIsEnabled(true);
        if (input.value !== data[input.inputName]) {
          if (input.value || data[input.inputName]) valueChanged = true;
        }
        return input;
      });

      const newUsers = [];
      for (i = assignedList.length - 1; i >= 0; i -= 1) {
        if (assignedList[i].id) {
          item = assignedList[i].id;
          if (newUsers.indexOf(item) === -1) {
            newUsers.push(item);
          }
        }
      }

      isDirty = !(
        (valueChanged && nameInput.isValid) ||
        (descriptionInput.isValid && isActiveInput.isValid)
      );
    }
    setDirty(isDirty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    nameInput.value,
    nameInput.isValid,
    descriptionInput.value,
    descriptionInput.isValid,
    isActiveInput.value,
    isActiveInput.isValid,
    assignedList,
  ]);

  const goToApp = (value) => {
    setSelectedApp(value);
    history.push({ pathname: '/apps/edit' });
  };

  const AppsUsersByEngineList = () =>
    appsByEngine?.length > 0 ? (
      <div>
        <TabView className="table-wrap">
          <TabPanel header="Subcribed apps" headerClassName="bounce">
            {appsByEngine ? (
              <DataTable
                value={appsByEngine}
                className="p-datatable-striped top-adjust half-screen"
                rows={5}
                resizableColumns
                paginator
                emptyMessage="No info found."
                scrollable
                selectionMode="single"
                onSelectionChange={(e) => goToApp(e.value)}
              >
                <Column
                  field="id"
                  header="App Id"
                  headerStyle={{
                    width: '100px',
                    textAlign: 'left',
                    color: 'white',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    background: '#22384d',
                  }}
                  bodyStyle={{
                    width: '50px',
                    maxWidth: '50px',
                    minWidth: '50px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                  }}
                />
                <Column
                  field="name"
                  header="App Name"
                  headerStyle={{
                    width: '180px',
                    textAlign: 'left',
                    color: 'white',
                    background: '#22384d',
                  }}
                  bodyStyle={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                  }}
                />
                <Column
                  field="description"
                  header="Description"
                  headerStyle={{
                    width: '280px',
                    textAlign: 'left',
                    color: 'white',
                    background: '#22384d',
                  }}
                  bodyStyle={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                  }}
                />
              </DataTable>
            ) : (
              <></>
            )}
          </TabPanel>
        </TabView>
      </div>
    ) : (
      <></>
    );

  const toogleShowAdvanced = () => {
    setShowAdvanced((s) => !s);
  };

  const ModulesComponent = ({ data: modulesInfo }) => {
    if (modulesInfo) {
      const ListModules = () => {
        return modulesInfo.map((e) => {
          return (
            <Button
              key={e.name}
              type="button"
              className="paramsExpected"
              onClick={(val) => {
                val.stopPropagation();
                // showInfo(e.name);
              }}
            >
              {e.name}
            </Button>
          );
        });
      };
      return (
        <div className={isModal ? 'groupParams2' : 'groupParams'}>
          <ListModules />
        </div>
      );
    }
    return <></>;
  };

  useEffect(() => {
    if (tools.showModal) {
      resetEngine();
    }
  }, [tools]);

  return (
    <form
      onSubmit={(e) => handleSubmit(e, newData)}
      className={
        isModal
          ? 'user-form-section'
          : menuCollapse
          ? 'user-form-section-no-modal'
          : 'user-form-section-no-modal2'
      }
    >
      {!loading ? (
        <section className="section-scroller">
          {!isModal && (
            <div className="flex-wrapper">
              <div className="flex aling-items-center">
                <Button
                  type="button"
                  title="Previous page"
                  icon="pi pi-arrow-left"
                  className="back2 bounce"
                  onClick={resetEngine}
                />
                <h2 className="subtitle-data-txt">Engine Info</h2>
              </div>
              <div className="flex-row">
                <Button className="advanced-cls3" type="button" onClick={toogleShowAdvanced}>
                  Advanced Settings
                </Button>
                <Dropdown
                  value={selectedWorkflow}
                  options={engineOptions}
                  onChange={onWorkflowChange}
                  optionLabel="name"
                  placeholder={null}
                  filterPlaceholder={null}
                  itemTemplate={modulesOptionTemplate}
                />
              </div>
            </div>
          )}
          <div className={`flex-row ${isModal ? 'user-formGroup2' : 'user-formGroup'}`}>
            <label>Name</label>
            <div className="flexer2">
              <InputText
                disabled={!isModal}
                spellCheck={false}
                value={nameInput.value}
                {...nameInput.bind}
                className="user-inputText"
              />
              <InputSwitch
                title="Is Active ?"
                disabled={!isModal}
                checked={isActiveInput.value}
                onChange={(e) => isModal && isActiveInput.setValue(e.value)}
              />
            </div>
          </div>
          {descriptionInput.value && (
            <div className={`flex-row ${isModal ? 'user-formGroup2' : 'user-formGroup'}`}>
              <label>Description</label>
              <InputText
                disabled={!isModal}
                spellCheck={false}
                value={descriptionInput.value}
                {...descriptionInput.bind}
                className="user-inputText"
              />
            </div>
          )}
          <div className={`flex-row ${isModal ? 'user-formGroup2' : 'user-formGroup'}`}>
            {!isModal && assignedList.length > 0 && (
              <>
                <label>Authorized User</label>
                <ModulesComponent data={assignedList} />
              </>
            )}
          </div>

          {showAdvanced && (
            <>
              <div className={`flex-row ${isModal ? 'user-formGroup2' : 'user-formGroup'}`}>
                <label>Engine ID</label>
                <InputText
                  disabled={!isModal}
                  spellCheck={false}
                  value={idInput.value}
                  {...idInput.bind}
                  className="user-inputText"
                />
              </div>
              {isModal && (
                <UsersAndEngineDrag
                  assignedList={assignedList}
                  availabledList={availabledList}
                  handleAssignedClick={handleAssignedClick}
                  handleAvailableClick={handleAvailableClick}
                  onAssigned={onAssigned}
                  onAvailable={onAvailable}
                  formType="engineForm"
                />
              )}
              {!isModal && <AppsUsersByEngineList />}
            </>
          )}
          {isModal && (
            <div className="flex-row" style={{ marginTop: '30px' }}>
              <Button
                className="user-form-button"
                label={data?.id ? 'Update' : 'Create'}
                disabled={dirty}
              >
                {loadingMini && <div className="loader2" />}
              </Button>
            </div>
          )}
        </section>
      ) : (
        <div className="load-container">
          <Loader transparant type="feeder" />
        </div>
      )}
    </form>
  );
};

EngineForm.defaultProps = {
  data: {},
};

EngineForm.propTypes = {
  data: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  isModal: PropTypes.bool.isRequired,
  loadingMini: PropTypes.bool.isRequired,
  resetEngine: PropTypes.func.isRequired,
  onEditButton: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  handleDeleteEngine: PropTypes.func.isRequired,
};

export default EngineForm;
