/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React from 'react';

import PropTypes from 'prop-types';

import './StatsBar.css';

const StatsBar = ({ defaultStates, defaultStatus }) => {
  const Card = ({ stats }) => {
    return (
      <div className={`card-stats ${stats?.type}`}>
        <div className="card-header">{stats?.value}</div>
        <div className="card-value">{stats?.amount}</div>
      </div>
    );
  };

  return defaultStatus.length > 0 || defaultStates.length > 0 ? (
    <div className="statsbar-wrapper">
      {defaultStatus.length > 0 ? (
        <div>
          <h3 className="subtilte-hr2">Status</h3>
          <hr className="line-hr2" />
          <div className="flex-row">
            {defaultStatus.map((s, i) => (
              <Card key={`${s}-${i}`} stats={s} />
            ))}
          </div>
        </div>
      ) : (
        <></>
      )}
      <hr className="line-end" />
      {defaultStates.length > 0 ? (
        <div>
          <h3 className="subtilte-hr">States</h3>
          <hr className="line-hr" />
          <div className="flex-row">
            {defaultStates.map((s, i) => (
              <Card key={`2${s}-${i}`} stats={s} />
            ))}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  ) : (
    <></>
  );
};

StatsBar.propTypes = {
  defaultStates: PropTypes.array.isRequired,
  defaultStatus: PropTypes.array.isRequired,
};

export default StatsBar;
