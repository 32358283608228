/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useContext, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import { GoBackComponent, Button } from '../../components';
import { AccountContext } from '../../contexts/AccountContext';
import { casesDashboardService } from '../../services';

// import PropTypes from 'prop-types';
import './CaseReport.css';

const CaseReport = ({ location }) => {
  const { typesInfo, setTypesInfo } = useContext(AccountContext);

  const history = useHistory();

  const goToHistoryCase = () => {
    history.push(`/dashboard/${location.pathname.split('/')[2]}/activity`);
  };

  const getListCasesTypes = async () => {
    const { success, data: CasesInfo } = await casesDashboardService.listCasesTypes();
    if (success) {
      setTypesInfo(CasesInfo);
    }
  };

  useEffect(() => {
    if (!typesInfo.length) {
      getListCasesTypes();
    }
  }, []);

  const title = typesInfo?.filter((f) => f.id === location.pathname.split('/')[2])[0]?.description;

  if (!title) {
    history.push('/dashboard');
  }

  return (
    <div className="report-container">
      <div className="report-header">
        <GoBackComponent title={title} callback="/dashboard" />
        <Button
          title="Historial"
          label="Historial"
          className="history-btn"
          onClick={goToHistoryCase}
        />
      </div>
      <div className="report-wrapper">
        <div className="inRow">
          <div className="card overview">
            <div className="body-report">
              <div className="header-report">
                <h2 className="report-subtitle bounce">Overview</h2>
              </div>
            </div>
          </div>
          <div className="card alert">
            <div className="body-report">
              <div className="header-report">
                <h2 className="report-subtitle bounce">Alert</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="card overall">
          <div className="body-report">
            <div className="header-report">
              <h2 className="report-subtitle bounce">Overall</h2>
            </div>
          </div>
        </div>
        <div className="card impact">
          <div className="body-report">
            <div className="header-report">
              <h2 className="report-subtitle bounce">Impact</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CaseReport.propTypes = {};

export default CaseReport;
