/* eslint-disable prefer-const */
/* eslint-disable object-shorthand */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
import React, { useContext, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { ProSidebar, Menu, MenuItem, SidebarFooter, SidebarContent } from 'react-pro-sidebar';
import { NavLink, useLocation, useHistory } from 'react-router-dom';

import HoundIcon from '../../assets/images/logo_icon_hound_transp.png';
import { AccountContext } from '../../contexts/AccountContext';
import { ResponsiveContext } from '../../contexts/ResponsiveContext';
import { TicketsContext } from '../../contexts/TicketsContext';
import { Button } from '../BasicComponents';
import 'react-pro-sidebar/dist/css/styles.css';
import './Header.css';

const Header = ({ routes }) => {
  const history = useHistory();
  const location = useLocation();
  const [routesFiltered, setRoutesFiltered] = useState(null);
  const { isTablet, isMobile } = useContext(ResponsiveContext);
  const {
    accountInfo,
    menuCollapse,
    totalApps,
    totalFlows,
    totalBatches,
    userState,
    setUserState,
    logOut,
    setAccountInfo,
    setSelectedApp,
    setSelectedFlow,
    setApps,
    setModeles,
    setForms,
  } = useContext(AccountContext);
  const {
    setCaseSelected,
    setTeamSelected,
    setMemberSelected,
    setGroupsRetrieved,
    setOverall,
    openTickets,
  } = useContext(TicketsContext);

  const { enabledModules, name } = accountInfo?.userData || {};
  const { data } = accountInfo?.workspaceData || {};
  useEffect(() => {
    if (enabledModules?.length) {
      const routesAlowed = enabledModules.map((module) => module.id);
      // console.log(routes, routesAlowed);
      const routesTemp = routes.filter((route) => {
        return [...routesAlowed].some((r) => {
          return r === route.module || route.module === 'WA';
        });
      });
      setRoutesFiltered(routesTemp);
    }
    return () => {
      setRoutesFiltered(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabledModules]);

  const checkForInactivity = () => {
    const expiredTime = localStorage?.getItem('expiredTime');
    setUserState(expiredTime < Date.now() ? 1 : 0);
    if (userState === 1) {
      setTimeout(() => {
        setUserState(2);
      }, 5000);
    }
  };

  const updateExpireTime = () => {
    setUserState(0);
    const expiredTime = Date.now() + 120000; // 2min inactive 120000
    if (userState !== 2) localStorage.setItem('expiredTime', expiredTime);
  };

  const resetContexts = () => {
    setSelectedApp({});
    setSelectedFlow({});
    setApps([]);
    setModeles([]);
    setForms([]);
    setCaseSelected({});
    setTeamSelected(null);
    setAccountInfo(null);
    setMemberSelected(null);
    setGroupsRetrieved({});
    setOverall([]);
  };

  const handleLogout = () => {
    logOut();
    resetContexts();
    history.push({ pathname: '/expired' });
  };

  useEffect(() => {
    if (userState === 1) {
      const interval = setTimeout(() => {
        handleLogout(true);
      }, 28800000); // 8h expired 28800000
      return () => {
        clearInterval(interval);
      };
    }
  }, [userState]);

  useEffect(() => {
    const interval = setInterval(() => {
      checkForInactivity();
    }, 500);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    updateExpireTime();
    window.addEventListener('click', updateExpireTime);
    window.addEventListener('keypress', updateExpireTime);
    window.addEventListener('scroll', updateExpireTime);
    window.addEventListener('mousemove', updateExpireTime);
    return () => {
      window.removeEventListener('click', updateExpireTime);
      window.removeEventListener('keypress', updateExpireTime);
      window.removeEventListener('scroll', updateExpireTime);
      window.removeEventListener('mousemove', updateExpireTime);
    };
  }, []);

  const activeRoute = (route) => {
    return route.children
      ? route.children.find((child) => {
          const isTicket = location.pathname.split('/')[1] === child.path.split('/')[1];
          return child.path === location.pathname || isTicket;
        })
      : location.pathname === route.path;
  };

  const AmountBadgeRoute = ({ route, active }) => {
    let amountPerRoute;
    switch (active?.name) {
      case 'Tickets':
        amountPerRoute = { amount: openTickets, title: 'Your active tickets' };
        break;
      case 'Edit App':
        amountPerRoute = { amount: totalApps, title: 'Your apps' };
        break;
      case 'Flow List':
        amountPerRoute = { amount: totalFlows, title: 'Your workflows' };
        break;
      case 'Batches':
        amountPerRoute = { amount: totalBatches, title: 'Your batches' };
        break;
      default:
        amountPerRoute = { amount: null, title: '' };
        break;
    }
    return (
      amountPerRoute?.amount > 0 && (
        <span title={amountPerRoute?.title} className="bounce bagde-flat-counter">
          {amountPerRoute?.amount}
        </span>
      )
    );
  };

  const menuBodyRoutes = routesFiltered
    ?.filter((route) => route.position === 'body')
    .map((route) => {
      return (
        <MenuItem
          style={{ background: 'transparent' }}
          icon={route.icon}
          active={activeRoute(route)}
          key={route.path}
        >
          <NavLink
            to={route.path}
            onClick={() => route.id === 'human-interaction' && setCaseSelected({})}
            className="menu-opt"
          >
            <p className="meno-txt">{route.name}</p>
            <AmountBadgeRoute route={route} active={activeRoute(route)} />
          </NavLink>
        </MenuItem>
      );
    });

  const menuFooterRoutes = routes
    ?.filter((route) => route.position === 'footer')
    .filter((route) => route.name === 'UserInfo' || route.name === 'Whatsapp')
    .map((route) => {
      return (
        <MenuItem
          icon={route.icon}
          key={route.path}
          active={activeRoute(route)}
          style={activeRoute(route)?.name === 'Whatsapp' ? { backgroundColor: '#25D366' } : {}}
        >
          <NavLink to={route.path} className="menu-opt">
            <p>{route.name}</p>
            <AmountBadgeRoute route={route} active={activeRoute(route)} />
          </NavLink>
        </MenuItem>
      );
    });

  const showUserInfo = () => {
    // console.log('User Data', accountInfo?.userData);
  };

  const showWorkspaceInfo = () => {
    // console.log('Workspace Data', accountInfo?.workspaceData.data);
  };

  const userRoutes = () => {
    const customTag = () => {
      switch (userState) {
        case 0:
          return { title: 'Active', styling: 'bagde-activity' };
        case 1:
          return { title: 'Inactive', styling: 'bagde-activity bagde-yellow' };
        case 2:
          return { title: 'Expired', styling: 'bagde-activity bagde-red' };
        default:
          return { title: 'Active', styling: 'bagde-activity' };
      }
    };
    const { title, styling } = customTag();
    return (
      <Button
        type="button"
        title="Info User"
        onClick={showUserInfo}
        className={isMobile || isTablet ? 'userBtn2' : 'userBtn'}
      >
        {isMobile || isTablet ? (
          <p className="username-txt">{(name[0] + name[1]).toLocaleUpperCase()}</p>
        ) : (
          <p className="username-txt">{name}</p>
        )}
        <span title={title} className={styling} />
      </Button>
    );
  };

  const SliderWorkspace = () => {
    return (
      <p title="Active Workspace" className="workspace-txt">
        {data?.name}
      </p>
    );
  };

  const workspaceRoutes = () => {
    return (
      <Button
        type="button"
        onClick={showWorkspaceInfo}
        className={isMobile || isTablet ? 'workspaceBtn2' : 'workspaceBtn'}
        title={`Active Workspace ${data?.name}`}
      >
        <img
          src={HoundIcon}
          className={isMobile || isTablet ? 'nav-userMobile' : 'nav-user'}
          title="Hound Software"
          alt="Hound logo"
        />
        {isMobile || isTablet ? (
          <p className="workspace-txt">{(data?.name[0] + data?.name[1]).toLocaleUpperCase()}</p>
        ) : (
          <p className="workspace-txt">{data?.name}</p>
        )}
      </Button>
    );
  };

  return (
    <div id="header">
      {/* collapsed props to change menu size using menucollapse state */}
      <ProSidebar collapsed={menuCollapse} className={`${!menuCollapse && 'expanded'}`}>
        {menuCollapse && (
          <div className="header-main">
            {workspaceRoutes()}
            {/* {userRoutes()} */}
          </div>
        )}
        <SidebarContent>{menuCollapse && <Menu>{menuBodyRoutes}</Menu>}</SidebarContent>
        {/* <SidebarFooter>
          {menuCollapse && <Menu iconShape="square">{menuFooterRoutes}</Menu>}
        </SidebarFooter> */}
      </ProSidebar>
    </div>
  );
};

Header.propTypes = {
  routes: PropTypes.array.isRequired,
};

export default Header;
