/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { useContext, useRef } from 'react';

import listEmptySourcesImage from '../../assets/svgs/arts/undraw_external.svg';
import { AccountContext } from '../../contexts/AccountContext';

import './CollectionsModule.css';

const CollectionsModuleDashboardSection = () => {
  const { accountInfo, menuCollapse } = useContext(AccountContext);
  const collection = useRef(null);
  const { workspaceData } = accountInfo;
  const { data } = workspaceData;

  const DetailEmptyState = () => {
    return (
      <div className="table-modeler-wrapper bounce">
        <div className="detail-container">
          <img
            className="detail-img"
            src={listEmptySourcesImage}
            alt="No external resources found!"
          />
          <p className="detail-message">No external resources found!</p>
        </div>
      </div>
    );
  };

  return (
    <div style={{ marginTop: '30px', height1: '100vh' }}>
      {data?.externalDashboardUrl ? (
        <iframe
          ref={collection}
          title="Google Data Studio"
          src={data?.externalDashboardUrl}
          style={{
            width: '100%',
            height: '80vh',
          }}
          allowFullScreen
        />
      ) : (
        <DetailEmptyState />
      )}
    </div>
  );
};

export default CollectionsModuleDashboardSection;
