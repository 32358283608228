import * as Yup from 'yup';

const formSchema = Yup.object().shape({
  title: Yup.string().required('Título es requerido'),
  description: Yup.string()
    .max(500, 'La descripción debe tener 500 caracteres como máximo')
    .required('Descripción es requerida'),
});

export default formSchema;
