/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react';

import { DebounceInput } from 'react-debounce-input';
import { useHistory } from 'react-router-dom';

import listEmptyFlowsImage from '../../assets/svgs/arts/undraw_flows.svg';
import { Button, DataTable, Column, Loader } from '../../components';
import { AccountContext } from '../../contexts/AccountContext';
import { CreateWorkflowForm } from '../../forms';
import { flowsService } from '../../services';

import './ModelerList.css';

const ModelerList = () => {
  const history = useHistory();
  const {
    accountInfo,
    setSelectedFlow,
    setTotalFlows,
    modeles,
    setModeles,
    setTools,
    tools: tooler,
    showSnackbar,
    handleCloseModal,
    menuCollapse,
  } = useContext(AccountContext);
  const { userData, workspaceData } = accountInfo;
  const { rights } = userData;
  const [globalFilter, setGlobalFilter] = useState(null);
  const [loading, setLoading] = useState(false);

  const [filter, setFilter] = useState(0);

  const HeaderAddApp = ({ title }) => {
    return <div className="activity-header">{title}</div>;
  };

  const getAllFlows = async () => {
    setLoading(true);
    const flowsResult = await flowsService.getAllFlowsByWS();
    if (flowsResult?.success) {
      setModeles(flowsResult?.data?.data);
      setTotalFlows(flowsResult?.data?.data.length);
    }
    setLoading(false);
  };

  const handleCreateFlow = async (e, info) => {
    setLoading(true);
    e.preventDefault();
    const payload = {
      name: info.name,
      description: info.description,
      WS: workspaceData?.data?.id,
    };
    const { success } = await await flowsService.createFlow(payload);

    if (success) {
      getAllFlows();
      showSnackbar('success', 'Atention!', 'Workflow created succesfully');
    } else {
      showSnackbar('error', '', 'Something went wrong updating the app! Please try again');
    }
    handleCloseModal();
    setLoading(false);
  };

  const showModal = (content, header) => {
    setTools({
      ...tooler,
      manageModal: { content, header },
      showModal: true,
    });
  };

  const onCreateFlow = () => {
    setSelectedFlow(null);
    // history.push(`/flows/flow?canEditFlow=false&&isNewFlow=true`);
    const addAppForm = (
      <CreateWorkflowForm
        data={{
          workspace: workspaceData?.data?.id || '',
        }}
        handleSubmit={handleCreateFlow}
      />
    );
    showModal(addAppForm, <HeaderAddApp title="New Workflow" />);
  };

  const handleFocusInput = () => {
    const input = document.getElementById('searchFlow-input');
    input?.focus();
  };

  useEffect(() => {
    if (filter === 2) {
      document.getElementById('messages').scrollTo(1000, 0);
      handleFocusInput();
    }
  }, [filter]);

  const onViewEdit = (flow, canEdit) => {
    setSelectedFlow(flow);
    history.push(`/flows/flow?canEditFlow=${rights !== 'WFREADONLY' || canEdit}&&isNewFlow=false`);
  };

  useEffect(() => {
    getAllFlows();
    if (modeles.length === 0) {
      // const handleUserValidation = () => {
      //   const validUser = enabledModules?.some((data) => data.id === 'MODELER');
      //   if (!validUser) {
      //     history.push('/401');
      //   }
      // };
      // handleUserValidation();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const EditColumn = (instance) => {
    return (
      <Button
        type="button"
        icon="pi pi-list"
        className="p-button-info button actionButton"
        onClick={(e) => {
          e.stopPropagation();
          history.push({ pathname: `/instances/${instance._id}`, instance });
        }}
      />
    );
  };

  const DetailEmptyState = () => {
    return (
      <div className="table-modeler-wrapper">
        <div className="detail-container">
          <img className="detail-img" src={listEmptyFlowsImage} alt="No flows found!" />
          <p className="modeler-message">No flows found!</p>
        </div>
      </div>
    );
  };

  const rowBodyTemplate = (rowData, props) => {
    return <div className="secuencial-cls">{props.rowIndex + 1}</div>;
  };

  const reversedList = modeles.reduce((acc, val) => [val, ...acc], []);

  return !loading ? (
    <div className="modeler-wrapper">
      <div className="modeler-container">
        {modeles.length > 0 ? (
          <div className="header-flows">
            <div className="info-space-A">
              <Button
                className="add-ticket-btn"
                label=""
                icon="pi pi-plus"
                onClick={onCreateFlow}
              />
              <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <DebounceInput
                  id="searchFlow-input"
                  className="buttonFilter2"
                  value={globalFilter}
                  debounceTimeout={700}
                  onChange={(e) => setGlobalFilter(e.target.value)}
                  placeholder="Search workflow"
                  autoComplete="off"
                />
                {globalFilter && (
                  <Button
                    type="button"
                    title="Clear apps"
                    className="pi pi-trash adjust-trash2"
                    onChange={(e) => setGlobalFilter('')}
                  />
                )}
              </span>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className={menuCollapse ? 'modeler-table' : 'modeler-table-ext'}>
          {reversedList.length > 0 ? (
            <DataTable
              className="p-datatable-striped"
              emptyMessage="No flows found."
              value={reversedList}
              globalFilter={globalFilter}
              responsiveLayout="scroll"
              paginator={reversedList.length > 10}
              rows={10}
              scrollable
              selectionMode="single"
              onSelectionChange={(e) => onViewEdit(e.value, false)}
              style={{
                width: '100%',
              }}
              scrollHeight="100%"
            >
              <Column
                header=""
                headerStyle={{
                  maxWidth: '60px',
                  minWidth: '60px',
                  width: '60px',
                  textAlign: 'center',
                  backgroundColor: '#22384d',
                  color: 'black',
                }}
                bodyStyle={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textAlign: 'center',
                }}
                body={rowBodyTemplate}
              />
              <Column
                field="_id"
                header="Workflow ID"
                headerStyle={{
                  boderRadius: '30px',
                  backgroundColor: '#22384d',
                  color: 'white',
                  maxWidth: '15vw',
                  minWidth: '200px',
                  width: '15vw',
                }}
                bodyStyle={{
                  overflow: 'visible',
                }}
                sortable
              />
              <Column
                field="name"
                header="Flow"
                headerStyle={{
                  boderRadius: '30px',
                  backgroundColor: '#22384d',
                  color: 'white',
                  maxWidth: '15vw',
                  minWidth: '200px',
                  width: '15vw',
                }}
                bodyStyle={{
                  overflow: 'visible',
                  height: '38px',
                }}
                sortable
              />
              <Column
                field="description"
                header="Description"
                headerStyle={{
                  backgroundColor: '#22384d',
                  color: 'white',
                  maxWidth: '15vw',
                  minWidth: '200px',
                  width: '15vw',
                }}
                bodyStyle={{
                  overflow: 'visible',
                }}
                sortable
              />
              {/* <Column
                field="type"
                header="Type"
                headerStyle={{
                  width: '5rem',
                  textAlign: 'center',
                  backgroundColor: '#22384d',
                  color: 'white',
                }}
                bodyStyle={{
                  textAlign: 'center',
                  overflow: 'visible',
                  
                }}
                sortable
              /> */}
              {/* <Column
                header="Instances"
                body={EditColumn}
                headerStyle={{
                  width: '5rem',
                  textAlign: 'center',
                  backgroundColor: '#22384d',
                  color: 'white',
                }}
                bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
              /> */}
            </DataTable>
          ) : (
            <DetailEmptyState />
          )}
        </div>
      </div>
    </div>
  ) : (
    <Loader fit transparant />
  );
};

export default ModelerList;
