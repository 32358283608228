/* eslint-disable no-undef */
import React from 'react';

import ReactDOM from 'react-dom';

import './index.css';
import './theme.css';
import './flex.css';
import App from './App';

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import 'primeicons/primeicons.css';
import 'aos/dist/aos.css';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
