const DEFAULT_STATUS = [
  { name: 'CREATED', code: 1 },
  { name: 'STARTED', code: 2 },
  { name: 'PROGRESS', code: 3 },
  { name: 'COMPLETADO', code: 4 },
  { name: 'HOLDING', code: 5 },
];

const DEFAULT_FILTERS = {
  state: { value: null, matchMode: 'in' },
  status: { value: null, matchMode: 'in' },
};

export { DEFAULT_STATUS, DEFAULT_FILTERS };
