/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/order */
/* eslint-disable prefer-const */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-plusplus */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-constant-condition */
/* eslint-disable camelcase */
/* eslint-disable react/no-array-index-key */
/* eslint-disable func-names */
/* eslint-disable max-len */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import React, { useContext, useState, useEffect, useRef } from 'react';

import { useHistory } from 'react-router-dom';

import pdfIcon from '../../../assets/svgs/pdf.svg';
import imageIcon from '../../../assets/svgs/image.svg';
import { Button, Loader, GoBackComponent, StepperCmp } from '../../../components';
import { AccountContext } from '../../../contexts/AccountContext';
import FileDisplayer from '../../Tooler/FileDisplayer/FileDisplayer';
import { ticketsService, casesDashboardService } from '../../../services';

import tools from '../../../utils/time';
import './OneCase.css';
import { Dialog } from 'primereact/dialog';

const STATUS = {
  UNPROCESSED: 'UNPROCESSED',
  RUNNING: 'RUNNING',
  PROGRESS: 'PROGRESS',
  DONE: 'DONE',
  COMPLETED: 'COMPLETED',
  INCOMPLETE: 'INCOMPLETE',
  ERROR: 'ERROR',
  DELETED: 'DELETED',
  PREUNLOADED: 'PRE-UNLOADED',
};

const STATUS_COLORS = {
  UNPROCESSED: '#a1a1a1',
  RUNNING: '#FFCC00', // Amarillo brillante
  PROGRESS: '#FFCC00',
  DONE: '#87A0B2', // Naranja brillante
  COMPLETED: '#A4DD74', // Verde lima
  INCOMPLETE: '#868C9C',
  ERROR: 'RED', // Azul claro
  PREUNLOADED: '#a1a1a1',
};

const OneCase = ({ location }) => {
  const history = useHistory();
  if (!location.caseData) {
    history.push('/cases');
  }
  const [loading, setLoading] = useState(true);
  const [infoCase, setInfoCase] = useState(null);
  const [infoCase2, setInfoCase2] = useState(null);
  const [historial, setHistorial] = useState(null);
  const [showHistorial, setShowHistorial] = useState(false);
  const [listStates, setListStates] = useState(null);
  const [files, setFiles] = useState(null);
  const [notes, setNotes] = useState(null);
  const [showFiles, setShowFiles] = useState(false);
  const [showValues, setShowValues] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const [showCase, setShowCase] = useState(true);
  const displayFileRef = useRef(null);
  const manageDisplayFileRef = useRef(null);
  const [displayerFile, setDisplayerFile] = useState({
    displayFileRef,
    manageDisplayFileRef,
    showDisplayFile: false,
  });
  const { showSnackbar } = useContext(AccountContext);

  const handleCloseDisplayerFile = () => {
    setDisplayerFile({
      ...displayerFile,
      showDisplayFile: false,
    });
  };

  const NotasContainer = () => {
    const data = notes?.map((n, i) => {
      return (
        <div key={`NotasContainer-${i}`} className="note-wrapper">
          <div className="note-line">
            <strong>{n.title}</strong>
            {n.description}
          </div>
          <div className="note-date">{n.details.split(',')[1] + n.details.split(',')[0]}</div>
        </div>
      );
    });
    return data;
  };

  const decodeNote = (note) => {
    const result = note?.split('///');
    return {
      title: result[0],
      details: result[1],
      description: result[2],
      agentPrefix: result[3],
    };
  };

  let acceptData = (notes3) => {
    let data = [];
    const infoNotes = notes3.map((n) => {
      return n.case_note;
    });
    const filtered = infoNotes.map((n) => {
      return decodeNote(n);
    });
    filtered.forEach((f) => {
      data.push(f);
    });
    return data;
  };

  const convertS3toFiles = (arrayBase64) => {
    const files3 = arrayBase64.map((element, index) => {
      const decodeImg = atob(element.data);
      const typedImgA = new Uint8Array(decodeImg.length);
      for (let i = 0; i < decodeImg.length; ++i) {
        typedImgA[i] = decodeImg.charCodeAt(i);
      }
      const blob = new Blob([typedImgA], { type: element.type }); // Create a BLOB object
      const file = new File([blob], element.name, { type: element.type });
      return file;
    });
    // // Comenta o descomenta esta línea según sea necesario
    return files3;
  };

  const getCaseStates = async (caseType) => {
    const statesTemp = await casesDashboardService.getCaseStates(caseType);
    if (statesTemp.data.length > 0) {
      setListStates(statesTemp.data);
      return statesTemp.data;
    }
    setListStates(null);
    return null;
  };

  const ConditionColumn = ({ created, color = 'green', title }) => {
    const difference = tools.timeSince(Date.parse(created));
    return (
      <div className={`dot2 ${color}`} title={`${title}: ${created}`}>
        <p className="dot-value">{difference}</p>
      </div>
    );
  };

  const fetchCaseMongo = async (id, loader = false) => {
    let statusAPI = [];
    const listState = await getCaseStates(location?.caseData?.caseTypeId);
    if (location?.caseData?.case_id) {
      const { success, data } = await casesDashboardService.getCaseInfo(
        location?.caseData?.case_id
      );
      if (success) {
        const { moduleA, moduleB } = data.data;
        setInfoCase(moduleA[0]);
        setInfoCase2(moduleB[0]);
        statusAPI.push(true);
      } else {
        setInfoCase(null);
        setInfoCase2(null);
        statusAPI.push(false);
      }
      const { success: s2, data: d2 } = await ticketsService.getS3({
        caseId: location?.caseData?.case_id,
      });
      if (s2) {
        const arc = convertS3toFiles(d2?.data);
        setFiles(arc);
        statusAPI.push(!!arc.length);
      } else {
        setFiles(null);
        setShowFiles(false);
        statusAPI.push(false);
      }
      const notesTemp = await casesDashboardService.getCaseNotes(location?.caseData?.case_id);
      if (notesTemp.data.length > 0) {
        const notes2 = acceptData(notesTemp.data[0]);
        setNotes(notes2);
        statusAPI.push(!!notes2.length);
      } else {
        setNotes(null);
        setShowNotes(false);
        statusAPI.push(false);
      }
      const historyTemp = await casesDashboardService.getCaseHistory(location?.caseData?.case_id);
      if (historyTemp.data.length > 0) {
        setHistorial(historyTemp.data);
        statusAPI.push(historyTemp.data !== 'Error' && listState?.length > 0);
      } else {
        setHistorial(null);
        setShowHistorial(false);
        statusAPI.push(false);
      }
      const hasAnySuccessed = statusAPI.some((s) => s === true);
      if (!hasAnySuccessed) {
        showSnackbar('error', '', 'Case could not be retrieved!', 3000);
        history.push('/cases');
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCaseMongo();
    return () => {};
  }, []);

  function capitalizeFirstLetter(word) {
    return word.replace(/_/g, ' ').charAt(0).toUpperCase() + word.replace(/_/g, ' ').slice(1);
  }

  const toogleHistory = () => {
    setShowValues(false);
    setShowNotes(false);
    setShowCase(false);
    setShowFiles(false);
    setShowHistorial((f) => !f);
  };

  const toogleFiles = () => {
    setShowValues(false);
    setShowNotes(false);
    setShowCase(false);
    setShowHistorial(false);
    setShowFiles((f) => !f);
  };
  const toogleValues = () => {
    setShowFiles(false);
    setShowNotes(false);
    setShowCase(false);
    setShowHistorial(false);
    setShowValues((v) => !v);
  };

  const toogleCases = () => {
    setShowFiles(false);
    setShowNotes(false);
    setShowValues(false);
    setShowHistorial(false);
    setShowCase((v) => !v);
  };

  const toogleNotes = () => {
    setShowFiles(false);
    setShowValues(false);
    setShowCase(false);
    setShowHistorial(false);
    setShowNotes((n) => !n);
  };

  const showDisplayerFile = (content, header) => {
    setDisplayerFile({
      ...displayerFile,
      manageDisplayFileRef: { content, header },
      showDisplayFile: true,
    });
  };

  const handleFile = (file) => {
    const FileContent = (
      <FileDisplayer file={file} handleCloseDisplayerFile={handleCloseDisplayerFile} notEvent />
    );
    showDisplayerFile(FileContent, null);
  };
  const StatusColumn = ({ status: codeStatus }) => {
    let styling = STATUS_COLORS.DELETED;
    let opa = 1;
    switch (codeStatus.toLocaleUpperCase()) {
      case STATUS.UNPROCESSED:
        styling = STATUS_COLORS.UNPROCESSED;
        break;
      case STATUS.ONGOING:
        styling = STATUS_COLORS.ONGOING;
        break;
      case STATUS.WARNING:
        styling = STATUS_COLORS.WARNING;
        break;
      case STATUS.COMPLETED:
        styling = STATUS_COLORS.COMPLETED;
        break;
      case STATUS.DELETED:
        styling = STATUS_COLORS.DELETED;
        opa = 0.2;
        break;
      case STATUS.PREUNLOADED:
        styling = STATUS_COLORS.PREUNLOADED;
        opa = 1;
        break;
      case STATUS.PROGRESS:
        styling = STATUS_COLORS.PROGRESS;
        opa = 1;
        break;

      default:
        styling = STATUS_COLORS.DELETED;
        break;
    }
    return (
      <div className="batchChip" style={{ background: styling, opacity: opa }}>
        {codeStatus || 'N/A'}
      </div>
    );
  };

  const handleSeeCases = (batchData) => {
    history.push({ pathname: `/batches/${batchData.batchID}`, batch: batchData });
  };

  const calcDiffDates = (dateA, dateB) => {
    const fecha1 = new Date(dateA);
    const fecha2 = new Date(dateB);
    const diferencia = fecha2 - fecha1;
    const segundos = Math.floor(diferencia / 1000);
    const minutos = Math.floor(segundos / 60);
    const horas = Math.floor(minutos / 60);
    const dias = Math.floor(horas / 24);

    const segundosRestantes = segundos % 60;
    const minutosRestantes = minutos % 60;
    const horasRestantes = horas % 24;
    return `${dias} days, ${horasRestantes} hours, ${minutosRestantes} minutes and ${segundosRestantes} seconds`;
  };
  function flattenJSON(data, parentKey = '') {
    let result = {};

    for (let key in data) {
      let newKey = parentKey ? `${parentKey}_${key}` : key;
      if (typeof data[key] === 'object' && !Array.isArray(data[key])) {
        Object.assign(result, flattenJSON(data[key], newKey));
      } else {
        result[newKey] = data[key];
      }
    }

    return result;
  }

  const isJSON = (str) => {
    try {
      const temp = JSON.parse(str);
      return Object.keys(temp).length > 0 ? flattenJSON(temp) : null;
    } catch (e) {
      return null;
    }
  };

  const ConvertFlattenData = ({ data }) => {
    return Object.keys(data).map((key) => {
      const isArray = isJSON(data[key]);
      return key !== 'files' && key.slice(0, 6) !== 'checks' ? (
        <div className="lineBatch2" key={key}>
          <strong>{capitalizeFirstLetter(key)}</strong>
          {!isArray ? data[key].toString() || '-' : <ConvertFlattenData data={isArray} />}
        </div>
      ) : null;
    });
  };

  return (
    <div>
      {!loading ? (
        <div className="main-case-container">
          <div className="flex-wrapper2" style={{ marginLeft: '-20px' }}>
            <GoBackComponent title="Go back" callback="/cases" />
            <h2 className="subtitle-data-txt2 bounce">{`Case ${infoCase2?.case_id || ''}`}</h2>
          </div>
          <div className="container-cases bounce">
            {infoCase2 && Object.keys(infoCase2)?.length > 0 && (
              <div className="slot-wrapper" style={{ background: 'rgba(0, 255, 255, 0.053)' }}>
                <Button className="btn-accordion" onClick={toogleCases}>
                  <h2 className="headings-batch bounce">General</h2>
                  <i
                    className={`pi ${
                      showCase ? 'pi-angle-up' : 'pi-angle-down'
                    } accordion-toggler-icon`}
                  />
                </Button>
                {showCase ? (
                  <>
                    {infoCase?.batchID && (
                      <div className="lineBatch">
                        <strong>Batch Id</strong>
                        {infoCase?.batchID}
                        <Button
                          type="button"
                          className="btn-goto"
                          onClick={() => handleSeeCases(infoCase)}
                        >
                          Go to batch
                        </Button>
                      </div>
                    )}
                    <div className="lineBatch">
                      <strong>Case Type</strong>
                      {location?.caseData?.caseType}
                    </div>
                    <div className="lineBatch">
                      <strong>State</strong>
                      {infoCase2?.state?.id || infoCase?.state}
                    </div>
                    <div className="lineBatch">
                      <strong>Status</strong>
                      <StatusColumn status={infoCase2.status || infoCase.status} />
                    </div>
                    <div className="lineBatch">
                      <strong>Created</strong>
                      <ConditionColumn
                        created={infoCase?.created || infoCase2?.creationDate}
                        color="green"
                        title="Created"
                      />
                    </div>
                    {infoCase2.lastUpdate !== 'Invalid date' && (
                      <div className="lineBatch">
                        <strong>Last Update</strong>
                        <ConditionColumn
                          created={infoCase2.lastUpdate}
                          color="gray"
                          title="Last Update"
                        />
                      </div>
                    )}
                    {infoCase2.closing !== 'Invalid date' && (
                      <div className="lineBatch">
                        <strong>Closed on</strong>
                        {infoCase2.closing}
                      </div>
                    )}
                    {(infoCase?.created !== 'Invalid date' || infoCase2?.creationDate) &&
                      infoCase2.closing !== 'Invalid date' && (
                        <div className="lineBatch">
                          <strong>Resolution time</strong>
                          {calcDiffDates(
                            infoCase?.created || infoCase2?.creationDate,
                            infoCase2.closing
                          )}
                        </div>
                      )}
                  </>
                ) : (
                  <></>
                )}
              </div>
            )}
            {historial && historial !== 'Error' && listStates?.length > 0 ? (
              <div className="slot-wrapper" style={{ background: 'rgba(34, 81, 156, 0.127)' }}>
                <Button className="btn-accordion" onClick={toogleHistory}>
                  <h2 className="headings-batch bounce">History</h2>
                  <i
                    className={`pi ${
                      showHistorial ? 'pi-angle-up' : 'pi-angle-down'
                    } accordion-toggler-icon`}
                  />
                </Button>
                {showHistorial && <StepperCmp history={historial} path={listStates} />}
              </div>
            ) : (
              <></>
            )}
            {infoCase?.data && Object.keys(infoCase?.data)?.length > 0 && (
              <div className="slot-wrapper">
                <Button className="btn-accordion" onClick={toogleValues}>
                  <h2 className="headings-batch bounce">Values</h2>
                  <i
                    className={`pi ${
                      showValues ? 'pi-angle-up' : 'pi-angle-down'
                    } accordion-toggler-icon`}
                  />
                </Button>
                {showValues
                  ? Object.keys(infoCase?.data).map((key) => {
                      const isArray = isJSON(infoCase?.data[key]);
                      return key !== 'files' && key.slice(0, 6) !== 'checks' ? (
                        <div className="lineBatch" key={key}>
                          <strong>{capitalizeFirstLetter(key)}</strong>
                          {!isArray ? (
                            infoCase?.data[key] || '-'
                          ) : (
                            <ConvertFlattenData data={isArray} />
                          )}
                        </div>
                      ) : null;
                    })
                  : null}
              </div>
            )}
            {files?.length > 0 ? (
              <div className="slot-wrapper" style={{ background: 'rgba(34, 56, 77, 0.182)' }}>
                <Button className="btn-accordion" onClick={toogleFiles}>
                  <h2 className="headings-batch bounce">Files</h2>
                  <i
                    className={`pi ${
                      showFiles ? 'pi-angle-up' : 'pi-angle-down'
                    } accordion-toggler-icon`}
                  />
                </Button>
                {showFiles &&
                  files?.map((f, i) => {
                    return (
                      <Button
                        key={`File-${i}`}
                        className="file-btn"
                        title="Show File"
                        onClick={() => handleFile(f)}
                      >
                        <img
                          className="img-icon"
                          src={f.name.toLowerCase().endsWith('.pdf') ? pdfIcon : imageIcon}
                          alt="files"
                        />
                        <p>{`File ${f.name}`}</p>
                      </Button>
                    );
                  })}
              </div>
            ) : null}
            {notes?.length > 0 ? (
              <div className="slot-wrapper" style={{ background: '#22384d1a' }}>
                <Button className="btn-accordion" onClick={toogleNotes}>
                  <h2 className="headings-batch bounce">Comments</h2>
                  <i
                    className={`pi ${
                      showNotes ? 'pi-angle-up' : 'pi-angle-down'
                    } accordion-toggler-icon`}
                  />
                </Button>
                {showNotes && <NotasContainer />}
              </div>
            ) : null}
          </div>
          <Dialog
            closable={false}
            header={displayerFile?.manageDisplayFileRef?.header}
            className="displayer-file"
            onHide={handleCloseDisplayerFile}
            visible={displayerFile?.showDisplayFile}
          >
            {displayerFile?.manageDisplayFileRef.content}
          </Dialog>
        </div>
      ) : (
        <div className="loader-wrapper">
          <Loader fit transparant />
        </div>
      )}
    </div>
  );
};

export default OneCase;
