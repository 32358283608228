import React from 'react';

import loginLogo from '../../assets/images/HoundLogo.png';
import './LoginHeader.css';

const LoginHeader = () => {
  return (
    <section className="lHeaderSection">
      <img src={loginLogo} alt="Login Logo" className="lHeaderImg bounce" />
    </section>
  );
};

export default LoginHeader;
