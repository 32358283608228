/* eslint-disable import/prefer-default-export */
import { httpRequest, wrap } from './tools';
// TODO make urls constants

const getHumanOfEmail = async (email) => {
  const fn = async () => {
    const url = `/humans/${email}/human`;
    const response = await httpRequest(url, 'GET', {}, true);
    return response;
  };

  return wrap(fn);
};

const getListHumansOfGroups = async (groupId) => {
  const fn = async () => {
    const url = `/human-groups/${groupId}/humans`;
    const response = await httpRequest(url, 'GET', {}, true);
    return response;
  };

  return wrap(fn);
};

const getHumanGroup = async (groupId) => {
  const fn = async () => {
    const url = `/human-groups/${groupId}`;
    const response = await httpRequest(url, 'GET', {}, true);
    return response;
  };

  return wrap(fn);
};

const createHumanGroup = async (team) => {
  const fn = async () => {
    const url = `/human-groups`;
    const response = await httpRequest(url, 'POST', team, true);
    if (response.status === 201) {
      return 'Team Created';
    }
    return response;
  };

  return wrap(fn);
};

const createHuman = async (member) => {
  const fn = async () => {
    const url = `/humans`;
    const response = await httpRequest(url, 'POST', member, true);
    return response;
  };

  return wrap(fn);
};

const updateHuman = async (member) => {
  const fn = async () => {
    const url = `/v2/human/${member.id}`;
    const response = await httpRequest(url, 'PATCH', member, true);
    return response;
  };

  return wrap(fn);
};

const deleteHuman = async (memberID) => {
  const fn = async () => {
    const url = `/v2/human/${memberID}`;
    const response = await httpRequest(url, 'DELETE', {}, true);
    return response;
  };

  return wrap(fn);
};

const getHumansByWS = async () => {
  const fn = async () => {
    const url = `/humans`;
    const response = await httpRequest(url, 'GET', {}, true);
    return response;
  };

  return wrap(fn);
};

const getGroupHumansByHuman = async (humanId) => {
  const fn = async () => {
    const url = `/humans/${humanId}/human-groups`;
    const response = await httpRequest(url, 'GET', {}, true);
    return response;
  };

  return wrap(fn);
};

const updateHumanGroup = async (teamNewData) => {
  const fn = async () => {
    const url = `/human-groups/${teamNewData.id}`;
    const response = await httpRequest(url, 'PATCH', teamNewData, true);
    if (response.status === 201) {
      return 'Team Created';
    }
    return response;
  };

  return wrap(fn);
};

export {
  getListHumansOfGroups,
  getHumanGroup,
  createHumanGroup,
  updateHumanGroup,
  createHuman,
  getHumanOfEmail,
  getHumansByWS,
  getGroupHumansByHuman,
  updateHuman,
  deleteHuman,
};
