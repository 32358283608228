/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-expressions */
/* eslint-disable func-names */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-danger */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable camelcase */
import React, { useContext } from 'react';

import { TicketsContext } from '../../../contexts/TicketsContext';
import './CaseDetail.css';

// eslint-disable-next-line react/prop-types
const CaseDetail = () => {
  const { selectedTicket } = useContext(TicketsContext);

  const DetailsContent = () => (
    <div className={`details-case-content `}>
      <div className="details-case-template-wrapper">
        <div className="details-case-template-container">
          <iframe
            id="template_maker"
            style={{ width: '100%', height: '100%', border: 'none' }}
            srcDoc={selectedTicket.template}
            title="displaying the template"
          />
        </div>
      </div>
    </div>
  );

  return (
    <div className="details-case-wrapper">
      <DetailsContent />
    </div>
  );
};

export default CaseDetail;
