/* eslint-disable no-unused-vars */
import React from 'react';

import PropTypes from 'prop-types';

import './Loader.css';

const Loader = ({ fit = false, type = 'building-blocks', transparant = false }) => {
  return (
    <div
      className={`loader-container ${fit && 'loader-container-adjusted'} ${
        transparant && 'noBackground'
      }`}
    >
      <span className="loader"> </span>
    </div>
  );
};

// eslint-disable-next-line react/require-default-props
Loader.propTypes = { fit: PropTypes.bool, type: PropTypes.string, transparant: PropTypes.bool };

export default Loader;
