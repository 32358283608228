import * as Yup from 'yup';

const formSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Name must be 2 characters at minimun')
    .max(150, 'Name must be 150 characters at maximun')
    .required('Name is required'),
  rule: Yup.string(),
  webhookName: Yup.string(),
  passphrase: Yup.string(),
});

export default formSchema;
