/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext } from 'react';

import Moment from 'moment';
import { useHistory } from 'react-router-dom';

import { GoBackComponent } from '../../components';
import { AccountContext } from '../../contexts/AccountContext';
import { casesDashboardService } from '../../services';
import CaseActivity from '../MainDashboard/Sections/CaseActivity/CaseActivity';

// import PropTypes from 'prop-types';

import './CaseInstances.css';

const CaseInstances = ({ location }) => {
  const history = useHistory();
  const { typesInfo, setTypesInfo, showSnackbar } = useContext(AccountContext);
  const caseSelected = location.pathname.split('/')[2];
  const [instances, setInstances] = useState([]);
  const [showHistory, setShowHistory] = useState(false);
  const [filter, setFilter] = useState(3);
  const [rangeDates, setRangeDates] = useState([]);
  const [caseStatus, setCaseStatus] = useState(null);
  const [caseCategory, setCaseCategory] = useState(null);
  const [caseOperationId, setCaseOperationId] = useState(null);
  const [summary, setSummary] = useState([]);
  const [caseSelectedId] = useState(caseSelected);
  const [flowState, setFlowState] = useState([]);
  const [flowStatus, setFlowStatus] = useState([]);

  const getMinDate = () => {
    switch (filter) {
      case 1: // Today
        return Moment().format('YYYY-MM-DD');
      case 2: // This week
        return Moment().subtract(7, 'days').format('YYYY-MM-DD');
      case 3: // This month
        return Moment().subtract(30, 'days').format('YYYY-MM-DD');
      case 4: // Last 90 days
        return Moment().subtract(90, 'days').format('YYYY-MM-DD');
      default:
        // Range
        return rangeDates[0] || '';
    }
  };

  const getMaxDate = () => {
    switch (filter) {
      case 1: // Today
        return Moment().format('YYYY-MM-DD');
      case 2: // This week
        return Moment().format('YYYY-MM-DD');
      case 3: // This month
        return Moment().format('YYYY-MM-DD');
      case 4: // Last 90 days
        return Moment().format('YYYY-MM-DD');
      default:
        // Range
        return rangeDates[1] || '';
    }
  };

  const fetchCases = async () => {
    const start = getMinDate();
    const end = getMaxDate();
    const { success, data: casesResult } = await casesDashboardService.listCases(
      start,
      end,
      caseStatus,
      caseCategory,
      caseOperationId,
      caseSelectedId
    );
    if (success && casesResult?.length > 0) {
      const temp = casesResult.filter((c) => {
        return c.status !== 'COMPLETED';
      });
      setInstances(showHistory ? casesResult : temp);
    } else {
      history.push(`/dashboard/${location.pathname.split('/')[2]}`);
    }
  };

  const getListCasesTypes = async () => {
    const { success, data: CasesInfo } = await casesDashboardService.listCasesTypes();
    if (success) {
      setTypesInfo(CasesInfo);
    }
  };

  const buildSumaries = () => {
    const totalsStates = [];
    const totalsStatus = [];
    const valoresUnicosStates = [
      ...new Set(instances.map((item) => (item.state.id !== null ? item.state.id : null))),
    ];
    const valoresUnicosStatus = [
      ...new Set(instances.map((item) => (item.status !== null ? item.status : null))),
    ];

    valoresUnicosStates.forEach((s) => {
      if (s !== null) {
        totalsStates.push(
          instances.filter((item) => {
            return item.state.id === s;
          }).length
        );
      }
    });

    valoresUnicosStatus.forEach((s) => {
      if (s !== null) {
        totalsStatus.push(instances.filter((item) => item.status === s).length);
      }
    });

    const defaultStates = valoresUnicosStates
      .filter((f) => f !== null)
      .map((v, i) => ({
        value: v,
        amount: totalsStates[i],
        type: 'states-cls',
      }));
    const defaultStatus = valoresUnicosStatus
      .filter((f) => f !== null)
      .map((v, i) => ({
        value: v,
        amount: totalsStatus[i],
        type: 'status-cls',
      }));
    setFlowState(defaultStates);
    setFlowStatus(defaultStatus);
  };

  useEffect(() => {
    if (instances?.length > 0 && typesInfo.length > 0) {
      buildSumaries();
    }
    return () => {};
  }, [instances]);

  const reset = () => {
    setFlowState([]);
    setFlowStatus([]);
    fetchCases();
    showSnackbar('success', '', 'Instances updated succesfully');
  };

  useEffect(() => {
    setFlowState([]);
    setFlowStatus([]);
    fetchCases();
    return () => {};
  }, [showHistory]);

  useEffect(() => {
    getListCasesTypes();
    fetchCases();
  }, []);

  const title = typesInfo.filter((f) => f.id === caseSelectedId)[0]?.description;

  return (
    <div className="report-container">
      <div className="report-wrapper">
        <div className="report-header">
          <GoBackComponent
            isWhite={false}
            title={!showHistory ? `Active ${title || ''} Activity` : `${title || ''} History`}
            callback="/dashboard#ALL"
          />
        </div>
        <CaseActivity
          instances={instances}
          setInstances={setInstances}
          setCaseStatus={setCaseStatus}
          setCaseCategory={setCaseCategory}
          setCaseOperationId={setCaseOperationId}
          titleCase={caseSelectedId}
          defaultStates={flowState}
          defaultStatus={flowStatus}
          searchCase={location.hash.split('#')[1] || ''}
          showHistory={showHistory}
          setShowHistory={setShowHistory}
          reset={reset}
        />
      </div>
    </div>
  );
};

CaseInstances.propTypes = {};

export default CaseInstances;
