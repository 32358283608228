/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useRef, useState, useContext, useEffect } from 'react';

import PropTypes from 'prop-types';
import Webcam from 'react-webcam';

import './Camera.css';
import { Button, Loader } from '../../../components';
import { ResponsiveContext } from '../../../contexts/ResponsiveContext';

const Camera = ({ type, handleCloseTooler, disclaimersTxt, event, color }) => {
  const { isMobile } = useContext(ResponsiveContext);
  const webcamRef = useRef(null);
  const loader = useRef(false);
  const [makeAPicture, setMakeAPicture] = useState(true);
  const [imgSrc, setImgSrc] = useState(null);

  const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
  };

  const handleRemoveImg = () => {
    event.window.postMessage({ type, deletePic: true }, '*');
    setImgSrc(null);
    setMakeAPicture(true);
  };

  const handleSaveImage = () => {
    event.window.postMessage({ type, savePic: true, pic: imgSrc }, '*');
    handleCloseTooler();
  };

  const hideLoader = () => {
    loader.current.className = 'hide-loader';
    setMakeAPicture(false);
  };

  useEffect(() => {
    setTimeout(() => {
      handleCloseTooler();
    }, 600000); // TEN minutos for dismount the component
  }, []);

  const WebcamComponent = () => (
    <div className="camera">
      <div className="camera-container">
        <div className="shot-area">
          <div>
            {!imgSrc ? (
              <>
                <div ref={loader} className="load-camera">
                  <Loader fit type="feeder" />
                </div>
                <Webcam
                  ref={webcamRef}
                  screenshotFormat="image/png"
                  style={{
                    width: isMobile ? '200px' : '400px',
                    minWidth: isMobile ? '265px' : '400px',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    height: isMobile ? '200px' : '300px',
                    minHeight: isMobile ? '200px' : '300px',
                  }}
                  onCanPlay={() => {
                    hideLoader();
                  }}
                />
              </>
            ) : (
              <img src={imgSrc} alt="Camera shoot" />
            )}
          </div>
        </div>
        <div className="disclaimer">
          <ol className="disclaimer-list">
            <h1 className="shot-area-description">{type}</h1>
            {!imgSrc ? <p className="shoot-txt">{disclaimersTxt}</p> : <></>}
            {!imgSrc ? (
              <Button disabled={makeAPicture} className="shoot-btn" onClick={capture}>
                Tomar fotografía
              </Button>
            ) : (
              <div className="actions-shoot">
                <Button
                  className="retry"
                  style={{ minHeight: '40px', maxWidth: '145px' }}
                  onClick={handleRemoveImg}
                >
                  Reintentar
                </Button>
                <Button
                  className="shoot-btn"
                  style={{ minHeight: '40px', maxWidth: '140px' }}
                  onClick={handleSaveImage}
                >
                  Subir foto
                </Button>
              </div>
            )}
          </ol>
        </div>
      </div>
    </div>
  );

  return <WebcamComponent />;
};

Camera.propTypes = {
  type: PropTypes.string.isRequired,
  disclaimersTxt: PropTypes.string.isRequired,
  event: PropTypes.any.isRequired,
};

export default Camera;
