/* eslint-disable no-unused-vars */
import React from 'react';

import PropTypes from 'prop-types';

import { Button, ScrollPanel } from '../BasicComponents';
import './UsersAndEngineDrag.css';

const UsersAndEngineDrag = ({
  assignedList = [],
  availabledList = [],
  formType,
  handleAssignedClick,
  handleAvailableClick,
  onAssigned,
  onAvailable,
  color,
}) => {
  return (
    <section className="flex-column user-engine-drag-section">
      <div className="dragger-wrapper">
        <div className="dragger-container">
          <section style={{ width: '100%' }}>
            <h3 className="user-subtitle-form">
              {formType === 'UserForm' ? 'Assigned Engines' : 'Assigned Users'}
            </h3>
            <div className="user-engine-box">
              <ScrollPanel style={{ width: '100%', height: '100%' }}>
                <ul className="user-engine-drag-list">
                  {assignedList?.map((a, i) => {
                    return (
                      <li
                        key={`${a.name}-${i.toString()}`}
                        aria-hidden="true"
                        onClick={() => handleAssignedClick(a.id)}
                      >
                        <p
                          title={`${a.id} - ${a.role} - ${a.email}`}
                          className={!a.selected ? 'user-engine-item' : 'user-engine-selectedItem'}
                        >
                          {a.name}
                        </p>
                      </li>
                    );
                  })}
                </ul>
              </ScrollPanel>
            </div>
          </section>
          <section style={{ width: '100%' }}>
            <h3 className="user-subtitle-form">
              {formType === 'UserForm' ? 'Available Engines' : 'Available Users'}
            </h3>
            <div className="user-engine-box">
              <ScrollPanel style={{ width: '100%', height: '100%' }}>
                {availabledList.length > 0 ? (
                  <ul className="user-engine-drag-list">
                    {availabledList?.map((a, i) => (
                      <li
                        key={`${a.name}-${i.toString()}`}
                        aria-hidden="true"
                        onClick={() => handleAvailableClick(a.id)}
                      >
                        <p
                          title={`${a.id} - ${a.role} - ${a.email}`}
                          className={!a.selected ? 'user-engine-item' : 'user-engine-selectedItem'}
                        >
                          {a.name}
                        </p>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <></>
                )}
              </ScrollPanel>
            </div>
          </section>
        </div>
        <section className="action-footer">
          <div className="u-e-drag-button-section">
            <Button className={`user-engine-drag-button orange `} onClick={onAssigned}>
              <i className="pi pi-angle-left user-engine-angle-icon" />
            </Button>
          </div>
          <div className="u-e-drag-button-section">
            <Button className={`user-engine-drag-button ${color}`} onClick={onAvailable}>
              <i className="pi pi-angle-right user-engine-angle-icon" />
            </Button>
          </div>
        </section>
      </div>
    </section>
  );
};

UsersAndEngineDrag.defaultProps = {
  assignedList: [],
  availabledList: [],
  formType: 'UserForm',
  color: '',
};

UsersAndEngineDrag.propTypes = {
  assignedList: PropTypes.array,
  availabledList: PropTypes.array,
  formType: PropTypes.string,
  handleAssignedClick: PropTypes.func.isRequired,
  handleAvailableClick: PropTypes.func.isRequired,
  onAssigned: PropTypes.func.isRequired,
  onAvailable: PropTypes.func.isRequired,
  color: PropTypes.string,
};

export default UsersAndEngineDrag;
