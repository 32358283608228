import * as React from 'react';

// eslint-disable-next-line react/prop-types
const Icon = ({ size = 43, color = 'currentColor', ...props }) => (
  <svg
    width={size}
    height={size}
    fill={color}
    viewBox="-3 -2 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16 7.33359V0.933594H10.4V3.33359H5.6V0.933594H0V7.33359H5.6V4.93359H7.2V12.9336H10.4V15.3336H16V8.93359H10.4V11.3336H8.8V4.93359H10.4V7.33359H16ZM4 5.73359H1.6V2.53359H4V5.73359ZM12 10.5336H14.4V13.7336H12V10.5336ZM12 2.53359H14.4V5.73359H12V2.53359Z"
      fill="white"
    />
  </svg>
);

export default Icon;
