/* eslint-disable react/style-prop-object */
/* eslint-disable no-unused-vars */
import React from 'react';

import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';

const DashboardChart = ({ dataSets, selectedChart }) => {
  const chart = dataSets[selectedChart];

  const data = {
    labels: chart ? chart.labels : [],
    datasets: [
      {
        label: 'Dataset',
        data: chart ? chart.data : [],
        borderColor: chart ? chart.color : '',
        backgroundColor: chart ? chart.borderColor : '',
      },
    ],
  };
  return <Line data={data} options={{ responsive: true }} />;
};

DashboardChart.propTypes = {
  dataSets: PropTypes.array.isRequired,
  selectedChart: PropTypes.number.isRequired,
};

export default DashboardChart;
