/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-unused-vars */
import React, { useContext, useRef, useState, useEffect } from 'react';

import { useFormik } from 'formik';
import PropTypes from 'prop-types';

import { Button } from '../../../components';
import { AccountContext } from '../../../contexts/AccountContext';
import { casesDashboardService, humansService } from '../../../services';
import { getCookie } from '../../../utils/tools';
import formSchema from './schema';
import './Notes.css';

const EMPTY_NOTE = { title: '', details: '', description: '', agentPrefix: '' };

function Notes({ event, handleCloseNotes, isEditing = false, note, caseId }) {
  const today = new Date().toString().split(`GMT`)[0];
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { accountInfo } = useContext(AccountContext);

  const getHuman = async () => {
    const { success, data: humanData } = await humansService.getHumanOfEmail(
      accountInfo?.userData?.email
    );
    if (success) {
      return humanData.data[0].human_id;
    }
    return false;
  };

  const getNotes = async (caseID) => {
    const { success: s2, data: d2 } = await casesDashboardService.getCaseNotes(caseID);
    if (s2) {
      return d2;
    }
    return false;
  };

  const handlePutNote = async (payload) => {
    let humanId;
    const notes = [
      `${payload.title}///${payload.details}///${payload.description}///${payload.agentPrefix}`,
    ];
    // console.log(notes);
    try {
      humanId = await getHuman();
    } catch (error2) {
      // console.log('');
    }
    const { success } = await casesDashboardService.putCaseNotes(caseId, {
      notes,
      human: humanId || '',
    });
    if (success) {
      return true;
    }
    setError(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
    return false;
  };

  const { handleSubmit, handleChange, handleBlur, values, errors, isValid, touched } = useFormik({
    initialValues: note || EMPTY_NOTE,
    validationSchema: formSchema,
    onSubmit: async (v) => {
      if (loading) {
        return;
      }
      setLoading(true);
      const name = accountInfo?.userData?.name.split(' ');
      let payload;
      if (!name) {
        payload = {
          ...values,
          details: `${accountInfo?.userData?.name}, ${today} ${isEditing ? ' (Editado)' : ''}`,
          agentPrefix: 'UE',
        };
      } else {
        payload = {
          ...values,
          details: `${accountInfo?.userData?.name}, ${today} ${isEditing ? ' (Editado)' : ''}`,
          agentPrefix: name[1] ? name[0][0] + name[1][0] : name[0][0] + name[0][1],
        };
      }
      const result = await handlePutNote(payload);
      if (result) {
        const result2 = await getNotes(caseId);
        if (result2) {
          event?.window.postMessage({ isNotes: true, notes: result2[0] }, '*');
          handleCloseNotes();
        }
      }
    },
  });

  const handleNegative = () => {
    event?.window.postMessage({ isNotes: true, note: values }, '*');
    handleCloseNotes();
  };

  useEffect(() => {
    setError(false);
    setLoading(false);
  }, [values]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="notes-wrapper">
        <p className="notes-title">{!isEditing ? `Agregar una nota` : `Editando la nota`}</p>
        <div className="flex-label">
          <p className="input-label">Detalle general</p>
          <p className="required-input" title="Requerido">
            *
          </p>
        </div>
        <div className="note-wrapper">
          <input
            id="title"
            name="title"
            type="text"
            className="note-detail"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values?.title}
            spellCheck={false}
          />
          {errors?.title && touched?.title ? (
            <p className="error-txt bounce">
              <img src="https://houndsoftware.net/assetstmp/mib/alarm.svg" alt="" />
              {errors?.title}
            </p>
          ) : (
            <></>
          )}
        </div>
        <div className="flex-label">
          <p className="input-label">Descripción</p>
          <p className="required-input" title="Requerido">
            *
          </p>
        </div>
        <div className="note-wrapper">
          <textarea
            id="description"
            name="description"
            className="note-textarea"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values?.description}
            spellCheck={false}
          />
          {errors?.description && touched?.description ? (
            <p className="error-txt bounce">
              <img src="https://houndsoftware.net/assetstmp/mib/alarm.svg" alt="" />
              {errors?.description}
            </p>
          ) : (
            <></>
          )}
          {error ? (
            <p className="error-txt bounce">
              <img src="https://houndsoftware.net/assetstmp/mib/alarm.svg" alt="" />
              La nota no pudo ser guardada
            </p>
          ) : (
            <></>
          )}
        </div>
        <div className="flex-actions-notes">
          <Button type="button" onClick={handleNegative} className="mb-secondary">
            Cerrar
          </Button>
          <Button type="submit" disabled={!isValid && loading} className="mb-primary">
            {!isEditing ? 'Agregar' : 'Editar'}
          </Button>
        </div>
      </div>
    </form>
  );
}

Notes.propTypes = {
  event: PropTypes.any.isRequired,
  handleCloseNotes: PropTypes.func.isRequired,
  isEditing: PropTypes.any.isRequired,
  note: PropTypes.any.isRequired,
  caseId: PropTypes.string.isRequired,
};

export default Notes;
