/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext, useRef } from 'react';

import { useLocation, useHistory } from 'react-router-dom';

import { GoBackComponent, Loader } from '../../components';
import { AccountContext } from '../../contexts/AccountContext';
import { ResponsiveContext } from '../../contexts/ResponsiveContext';
import { environment } from '../../enviroments/enviroment';
import useWindowSize from '../../hooks/useWindowSize';
import { flowsService } from '../../services';

import './ModelerIframe.css';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ModelerIframe = ({ location }) => {
  const info = useWindowSize();
  const iframeRef = useRef(null);
  const loader = useRef(false);
  const history = useHistory();
  const { accountInfo, selectedFlow, setMenuCollapse } = useContext(AccountContext);
  const { isMobile } = useContext(ResponsiveContext);
  const { userData } = accountInfo;
  const { enabledModules, id: userId, rights } = userData;
  const query = useQuery();
  const [url, setUrl] = useState();
  const [name, setName] = useState('New Flow');

  const handleUpdateWS = async () => {
    const payload = { wsId: selectedFlow?.WS };
    await flowsService.updateFlow(selectedFlow?._id || selectedFlow?.id, payload);
  };

  useEffect(() => {
    if (info.width < 570) {
      setMenuCollapse(false);
    } else {
      setMenuCollapse(true);
    }
  }, [info]);

  useEffect(() => {
    if (!selectedFlow?._id || !selectedFlow?.id) location.isCreating && history.goBack();
    const handleUserValidation = () => {
      const validUser = enabledModules?.some((data) => data.id === 'MODELER');
      // if (!validUser || location.isCreating) {
      //   history.push('/401');
      // }
    };
    handleUserValidation();
    const intervalId = setInterval(() => {
      handleUpdateWS();
    }, 30000);
    return () => {
      handleUpdateWS();
      clearInterval(intervalId);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let iframeUrl = `${
      environment.modeler.host
    }/index.html?language=${'en'}&edit=true&idUser=1&apiUser=${userId}&backend=${environment.ws}`;
    const isNewFlow = query.get('isNewFlow') === 'true';
    if (!isNewFlow) {
      setName(selectedFlow?.name);
      const workflowId = selectedFlow?._id || selectedFlow?.id;
      const canEdit = query.get('canEditFlow');
      iframeUrl = `${environment.modeler.host}/index.html?language=${'es'}
        &edit=${
          canEdit === 'true' && rights !== 'WFREADONLY' ? 'true' : 'false'
        }&idUser=1&apiUser=${userId}&document=${workflowId}&backend=${environment.ws}`;
    }
    setUrl(iframeUrl);
  });

  const handleReady = () => {
    loader.current.className = 'hide-loader';
  };

  return (
    <div className="modeler-iframe-wrapper" title={name}>
      <div className="header-flows">
        <div className="info-space-A">
          <GoBackComponent title={selectedFlow?.name || 'Go back'} isWhite={false} />
        </div>
      </div>
      <div className="modeler-iframe-container">
        <div className="modeler-iframe-iframe">
          <iframe
            onLoad={handleReady}
            ref={iframeRef}
            id="modelerIFrame"
            src={url}
            frameBorder="0"
            className="modeler-iframe"
            title={name}
          />
        </div>
      </div>
      <div ref={loader} className="load-flow">
        <Loader fit transparant />
      </div>
    </div>
  );
};
export default ModelerIframe;
