/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';

import { useFormik } from 'formik';
import md5 from 'md5';
import PropTypes from 'prop-types';
import { DebounceInput } from 'react-debounce-input';

import { Button, InputText } from '../../components';
import { loginService } from '../../services';
import { setCookie } from '../../utils/tools';
import formSchema from './schema';

import './UpdatePassword.css';

const EMPTY_PASSWORD = { current_pwd: '', new_pwd: '', confirmation_pwd: '' };

const UpdatePassword = ({ data, handleCloseCancel, handleSubmitUpdate }) => {
  const [isAutenticated, setisAutenticated] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [failed, setFailed] = useState(false);
  const { handleChange, handleBlur, values, errors, touched, resetForm, isValid } = useFormik({
    initialValues: EMPTY_PASSWORD,
    validationSchema: formSchema,
  });

  const checkPwd = async (event) => {
    event.preventDefault();
    if (event.target.value.length > 1) {
      setLoading(false);
      const encryt = md5(`${data?.username}/${event.target.value}/hound`);
      const { success, data: dateRepsonse } = await loginService?.createToken({
        username: data?.username,
        password: encryt,
      });
      if (success) {
        const { user: dataUser, access_token, expires_in } = dateRepsonse;
        setCookie(
          'credentials',
          JSON.stringify({
            userId: dataUser.id,
            accessToken: access_token,
            expiresIn: expires_in,
          })
        );
        setisAutenticated(true);
        setFailed(false);
      } else {
        setFailed(true);
        resetForm();
        setisAutenticated(false);
      }
    }
  };

  const isValidPwd = (newPassword) => {
    let valid = 0;
    if (!/[A-Z]/.test(newPassword)) {
      valid = 2;
    }
    if (!/[a-z]/.test(newPassword)) {
      valid = 3;
    }
    if (!/\d/.test(newPassword)) {
      valid = 4;
    }
    if (!newPassword.length >= 8) {
      valid = 1;
    }
    return valid;
  };

  function validatePassword(newPassword, confirmPassword) {
    // Check if the new password meets the specified criteria
    const isPasswordValid = isValidPwd(newPassword);
    // Check if the new password matches the confirmed password
    const passwordsMatch = newPassword === confirmPassword;

    // Return the validation result
    return {
      isPasswordValid: isPasswordValid === 0,
      passwordsMatch,
    };
  }

  useEffect(() => {
    if (values) {
      const { isPasswordValid, passwordsMatch } = validatePassword(
        values.new_pwd,
        values.confirmation_pwd
      );
      setIsDisabled(isPasswordValid && passwordsMatch);
    }
  }, [values]);

  return (
    <form>
      <section className="recovery-section">
        {/* {!isAutenticated ? (
          <img className="update-img" src={SecureImg} alt="authentificated password" />
        ) : (
          <img className="update-img" src={AuthImg} alt="auth your password" />
        )} */}
        <h2 style={{ marginTop: '20px' }}>Password Requirements:</h2>
        <ul className="bullet-pwd">
          <li>Must have at least 8 characters.</li>
          <li>Must include at least one uppercase letter.</li>
          <li>Must include at least one lowercase letter.</li>
          <li>Must include at least one numeric digit.</li>
        </ul>
        <div className="update-pwd-container">
          <DebounceInput
            id="current_pwd"
            name="current_pwd"
            spellCheck={false}
            type="password"
            className="pwd-input"
            debounceTimeout={700}
            placeholder="Current password"
            onChange={(event) => {
              handleChange(event);
              checkPwd(event);
            }}
            onBlur={handleBlur}
            value={values.current_pwd}
            autoComplete="off"
            disabled={isAutenticated}
          />
          {failed ? (
            <p className="pwd-error">
              The passwords you entered do not match. Please double-check and try again.
            </p>
          ) : (
            <></>
          )}
        </div>

        {isAutenticated ? (
          <>
            <div className="update-pwd-container">
              <InputText
                spellCheck={false}
                id="new_pwd"
                name="new_pwd"
                type="password"
                className="pwd-input"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.new_pwd}
                autoComplete="off"
                placeholder="New password"
              />
              {errors?.new_pwd && touched?.new_pwd ? (
                <p className="pwd-error">{errors?.new_pwd}</p>
              ) : (
                <></>
              )}
            </div>
            <div className="update-pwd-container">
              <InputText
                spellCheck={false}
                id="confirmation_pwd"
                name="confirmation_pwd"
                type="password"
                className="pwd-input"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.confirmation_pwd}
                autoComplete="off"
                placeholder="Confirm new password"
              />
              {values.new_pwd !== values.confirmation_pwd && touched?.confirmation_pwd ? (
                <p className="pwd-error">{errors?.confirmation_pwd}</p>
              ) : (
                <></>
              )}
            </div>
          </>
        ) : (
          <></>
        )}

        <div className="keep-row">
          <Button className="recovery-cancel" label="Cancel" onClick={handleCloseCancel} />
          {isAutenticated ? (
            <Button
              type="button"
              // disabled={!isDisabled}
              className="rvy-primary"
              label="Update"
              onClick={() => {
                handleSubmitUpdate(data.id, {
                  password: md5(`${data?.username}/${values?.new_pwd}/hound`),
                });
              }}
            >
              {loading && <div className="loader2" />}
            </Button>
          ) : (
            <></>
          )}
        </div>
      </section>
    </form>
  );
};

UpdatePassword.propTypes = {
  data: PropTypes.object.isRequired,
  handleSubmitUpdate: PropTypes.func.isRequired,
  handleCloseCancel: PropTypes.func.isRequired,
};

export default UpdatePassword;
