import { httpRequest, wrap } from './tools';

const getAppsbyUser = async (userId) => {
  const fn = async () => {
    const url = `/users/${userId}/apps`;
    const response = await httpRequest(url, 'GET', {}, true);

    return response.data;
  };

  return wrap(fn);
};

const getAppInstanceOFApp = async (appId, status = null, startDate = null, endDate = null) => {
  const fn = async () => {
    const url = `/apps/${appId}/instances?status=${status ?? ''}&startDate=${
      startDate ?? ''
    }&endDate=${endDate ?? ''}`;
    const response = await httpRequest(url, 'GET', {}, true);

    return response.data;
  };

  return wrap(fn);
};

const getEngiesByUser = async (userId) => {
  const fn = async () => {
    const url = `/users/${userId}/engines`;
    const response = await httpRequest(url, 'GET', {}, true);

    return response.data;
  };

  return wrap(fn);
};

const getAppsByEngine = async (engineId) => {
  const fn = async () => {
    const url = `/engines/${engineId}/apps`;
    const response = await httpRequest(url, 'GET', {}, true);

    return response.data;
  };

  return wrap(fn);
};

const getUsersByEngine = async (engineId) => {
  const fn = async () => {
    const url = `/engines/${engineId}/users`;
    const response = await httpRequest(url, 'GET', {}, true);

    return response.data;
  };

  return wrap(fn);
};

// eslint-disable-next-line import/prefer-default-export
export { getAppsbyUser, getAppInstanceOFApp, getEngiesByUser, getAppsByEngine, getUsersByEngine };
