/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable max-len */
/* eslint-disable camelcase */
import React, { useContext, useEffect, useState } from 'react';

import md5 from 'md5';
import { useHistory } from 'react-router-dom';

import { LoginHeader } from '../../components';
import { AccountContext } from '../../contexts/AccountContext';
import { LoginForm } from '../../forms';
import { loginService } from '../../services';
import { getCookie, setCookie } from '../../utils/tools';
import './LoginPage.css';

const LoginPage = ({ location }) => {
  const history = useHistory();
  const { updateContextAccountInfo, showSnackbar } = useContext(AccountContext);
  const [loading, setLoading] = useState(false);

  console.log('location', location);

  useEffect(() => {
    if (location.hash === '#update') {
      history?.push({ pathname: '/update', pwd: true, search: location.search });
    }
  }, []);

  const handleErrorLoginNotification = (err) => {
    if (err.response) {
      // client received an error response (5xx, 4xx)
      switch (err.response.status) {
        case 400: // Bad request
          showSnackbar('error', '', 'Username or password incorrect. Please try again');
          break;
        case 401: // Unauthorized
          showSnackbar('error', '', 'Unauthorized');
          break;
        case 403: // Unauthorized
          showSnackbar('error', '', 'Username or password incorrect. Please try again');
          break;
        case 404: // Not found
          showSnackbar('error', '', 'Case not found, please refresh');
          break;
        case 408: // Timeout
          showSnackbar('warn', '', 'The system is not available.');
          break;
        case 500: // Internal Server Error
          showSnackbar('warn', '', 'The system is not available.');
          break;
        default:
          showSnackbar('error', '', 'The system is not available.');
      }
    } else {
      showSnackbar('info', '', 'The system is not available.');
    }
  };

  const onSubmit = async (event, { username, password }) => {
    event.preventDefault();
    setLoading(false);
    const encryt = md5(`${username}/${password}/hound`);
    // console.log('MD5: ', encryt);
    const { success, data } = await loginService?.createToken({ username, password: encryt });
    // const { success, data } = await loginService?.createToken({ username, password });
    if (success) {
      const { user: dataUser, access_token, expires_in } = data;
      setCookie(
        'credentials',
        JSON.stringify({
          userId: dataUser.id,
          accessToken: access_token,
          expiresIn: expires_in,
        })
      );
      const routeToGo = await updateContextAccountInfo();
      history?.push(routeToGo);
    } else {
      handleErrorLoginNotification(data);
    }
  };

  const onGoogleLogIn = () => {};

  const onRecovery = () => {
    history?.push('/recovery');
  };

  // console.log('location.isExpired', location.isExpired);
  // if (location.isExpired) {
  //   return <>Sebas</>;
  // }

  return (
    <div className="login-container bounce">
      <div className="wrapper">
        <LoginHeader />
        <h3 className="lHeaderSubtitle">Enter your credentials</h3>
        <LoginForm
          onSubmit={onSubmit}
          loading={loading}
          handleGoogleLogIn={onGoogleLogIn}
          handleRecovery={onRecovery}
        />
      </div>
    </div>
  );
};

export default LoginPage;
