import { useEffect, useState } from 'react';

const useInput = (inputName, initValue, regex) => {
  const [value, setValue] = useState(initValue);
  const [rgx] = useState(regex);
  const [wasTouched, setWasTouched] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [errors, setErrors] = useState([]);
  const [isEnabled, setIsEnabled] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [dataRow, setDataRow] = useState({ [inputName]: initValue });

  const bind = {
    onChange: (e) => {
      setValue(e.target.value);
    },
    onFocus: () => {
      setWasTouched(true);
    },
    value,
  };

  useEffect(() => {
    const tempError = [];
    if (wasTouched || isEnabled) {
      // eslint-disable-next-line array-callback-return
      rgx.map((validator) => {
        const result = !!value.match(validator.regex);
        if (!result) {
          tempError.push(validator);
        }
      });
      setIsValid(tempError.length < 1);
      setErrors(tempError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, isEnabled, wasTouched]);

  const inputComponent = {
    value,
    bind,
    isValid,
    errors,
    wasTouched,
    dataRow,
    inputName,
    setIsEnabled,
    setValue,
  };

  return inputComponent;
};

export default useInput;
