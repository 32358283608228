/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useContext } from 'react';

import { ReactComponent as SupportIcon } from '../../assets/svgs/question-circle.svg';
import { AccountContext } from '../../contexts/AccountContext';
import { SupportTicketForm } from '../../forms';
import { Avatar, Button } from '../BasicComponents';

// import PropTypes from 'prop-types';

import './SupportComponent.css';

const SupportComponent = () => {
  const { tools, setTools, showSnackbar, handleCloseModal } = useContext(AccountContext);

  const showModal = (content, header) => {
    setTools({
      ...tools,
      manageModal: { content, header },
      showModal: true,
    });
  };

  const handleSubmitTicket = () => {
    handleCloseModal();
    showSnackbar('info', '', 'API CALL in progresss !!');
  };

  const HeaderForm = ({ title, iconName }) => {
    return (
      <div className="activity-header">
        <Avatar
          icon={`pi ${iconName}`}
          className="p-mr-2  subs-table-icons"
          style={{ backgroundColor: '#6fabf3', color: '#ffffff' }}
          size="small"
          shape="circle"
        />
        {title}
      </div>
    );
  };

  const handleShowSupport = () => {
    const ticketForm = <SupportTicketForm handleSubmit={handleSubmitTicket} data={[]} />;
    showModal(ticketForm, <HeaderForm title="Support ticket" iconName="pi-question" />);
  };

  return (
    <div>
      <Button className="support-btn" onClick={handleShowSupport}>
        <SupportIcon />
      </Button>
    </div>
  );
};

SupportComponent.propTypes = {};

export default SupportComponent;
