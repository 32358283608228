/* eslint-disable react/prop-types */
import React, { useRef, useState } from 'react';

import SignatureCanvas from 'react-signature-canvas';

import { Button } from '../../../components';
import './SignaturePad.css';

const SignaturePad = ({ events }) => {
  console.log(events);
  const sigCanvas = useRef(null);
  const [imageURL, setImageURL] = useState(null);
  const styling = {
    intro: 'Con esta firma, aseguro que toda la informacion es veridica.',
    bg: '#083A43',
    penColor: '#DFF8A7',
    padColor: '#DFF8A7',
    primaryBtn: 'blue',
    secondary: 'blue',
    cls: 'sigCanvas',
    primaryColor: 'red',
    secondaryColor: 'green',
    title: 'Sign Below',
  };

  const clear = () => {
    setImageURL(null);
    sigCanvas?.current?.clear();
  };

  const check = () => {
    const dataUrl = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
    setImageURL(dataUrl);
  };

  const save = () => {
    console.log(imageURL);
  };

  return (
    <div className="signature-container" style={{ background: styling.bg }}>
      {!imageURL && (
        <>
          <p className="text-intro-sign">{styling.intro}</p>
          <SignatureCanvas
            ref={sigCanvas}
            penColor={styling.penColor}
            canvasProps={{
              className: styling.cls,
            }}
            style={{ borderColor: styling.padColor }}
          />
        </>
      )}
      {imageURL && (
        <div className="image-preview">
          <p className="text-intro-sign">Confirmo la firma:</p>
          <img className="image-sign" src={imageURL} alt="Confirmo la firma" />
        </div>
      )}
      <div className="buttons">
        <Button
          className="secondary-btn"
          onClick={clear}
          style={{ background: styling.secondaryColor, borderColor: styling.padColor }}
        >
          Clear
        </Button>
        {!imageURL && (
          <Button
            className="primary-btn"
            style={{ background: styling.primaryColor }}
            onClick={check}
          >
            Check
          </Button>
        )}
        {imageURL && (
          <Button
            className="primary-btn"
            style={{ background: `${styling.primaryColor} !important;` }}
            onClick={save}
          >
            Save
          </Button>
        )}
      </div>
    </div>
  );
};

export default SignaturePad;
