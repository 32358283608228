/* eslint-disable no-unused-vars */
import React from 'react';

import PropTypes from 'prop-types';

import listEmptyImage from '../../assets/svgs/arts/undraw_throw.svg';
import { Button } from '../../components';

import './ConfirmDeleteAppForm.css';

function ConfirmDeleteAppForm({ onDeleteApp, onCloseModal }) {
  return (
    <div className="form-wrapper">
      <img className="trash-can" src={listEmptyImage} alt="Show a trash can" />
      <p className="conf-txt bounce">Are you sure you want to delete the Application?</p>
      <div className="action-flex-row">
        <Button className="conf-close" onClick={onCloseModal} label="Cancel" />
        <Button className="primary-btn" onClick={onDeleteApp} label="Delete" />
      </div>
    </div>
  );
}

ConfirmDeleteAppForm.propTypes = {
  onDeleteApp: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default ConfirmDeleteAppForm;
