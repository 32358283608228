/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { Button, InputText } from '../../components';

import './InputsCaseTypeForm.css';

const DynamicInputs = ({ initialProps, handleSubmit }) => {
  const [props, setProps] = useState(initialProps ? initialProps?.split('/') : ['']);

  const handleAdd = () => {
    setProps([...props, '']);
  };

  const handleRemove = (index) => {
    const newProps = props?.filter((_, i) => i !== index);
    setProps(newProps);
  };

  const handleChange = (index, event) => {
    const newProps = props?.map((prop, i) => (i === index ? event.target.value : prop));
    setProps(newProps);
  };

  const getResultString = () => {
    return props?.filter((prop) => prop.trim() !== '').join('/');
  };

  return (
    <form onSubmit={(e) => handleSubmit()}>
      <section className="add-app-section">
        <div className="flex-col">
          {props?.map((prop, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div style={{ marginTop: '20px' }} className="flexer" key={`Di-${index}`}>
              <span className="badge-numbers">{index}</span>
              <InputText
                type="text"
                value={prop}
                onChange={(event) => handleChange(index, event)}
                placeholder="Select an input"
              />
              <Button
                className="secondary-color"
                style={{ marginRight: '0' }}
                icon="pi pi-trash"
                type="button"
                onClick={() => handleRemove(index)}
              />
            </div>
          ))}
          <Button
            style={{ marginTop: '20px', marginLeft: 'auto' }}
            type="button"
            className="flex-row"
            icon="pi pi-plus"
            onClick={handleAdd}
          >
            Add input
          </Button>
          <div className="flex-row" style={{ marginTop: '30px' }}>
            <Button
              className="app-form-button"
              label={initialProps ? 'Edit' : 'Add'}
              disabled={!getResultString()}
            />
          </div>
        </div>
      </section>
    </form>
  );
};

const InputsCaseTypeForm = ({ data, handleSubmit, editing }) => {
  return <DynamicInputs initialProps={data} handleSubmit={handleSubmit} />;
};

InputsCaseTypeForm.propTypes = {
  data: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  editing: PropTypes.bool.isRequired,
};

export default InputsCaseTypeForm;
