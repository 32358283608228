import { httpRequest, wrap } from './tools';

const getUser = async (id) => {
  const fn = async () => {
    const url = `/users/${id}`;
    const response = await httpRequest(url, 'GET', {}, true);
    return response.data;
  };

  return wrap(fn);
};

const getUserIdByEmail = async (email) => {
  const fn = async () => {
    const url = `/userId/${email}`;
    const response = await httpRequest(url, 'GET', {}, true);
    return response.data;
  };

  return wrap(fn);
};

const getGroups = async (id) => {
  const fn = async () => {
    const url = `/users/${id}/human-groups`;
    const response = await httpRequest(url, 'GET', {}, true);
    return response.data;
  };

  return wrap(fn);
};

const createUser = async (user) => {
  const fn = async () => {
    const url = `/users`;
    const response = await httpRequest(url, 'POST', user, true);
    return response.data;
  };

  return wrap(fn);
};

const updateUser = async (userId, payload) => {
  const fn = async () => {
    const url = `/users/${userId}`;
    const response = await httpRequest(url, 'PATCH', payload, true);
    return response.data;
  };

  return wrap(fn);
};

export { getUser, getGroups, createUser, updateUser, getUserIdByEmail };
