/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState, useContext } from 'react';

import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import {
  TabView,
  TabPanel,
  Button,
  DataTable,
  Column,
  UsersAndEngineDrag,
  InputText,
  MultiSelect,
  Dropdown,
  Loader,
} from '../../components';
import { AccountContext } from '../../contexts/AccountContext';
import { useInput } from '../../hooks';
import { enginesService, adminInfoService } from '../../services';
import regexs from '../../utils/regexs';
import './UserForm.css';

const UserForm = ({
  data,
  handleSubmit,
  handleRecoveryUser,
  handleUpdatePasswordUser,
  isModal = false,
  onEditButton,
  handleCloseModal,
  isAdmin,
  resetUser,
  loadingMini = true,
  handleDeleteUser,
  isPersonal,
}) => {
  const lanOptions = [
    { name: 'English', id: 'en', code: 0, icon: '', display: true },
    { name: 'Spanish', id: 'es', code: 1, icon: '', display: true },
  ];
  const [showAdvanced, setShowAdvanced] = useState(false);
  const { setSelectedApp, showSnackbar, menuCollapse } = useContext(AccountContext);
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [dirty, setDirty] = useState(false);
  const [assignedList, setAssignedList] = useState(data?.engines || []);
  const [availabledList, setAvailableList] = useState([]);
  const [selectedWorkflow, setSelectedWorkflow] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState([lanOptions[0]]);
  const [selectedModules, setSelectedModules] = useState([]);
  // User section
  const [appsByUser, setAppsByUser] = useState(null);
  const [enginesByUser, setEnginesByUser] = useState(null);
  if (!isModal && !data?.id) {
    handleCloseModal();
  }

  const toogleShowAdvanced = () => {
    setShowAdvanced((s) => !s);
  };

  const styledColumn = {
    width: '110px',
    textAlign: 'left',
    color: 'black',
    background: '#9fc7c263',
  };

  const DEFAULT_ROLE = [
    { name: 'Administrador', id: 'ADMIN', code: 1 },
    { name: 'Agente', id: 'NORMAL', code: 2 },
    { name: 'Otro', id: 'OTHER', code: 3 },
  ];

  const DEFAULT_MODULES = [
    { name: 'Dashboard', id: 'DASHBOARD', code: 1 },
    { name: 'Apps', id: 'APPS', code: 2 },
    { name: 'Batches', id: 'BATCHES', code: 3 },
    { name: 'Tickets', id: 'HUMANINTERFACE', code: 4 },
    { name: 'Worksflows', id: 'MODELER', code: 5 },
    { name: 'Financial', id: 'COLLECTIONS', code: 6 },
  ];

  const userOptions = [
    { name: 'Edit user', code: 'update', icon: 'pi pi-fw pi-refresh', display: true },
    {
      name: isPersonal ? 'Change your password' : 'Send recovery password',
      code: isPersonal ? 'updatePassword' : 'recovery',
      icon: 'pi pi-fw pi-flag',
      display: true,
    },
    { name: 'Delete user', code: 'delete', icon: 'pi pi-fw pi-trash', display: true },
  ];

  const userOptions2 = [
    // { name: 'Edit information', code: 'update', icon: 'pi pi-fw pi-refresh', display: true },
    {
      name: isPersonal ? 'Change your password' : 'Send recovery password',
      code: isPersonal ? 'updatePassword' : 'recovery',
      icon: 'pi pi-fw pi-flag',
      display: true,
    },
  ];

  useEffect(() => {
    const activeModules = DEFAULT_MODULES.filter((m) => {
      return data?.enabledModules?.some((e) => {
        return e.id === m.id;
      });
    });
    setSelectedModules(activeModules);
    return () => {
      setSelectedModules(null);
      setSelectedWorkflow(null);
      setSelectedLanguage([lanOptions[0]]);
      setSelectedModules(null);
      setAppsByUser(null);
      setEnginesByUser(null);
    };
  }, []);

  const findRole = () => {
    return DEFAULT_ROLE.filter((r) => {
      return r.id === data?.role;
    });
  };

  const findLanguage = () => {
    return DEFAULT_ROLE.filter((r) => {
      return r.id === data?.role;
    });
  };

  const [selectedRole, setSelectedRole] = useState(findRole());

  const getAppsbyUser = async (userId) => {
    const { success, data: appsbyUserData } = await adminInfoService.getAppsbyUser(userId);
    if (success) {
      setAppsByUser(appsbyUserData);
    } else {
      setAppsByUser(null);
      // showSnackbar('error', '', 'We could not get the apps. Please try again');
    }
  };

  const getEnginesByUser = async (userId) => {
    const { success, data: enginesByUserData } = await adminInfoService.getEngiesByUser(userId);
    if (success) {
      setEnginesByUser(enginesByUserData);
    } else {
      setEnginesByUser(null);
      // showSnackbar('error', '', 'We could not get the engines. Please try again');
    }
    setLoading(false);
  };

  useEffect(async () => {
    await getAppsbyUser(data?.id);
    await getEnginesByUser(data?.id);
  }, []);

  useEffect(async () => {
    if (selectedWorkflow) {
      const { code } = selectedWorkflow;
      switch (code) {
        case 'update':
          // onEditButton(data?.id, data?.type);
          showSnackbar('info', '', 'Edit in progress!', 3000);
          break;
        case 'recovery':
          handleRecoveryUser(data);
          break;
        case 'updatePassword':
          handleUpdatePasswordUser(data);
          break;
        case 'delete':
          // handleDeleteUser(data);
          showSnackbar('info', '', 'Delete in progress!', 3000);
          break;
        default:
          break;
      }
    }
    setSelectedWorkflow(null);
    return () => {
      setSelectedWorkflow(null);
    };
  }, [selectedWorkflow]);

  const goToApp = (value) => {
    setSelectedApp(value);
    history.push({ pathname: '/apps/edit' });
  };

  const AppsEnginesByUserList = () => (
    <div>
      {appsByUser?.length > 0 || enginesByUser?.length > 0 ? (
        <TabView style={{ marginTop: '20px' }}>
          {appsByUser && (
            <TabPanel header="Apps" headerClassName="bounce">
              <DataTable
                value={appsByUser}
                className="p-datatable-striped top-adjust half-screen"
                rows={7}
                resizableColumns
                paginator
                emptyMessage="No info found."
                scrollable
                selectionMode="single"
                onSelectionChange={(e) => goToApp(e.value)}
              >
                <Column
                  field="id"
                  header="App Id"
                  headerStyle={{
                    width: '90px',
                    textAlign: 'left',
                    color: 'white',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    backgroundColor: '#22384d',
                  }}
                  bodyStyle={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                  }}
                />
                <Column
                  field="name"
                  header="App Name"
                  headerStyle={{
                    width: '180px',
                    textAlign: 'left',
                    color: 'white',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    backgroundColor: '#22384d',
                  }}
                  bodyStyle={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                  }}
                />
                <Column
                  field="description"
                  header="Description"
                  headerStyle={{
                    width: '200px',
                    textAlign: 'left',
                    color: 'white',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    backgroundColor: '#22384d',
                  }}
                  bodyStyle={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                  }}
                />
              </DataTable>
            </TabPanel>
          )}
          {enginesByUser && (
            <TabPanel header="Engines" headerClassName="bounce">
              <DataTable
                value={enginesByUser}
                className="p-datatable-striped top-adjust half-screen"
                rows={8}
                resizableColumns
                paginator
                emptyMessage="No info found."
              >
                <Column
                  field="id"
                  header="Engine Id"
                  headerStyle={{
                    width: '90px',
                    textAlign: 'left',
                    color: 'white',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    backgroundColor: '#22384d',
                  }}
                  bodyStyle={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                  }}
                />
                <Column
                  field="name"
                  header="Name"
                  headerStyle={{
                    width: '180px',
                    textAlign: 'left',
                    color: 'white',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    backgroundColor: '#22384d',
                  }}
                  bodyStyle={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                  }}
                />
                <Column
                  field="description"
                  header="Description"
                  headerStyle={{
                    width: '200px',
                    textAlign: 'left',
                    color: 'white',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    backgroundColor: '#22384d',
                  }}
                  bodyStyle={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                  }}
                />
              </DataTable>
            </TabPanel>
          )}
        </TabView>
      ) : (
        <></>
      )}
    </div>
  );

  const onWorkflowChange = (e) => {
    setSelectedWorkflow(e.value);
  };

  const onLanguageChange = (e) => {
    setSelectedLanguage([...e.target.value[1]]);
  };

  const nameInput = useInput('name', data.name || '', [
    {
      validatorName: 'name-required',
      regex: regexs.required,
    },
  ]);

  const usernameInput = useInput('username', data.username || '', [
    {
      validatorName: 'username-required',
      regex: regexs.required,
    },
  ]);

  const passwordInput = useInput('password', data.password || '', [
    {
      validatorName: 'password-required',
      regex: regexs.password_8charslong,
    },
  ]);

  const emailInput = useInput('email', data.email || '', [
    {
      validatorName: 'email-required',
      regex: regexs.email,
    },
  ]);

  const roleInput = useInput('phone', selectedRole[0]?.id || '', [
    {
      validatorName: 'role-required',
      regex: regexs.required,
    },
  ]);

  const lanInput = useInput('languaje', selectedLanguage[0]?.name || '', [
    {
      validatorName: 'role-required',
      regex: regexs.required,
    },
  ]);

  const phoneInput = useInput('phone', data.phone || '', [
    {
      validatorName: 'phone-required',
      regex: regexs.required,
    },
  ]);

  const modulesInput = useInput('modules', data.modules || '', [
    {
      validatorName: 'modules-required',
      regex: regexs.required,
    },
  ]);

  const newData = {
    id: data.id || '',
    name: nameInput.value,
    username: usernameInput.value,
    password: passwordInput.value,
    email: emailInput.value,
    phone: phoneInput.value,
    role: roleInput.value,
    enabledModules: modulesInput.value,
    engines: assignedList,
  };

  const inputs = [
    nameInput,
    usernameInput,
    passwordInput,
    emailInput,
    roleInput,
    phoneInput,
    modulesInput,
  ];

  const handleAssignedClick = (id) => {
    const index = assignedList.findIndex((item) => item.id === id);

    if (index !== -1) {
      assignedList[index].selected = !assignedList[index].selected;
      setAssignedList([...assignedList]);
    }
  };

  const handleAvailableClick = (id) => {
    const index = availabledList.findIndex((item) => item.id === id);

    if (index !== -1) {
      availabledList[index].selected = !availabledList[index].selected;
      setAvailableList([...availabledList]);
    }
  };

  const onAssigned = (e) => {
    e.preventDefault();

    availabledList.forEach((item) => {
      if (item.selected) {
        // eslint-disable-next-line no-param-reassign
        const obj = { ...item, selected: false };
        assignedList?.push(obj);
      }
    });
    const newAvailabled = availabledList?.filter((item) => !item.selected);
    setAvailableList([...newAvailabled]);
    setAssignedList([...assignedList]);
  };

  const onAvailable = (e) => {
    e.preventDefault();

    assignedList?.forEach((item) => {
      if (item.selected) {
        // eslint-disable-next-line no-param-reassign
        const obj = { ...item, selected: false };
        availabledList?.push(obj);
      }
    });
    const newAssigned = assignedList.filter((item) => !item.selected);
    setAvailableList([...availabledList]);
    setAssignedList([...newAssigned]);
  };

  const loadAvailables = async () => {
    const { success, data: enginesAvailable } = await enginesService.getEnginesByWorkspace(
      data.workspace
    );
    if (success) {
      setAvailableList(enginesAvailable);
    } else {
      setAvailableList([]);
    }
  };

  useEffect(() => {
    const isDirty = false;

    // if (isEmpty(data)) {
    //   isDirty = data.id
    //     ? !(
    //         usernameInput.isValid &&
    //         emailInput.isValid &&
    //         phoneInput.isValid &&
    //         modulesInput.isValid
    //       )
    //     : !(
    //         usernameInput.isValid &&
    //         passwordInput.isValid &&
    //         emailInput.isValid &&
    //         phoneInput.isValid &&
    //         modulesInput.isValid
    //       );
    // } else {
    //   let valueChanged = false;

    //   inputs.forEach((input) => {
    //     input.setIsEnabled(true);

    //     if (input.value !== data[input.inputName]) {
    //       valueChanged = true;
    //     }
    //   });
    //   isDirty = data.id
    //     ? !(
    //         valueChanged &&
    //         nameInput.isValid &&
    //         usernameInput.isValid &&
    //         emailInput.isValid &&
    //         phoneInput.isValid &&
    //         modulesInput.isValid
    //       )
    //     : !(
    //         valueChanged &&
    //         nameInput.isValid &&
    //         usernameInput.isValid &&
    //         emailInput.isValid &&
    //         passwordInput.isValid &&
    //         phoneInput.isValid &&
    //         modulesInput.isValid
    //       );
    // }

    setDirty(isDirty);
  }, [
    nameInput.value,
    usernameInput.value,
    passwordInput.value,
    emailInput.value,
    phoneInput.value,
    modulesInput.value,
    nameInput.isValid,
    usernameInput.isValid,
    passwordInput.isValid,
    emailInput.isValid,
    phoneInput.isValid,
    modulesInput.isValid,
  ]);

  useEffect(async () => {
    await loadAvailables();
    if (!isModal) {
      setLoading(true);
    } else {
      setLoading(false);
    }
    return () => {
      setLoading(false);
      setAppsByUser(null);
      setEnginesByUser(null);
    };
  }, []);

  const modulesOptionTemplate = (option) => {
    return (
      option.display && (
        <div className="flex option-backgroung">
          <i className={option.icon} />
          <p className="value-txt">{option.name}</p>
        </div>
      )
    );
  };

  const showInfo = (id) => {
    switch (id) {
      case DEFAULT_MODULES[0].id:
        break;
      case DEFAULT_MODULES[1].id:
        break;
      case DEFAULT_MODULES[2].id:
        break;
      case DEFAULT_MODULES[3].id:
        break;
      case DEFAULT_MODULES[4].id:
        break;

      default:
        break;
    }
  };

  const ModulesComponent = ({ data: modulesInfo }) => {
    if (modulesInfo) {
      const ListModules = () => {
        return modulesInfo.map((e) => {
          return (
            <Button
              key={e.name}
              type="button"
              className="resultExpected"
              onClick={(val) => {
                val.stopPropagation();
                showInfo(e.name);
              }}
            >
              {e.name}
            </Button>
          );
        });
      };
      return (
        <div className={isModal ? 'groupParams2' : 'groupParams'}>
          <ListModules />
        </div>
      );
    }
    return <></>;
  };

  const EnginesComponent = ({ data: modulesInfo }) => {
    if (modulesInfo?.engines) {
      const ListModules = () => {
        return modulesInfo.engines.map((e) => {
          return (
            <Button
              key={e.name}
              type="button"
              className="module-item"
              onClick={(val) => {
                val.stopPropagation();
                showInfo(e.name);
              }}
            >
              {e.name}
            </Button>
          );
        });
      };
      return (
        <div className="flex modules-wraper">
          <ListModules />
        </div>
      );
    }
    return <></>;
  };

  const modulesData = [
    <p className="chip">Dashboard</p>,
    <p className="chip">Apps</p>,
    <p className="chip">Tickets</p>,
    <p className="chip">Worksflows</p>,
    <p className="chip">Financial</p>,
    <p className="chip">Settings</p>,
  ];

  const roleData = [
    <p className="chip">Administrador</p>,
    <p className="chip">Agente</p>,
    <p className="chip">Otro</p>,
  ];

  const modulesTemplate = (option) => {
    return <div className="flex option-backgroung2">{modulesData[option.code - 1]}</div>;
  };

  const rolesTemplate = (option) => {
    return <div className="flex option-backgroung">{roleData[option.code - 1]}</div>;
  };

  const langTemplate = (option) => {
    return <div className="flex option-backgroung">{lanOptions[option.code]?.name}</div>;
  };

  const onChangeRole = (val) => {
    setSelectedRole([val]);
  };

  const onChangeLan = (val) => {
    setSelectedLanguage([val]);
  };

  return (
    <form
      onSubmit={() => handleSubmit(data.id, newData)}
      className={
        isModal
          ? 'user-form-section'
          : menuCollapse
          ? 'user-form-section-no-modal'
          : 'user-form-section-no-modal2'
      }
    >
      {!loading ? (
        <section className={isAdmin ? 'section-scroller' : 'section-scroller2'}>
          {!isModal ? (
            <div className="flex-wrapper">
              <div className="flex aling-items-center">
                {isAdmin && !isModal && (
                  <Button
                    title="Previous page"
                    icon="pi pi-arrow-left"
                    className="back2 bounce"
                    onClick={resetUser}
                  />
                )}
                <h2 className="subtitle-data-txt">User Info</h2>
              </div>
              {!isModal && (
                <div className="flex-row" style={{ alignItems: 'flex-start' }}>
                  <Button className="advanced-cls3" type="button" onClick={toogleShowAdvanced}>
                    Advanced Settings
                  </Button>
                  <Dropdown
                    value={selectedWorkflow}
                    options={isAdmin ? userOptions : userOptions2}
                    onChange={onWorkflowChange}
                    optionLabel="name"
                    placeholder={null}
                    filterPlaceholder={null}
                    itemTemplate={modulesOptionTemplate}
                  />
                </div>
              )}
            </div>
          ) : (
            <></>
          )}
          <div className={`flex-row ${isModal ? 'user-formGroup2' : 'user-formGroup'}`}>
            <label>Name</label>
            <InputText
              disabled={!isModal}
              spellCheck={false}
              value={nameInput.value}
              {...nameInput.bind}
              className={
                !nameInput.isValid && nameInput.wasTouched
                  ? 'user-inputText input-required'
                  : 'user-inputText'
              }
            />
          </div>
          <div className={`flex-row ${isModal ? 'user-formGroup2' : 'user-formGroup'}`}>
            <label>Username</label>
            <InputText
              disabled={!isModal}
              spellCheck={false}
              value={usernameInput.value}
              {...usernameInput.bind}
              className={
                !usernameInput.isValid && usernameInput.wasTouched
                  ? 'user-inputText input-required'
                  : 'user-inputText'
              }
            />
          </div>
          {!usernameInput.isValid && usernameInput.wasTouched ? (
            <small className="input-required-text user-input-required-text">
              This field is required.
            </small>
          ) : null}
          <div className={`flex-row ${isModal ? 'user-formGroup2' : 'user-formGroup'}`}>
            <label>Email</label>
            <InputText
              disabled
              spellCheck={false}
              value={emailInput.value}
              className={
                !emailInput.isValid && emailInput.wasTouched
                  ? 'user-inputText input-required p-invalid'
                  : 'user-inputText'
              }
              {...emailInput.bind}
            />
          </div>
          {!emailInput.isValid && emailInput.wasTouched ? (
            <small className="input-required-text user-input-required-text">
              Please input a well formatted email
            </small>
          ) : null}
          <div className={`flex-row ${isModal ? 'user-formGroup2' : 'user-formGroup'}`}>
            <label>Phone</label>
            <InputText
              placeholder="Enter the phone number"
              disabled={!isModal}
              spellCheck={false}
              value={phoneInput.value}
              className={
                !phoneInput.isValid && phoneInput.wasTouched
                  ? 'user-inputText input-required'
                  : 'user-inputText'
              }
              {...phoneInput.bind}
            />
          </div>
          {!phoneInput.isValid && phoneInput.wasTouched ? (
            <small className="input-required-text user-input-required-text">
              This field is required
            </small>
          ) : null}
          {showAdvanced && (
            <>
              {!isModal ? (
                <div className={`flex-row ${isModal ? 'user-formGroup2' : 'user-formGroup'}`}>
                  <label>Role</label>
                  <InputText
                    disabled={!isModal}
                    spellCheck={false}
                    value={roleInput.value}
                    className={
                      !roleInput.isValid && roleInput.wasTouched
                        ? 'user-inputText input-required p-invalid'
                        : 'user-inputText'
                    }
                    {...roleInput.bind}
                  />
                </div>
              ) : isAdmin ? (
                <div className={`flex-row ${isModal ? 'user-formGroup2' : 'user-formGroup'}`}>
                  <label>Role</label>
                  <div className="flex-row">
                    <input
                      disabled
                      type="text"
                      value={selectedRole[0]?.id}
                      className="edit-inputText"
                    />
                    <Dropdown
                      disabled={!isAdmin}
                      className="user-dropdown"
                      value={selectedRole}
                      options={DEFAULT_ROLE}
                      onChange={(e) => onChangeRole(e.value)}
                      optionLabel="id"
                      placeholder={null}
                      filterPlaceholder={null}
                      itemTemplate={rolesTemplate}
                    />
                  </div>
                </div>
              ) : null}
              <div className={`flex-row ${isModal ? 'user-formGroup2' : 'user-formGroup'}`}>
                <label>Language</label>
                <div className="flex-row less-margin">
                  {!isModal ? (
                    <InputText
                      disabled={!isModal}
                      spellCheck={false}
                      value={lanInput.value}
                      className={
                        !lanInput.isValid && lanInput.wasTouched
                          ? 'user-inputText input-required'
                          : 'user-inputText'
                      }
                      {...lanInput.bind}
                    />
                  ) : (
                    <div className="flex-row">
                      <input
                        disabled
                        type="text"
                        value={selectedLanguage[0]?.name}
                        className="edit-inputText"
                      />
                      <Dropdown
                        className="user-dropdown"
                        value={selectedLanguage}
                        options={lanOptions}
                        onChange={(e) => onChangeLan(e.value)}
                        optionLabel="name"
                        placeholder={null}
                        filterPlaceholder={null}
                        itemTemplate={langTemplate}
                      />
                    </div>
                  )}
                </div>
              </div>
              {isAdmin && (
                <div className={`flex-row ${isModal ? 'user-formGroup2' : 'user-formGroup'}`}>
                  <label>System Visibility</label>
                  {!isModal ? (
                    <ModulesComponent data={selectedModules} />
                  ) : (
                    <MultiSelect
                      placeholder="Select modules to display"
                      optionLabel="name"
                      className="multiselect-modules w-full md:w-20rem"
                      value={selectedModules}
                      options={DEFAULT_MODULES}
                      itemTemplate={modulesTemplate}
                      onChange={(e) => setSelectedModules(e.value)}
                    />
                  )}
                </div>
              )}
              {!modulesInput.isValid && modulesInput.wasTouched ? (
                <small className="input-required-text user-input-required-text">
                  This field is required
                </small>
              ) : null}
              {!isAdmin && !isModal && (
                <div className={`flex-row ${isModal ? 'user-formGroup2' : 'user-formGroup'}`}>
                  <label>Engines Visibility</label>
                  <EnginesComponent data={data} />
                </div>
              )}
              {isAdmin && isModal && (
                <UsersAndEngineDrag
                  assignedList={assignedList}
                  availabledList={availabledList}
                  handleAssignedClick={handleAssignedClick}
                  handleAvailableClick={handleAvailableClick}
                  onAssigned={onAssigned}
                  onAvailable={onAvailable}
                  formType="userForm"
                />
              )}
              {!isModal && isAdmin && <AppsEnginesByUserList />}
            </>
          )}
          {isModal && (
            <div className="flex-row" style={{ marginTop: '30px' }}>
              <Button className="user-form-button" label={data.id ? 'Update' : 'Create'}>
                {loadingMini && <div className="loader2" />}
              </Button>
            </div>
          )}
        </section>
      ) : (
        <div className="load-container">
          <Loader transparant type="feeder" />
        </div>
      )}
    </form>
  );
};

UserForm.propTypes = {
  data: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleRecoveryUser: PropTypes.func.isRequired,
  handleUpdatePasswordUser: PropTypes.func.isRequired,
  isModal: PropTypes.bool.isRequired,
  onEditButton: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  loadingMini: PropTypes.bool.isRequired,
  handleDeleteUser: PropTypes.func.isRequired,
  isPersonal: PropTypes.bool.isRequired,
};

export default UserForm;
