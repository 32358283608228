import React from 'react';

import PropTypes from 'prop-types';

const Treemap = ({ selectedType }) => {
  return (
    <div className="">
      <h1 className="titles bounce">
        {selectedType > 0 ? 'States Distribution' : 'Cases Distribution'}
      </h1>
      <hr className="lines-hr" />
      <div className="info-container">
        <section
          id="chartCasesTypes"
          style={{
            height: '100%',
            width: '100%',
            marginTop: '-20px',
          }}
        />
      </div>
    </div>
  );
};

Treemap.propTypes = {
  selectedType: PropTypes.number.isRequired,
};

export default Treemap;
