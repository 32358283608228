/* eslint-disable no-plusplus */
/* eslint-disable prefer-const */
/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
/* eslint-disable no-empty-pattern */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';

import moment from 'moment-timezone';
import { DebounceInput } from 'react-debounce-input';
import { useHistory } from 'react-router-dom';

import RefreshIcon from '../../../assets/images/refresh.png';
import { Loader } from '../../../components';
import { Button, DataTable, Column } from '../../../components/BasicComponents';
import { AccountContext } from '../../../contexts/AccountContext';
import { BatchForm } from '../../../forms';
import { batchesService, casesDashboardService } from '../../../services';

import './History.css';

function History({}) {
  const history = useHistory();
  const [isLoading, setIsloading] = useState(false);
  const [batchFilter, setBatchFilter] = useState('');
  const {
    setTypesInfo,
    showSnackbar,
    batches,
    setBatches,
    setTools,
    tools: tooler,
    handleCloseModal,
    accountInfo,
  } = useContext(AccountContext);

  const getListCasesTypes = async () => {
    const { success, data: casesInfo } = await casesDashboardService.listCasesTypes();
    if (success) {
      const filter = casesInfo.filter((p) => p.id !== 'TBD');
      setTypesInfo(filter);
    }
  };

  const getListBatches = async () => {
    setIsloading(true);
    const { success, data: batchesList } = await batchesService.listBatchesByWorkspace();
    if (success) {
      setBatches(batchesList);
    } else {
      setBatches(null);
    }
    setIsloading(false);
  };
  useEffect(() => {
    getListCasesTypes();
    getListBatches();
  }, []);

  const handleReset = () => {
    setBatchFilter('');
  };

  const STATUS = {
    UNPROCESSED: 'UNPROCESSED',
    RUNNING: 'RUNNING',
    PROGRESS: 'PROGRESS',
    DONE: 'DONE',
    COMPLETED: 'COMPLETED',
    INCOMPLETE: 'INCOMPLETE',
    ERROR: 'ERROR',
    DELETED: 'DELETED',
    PREUNLOADED: 'PRE-UNLOADED',
  };

  const STATUS_COLORS = {
    UNPROCESSED: '#a1a1a1',
    RUNNING: '#FFCC00', // Amarillo brillante
    PROGRESS: '#FFCC00',
    DONE: '#87A0B2', // Naranja brillante
    COMPLETED: '#A4DD74', // Verde lima
    INCOMPLETE: '#868C9C',
    ERROR: 'RED', // Azul claro
    PREUNLOADED: '#a1a1a1',
  };

  const formatDate = (date) => {
    const newDate = new Date(date);
    const opciones = {
      month: 'numeric', // Nombre completo del mes (por ejemplo, "enero")
      day: 'numeric', // Día del mes (por ejemplo, "26")
      hour: 'numeric', // Hora (por ejemplo, "13")
      year: 'numeric', // Hora (por ejemplo, "13")
      minute: 'numeric', // Minutos (por ejemplo, "45")
    };

    // Formatear la fecha
    const formatoFecha = new Intl.DateTimeFormat('en-EN', opciones).format(newDate);
    return formatoFecha;
  };
  const ConditionColumn = ({ created }) => {
    const utcDate = new Date(created);

    // console.log('www', formatDate(created));
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const localDate = utcDate.toLocaleString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true, // Opcional: usa el formato de 24 horas
    });

    const now2 = new Date(`${localDate}`).toLocaleDateString('en-US', {
      timeZone: userTimeZone,
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true, // Opcional: usa el formato de 24 horas
    });

    const now = new Date().toLocaleDateString('en-US', {
      timeZone: userTimeZone,
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true, // Opcional: usa el formato de 24 horas
    });

    // Convertir las fechas a objetos moment
    const momentDate1 = moment(now.split(', ').join(' '), 'MM/DD/YYYY hh:mm:ss A');
    const momentDate2 = moment(now2.split(', ').join(' '), 'MM/DD/YYYY hh:mm:ss A');
    const differenceInSeconds =
      momentDate1.diff(momentDate2, 'seconds') || momentDate2.diff(momentDate1, 'seconds');
    // Función timeSince
    const timeSince = (seconds) => {
      let difference = 'now';

      const duration = moment.duration(seconds, 'seconds');
      const days = Math.floor(duration.asDays());
      if (days < 1) {
        const hours = Math.floor(duration.asHours()) - days * 24;
        if (hours < 1) {
          const mins = Math.floor(duration.asMinutes()) - days * 24 * 60 - hours * 60;
          if (mins < 1) {
            const sec =
              Math.floor(duration.asSeconds()) - days * 24 * 60 * 60 - hours * 60 * 60 - mins * 60;
            if (sec > 1) {
              difference = `${sec}s`;
            }
          } else {
            difference = `${mins}m`;
          }
        } else {
          difference = `${hours}h`;
        }
      } else {
        difference = `${days}d`;
      }

      return difference;
    };

    // Aplicar la función timeSince
    const timeDifference = timeSince(differenceInSeconds);
    return (
      <div className="flex-rows">
        <div className={`dot2 green `} title={`Created: ${created}`}>
          <p className="dot-value">{timeDifference}</p>
        </div>
      </div>
    );
  };

  const StatusColumn = ({ status: codeStatus }) => {
    let styling = STATUS_COLORS.DELETED;
    let opa = 1;
    switch (codeStatus.toLocaleUpperCase()) {
      case STATUS.UNPROCESSED:
        styling = STATUS_COLORS.UNPROCESSED;
        break;
      case STATUS.ONGOING:
        styling = STATUS_COLORS.ONGOING;
        break;
      case STATUS.WARNING:
        styling = STATUS_COLORS.WARNING;
        break;
      case STATUS.COMPLETED:
        styling = STATUS_COLORS.COMPLETED;
        break;
      case STATUS.DELETED:
        styling = STATUS_COLORS.DELETED;
        opa = 0.2;
        break;
      case STATUS.PREUNLOADED:
        styling = STATUS_COLORS.PREUNLOADED;
        opa = 1;
        break;
      case STATUS.PROGRESS:
        styling = STATUS_COLORS.PROGRESS;
        opa = 1;
        break;

      default:
        styling = STATUS_COLORS.DELETED;
        break;
    }
    return (
      <div className="batchChip" style={{ background: styling, opacity: opa }}>
        {codeStatus || '-'}
      </div>
    );
  };

  const showModal = (content, header) => {
    setTools({
      ...tooler,
      manageModal: { content, header },
      showModal: true,
    });
  };

  const HeaderAddApp = ({ title }) => {
    return <div className="activity-header bounce">{title}</div>;
  };

  const OwnerColumn = ({ user_name, user_id }) => {
    const styling = '';
    const isYourself = user_id === accountInfo.userData.id;
    return (
      <div className="ownerChip" style={{ background: styling }}>
        {isYourself ? 'You' : user_name || 'N/A'}
      </div>
    );
  };

  const addBatchForm = <BatchForm preBatch={[]} loadBatch={getListBatches} />;

  function contarOcurrenciasPorEstado(arr) {
    // Objeto para almacenar el recuento de ocurrencias por estado
    let ocurrenciasPorEstado = {};

    // Iterar sobre el arreglo de objetos
    arr?.forEach((obj) => {
      // Verificar si el estado ya ha sido contabilizado
      if (ocurrenciasPorEstado[obj.status]) {
        // Si ya existe, incrementar el contador
        ocurrenciasPorEstado[obj.status]++;
      } else {
        // Si no existe, inicializar el contador en 1
        ocurrenciasPorEstado[obj.status] = 1;
      }
    });

    // Devolver el objeto con el recuento de ocurrencias por estado
    return ocurrenciasPorEstado;
  }

  function contarOcurrenciasPorCase(arr) {
    // console.log(arr);
    // Objeto para almacenar el recuento de ocurrencias por estado
    let ocurrenciasPorCase = {};

    // Iterar sobre el arreglo de objetos
    arr?.forEach((obj) => {
      // Verificar si el estado ya ha sido contabilizado
      if (ocurrenciasPorCase[obj.case_type_description]) {
        // Si ya existe, incrementar el contador
        ocurrenciasPorCase[obj.case_type_description]++;
      } else {
        // Si no existe, inicializar el contador en 1
        ocurrenciasPorCase[obj.case_type_description] = 1;
      }
    });

    // Devolver el objeto con el recuento de ocurrencias por estado
    return ocurrenciasPorCase;
  }

  const onExeApp = async (batchID) => {
    const { success } = await batchesService.executeBatch(batchID);
    handleCloseModal();
    if (success) {
      showSnackbar('success', '', 'Batch in progress.', 4000);
    } else {
      showSnackbar('error', '', 'Batch not executed.', 4000);
    }
    getListBatches();
  };

  const ProcessedColumn = ({ processed, status, batchID }) => {
    if (!processed && status === 'Ongoing') {
      return (
        <Button
          label="Stop batch"
          type="button"
          icon="pi pi-spin pi-spinner"
          className="stop-btn"
          onClick={() => {
            // showModal(exeStopForm, <HeaderAddApp title="✋ Stop batch" />);
            onStopApp();
          }}
        />
      );
    }
    if (processed) {
      return <>{formatDate(processed)}</>;
    }
    return <>-</>;
  };

  const CasesStats = () => {
    const ocurrencias = contarOcurrenciasPorEstado(batches);
    const cases = contarOcurrenciasPorCase(batches);
    return (
      <div className="caseType-container">
        {Object.keys(cases)?.map((o) => {
          return (
            <div className="card-batch2">
              <div className="casetype-name">{o}</div>
              <div className="casetype-value">{cases[o]}</div>
            </div>
          );
        })}
        <hr className="card-hr" />
        {Object.keys(ocurrencias)?.map((o) => {
          return (
            <div className="card-batch">
              <div className="casetype-name">{o}</div>
              <div className="casetype-value">{ocurrencias[o]}</div>
            </div>
          );
        })}
      </div>
    );
  };

  const handleSeeCases = (batchData) => {
    history.push({ pathname: `/batches/${batchData.batchID}`, batch: batchData });
  };

  const rowBodyTemplate = (rowData, props) => {
    return <div className="secuencial-cls">{props.rowIndex + 1}</div>;
  };

  const filtered = batches?.filter((b) => {
    return b.batchID.includes(batchFilter);
  });

  const joinArray = batchFilter !== '' ? filtered : batches;
  const reversedList = joinArray?.reduce((acc, val) => [val, ...acc], []);

  return !isLoading ? (
    <div className="cases-container">
      <div className="search-wrapper">
        <Button
          className="add-ticket-btn"
          icon="pi pi-plus"
          onClick={() => {
            showModal(addBatchForm, <HeaderAddApp title="🚀 New batch" />);
          }}
        />
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <DebounceInput
            id="searchTicket-input"
            className="buttonFilter2"
            value={batchFilter}
            debounceTimeout={700}
            onChange={(e) => setBatchFilter(e.target.value)}
            placeholder="Batch ID"
            autoComplete="off"
          />
          {batchFilter && (
            <Button
              type="button"
              title="Clear apps"
              className="pi pi-trash adjust-trash2"
              onClick={handleReset}
            />
          )}
        </span>
        <Button
          title="Refresh tickets"
          className="refresh-basket"
          onClick={() => {
            showSnackbar('success', '', '¡Batches refreshed!', 2000);
            getListBatches();
          }}
        >
          <img className="refresh-img" src={RefreshIcon} alt="refresh tickets button" />
        </Button>
      </div>
      {batches?.length > 0 && <CasesStats />}
      <DataTable
        className="p-datatable-striped"
        emptyMessage="No batches found."
        value={reversedList}
        globalFilterFields={['case-type']}
        responsiveLayout="scroll"
        paginator={reversedList?.length > 10}
        rows={10}
        resizableColumns
        // filters={filters}
        scrollable
        scrollHeight="80vh"
        selectionMode="single"
        onSelectionChange={(e) => handleSeeCases(e.value)}
        style={{
          width: '100%',
        }}
      >
        <Column
          header=""
          headerStyle={{
            maxWidth: '5vw',
            minWidth: '70px',
            width: '5vw',
            textAlign: 'center',
            backgroundColor: '#22384d',
            color: 'white',
          }}
          bodyStyle={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textAlign: 'center',
          }}
          body={rowBodyTemplate}
        />
        <Column
          field="created"
          header=""
          headerStyle={{
            backgroundColor: '#22384d',
            color: 'white',
            maxWidth: '5vw',
            minWidth: '70px',
            width: '5vw',
            textAlign: 'center',
          }}
          bodyStyle={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textAlign: 'left',
          }}
          body={ConditionColumn}
          sortable
        />
        <Column
          field="batchID"
          filterField="batchID"
          header="Batch ID"
          headerStyle={{
            maxWidth: '7vw',
            minWidth: '100px',
            width: '7vw',
            textAlign: 'left',
            backgroundColor: '#22384d',
            color: 'white',
          }}
          bodyStyle={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textAlign: 'center',
          }}
        />
        <Column
          field="status.id"
          filterField="status"
          header="Status"
          headerStyle={{
            height: '50px',
            maxWidth: '12vw',
            minWidth: '100px',
            width: '12vw',
            textAlign: 'center',
            backgroundColor: '#22384d',
            color: 'white',
          }}
          body={StatusColumn}
          bodyStyle={{ overflow: 'visible' }}
          sortable
        />
        <Column
          field="user_id"
          header="Owner"
          headerStyle={{
            maxWidth: '8vw',
            minWidth: '100px',
            width: '8vw',
            textAlign: 'center',
            backgroundColor: '#22384d',
            color: 'white',
          }}
          bodyStyle={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
          body={OwnerColumn}
        />
        <Column
          field="case_type_description"
          header="Case Type"
          headerStyle={{
            maxWidth: '15vw',
            minWidth: '240px',
            width: '15vw',
            textAlign: 'center',
            backgroundColor: '#22384d',
            color: 'white',
          }}
          bodyStyle={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textAlign: 'center',
          }}
        />
        <Column
          field="description"
          header="Description"
          headerStyle={{
            maxWidth: '12vw',
            minWidth: '120px',
            width: '12vw',
            textAlign: 'left',
            backgroundColor: '#22384d',
            color: 'white',
          }}
          bodyStyle={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        />
        <Column
          field="processed"
          filterField="processed"
          header="Processed"
          headerStyle={{
            backgroundColor: '#22384d',
            color: 'white',
            maxWidth: '12vw',
            minWidth: '200px',
            width: '12vw',
            textAlign: 'center',
          }}
          bodyStyle={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textAlign: 'center',
          }}
          body={ProcessedColumn}
          sortable
        />
      </DataTable>
    </div>
  ) : (
    <div className="loader-wrapper">
      <Loader fit type="feeder" transparant />
    </div>
  );
}

History.propTypes = {};

export default History;
