import { httpRequest, wrap } from './tools';

const getEventsByApp = async (appId) => {
  const fn = async () => {
    const url = `/apps/${appId}/events`;
    const response = await httpRequest(url, 'GET', {}, true);

    return response.data;
  };

  return wrap(fn);
};

const createEvent = async (event) => {
  const fn = async () => {
    const url = `/events`;
    const response = await httpRequest(url, 'POST', event, true);

    return response.data;
  };

  return wrap(fn);
};

const updateEvent = async (event) => {
  const fn = async () => {
    const url = `/events/${event.id}`;
    const response = await httpRequest(url, 'PATCH', event, true);

    return response.data;
  };

  return wrap(fn);
};

const deleteEventOfApp = async (appId, eventId) => {
  const fn = async () => {
    const url = `/apps/${appId}/events/${eventId}`;
    const response = await httpRequest(url, 'DELETE', {}, true);

    return response.data;
  };

  return wrap(fn);
};

const getEventById = async (id) => {
  const fn = async () => {
    const url = `/events/${id}`;
    const response = await httpRequest(url, 'GET', {}, true);

    return response.data;
  };

  return wrap(fn);
};

const updateIsActive = async (appId, eventId, data = {}) => {
  const fn = async () => {
    const url = `/apps/${appId}/events/${eventId}`;
    const response = await httpRequest(url, 'PATCH', data, true);

    return response.data;
  };

  return wrap(fn);
};

const generateWebhook = async () => {
  const fn = async () => {
    const url = `/webhooks/generateUrl`;
    const response = await httpRequest(url, 'POST', {}, true);

    return response.data;
  };

  return wrap(fn);
};

const generateToken = async (user) => {
  const fn = async () => {
    const url = `/webhooks/generateToken`;
    const response = await httpRequest(url, 'POST', user, true);

    return response.data;
  };

  return wrap(fn);
};

const createWebhook = async (webhook) => {
  const fn = async () => {
    const url = `/webhooks`;
    const response = await httpRequest(url, 'POST', webhook, true);

    return response.data;
  };

  return wrap(fn);
};

const updateWebhook = async (webhook) => {
  const fn = async () => {
    const url = `/webhooks/${webhook.id}`;
    const response = await httpRequest(url, 'PATCH', webhook, true);

    return response.data;
  };

  return wrap(fn);
};

export {
  getEventById,
  getEventsByApp,
  generateWebhook,
  generateToken,
  updateIsActive,
  createWebhook,
  updateWebhook,
  deleteEventOfApp,
  createEvent,
  updateEvent,
};
