/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import { Button } from '../../components/BasicComponents';
import { useInput } from '../../hooks';
import './LoginForm.css';

const LoginForm = ({ onSubmit, loading, handleGoogleLogIn, handleRecovery }) => {
  const usernameInput = useInput('username', '', []);
  const passwordInput = useInput('password', '', []);
  const [hiddenPassword, setHiddenPassword] = useState(true);
  const [isValidForm, setisValidForm] = useState(false);

  const user = {
    username: usernameInput?.value.trim(),
    password: passwordInput?.value.trim(),
  };

  useEffect(() => {
    // TODO add a minimum len to the password && passwordInput.value.length >= 6
    if (usernameInput.value && passwordInput.value) {
      setisValidForm(true);
    }
    return () => {
      setisValidForm(false);
    };
  }, [usernameInput, passwordInput]);

  const handleShowPassword = (e) => {
    e.preventDefault();
    setHiddenPassword((val) => !val);
  };

  return (
    <form className="form-login" onSubmit={(event) => onSubmit(event, user)}>
      <div className="loginFormGroup">
        <span className="p-input-icon-left">
          <i className="pi pi-user" />
          <input
            spellCheck={false}
            disabled={loading}
            className="loginInputText"
            id="username"
            placeholder="Username"
            {...usernameInput.bind}
            required
          />
        </span>
      </div>
      <div className="loginFormGroup">
        <span className="p-input-icon-left p-input-icon-right">
          <i className="pi pi-lock" />
          <input
            spellCheck={false}
            disabled={loading}
            className="loginInputText"
            id="password"
            placeholder="Password"
            type={!hiddenPassword ? 'text' : 'password'}
            {...passwordInput.bind}
            required
          />
          {hiddenPassword ? (
            <i onClick={(event) => handleShowPassword(event)} className="pi pi-eye" />
          ) : (
            <i onClick={(event) => handleShowPassword(event)} className="pi pi-eye-slash" />
          )}
        </span>
      </div>
      {!loading ? (
        <Button
          disabled={!isValidForm}
          className={!isValidForm ? 'submit-btn' : 'submit-btn bounce'}
          label="Login"
        />
      ) : null}
      <hr className="hr-line" />
      {/* <div className="google-container">
        <Button type="button" className="google-btn" onClick={handleGoogleLogIn}>
          <img src={GoogleIcon} alt="Google" className="google-icon" />
          <span>Continue with Google</span>
        </Button>
      </div> */}
      <div className="google-container">
        <Button type="button" className="google-btn2" onClick={handleRecovery}>
          <span>Forgot your password</span>
        </Button>
      </div>
    </form>
  );
};

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  handleGoogleLogIn: PropTypes.func.isRequired,
  handleRecovery: PropTypes.func.isRequired,
};

export default LoginForm;
