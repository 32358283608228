import React, { createContext } from 'react';

import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';

export const ResponsiveContext = createContext({});

export const ResponsiveContextProvider = ({ children }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 570px)' });
  const isTablet = useMediaQuery({ query: '(max-width: 900px)' });
  const isLittleDesktop = useMediaQuery({ query: '(max-width: 1350px)' });
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
  // TODO Other options available
  // const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
  // const isDesktopOrLaptop = useMediaQuery({
  //   query: '(min-width: 1224px)',
  // });
  // const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' });
  // const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  // const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
  // const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' });

  return (
    <ResponsiveContext.Provider value={{ isMobile, isTablet, isLittleDesktop, isPortrait }}>
      {children}
    </ResponsiveContext.Provider>
  );
};

ResponsiveContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
