/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import { Button } from '../BasicComponents';

import './LineCard.css';

// eslint-disable-next-line react/prop-types
const LineCard = ({
  key = 'key',
  lineId,
  data,
  state,
  processed,
  caseId = '',
  selected,
  onSelect,
}) => {
  const [isActive, setIsActive] = useState(false);
  useEffect(() => {
    return () => {
      setIsActive(false);
    };
  }, []);

  const fechaGMT = new Date(processed);
  // Obtener la diferencia horaria en minutos entre la hora local y GMT
  const diferenciaHorariaMinutos = fechaGMT.getTimezoneOffset();
  // Ajustar la hora restando la diferencia horaria en minutos
  fechaGMT.setMinutes(fechaGMT.getMinutes() - diferenciaHorariaMinutos);
  // Obtener la hora local
  const horaLocal = fechaGMT.toLocaleTimeString();
  const fechaLocal = fechaGMT.toLocaleDateString();

  return (
    <Button
      key={key}
      type="button"
      className={selected ? 'card-container-seleted' : 'card-container'}
      style={{ backgroundColor: '#3e678e !important' }}
      onClick={() => {
        setIsActive(true);
        onSelect(lineId);
        setTimeout(() => {
          setIsActive(false);
        }, 1000);
      }}
      disable={`${!isActive}`}
    >
      <div style={{ width: '100%' }}>
        {data[0] ? <h3 className="header-title">{data[0]}</h3> : <></>}
        {/* {data[1] ? (
          <h4 className='header-title2'>{data[1]}</h4>
        ) : (
          <></>
        )}
        {data[2] ? (
          <h4 className='header-title2'>{data[2]}</h4>
        ) : (
          <></>
        )} */}
        {!data[0] && !data[1] && !data[2] ? (
          <h4 className="header-title2">No data passed to the webhook</h4>
        ) : (
          <></>
        )}
        {lineId && <h4 className="header-title2">{`Line ID: ${lineId}`}</h4>}
        {caseId && <h4 className="header-title2">{`Caso: ${caseId}`}</h4>}
        {state && (
          <div className="state-container">
            <p className="badge-state">{state === 'Ready To Start' ? 'Por iniciar' : state}</p>
            {processed && <p className="badge-proccesed">{`${fechaLocal} ${horaLocal}`}</p>}
          </div>
        )}
      </div>
    </Button>
  );
};

LineCard.propTypes = {
  key: PropTypes.string.isRequired,
  lineId: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  state: PropTypes.string.isRequired,
  caseId: PropTypes.string.isRequired,
  processed: PropTypes.any.isRequired,
};

export default LineCard;
