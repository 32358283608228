/* eslint-disable prefer-const */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable no-constant-condition */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useContext, useState, useRef } from 'react';

import { DebounceInput } from 'react-debounce-input';

import listEmptyTeams from '../../../assets/svgs/arts/undraw_teams.svg';
import { Accordion, Button, Loader } from '../../../components';
import { AccountContext } from '../../../contexts/AccountContext';
import { TicketsContext } from '../../../contexts/TicketsContext';
import { ToolsContext } from '../../../contexts/ToolsContext';
import { MemberForm, TeamForm } from '../../../forms';
import { workSpaceService, humansService } from '../../../services';

import './Settings.css';

function Settings() {
  const leftSide = useRef(null);
  const rightSide = useRef(null);
  const [nameFilter, setNameFilter] = useState('');
  const [disableClear, setDisableClear] = useState(false);
  const [filteredTeams, setFilteredTeams] = useState([]);
  const [filteredOperators, setFilteredOperators] = useState([]);
  const [loading, setLoading] = useState(true);
  const { accountInfo, showDetails, setShowDetails, enginesByWorkspace, showSnackbar } = useContext(
    AccountContext
  );
  const { workspaceData } = accountInfo;
  const [availabledList, setAvailableList] = useState([]);
  const {
    groupsRetrieved,
    overall,
    setTeamSelected,
    setMemberSelected,
    fetchOverAllCases,
    humansRetrieved,
    teamSelected,
    memberSelected,
    setHumansRetrieved,
  } = useContext(TicketsContext);

  const { showModal, handleCloseModal } = useContext(ToolsContext);

  const loadAvailableUsers = async () => {
    const { success, data: availableUserData } = await workSpaceService.getAllUsersByWorkspace(
      workspaceData?.data?.id
    );
    if (success) {
      setAvailableList(availableUserData.data);
    } else {
      setAvailableList([]);
    }
  };

  const retriveHumans = async () => {
    const { success, data } = await humansService.getHumansByWS();
    if (success) {
      setHumansRetrieved(data.data[0]);
    }
  };

  useEffect(async () => {
    if (teamSelected?.name) {
      // await getTeam(teamSelected?.id);
    }
    return () => {};
  }, [teamSelected]);

  useEffect(async () => {
    await loadAvailableUsers();
    await retriveHumans();
    if (overall?.length === 0) {
      fetchOverAllCases();
    }
    return () => {
      setShowDetails(false);
    };
  }, []);

  const handleFilterDistribution = [
    {
      id: 1,
      type: 'teams',
      title: 'Teams',
      amount: filteredTeams?.length || 0,
      content: filteredTeams,
      legend: 'A group of operators!',
    },
    {
      id: 2,
      type: 'operators',
      title: 'Available Operators',
      amount: filteredOperators?.length || 0,
      content: filteredOperators,
      legend: 'In charge of the tickets!',
    },
  ];

  const handleDistribution = [
    {
      id: 1,
      type: 'teams',
      title: 'Teams',
      amount: groupsRetrieved?.length || 0,
      content: groupsRetrieved,
      legend: 'A group of members!',
    },
    {
      id: 2,
      type: 'operators',
      title: 'Available Members',
      amount: humansRetrieved?.length || 0,
      content: humansRetrieved,
      legend: 'In charge of the tickets!',
    },
  ];

  const handleFocusInput = () => {
    const input = document.getElementById('searchSettings-input');
    input?.focus();
  };

  useEffect(() => {
    setTeamSelected(null);
    setMemberSelected({});
    setTimeout(() => {
      handleFocusInput();
      setLoading(false);
    }, 1000);
  }, []);

  const handleFiltering = () => {
    const filterInLower = nameFilter.toLowerCase();
    const teamsReady = groupsRetrieved?.filter(
      (g) => g?.name.toLowerCase()?.indexOf(filterInLower) >= 0
    );
    const operatorsReady = humansRetrieved?.filter(
      (u) => u?.name?.toLowerCase()?.indexOf(filterInLower) >= 0
    );
    return { team: teamsReady, operator: operatorsReady };
  };

  useEffect(() => {
    setTeamSelected(null);
    setMemberSelected(null);
    if (nameFilter !== '') {
      const { team, operator } = handleFiltering();
      setFilteredTeams(team);
      setFilteredOperators(operator);
    } else {
      setFilteredTeams([]);
      setFilteredOperators([]);
    }
    setTimeout(() => {
      if (nameFilter !== '') {
        setDisableClear(false);
      } else {
        setDisableClear(true);
      }
    }, 200);
  }, [nameFilter]);

  const HIListing = () => (
    <div className="listing">
      <div className="HI-listing-container">
        <Accordion
          headerColor="header-tickets-bg"
          items={nameFilter ? handleFilterDistribution : handleDistribution}
        />
      </div>
    </div>
  );

  const ListEmptyState = () => {
    return (
      <div className="list-empty-apps-wrapper">
        <div className="detail-container">
          <img className="detail-img" src={listEmptyTeams} alt="No apps found!" />
          <p className="details-message bounce">
            Select one team/member to visualize the information.
          </p>
        </div>
      </div>
    );
  };

  const HeaderForm = ({ titleForm }) => {
    return <div className="activity-header bounce">{titleForm}</div>;
  };

  const createATeam = async (payload, isNewTeam, callback) => {
    if (isNewTeam) {
      const { success: teamInfoFlag } = await humansService.createHumanGroup(payload);
      if (teamInfoFlag) {
        setTeamSelected(null);
        showSnackbar('success', '', 'Team added!', 4000);
        handleCloseModal();
        return true;
      }
      showSnackbar('error', '', `Failed team creation!`, 5000);
      return false;
    }
    const { success: teamInfoFlag } = await humansService.updateHumanGroup(payload);
    if (teamInfoFlag) {
      setTeamSelected(null);
      showSnackbar('success', '', 'Team edited!', 4000);
      handleCloseModal();
      return true;
    }
    showSnackbar('error', '', `Failed team edition!`, 5000);
    return false;
  };

  const onClickMember = (value) => {
    console.log('onClickMember', value);
  };

  const ManagerData = () => {
    return !loading ? (
      <div className="manager-cls">
        {teamSelected ? (
          <TeamForm
            data={teamSelected}
            enginesRetrieved={enginesByWorkspace}
            handleSubmitTeam={createATeam}
          />
        ) : (
          <MemberForm
            data={memberSelected}
            handleSubmit={() => null}
            onClickMember={onClickMember}
          />
        )}
      </div>
    ) : (
      <div className="load-container">
        <Loader transparant type="feeder" />
      </div>
    );
  };

  return (
    <div className="engine-section">
      <div className="left-search">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <DebounceInput
            className="buttonFilter2"
            value={nameFilter}
            debounceTimeout={700}
            onChange={(e) => setNameFilter(e.target.value)}
            placeholder="Search"
            autoComplete="off"
          />
          {nameFilter && (
            <Button
              type="button"
              title="Clear engine"
              className="pi pi-trash adjust-trash2"
              onClick={() => setNameFilter('')}
            />
          )}
        </span>
        <Button
          className="add-ticket-btn"
          icon="pi pi-plus"
          onClick={(e) => {
            e.stopPropagation();
            const teamForm = (
              <TeamForm
                isNewTeam
                isEditing
                enginesRetrieved={enginesByWorkspace}
                handleSubmitTeam={createATeam}
              />
            );
            showModal(teamForm, <HeaderForm titleForm="Add new Team" />);
          }}
        />
        {/* <Button
            className="add-ticket-btn"
            label=""
            icon="pi pi-plus"
            style={{ margin: '0px' }}
            onClick={() => console.log('')}
          /> */}
      </div>
      <div className="flex-row" style={{ height: '100vh', alignItems: 'flex-start' }}>
        <div ref={leftSide} className="left-engine">
          <HIListing />
        </div>
        <div ref={rightSide} className="right-engine">
          <>{showDetails ? <ManagerData /> : <ListEmptyState />}</>
        </div>
      </div>
    </div>
  );
}

Settings.propTypes = {};

export default Settings;
