/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';

import { useHistory } from 'react-router-dom';

import loginLogo from '../../assets/images/HoundLogo.png';
import { Button, LoginHeader } from '../../components';
import { UpdateExternalPwd } from '../../forms';

import './UpdateUser.css';

const UpdateUser = ({ location }) => {
  const factoryInstance = useRef(null);
  const history = useHistory();
  const [shape, setShape] = useState(null);
  const [target, setTarget] = useState(null);
  const [loading, setLoading] = useState(false);
  const [onError, setOnError] = useState(false);
  const [isReady, setIsReady] = useState(false);

  const goHome = () => {
    history.push('/');
  };

  useEffect(() => {
    setTarget(location?.search || null);
    if (location?.pwd) {
      setShape(1);
    }
    // window.location.search = '';
  }, []);

  const Expired = () => {
    return (
      <div>
        <img src={loginLogo} alt="Login Logo" className="lHeaderImg bounce" />
        <h2>Expired sesion</h2>
        <p className="white-txt">Oops! It looks like your session has expired for your security.</p>
        <Button className="back-btn" label="Go back" onClick={goHome} />
      </div>
    );
  };

  const handleUpdateUser = (info) => {
    // console.log(info);
  };

  useEffect(() => {
    // factoryInstance.current = <></>;
    switch (shape) {
      case 0:
        break;
      case 1:
        factoryInstance.current = (
          <div className="update-container bounce">
            <div className="wrapper">
              <UpdateExternalPwd
                handleSubmit={() => {}}
                handleRecoveryUser={() => {}}
                data={{}}
                handleCloseCancel={goHome}
                handleSubmitUpdate={handleUpdateUser}
              />
            </div>
          </div>
        );
        break;
      default:
        factoryInstance.current = (
          <div className="expired-container bounce">
            <div className="wrapper">
              <Expired> </Expired>
            </div>
          </div>
        );
        break;
    }
    return () => {
      setShape(null);
    };
  }, [shape]);

  const handleSubmit = (email) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setOnError(true);
      setIsReady(true);
    }, 2000);
  };

  return factoryInstance.current;
};

export default UpdateUser;
