/* eslint-disable no-constant-condition */
/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';

import deleteIcon from '../../assets/images/delete.png';
import EditIcon from '../../assets/images/edit_modify_icon-icons.com_72390.png';
import GraphIcon from '../../assets/images/stats_icon.png';
import WorkflowIcon from '../../assets/images/Workflow_4803.png';
import thunderIcon from '../../assets/svgs/thunderstorms.svg';
import AppNameComponent from '../AppName/AppName';
import { Button, InputSwitch } from '../BasicComponents';

import './AppCard.css';

const HelpCircleIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="transparent"
  >
    <circle cx="12" cy="12" r="10" stroke="black" strokeWidth="1.5" />
    <path
      d="M10 9C10 7.89543 10.8954 7 12 7C13.1046 7 14 7.89543 14 9C14 9.39815 13.8837 9.76913 13.6831 10.0808C13.0854 11.0097 12 11.8954 12 13V13.5"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M11.992 17H12.001"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const AppCard = ({
  app,
  onAppEdit,
  onDelete,
  onHistorySelect,
  onGenerateSelected,
  onFlowSelected,
  backgroundColor,
  onChangeIsActive,
  showFlow = false,
  handleExternalWorkflowMsg,
  engine,
}) => {
  const CardHeader = () => (
    <div className="app-card-header">
      <div className={`app-card-header-left ${backgroundColor}`}>
        <AppNameComponent appName={app.name} />
      </div>
      <div className="app-card-header-right">
        <div className="app-card-header-right-up">
          <p className="header-right-up-name">{app.name}</p>
        </div>
      </div>
    </div>
  );

  const CardBody = () => (
    <div className="app-card-body">
      <div className="app-card-body-up">
        <div className="app-card-body-left">
          <p className="app-card-item app-card-subtitle">Owner</p>
          <p className="app-card-item">{app?.user?.name}</p>
          <p className="app-card-item" style={{ fontSize: '7px' }}>
            {moment(app?.creationDate).format('MM/D/YY, HH:mm')}
          </p>
        </div>
        <div className="app-card-body-right">
          <p className="app-card-item app-card-subtitle">Events</p>
          <p className="app-card-item">{app?.events?.length}</p>
        </div>
        <div className="app-card-body-right">
          <p className="app-card-item app-card-subtitle" style={{ marginLeft: '-12px' }}>
            App ID
          </p>
          <p className="app-id">{`${app.id}`}</p>
        </div>
      </div>
      {app?.description && (
        <div className="app-card-body-down">
          <p className="app-card-body-down-description">{app?.description}</p>
        </div>
      )}

      <div className="app-card-header-right-down">
        <div className="header-right-down-actions">
          {/* <Button
              disabled={app?.events?.length === 0}
              title="Generate Flow"
              className="p-button-outlined p-button-secondary user-opt-button2"
              onClick={() => {
                onGenerateSelected(app);
              }}
            >
              <img
                src={thunderIcon}
                alt="Menu Logo Hound Software"
                className="button-useropt-image"
                style={{ height: '25px', width: '25px' }}
              />
            </Button> */}
          <div className="delete-cls">
            <button type="button" className="delete-icon-btn" onClick={() => onDelete(app.id)}>
              <img src={deleteIcon} alt="Delete button" className="button-useropt-image" />
            </button>
          </div>
          {!showFlow || true ? (
            <Button
              title="App Flow"
              className="p-button-outlined p-button-secondary user-opt-button"
              onClick={() => {
                onFlowSelected(app, engine);
              }}
            >
              <img
                src={WorkflowIcon}
                alt="Menu Logo Hound Software"
                className="button-useropt-image2"
              />
            </Button>
          ) : (
            <Button
              title="App Flow"
              className="user-opt-button3"
              onClick={() => {
                handleExternalWorkflowMsg(app);
              }}
            >
              <HelpCircleIcon />
            </Button>
          )}
          {app?.statistics?.appsInstances !== 0 && (
            <Button
              title="App History"
              className="p-button-outlined p-button-secondary user-opt-button"
              onClick={() => {
                onHistorySelect(app);
              }}
            >
              <img
                src={GraphIcon}
                alt="Stats Logo Hound Software"
                className="button-useropt-image"
              />
            </Button>
          )}
          <Button
            title="Edit App"
            className="p-button-rounded p-button-outlined p-button-secondary user-opt-button"
            onClick={() => {
              onAppEdit(app);
            }}
          >
            <img src={EditIcon} alt="Menu Logo Hound Software" className="button-useropt-image" />
          </Button>
        </div>
        <InputSwitch
          style={{ marginRight: '7px' }}
          checked={app.isActive}
          onChange={(e) => onChangeIsActive(app.id, e.value)}
        />
      </div>
    </div>
  );

  const CardFooter = () => (
    <div className="app-card-footer">
      <div className="app-card-footer-item" title="# Active Days">
        <p className="app-card-item app-card-subtitle">Active Days</p>
        <p className="app-card-item">{app?.statistics?.active}</p>
      </div>
      <div className="separator white vertical-separator" />
      <div className="app-card-footer-item" title="# App Instances">
        <p className="app-card-item app-card-subtitle">Instances</p>
        <p className="app-card-item">{app?.statistics?.appsInstances}</p>
      </div>
      <div className="separator white vertical-separator" />
      <div className="app-card-footer-item" title="# Executed Points">
        <p className="app-card-item app-card-subtitle">Executed</p>
        <p className="app-card-item">{app?.statistics?.executedPoints}</p>
      </div>
    </div>
  );

  return (
    <div className="app-card-wrapper bounce">
      <CardHeader />
      <CardBody />
      <CardFooter />
    </div>
  );
};

AppCard.propTypes = {
  app: PropTypes.object.isRequired,
  onAppEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onHistorySelect: PropTypes.func.isRequired,
  onFlowSelected: PropTypes.func.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  onChangeIsActive: PropTypes.func.isRequired,
  onGenerateSelected: PropTypes.func.isRequired,
  showFlow: PropTypes.bool.isRequired,
  handleExternalWorkflowMsg: PropTypes.func.isRequired,
  engine: PropTypes.string.isRequired,
};

export default AppCard;
