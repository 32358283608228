import { httpRequest, wrap } from './tools';

const callExternal = async (payload) => {
  const fn = async () => {
    const url = `/call-external-api`;
    const response = await httpRequest(url, 'POST', payload, true);

    return response.data;
  };

  return wrap(fn);
};
// Mi Banco - Vendor

// eslint-disable-next-line import/prefer-default-export
export { callExternal };
