/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

import PropTypes from 'prop-types';

import DeleteEngineImg from '../../assets/svgs/deleteEngine.svg';
import { Button } from '../../components';

import './DeleteEngine.css';

const DeleteEngine = ({ data, handleCloseCancel, handleSubmit, loading }) => {
  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <section className="recovery-section">
        <img className="recovery-img" src={DeleteEngineImg} alt="forgot password" />
        <h2 className="disclosure-txt bounce">
          ¿Deseas eliminar el engine
          <span className="name-txt">{` ${data?.name} `}</span>
          de forma definitiva?
        </h2>
        <div className="keep-row">
          <Button className="recovery-cancel" label="Cancelar" onClick={handleCloseCancel} />
          <Button disabled className="recovery-primary" label="Si, eliminar" onClick={handleSubmit}>
            {loading && <div className="loader2" />}
          </Button>
        </div>
      </section>
    </form>
  );
};

DeleteEngine.propTypes = {
  data: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleCloseCancel: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default DeleteEngine;
