/* eslint-disable no-unused-vars */
import React from 'react';

import PropTypes from 'prop-types';

import { Button } from '../../components';

import './SupportTicketForm.css';

const SupportTicketForm = ({ handleSubmit, data }) => {
  return (
    <div className="support-wrapper">
      <p>Form is in progress</p>
      <div className="submit-button">
        <Button onClick={() => handleSubmit()}>Submit ticket</Button>
      </div>
    </div>
  );
};

SupportTicketForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
};

export default SupportTicketForm;
