/* eslint-disable import/order */
/* eslint-disable no-unused-vars */
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import './SubMenu.css';
import { Button } from '../../../components/BasicComponents';

import { AccountContext } from '../../../contexts/AccountContext';

function SubMenu() {
  const { accountInfo, logOut } = useContext(AccountContext);
  const { userData } = accountInfo;
  const history = useHistory();
  const HandleRedirection = () => {
    logOut();
    history?.push('/');
  };
  return (
    <div className="wrapper-submenu">
      <div className="wrapper-row">
        <p className="label-txt">Name: </p>
        <p className="value-txt">{userData.name}</p>
      </div>
      <div className="wrapper-row">
        <p className="label-txt">Correo: </p>
        <p className="value-txt">{userData.email}</p>
      </div>
      {/* <div className="action-submenu">
        <Button type="button" className="logout-btn" onClick={HandleRedirection}>
          Cerrar sesión
        </Button>
      </div> */}
    </div>
  );
}

export default SubMenu;
