/* eslint-disable no-unused-vars */
import React from 'react';

import PropTypes from 'prop-types';

import listEmptyImage from '../../assets/svgs/arts/cancel.svg';
import { Button } from '../../components';

import './BatchStopForm.css';

function BatchStopForm({ onExeApp, onCloseModal, batch }) {
  return (
    <div className="form-wrapper">
      <img className="stop-img" src={listEmptyImage} alt="Show a trash can" />
      <p className="conf-txt">Are you sure you want to stop the batch?</p>
      <div className="action-flex-row">
        <Button className="conf-close" onClick={onCloseModal} label="Cancel" />
        <Button className="primary-btn" onClick={onExeApp} label="Continue" />
      </div>
    </div>
  );
}

BatchStopForm.propTypes = {
  onExeApp: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  batch: PropTypes.object.isRequired,
};

export default BatchStopForm;
