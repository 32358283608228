/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState, useContext } from 'react';

import Moment from 'moment';
import PropTypes from 'prop-types';

import { TabView, TabPanel, TreeTable, Column } from '../../components';
import { AccountContext } from '../../contexts/AccountContext';
// import { ResponsiveContext } from '../../contexts/ResponsiveContext';
import useWindowSize from '../../hooks/useWindowSize';
import { casesDashboardService } from '../../services';
import './ViewCaseForm.css';

const ViewCaseForm = ({ data }) => {
  const info = useWindowSize();
  const { showSnackbar } = useContext(AccountContext);
  // const { isMobile, isTablet } = useContext(ResponsiveContext);
  const [logs, setLogs] = useState([]);

  const fetchLogs = async () => {
    const logsResult = await casesDashboardService.listCasesLogs(data.id);
    let i = 0;
    const results = [];
    const formatResults = [];

    if (logsResult.success && logsResult.data.length > 0) {
      for (i = 0; i < logsResult.data.length; i += 1) {
        results[i] = {};
        results[i].key = i + 1;
        results[i].data = logsResult.data[i];
      }
    } else {
      showSnackbar('error', 'Error to fetch logs!', '');
    }

    results.map((item) => {
      if (item.data.fieldChanged) {
        formatResults.push({
          key: item.key,
          data: {
            date: item.data.date,
            actionDescription: `The field ${item.data.fieldChanged} changed to ${item.data.newValue}.`,
          },
        });
      } else if (item.data.actionDescription === 'Create Case') {
        formatResults.push({
          key: item.key,
          data: {
            date: item.data.date,
            actionDescription: `The Case was created.`,
          },
        });
      } else {
        formatResults.push({
          key: item.key,
          data: {
            date: item.data.date,
            actionDescription: item.data.actionDescription,
          },
        });
      }
      return true;
    });
    setLogs(formatResults);
  };

  useEffect(async () => {
    fetchLogs();
  }, []);

  function renderCustomData(jsonData) {
    const itemList = [];
    if (jsonData) {
      Object.keys(jsonData)?.forEach((item) => {
        if (jsonData[item]) {
          itemList.push(
            <div className="flex app-formGroup">
              <label className="view-case-title">
                {item}
                &#58;&nbsp;
              </label>
              <p className="view-case-value">{jsonData[item]}</p>
            </div>
          );
        }
      });
    }
    return itemList;
  }

  function renderDate(date) {
    if (date) {
      return Moment(date).format('MM-DD-YYYY HH:mm');
    }
    return 'NaN';
  }

  return (
    <div className="view-case-tab-view">
      <TabView>
        <TabPanel header="Detail" headerClassName="bounce">
          <section className="tabs">
            <section className="app-form-section-2-columns">
              <div className="flex app-formGroup">
                <label className="view-case-title">Id</label>
                <p className="view-case-value">{data.id}</p>
              </div>
              <div className="flex app-formGroup">
                <label className="view-case-title">Operation</label>
                <p className="view-case-value">{data.operation.id}</p>
              </div>
              <div className="flex app-formGroup">
                <label className="view-case-title">Category</label>
                <p className="view-case-value">{data.category.id}</p>
              </div>
              <div className="flex app-formGroup">
                <label className="view-case-title">Creation Date</label>
                <p className="view-case-value">{renderDate(data.creationDate)}</p>
              </div>
              <div className="flex app-formGroup">
                <label className="view-case-title">Last Update</label>
                <p className="view-case-value">{renderDate(data.lastUpdate)}</p>
              </div>
              <div className="flex app-formGroup">
                <label className="view-case-title">Due Date</label>
                <p className="view-case-value">{renderDate(data.dueDate)}</p>
              </div>
              <div className="flex app-formGroup">
                <label className="view-case-title">Hold Until Date</label>
                <p className="view-case-value">{renderDate(data.holdUntilDate)}</p>
              </div>
              <div className="flex app-formGroup">
                <label className="view-case-title">Last Contact Date</label>
                <p className="view-case-value">{renderDate(data.lastContactDate)}</p>
              </div>
              <div className="flex app-formGroup">
                <label className="view-case-title">Closing Date</label>
                <p className="view-case-value">{renderDate(data.closingDate)}</p>
              </div>
              <div className="flex app-formGroup">
                <label className="view-case-title">Is open ?</label>
                <p className="view-case-value">{renderDate(data?.isOpen.toString())}</p>
              </div>
              <div className="flex app-formGroup">
                <label className="view-case-title">Amount</label>
                <p className="view-case-value">{data.amount}</p>
              </div>
              <div className="flex app-formGroup">
                <label className="view-case-title">Pending Payment</label>
                <p className="view-case-value">{data.pendingPayments}</p>
              </div>
              <div className="flex app-formGroup">
                <label className="view-case-title">Status</label>
                <p className="view-case-value">{data.status}</p>
              </div>
              <div className="flex app-formGroup">
                <label className="view-case-title">State</label>
                <p className="view-case-value">{data.state}</p>
              </div>
              <div className="flex app-formGroup">
                <label className="view-case-title">Name</label>
                <p className="view-case-value">{data.agentName}</p>
              </div>
              <div className="flex app-formGroup">
                <label className="view-case-title">Status Customer</label>
                <p className="view-case-value">{data.statusCustomer}</p>
              </div>
              <div className="flex app-formGroup">
                <label className="view-case-title">Assigned Human Group</label>
                <p className="view-case-value">{data.assignedHumanGroup.id}</p>
              </div>
              <div className="flex app-formGroup">
                <label className="view-case-title">Assigned Human</label>
                <p className="view-case-value">{data.assignedHuman.id}</p>
              </div>
              <div className="flex app-formGroup">
                <label className="view-case-title">Third Party</label>
                <p className="view-case-value">{data.thirdParty.id}</p>
              </div>
              {renderCustomData(data.customData)}
            </section>
          </section>
        </TabPanel>
        <TabPanel header="Logs" headerClassName="bounce">
          <div className="tabs">
            <TreeTable
              value={logs}
              resizableColumns
              emptyMessage="No logs found."
              rows={10}
              fontSize="10pt"
              scrollHeight={`${info.height * 0.2}px`}
              paginator
            >
              <Column
                field="date"
                header="Date"
                headerStyle={{
                  width: '9rem',
                  backgroundColor: '#F2F2F2',
                  color: 'black',
                  textAlign: 'left',
                }}
                style={{
                  fontSize: '13px',
                  textAlign: 'left',
                }}
              />
              <Column
                field="actionDescription"
                header="Action Description"
                headerStyle={{
                  width: '20rem',
                  backgroundColor: '#F2F2F2',
                  color: 'black',
                  textAlign: 'left',
                }}
                style={{ fontSize: '13px', textAlign: 'left' }}
              />
            </TreeTable>
          </div>
        </TabPanel>
      </TabView>
    </div>
  );
};

ViewCaseForm.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ViewCaseForm;
