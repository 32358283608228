import React from 'react';

import PropTypes from 'prop-types';

import appIcon from '../../assets/images/ai_gear_hound5.png';
import './AppName.css';

const AppNameComponent = ({ appName, isEdit }) => {
  return (
    <section id="appName">
      <img
        id="appIcon"
        className={`app-name__icon ${isEdit && 'app-name__icon2'}`}
        src={appIcon}
        alt="App Icon"
      />
      {isEdit ? <h3>{`Edit ${appName}`}</h3> : <></>}
    </section>
  );
};

AppNameComponent.defaultProps = {
  isEdit: false,
};

AppNameComponent.propTypes = {
  isEdit: PropTypes.bool,
  appName: PropTypes.string.isRequired,
};

export default AppNameComponent;
