/* eslint-disable no-unused-vars */
import React from 'react';

import PropTypes from 'prop-types';

import listEmptyImage from '../../assets/svgs/arts/version.svg';
import { Button } from '../../components';

import './BatchExeForm.css';

function BatchExeForm({ onExeApp, onCloseModal, batchID }) {
  return (
    <div className="form-wrapper">
      <img className="exe-img" src={listEmptyImage} alt="Show a trash can" />
      <p className="conf-txt">{`Are you sure you want to execute the batch ${batchID}?`}</p>
      <div className="action-flex-row">
        <Button className="conf-close" onClick={onCloseModal} label="Cancel" />
        <Button className="primary-btn" onClick={onExeApp} label="Continue" />
      </div>
    </div>
  );
}

BatchExeForm.propTypes = {
  onExeApp: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  batchID: PropTypes.func.isRequired,
};

export default BatchExeForm;
