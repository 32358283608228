import { httpRequest, wrap } from './tools';

const getAllApps = async (userId) => {
  const fn = async () => {
    // const url = `/workspaces/${workspaceId}/apps`;
    const url = `/users/${userId}/apps`;
    const response = await httpRequest(url, 'GET', {}, true);

    return response.data;
  };

  return wrap(fn);
};

const updateApp = async (id, app) => {
  const fn = async () => {
    const url = `/apps/${id}`;
    const response = await httpRequest(url, 'PATCH', app, true);

    return response.data;
  };

  return wrap(fn);
};

const deleteApp = async (id) => {
  const fn = async () => {
    const url = `/apps/${id}`;
    const response = await httpRequest(url, 'DELETE', null, true);
    if (response.status === 204) {
      return 'Generic';
    }
    return response.data;
  };

  return wrap(fn);
};

const createApp = async (user, app) => {
  const fn = async () => {
    const url = `/apps`;
    const request = {
      name: app.name,
      description: app.description,
      user: {
        id: user.id,
      },
      engine: {
        id: app.engine,
      },
      isActive: true,
      workflow: {
        id: '5e70fc16115990475b6e90f8',
      },
      calendar: {
        id: '5e6fde5b18a728191258f0ef',
      },
    };
    const response = await httpRequest(url, 'POST', request, true);
    if (response.status === 201) {
      return 'Created';
    }
    return response.data;
  };

  return wrap(fn);
};

const getCalendars = async (data) => {
  const fn = async () => {
    const url = `/flowtimer/calendar/names`;
    const response = await httpRequest(url, 'POST', data);

    return response.data;
  };

  return wrap(fn);
};

const listInstancesOfApp = async (appId, start, end, status) => {
  const fn = async () => {
    let url = `/apps/${appId}/instances?a=a`;
    url += start ? `&startDate=${start}` : '';
    url += end ? `&endDate=${end}` : '';
    url += status ? `&status=${status}` : '';
    const response = await httpRequest(url, 'GET', {}, true);

    return response.data;
  };

  return wrap(fn);
};

const listStagesOfInstanceOfApp = async (appId, instanceId) => {
  const fn = async () => {
    const url = `/apps/${appId}/instances/${instanceId}/stages`;
    const response = await httpRequest(url, 'GET', {}, true);

    return response.data;
  };

  return wrap(fn);
};

const getStageOfInstanceOfApp = async (appId, instanceId, stageId) => {
  const fn = async () => {
    const url = `/apps/${appId}/instances/${instanceId}/stages/${stageId}`;
    const response = await httpRequest(url, 'GET', {}, true);

    return response.data;
  };

  return wrap(fn);
};

const getInfoOfInstance = async (workflowId) => {
  const fn = async () => {
    const url = `/instances/${workflowId}`;
    const response = await httpRequest(url, 'GET', {}, true);

    return response.data;
  };

  return wrap(fn);
};

const getValuesOfInstance = async (workflowId) => {
  const fn = async () => {
    const url = `/instances/${workflowId}/values`;
    const response = await httpRequest(url, 'GET', {}, true);

    return response.data;
  };

  return wrap(fn);
};

// eslint-disable-next-line import/prefer-default-export
export {
  getAllApps,
  getCalendars,
  updateApp,
  deleteApp,
  createApp,
  listInstancesOfApp,
  listStagesOfInstanceOfApp,
  getStageOfInstanceOfApp,
  getInfoOfInstance,
  getValuesOfInstance,
};
