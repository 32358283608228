/* eslint-disable no-constant-condition */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useContext, useState } from 'react';

import { DebounceInput } from 'react-debounce-input';
import { useHistory } from 'react-router-dom';

import RefreshIcon from '../../assets/images/refresh.png';
import listEmptyTicketImage from '../../assets/svgs/archive.svg';
import MailIcon from '../../assets/svgs/mailbox.svg';
import { Accordion, Button, Loader, MultiSelect } from '../../components';
import { AccountContext } from '../../contexts/AccountContext';
import { ResponsiveContext } from '../../contexts/ResponsiveContext';
import { TicketsContext } from '../../contexts/TicketsContext';
import { EditATicketForm } from '../../forms';
import './HumanInterface.css';

const MyTickets = ({ isLoading }) => {
  const history = useHistory();
  const [selectedCasesGroups, setSelectedCasesGroups] = useState([]);
  const { accountInfo, showSnackbar, menuCollapse } = useContext(AccountContext);
  const { isMobile } = useContext(ResponsiveContext);
  const {
    setCases,
    assigned,
    unassigned,
    caseSelected,
    setCaseSelected,
    handleFilterCase,
    styleGuideByGroup,
    groupsRetrieved,
    casesByUser,
    onCreateATicket,
  } = useContext(TicketsContext);
  const { userData } = accountInfo || {};
  const { enabledModules, role } = userData || [];

  const defaultCaseState = {
    cases: [],
    assigned: [],
    unassigned: [],
    selected: {},
    loading: true,
  };

  // const validAdminUser = role === 'ADMIN';

  const [caseFilter, setCaseFilter] = useState('');
  const [disableClear, setDisableClear] = useState(false);
  const [filteredCases, setFilteredCases] = useState([]);

  useEffect(async () => {
    setTimeout(() => {
      if (selectedCasesGroups?.length || caseFilter !== '') {
        setDisableClear(false);
      } else {
        setDisableClear(true);
      }
    }, 200);
  }, [caseFilter, selectedCasesGroups]);

  const HeaderForm = ({ title, iconName = '' }) => {
    return <div className="activity-header">{title}</div>;
  };

  const filteredTeams = Array.isArray(groupsRetrieved)
    ? groupsRetrieved.filter((g) => {
        return !g?.name?.includes('MyBasket');
      })
    : [];

  const filterTemplate = (option) => {
    if (option.value === 2) {
      return (
        <Button className="refresh-basket" label={option.name} onClick={() => casesByUser()} />
      );
    }
    if (option.value === 1) {
      return (
        <Button
          className="button create-button"
          label={option.name}
          icon="pi pi-plus"
          onClick={() =>
            onCreateATicket({
              header: <HeaderForm title="Create a ticket" />,
              content: (
                <EditATicketForm
                  isNewTicket
                  caseInfo={[]}
                  groupsRetrieved={filteredTeams}
                  refresh={() => null}
                />
              ),
            })
          }
        />
      );
    }
    return <p className="chipFilter">{option.name}</p>;
  };

  const handleDistribution = [
    {
      id: 1,
      type: 'ticket',
      title: 'Assigned to me',
      titleMobile: 'Assigned',
      amount: assigned?.length || 0,
      content: assigned,
      legend: 'On progress!',
    },
    {
      id: 2,
      type: 'ticket',
      title: 'Pending',
      titleMobile: 'Pending',
      amount: unassigned?.length || 0,
      content: unassigned,
      legend: 'Waiting for you!',
    },
  ];

  const handleFilterDistribution = [
    {
      id: 1,
      title: 'Results',
      titleMobile: 'Results',
      type: 'ticket',
      amount: filteredCases?.length || 0,
      content: filteredCases,
      legend: 'Filter results!',
    },
  ];

  const groupTemplate = (c) => {
    return (
      <div className={`wrapper-groups `} style={{ background: c.colorTeam }}>
        <p className="groups">{c.name}</p>
      </div>
    );
  };

  const handleReset = () => {
    setSelectedCasesGroups([]);
    setCaseFilter('');
  };

  const handleFocusInput = () => {
    const input = document.getElementById('searchTicket-input');
    input?.focus();
  };

  useEffect(() => {
    const handleUserValidation = () => {
      const validUser = enabledModules?.some((data) => data.id === 'HUMAN INTERFACE');
      if (!validUser) {
        history.push('/404');
      }
    };
    handleUserValidation();
    casesByUser();
    setTimeout(() => {
      handleFocusInput();
    }, 2500);
    return () => {
      setCases(defaultCaseState);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      Object.keys(caseSelected).length === 0 && casesByUser();
    }, 15000);
    return () => {
      clearInterval(interval);
    };
  }, [caseSelected]);

  useEffect(async () => {
    setCaseSelected({});
    if (caseFilter !== '' || filteredTeams?.length > 0) {
      setFilteredCases(await handleFilterCase(caseFilter, selectedCasesGroups));
    } else {
      setFilteredCases([]);
    }
    setTimeout(() => {
      if (selectedCasesGroups?.length || caseFilter !== '') {
        setDisableClear(false);
      } else {
        setDisableClear(true);
      }
    }, 200);
  }, [caseFilter, selectedCasesGroups]);

  const HIListing = () => (
    <div className="HI-listing">
      {assigned.length !== 0 || !unassigned.length !== 0 ? (
        <>
          <div className="search-wrapper">
            <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <DebounceInput
                id="searchTicket-input"
                className="buttonFilter2"
                value={caseFilter}
                debounceTimeout={700}
                onChange={(e) => setCaseFilter(e.target.value)}
                placeholder="Case ID"
                autoComplete="off"
              />
              {/* <Button
                type="button"
                title="Clear ticket"
                className="pi pi-trash adjust-trash2"
                onClick={() => setCaseFilter('')}
              /> */}
            </span>
            <div className="group-filter-wrapper">
              {filteredTeams.length > 0 ? (
                <MultiSelect
                  optionLabel="name"
                  placeholder="Teams"
                  className="history-A-dropdown"
                  value={selectedCasesGroups}
                  options={filteredTeams || []}
                  onChange={(e) => {
                    if (e.value.length === 1) {
                      setSelectedCasesGroups([e.value[0]]);
                    } else {
                      e.value[1] && setSelectedCasesGroups([e.value[1]]);
                    }
                  }}
                  itemTemplate={groupTemplate}
                  maxSelectedLabels={2}
                />
              ) : (
                <></>
              )}
            </div>
            {!disableClear && (
              <Button
                title="Clear search"
                icon="pi pi-trash"
                className="history-A-clear"
                onClick={handleReset}
              />
            )}
            <Button
              title="Refresh tickets"
              className="refresh-basket2"
              onClick={() => {
                showSnackbar('success', '', '¡Tickets  refreshed!', 2000);
                casesByUser();
                handleReset();
              }}
            >
              <img className="refresh-img" src={RefreshIcon} alt="refresh tickets button" />
            </Button>
          </div>
          <Accordion
            items={
              caseFilter !== '' || selectedCasesGroups?.length > 0
                ? handleFilterDistribution
                : handleDistribution
            }
            headerColor="header-tickets-bg3"
          />
        </>
      ) : (
        <div className="empty-listing">
          <img className="empty-basket" src={listEmptyTicketImage} alt="empty basket" />
          Your inbox is empty: There are no tickets at this time
        </div>
      )}
    </div>
  );

  const handleRefresh = () => {
    showSnackbar('success', '', '¡Tickets  refreshed!', 2000);
    casesByUser();
    handleReset();
  };

  if (isLoading) {
    return (
      <div className="loader-wrapper">
        <Loader fit transparant type="feeder" />
      </div>
    );
  }

  return (
    <div className="HI-wrapper">
      {assigned?.length || unassigned?.length || filteredCases?.length ? (
        <div className="HI-container">
          <HIListing />
        </div>
      ) : (
        <div className="list-empty-wrapper bounce">
          <div className="detail-container">
            <div className="detail-wrapper">
              <img src={MailIcon} alt="mail empty" className="mail-img" />
              <p className="detail-message bounce">There are no more tickets in your inbox.</p>
              <Button title="Refresh tickets" className="refresh-txt" onClick={handleRefresh}>
                Refresh inbox
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MyTickets;
