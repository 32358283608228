/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useContext } from 'react';

import PropTypes from 'prop-types';
import { Carousel } from 'react-responsive-carousel';

import { Button } from '../../components';
import { AccountContext } from '../../contexts/AccountContext';
import './ThunderAppForm.css';

function ThunderAppForm({ data, handleSubmit }) {
  const { handleCloseModal } = useContext(AccountContext);
  return (
    <div className="thunder-form-wrapper">
      {/* <p className="thunder-subHeader">
        As a user I will have the possibility to trigger a new instance of the workflow by asking
        for:
      </p>
      <Carousel
        infiniteLoop
        transitionTime={400}
        showThumbs={false}
        showStatus={false}
        showArrows={false}
        selectedItem={0}
        emulateTouch
        // showArrows={false}
        swipeable
        swipeScrollTolerance={2}
      >
        <div className="thunder-step">
          <p className="thunder-label">1 o more instance(s) ?</p>
        </div>
        <div className="thunder-step">
          <p className="thunder-label">Event selection</p>
        </div>
        <div className="thunder-step">
          <p className="thunder-label">Required parameters that the start node receives</p>
        </div>
      </Carousel> */}
      {/* <StepperCmp data={data} /> */}
      <div className="flex-row" style={{ marginTop: '20px' }}>
        <Button className="user-form-button" label="Close" onClick={handleCloseModal} />
      </div>
    </div>
  );
}

ThunderAppForm.propTypes = {
  data: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default ThunderAppForm;
