/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

import PropTypes from 'prop-types';

import DeleteUserImg from '../../assets/svgs/deleteUser.svg';
import { Button } from '../../components';

import './DeleteMember.css';

const DeleteMember = ({ data, handleCloseCancel, handleSubmit, loading }) => {
  const onSubmit = () => {
    handleSubmit(data?.human_id);
  };
  return (
    <form>
      <section className="recovery-section">
        <img className="recovery-img" src={DeleteUserImg} alt="forgot password" />
        <h2 className="disclosure-txt bounce">
          ¿Do you want to delete the member
          <span className="name-txt">{` ${data?.name} ?`}</span>
        </h2>
        <div className="keep-row">
          <Button className="recovery-cancel" label="Cancelar" onClick={handleCloseCancel} />
          <Button
            type="button"
            className="recovery-primary"
            label="Si, eliminar"
            onClick={onSubmit}
          >
            {loading && <div className="loader2" />}
          </Button>
        </div>
      </section>
    </form>
  );
};

DeleteMember.propTypes = {
  data: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleCloseCancel: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default DeleteMember;
