/* eslint-disable no-underscore-dangle */
import { httpRequest, wrap } from './tools';

const createBatch = async (payload) => {
  const fn = async () => {
    const url = `/batches`;
    const response = await httpRequest(url, 'POST', payload, true);
    return response.data;
  };

  return wrap(fn);
};

const listBatchesByWorkspace = async () => {
  const fn = async () => {
    const url = `/batches`;
    const response = await httpRequest(url, 'GET', null, true);
    return response.data;
  };

  return wrap(fn);
};

const listBatchLines = async (batchId) => {
  const fn = async () => {
    const url = `/batches/${batchId}/lines`;
    const response = await httpRequest(url, 'GET', null, true);
    return response.data;
  };

  return wrap(fn);
};

const getBatch = async (id) => {
  const fn = async () => {
    const url = `/batches/${id}`;
    const response = await httpRequest(url, 'GET', null, true);
    return response.data;
  };

  return wrap(fn);
};

const updateBatch = async (data) => {
  const fn = async () => {
    const url = `/batches/${data.id}`;
    const response = await httpRequest(url, 'PATCH', data, true);

    return response.data;
  };

  return wrap(fn);
};

const deleteBatch = async (appId) => {
  const fn = async () => {
    const url = `/batches/${appId}`;
    const response = await httpRequest(url, 'DELETE', {}, true);

    return response.data;
  };

  return wrap(fn);
};

const executeBatch = async (appId) => {
  const fn = async () => {
    const url = `/batches/${appId}`;
    const response = await httpRequest(url, 'POST', {}, true);

    return response.data;
  };

  return wrap(fn);
};

const getBatchLine = async (batchId, lineId) => {
  const fn = async () => {
    const url = `/batches/${batchId}/lines/${lineId}`;
    const response = await httpRequest(url, 'GET', {}, true);

    return response.data;
  };

  return wrap(fn);
};

const updateBatchCase = async (caseId, payload) => {
  const fn = async () => {
    const url = `/cases/${caseId}`;
    const response = await httpRequest(url, 'PATCH', payload, true);

    return response.data;
  };

  return wrap(fn);
};

const deleteBatchLine = async (batchId, lineId) => {
  const fn = async () => {
    const url = `/batches/${batchId}/lines/${lineId}`;
    const response = await httpRequest(url, 'DELETE', {}, true);

    return response.data;
  };

  return wrap(fn);
};

const executeBatchLine = async (batchId, lineId) => {
  const fn = async () => {
    const url = `/batches/${batchId}/lines/${lineId}`;
    const response = await httpRequest(url, 'POST', {}, true);

    return response.data;
  };

  return wrap(fn);
};

const downloadBatch = async (batchId) => {
  const fn = async () => {
    const url = `/download/batches/${batchId}`;
    const response = await httpRequest(url, 'GET', {}, true);

    return response.data;
  };

  return wrap(fn);
};

// eslint-disable-next-line import/prefer-default-export
export {
  downloadBatch,
  createBatch,
  listBatchesByWorkspace,
  getBatch,
  updateBatch,
  deleteBatch,
  executeBatch,
  getBatchLine,
  updateBatchCase,
  deleteBatchLine,
  executeBatchLine,
  listBatchLines,
};
